import { Box } from "@mantine/core";
import { TickFormatter } from "@visx/axis";
import { localPoint } from "@visx/event";
import { ScaleInput, ScaleTypeToD3Scale } from "@visx/scale";
import { AreaStack, Bar } from "@visx/shape";
import { StackKey } from "@visx/shape/lib/types";
import { useTooltip } from "@visx/tooltip";
import { bisector } from "d3-array";
import { ScaleLinear, ScaleOrdinal, ScaleTime } from "d3-scale";
import { timeParse } from "d3-time-format";
import React, { ReactElement, useCallback } from "react";
import { AxisBottom, AxisLeft } from "../_utils/axes";
import { ChartDimensions, ChartDisplayOptions } from "../_utils/utils";
import { Legend } from "./legend";
import { StackedAreasReading, StackedAreasSeries } from "./stacked-areas";
import { Tooltip } from "./tooltip";

const getSeriesKeys = <T,>(series: StackedAreasSeries<T>[]): StackKey[] =>
    series.filter(({ active }) => active).map(({ key }) => key as StackKey);

const getFill = <T,>(series: StackedAreasSeries<T>[], stackKey: StackKey): string => {
    const i = series.findIndex(({ key }) => key === stackKey);
    const { fill } = series[i] ?? { fill: "pink" };
    return fill;
};

const getDate = <T extends StackedAreasReading>(d: T, dateFormat: string): number =>
    (timeParse(dateFormat)(d.date) as Date).valueOf();

type StackedAreasTemplateProps<T> = {
    dimensions: ChartDimensions;
    series: StackedAreasSeries<T>[];
    data: StackedAreasReading<T>[];
    axisColor: string;
    dateFormat: string;
    xScale: ScaleTime<number, number, never>;
    yScale: ScaleLinear<number, number, never>;
    colorScale: ScaleOrdinal<string, string, never>;
    events: boolean;
    displayOptions: ChartDisplayOptions;
    toggleActiveSeries: (code: string) => void;
    xTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["time"]>>;
    yTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["linear"]>>;
};

export const StackedAreasTemplate = <T extends Record<string, number>>({
    dimensions,
    axisColor,
    series,
    data,
    dateFormat,
    xScale,
    yScale,
    colorScale,
    events,
    displayOptions: { showAxes, showLegend, showTooltip },
    toggleActiveSeries,
    xTickFormatter,
    yTickFormatter,
}: StackedAreasTemplateProps<T>): ReactElement => {
    const { parentHeight, parentWidth, margin, innerHeight, innerWidth } = dimensions;
    const {
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip: showTooltip_,
    } = useTooltip<StackedAreasReading<T> | undefined>();

    const bisectDate = bisector<StackedAreasReading<T>, Date>((d) => new Date(d.date)).left;

    const handleTooltip = useCallback(
        (event: React.TouchEvent<SVGGElement> | React.MouseEvent<SVGGElement>) => {
            const { x, y }: { x: number; y: number } = localPoint(event) || { x: 0, y: 0 };
            const x0: Date = xScale.invert(x - margin.left);
            const index: number = bisectDate(data, x0, 1);
            const d0: StackedAreasReading<T> | undefined = data[index - 1];
            const d1: StackedAreasReading<T> | undefined = data[index];
            let d: StackedAreasReading<T> | undefined = d0;
            if (d1 && new Date(d1.date)) {
                d =
                    x0.valueOf() - new Date(d0!.date).valueOf() >
                    new Date(d1.date).valueOf() - x0.valueOf()
                        ? d1
                        : d0;
            }
            // eslint-disable-next-line no-magic-numbers
            const tooltipTop: number = y / (innerHeight ?? 0) < 0.5 ? y : y - 120;

            showTooltip_({
                tooltipData: d,
                tooltipLeft: x,
                tooltipTop: tooltipTop,
            });
        },
        [xScale, margin.left, bisectDate, data, innerHeight, showTooltip_],
    );
    return (
        <Box pos="relative">
            <svg width={parentWidth} height={parentHeight}>
                <g transform={`translate(${margin.left} ${margin.top})`}>
                    <AreaStack
                        top={margin.top}
                        left={margin.left}
                        keys={getSeriesKeys(series)}
                        data={data}
                        value={(d, key: StackKey): number => d.values[key] ?? 0}
                        x={(d): number => xScale(getDate(d.data, dateFormat)) ?? 0}
                        y0={(d): number => yScale(d[0]) ?? 0}
                        y1={(d): number => yScale(d[1]) ?? 0}
                    >
                        {({ stacks, path }): JSX.Element[] =>
                            stacks.map((stack) => {
                                const fill = getFill(series, stack.key);
                                return (
                                    <path
                                        key={`stack-${stack.key}`}
                                        d={path(stack) || ""}
                                        fill={fill}
                                        onClick={(): void => {
                                            if (events) {
                                                alert(`${stack.key}`);
                                            }
                                        }}
                                    />
                                );
                            })
                        }
                    </AreaStack>
                    {showAxes.left && (
                        <AxisLeft
                            axisColor={axisColor}
                            yScale={yScale}
                            tickFormat={yTickFormatter}
                            numTicks={4}
                        />
                    )}
                    {showAxes.bottom && (
                        <AxisBottom
                            axisColor={axisColor}
                            xScale={xScale}
                            kind="time"
                            top={innerHeight}
                            tickFormat={xTickFormatter}
                            numTicks={2}
                        />
                    )}
                    <Bar
                        width={innerWidth}
                        height={innerHeight}
                        fill="transparent"
                        onMouseMove={(e): void => {
                            showTooltip && handleTooltip(e);
                        }}
                        onMouseLeave={hideTooltip}
                    />
                </g>
            </svg>
            {showLegend && (
                <Legend
                    colorScale={colorScale}
                    series={series}
                    toggleActiveSeries={toggleActiveSeries}
                    dimensions={dimensions}
                />
            )}
            {showTooltip && (
                <Tooltip
                    series={series}
                    tooltipLeft={tooltipLeft}
                    tooltipTop={tooltipTop}
                    tooltipData={tooltipData}
                />
            )}
        </Box>
    );
};
