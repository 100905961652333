import { Box } from "@mantine/core";
import * as allCurves from "@visx/curve";
import { localPoint } from "@visx/event";
import { Grid } from "@visx/grid";
import { Group as VisxGroup } from "@visx/group";
import { Bar, BarStack, Line, LinePath } from "@visx/shape";
import { useTooltip } from "@visx/tooltip";
import { ScaleLinear, ScaleOrdinal, ScaleTime } from "d3-scale";
import { timeParse } from "d3-time-format";
import React, { ReactElement, useCallback } from "react";
import { halve, MagnitudeOptions } from "../../../../utils";
import { AxisBottom, AxisLeft, AxisRight, TickFormatter } from "../_utils/axes";
import { ChartDimensions, ChartDisplayOptions } from "../_utils/utils";
import { Legend } from "./legend";
import { TimeSeriesBaseReading, TimeSeriesSeries } from "./time-series-chart";
import { TimeSeriesChartTooltipData, Tooltip } from "./tooltip";

// accessors
const getStartDate = <T extends string>(strSpecifier: string, d: TimeSeriesBaseReading<T>): Date =>
    timeParse(strSpecifier)(d.startTimeLocal) || new Date("1970-01-01");
const getEndDate = <T extends string>(strSpecifier: string, d: TimeSeriesBaseReading<T>): Date =>
    timeParse(strSpecifier)(d.endTimeLocal) || new Date("1970-12-31");

type TimeSeriesChartTemplateProps<T extends string> = {
    dateScale: ScaleTime<number, number, never>;
    energyScale: ScaleLinear<number, number, never>;
    barSeriesSpacing: number;
    colorScale: ScaleOrdinal<T, string, never>;
    axisColor: string;
    activeSeriesKeys: T[];
    data: TimeSeriesBaseReading<T>[];
    allSeries: TimeSeriesSeries<T>[];
    barSeries: TimeSeriesSeries<T>[];
    activeBarSeriesKeys: T[];
    activeSeries: TimeSeriesSeries<T>[];
    timeParserSpecifier: string;
    lineSeries: TimeSeriesSeries<T>[];
    activeLineSeries: TimeSeriesSeries<T>[];
    dimensions: ChartDimensions;
    displayOptions: ChartDisplayOptions;
    toggleActiveSeries: (label: string) => void;
    yTickFormatter: TickFormatter;
    xTickFormatter: TickFormatter;
    tooltipDateFormatter: (startTime: Date, endTime: Date) => string;
    tooltipMagnitudeOptions?: MagnitudeOptions;
};
export const ChartTemplate = <T extends string>({
    dimensions,
    dateScale,
    energyScale,
    colorScale,
    data,
    axisColor,
    activeSeriesKeys,
    allSeries,
    barSeries,
    activeBarSeriesKeys,
    barSeriesSpacing,
    toggleActiveSeries,
    activeSeries,
    xTickFormatter,
    yTickFormatter,
    timeParserSpecifier,
    lineSeries,
    activeLineSeries,
    tooltipDateFormatter,
    displayOptions: { showAxes, showLegend, showTooltip, justifyLegend, showGrid },
    tooltipMagnitudeOptions,
}: TimeSeriesChartTemplateProps<T>): ReactElement => {
    const { innerHeight, innerWidth, margin, parentWidth, parentHeight } = dimensions;
    const {
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip: showTooltip_,
    } = useTooltip<TimeSeriesChartTooltipData<T>>();

    const handleTooltip = useCallback(
        (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
            const { x, y } = localPoint(event) || { x: 0, y: 0 };
            const x0 = dateScale.invert(x - margin.left + 1);

            const ds = data.find(
                (d) =>
                    (
                        timeParse(timeParserSpecifier)(d.startTimeLocal) ||
                        new Date(d.startTimeLocal)
                    ).getTime() < x0.getTime() &&
                    (
                        timeParse(timeParserSpecifier)(d.endTimeLocal) || new Date(d.endTimeLocal)
                    ).getTime() >= x0.getTime(),
            );
            if (ds) {
                // eslint-disable-next-line no-magic-numbers
                const tooltipTop = y / (innerHeight ?? 0) < 0.5 ? y : y - 120;

                showTooltip_({
                    tooltipData: { data: ds },
                    tooltipLeft: x,
                    tooltipTop: tooltipTop,
                });
            } else {
                hideTooltip();
            }
        },
        [dateScale, margin.left, data, innerHeight, showTooltip_, hideTooltip, timeParserSpecifier],
    );

    return (
        <Box pos="relative">
            <svg width={parentWidth} height={parentHeight}>
                {showGrid && (
                    <Grid
                        top={0}
                        left={margin.left}
                        xScale={dateScale}
                        yScale={energyScale}
                        width={innerWidth}
                        height={innerHeight}
                        stroke={axisColor}
                        strokeOpacity={0.1}
                        numTicksColumns={halve(data.length)}
                    />
                )}

                <VisxGroup left={margin.left} top={margin.top}>
                    {barSeries && (
                        <BarStack<TimeSeriesBaseReading<T>, T>
                            data={data}
                            keys={activeBarSeriesKeys}
                            x={(d): Date => getStartDate(timeParserSpecifier, d)}
                            value={(d, key: T): Record<T, number>[T] => d.values[key] ?? 0}
                            xScale={dateScale}
                            yScale={energyScale}
                            color={colorScale}
                        >
                            {(barStacks): JSX.Element[][] =>
                                barStacks.map(({ bars }, barStackIndex) =>
                                    bars.map(({ y, height, color, index, bar: { data } }) => {
                                        const width =
                                            dateScale(getEndDate(timeParserSpecifier, data)) -
                                            dateScale(getStartDate(timeParserSpecifier, data)) -
                                            barSeriesSpacing;
                                        const x =
                                            dateScale(getStartDate(timeParserSpecifier, data)) +
                                            (barSeriesSpacing > 0 ? halve(barSeriesSpacing) : 0);

                                        return (
                                            <rect
                                                key={`bar-stack-${barStackIndex}-${index}`}
                                                x={x}
                                                y={y}
                                                height={height}
                                                width={width}
                                                fill={color}
                                            />
                                        );
                                    }),
                                )
                            }
                        </BarStack>
                    )}
                    {showAxes.left && (
                        <AxisLeft
                            axisColor={axisColor}
                            yScale={energyScale}
                            tickFormat={yTickFormatter}
                            numTicks={2}
                        />
                    )}
                    {showAxes.right && (
                        <AxisRight
                            innerWidth={innerWidth}
                            axisColor={axisColor}
                            yScale={energyScale}
                            tickFormat={yTickFormatter}
                            numTicks={2}
                        />
                    )}
                    {showAxes.bottom && (
                        <AxisBottom
                            kind="time"
                            top={innerHeight}
                            xScale={dateScale}
                            tickFormat={xTickFormatter}
                            axisColor={axisColor}
                            numTicks={3}
                            textAnchor="start"
                        />
                    )}
                    {lineSeries && (
                        <>
                            {activeLineSeries.map((series) => {
                                return (
                                    <LinePath
                                        key={series.key}
                                        width={innerWidth}
                                        height={innerHeight}
                                        curve={allCurves.curveMonotoneX}
                                        data={data}
                                        x={(d): number =>
                                            dateScale(getStartDate(timeParserSpecifier, d)) +
                                                (dateScale(getEndDate(timeParserSpecifier, d)) -
                                                    dateScale(
                                                        getStartDate(timeParserSpecifier, d),
                                                    ) -
                                                    1) /
                                                    // eslint-disable-next-line no-magic-numbers
                                                    2 || 0
                                        }
                                        y={(d): number => energyScale(d.values[series.key] ?? 0)}
                                        stroke={series.color}
                                        strokeWidth={2}
                                        strokeOpacity={1}
                                        shapeRendering="geometricPrecision"
                                        strokeDasharray="5,3" // 5 units length, 3 units space
                                    />
                                );
                            })}
                            <Bar
                                width={innerWidth}
                                height={innerHeight}
                                fill="transparent"
                                onMouseMove={(e): void => {
                                    showTooltip && handleTooltip(e);
                                }}
                                onMouseLeave={hideTooltip}
                            />
                        </>
                    )}

                    {tooltipData && (
                        <Line
                            from={{ x: tooltipLeft ? tooltipLeft - margin.left : 0, y: 0 }}
                            to={{ x: tooltipLeft ? tooltipLeft - margin.left : 0, y: innerHeight }}
                            stroke={axisColor}
                            strokeWidth={1}
                            pointerEvents="none"
                        />
                    )}
                </VisxGroup>
            </svg>

            {showLegend && (
                <Legend
                    justifyLegend={justifyLegend}
                    colorScale={colorScale}
                    activeSeriesKeys={activeSeriesKeys}
                    allSeries={allSeries}
                    toggleActiveSeries={toggleActiveSeries}
                    dimensions={dimensions}
                />
            )}
            {showTooltip && (
                <Tooltip
                    tooltipLeft={tooltipLeft}
                    tooltipTop={tooltipTop}
                    tooltipData={tooltipData}
                    activeSeries={activeSeries}
                    tooltipDateFormatter={tooltipDateFormatter}
                    tooltipMagnitudeOptions={tooltipMagnitudeOptions}
                />
            )}
        </Box>
    );
};
