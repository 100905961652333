import { formatNumber } from "./format-number";

const DEFAULT_DECIMALS = 2;

export const formatKpi = (val: number, decimals: number = DEFAULT_DECIMALS): number | string => {
    if (!isFinite(val)) {
        return "N/A";
    }
    if (isNaN(val)) {
        return formatNumber(0, decimals);
    }
    return formatNumber(val, decimals);
};
