import { FetchError, isFetchError } from "@flexidao/api-client";
import { NotificationProps, notifications } from "@mantine/notifications";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactElement } from "react";
import { isJSXElement } from "@flexidao/ui-lib/query-wrappers/utils";
import { QueryWrapperSkeletonArgs } from "./types";

type QueryWrapperProps<T> = {
    query: UseQueryResult<T | FetchError>;
    errorNotification?: NotificationProps;
    skeleton: ReactElement | ((args: QueryWrapperSkeletonArgs) => ReactElement);
    content: (data: T) => ReactElement;
    error: ReactElement;
};

export const QueryWrapper = <T>({
    content,
    error,
    query,
    skeleton,
    errorNotification,
}: QueryWrapperProps<T>): ReactElement => {
    if (skeleton && (query.isLoading || query.isFetching)) {
        if (isJSXElement(skeleton)) {
            return skeleton;
        }
        return skeleton(query);
    }
    if (
        query.isError ||
        isFetchError(query.data) ||
        query.data === undefined ||
        query.data === null
    ) {
        console.error("Query error", query.data);
        if (errorNotification) {
            notifications.show(errorNotification);
        }
        return error;
    }
    return content(query.data);
};
