import * as D from "schemawax";

import {
    AssetCommissioningDate,
    CfeDiagnosticDto,
    ContractStartDate,
    EstimationStatus,
} from "@flexidao/dto";
import { exhaustiveCheck, parseIntSafe } from "@flexidao/helpers";
import {
    cfeDiagnosticContractTypeDecoder,
    dataAccessDecoder,
    dateDecoder,
    energySourceDecoder,
    industryTypeDecoder,
} from "./misc";

export const estimationStatusDecoder: D.Decoder<EstimationStatus> = D.literalUnion(
    EstimationStatus.Pending,
    EstimationStatus.Processing,
    EstimationStatus.Ready,
);

export const consumptionDataDecoder: D.Decoder<CfeDiagnosticDto.ConsumptionData> = D.oneOf(
    D.object({
        required: {
            kind: D.literal(CfeDiagnosticDto.DataSource.Estimated),
            yearlyConsumptionMWh: D.number,
        },
    }),
    D.object({
        required: {
            kind: D.literal(CfeDiagnosticDto.DataSource.Uploaded),
            uploadedConsumption_kWh: D.array(D.number).andThen((value) => {
                if (value.length === 8760) {
                    for (const consumption of value) {
                        if (isNaN(parseIntSafe(consumption.toString()))) {
                            throw new D.DecoderError(
                                `Invalid uploaded data value '${consumption}'.`,
                            );
                        }
                    }
                    return value;
                } else {
                    throw new D.DecoderError(`Invalid uploaded data length '${value.length}'.`);
                }
            }),
        },
    }),
);

const zoneConsumptionDecoder: D.Decoder<CfeDiagnosticDto.Consumption> = D.object({
    required: {
        industryTypeId: industryTypeDecoder,
        data: consumptionDataDecoder,
    },
});

export const contractDataDecoder: D.Decoder<CfeDiagnosticDto.ContractData> = D.oneOf(
    D.object({
        required: {
            kind: D.literal(CfeDiagnosticDto.DataSource.Estimated),
            yearlyCoverageMWh: D.number,
        },
    }),
    D.object({
        required: {
            kind: D.literal(CfeDiagnosticDto.DataSource.Uploaded),
            uploadedProduction_kWh: D.array(D.number).andThen((value) => {
                if (value.length === 8760) {
                    for (const production of value) {
                        if (isNaN(parseIntSafe(production.toString()))) {
                            throw new D.DecoderError(
                                `Invalid uploaded data value '${production}'.`,
                            );
                        }
                    }
                    return value;
                } else {
                    throw new D.DecoderError(`Invalid uploaded data length '${value.length}'.`);
                }
            }),
        },
    }),
);

const contractStartDateDecoder: D.Decoder<ContractStartDate> = D.string.andThen(
    (value: string): ContractStartDate => {
        const castedValue = value as ContractStartDate;
        switch (castedValue) {
            case ContractStartDate.Before2024:
            case ContractStartDate.After2024:
                return castedValue;
            default:
                return exhaustiveCheck(
                    castedValue,
                    `Unknown contract start date option '${castedValue}'`,
                );
        }
    },
);
const assetCommissioningDateDecoder: D.Decoder<AssetCommissioningDate> = D.string.andThen(
    (value: string): AssetCommissioningDate => {
        const castedValue = value as AssetCommissioningDate;
        switch (castedValue) {
            case AssetCommissioningDate.Unknown:
            case AssetCommissioningDate.Less15YearsAgo:
            case AssetCommissioningDate.EqualOrMore15YearsAgo:
                return castedValue;
            default:
                return exhaustiveCheck(
                    castedValue,
                    `Unknown asset commissioning date option '${castedValue}'`,
                );
        }
    },
);

export const zoneContractDecoder: D.Decoder<CfeDiagnosticDto.Contract> = D.object({
    required: {
        contractTypeId: cfeDiagnosticContractTypeDecoder,
        energySourceId: energySourceDecoder,
        data: contractDataDecoder,
        isKnownAsset: D.boolean,
        countryOfProductionId: D.string,
        zoneOfProductionId: D.string,
    },
    optional: {
        contractStartDate: contractStartDateDecoder,
        assetCommissioningDate: assetCommissioningDateDecoder,
    },
});

export const inputZoneDecoder: D.Decoder<{
    countryId: string;
    zoneId: string;
    consumption: Array<CfeDiagnosticDto.Consumption>;
    contracts: Array<CfeDiagnosticDto.Contract>;
}> = D.object({
    required: {
        countryId: D.string,
        zoneId: D.string,
        consumption: D.array(zoneConsumptionDecoder),
        contracts: D.array(zoneContractDecoder),
    },
});

export const estimationInputDecoder: D.Decoder<CfeDiagnosticDto.EstimationInput> = D.object({
    required: {
        name: D.string,
        zones: D.array(inputZoneDecoder),
    },
});

export const hourlyAverageDecoder: D.Decoder<CfeDiagnosticDto.HourlyAverage> = D.object({
    required: {
        contractedCfe_Wh: D.number,
        renewableMix_Wh: D.number,
        nonCfeGridMix_Wh: D.number,
        excess_Wh: D.number,
        consumption_Wh: D.number,
    },
});

export const outputZoneDecoder: D.Decoder<CfeDiagnosticDto.ZoneEstimation> = D.object({
    required: {
        zoneId: D.string,
        zoneName: D.string,
        countryId: D.string,
        countryName: D.string,
        dataAccess: dataAccessDecoder,
        cfeScore: D.number,
        contractedCfeScore: D.number,
        re100Score: D.number,
        totalContracted_Wh: D.number,
        inducedEmissions_gCO2: D.number,
        avoidedEmissions_gCO2: D.number,
        totalConsumption_Wh: D.number,
        hourlyAverages24h: D.array(hourlyAverageDecoder),
        hourlyCfeScores8760h: D.array(D.nullable(D.number)),
        hourlyLocationBasedCo2Emissions_gCO2: D.number,
        yearlyLocationBasedCo2Emissions_gCO2: D.number,
        hourlyMarketBasedCo2Emissions_gCO2: D.number,
        yearlyMarketBasedCo2Emissions_gCO2: D.number,
    },
});

export const estimationOutputDecoder: D.Decoder<CfeDiagnosticDto.EstimationOutput> = D.object({
    required: {
        zones: D.array(outputZoneDecoder),
    },
});

export const estimationDecoder: D.Decoder<CfeDiagnosticDto.Estimation> = D.object({
    required: {
        name: D.string,
        input: estimationInputDecoder,
        tenantId: D.string,
        updated: dateDecoder,
        status: estimationStatusDecoder,
        estimationId: D.string,
    },
    optional: {
        deleted: dateDecoder,
        output: D.nullable(estimationOutputDecoder),
    },
});

export const estimationBriefDecoder: D.Decoder<CfeDiagnosticDto.EstimationBrief> = D.object({
    required: {
        estimationId: D.string,
        name: D.string,
        status: estimationStatusDecoder,
        updated: dateDecoder,
    },
});

export const estimationBriefArrayDecoder: D.Decoder<Array<CfeDiagnosticDto.EstimationBrief>> =
    D.array(estimationBriefDecoder);

export const getAllEstimationsDecoder: D.Decoder<CfeDiagnosticDto.EstimationGetAll> = D.object({
    required: {
        totalPages: D.number,
        estimations: estimationBriefArrayDecoder,
    },
});
