import {
    InferApiFunctionParams,
    InferApiFunctionReturn,
    ReportHubDto,
    reportHubPaths,
} from "@flexidao/dto";
import * as D from "schemawax";
import { paths } from "../../../dto/src/schemas/report-hub-api-schema";
import { ApiClient, InferParams } from "../api-client";
import { featureFlagDecoder, reportHubDecoders } from "../decoders";
import {
    allocationFiltersDecoder,
    countryAssignmentResponseDecoder,
    postAllocationsByTenantIdResponseDecoder,
    postAssignmentResponse,
    reportingKpisDecoder,
} from "../decoders/report-hub";
import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash } from "../utils";
import { reportHubDecoders as reportHubDecodersV2 } from "../v2/decoders";

export type BaseArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
};

export type GetContractTrackingOverviewPpaArgs = BaseArgs & {
    startMonthLocal: string;
    endMonthLocal: string;
    regionIds?: Array<string> | null;
};

export type GetContractTrackingOverviewGreenTariffArgs = BaseArgs & {
    calendarYear: number;
    regionIds?: Array<string> | null;
};

export type GetContractTrackingOverviewUnbundledArgs = BaseArgs & {
    reportingPeriodYear: number;
    unbundledContractIds?: Array<string> | null;
};

export type GetContractTrackingRegionsPpaArgs = BaseArgs & {
    startMonthLocal: string;
    endMonthLocal: string;
};

export type GetContractTrackingRegionsDirectPpaArgs = BaseArgs & {
    startMonthLocal: string;
    endMonthLocal: string;
};

export type GetContractTrackingRegionsGreenTariffArgs = BaseArgs & { calendarYear: number };

export type GetContractTrackingFiltersUnbundledArgs = BaseArgs & { reportingPeriodYear: number };

export type GetContractTrackingPpaContractsArgs = BaseArgs & {
    regionIds?: Array<string> | null;
    startMonthLocal: string;
    endMonthLocal: string;
};
export type GetContractTrackingGreenTariffContractsArgs = BaseArgs & {
    regionIds?: Array<string> | null;
    calendarYear: number;
};

export type GetContractTrackingMonthlyPpaArgs = BaseArgs & {
    startMonthLocal: string;
    endMonthLocal: string;
    contract?: string;
    site?: string;
    regionIds?: Array<string> | null;
};

export type GetContractTrackingMonthlyDirectPpaArgs = BaseArgs & {
    startMonthLocal: string;
    endMonthLocal: string;
    contract?: string;
    regionIds?: Array<string> | null;
};

export type GetContractTrackingMonthlyGreenTariffArgs = BaseArgs & {
    calendarYear: number;
    contract?: string;
    regionIds?: Array<string> | null;
};

export type GetAllocationsByTenantIdArgs = BaseArgs & {
    page?: number;
    pageSize?: number;
    orderBy?: ReportHubDto.GetAllocationsOrderBy;
    orderDirection?: "asc" | "desc";
    transactionIdFilter?: Array<string>;
    productionSiteNameFilter?: Array<string | null>;
    allocatedToNameFilter?: Array<string | null>;
    productionCountryFilter?: Array<string | null>;
    energySourceIdFilter?: Array<string | null>;
    registryIdFilter?: Array<string | null>;
};

export type GetAllocationsSummaryByTenantIdArgs = BaseArgs;

export type GetLocalContractsByContractIdByTenantIdArgs = BaseArgs & {
    contractId: string;
};

export type PutLocalContractsByContractIdByTenantIdArgs = BaseArgs &
    ReportHubDto.UpdateLocalContractPayload & {
        contractId: string;
    };

export type GetContractsByTenantIdArgs = BaseArgs;

export type GetGlobalOverviewCountriesCoverageArgs = BaseArgs & {
    year: number;
    countryIds?: Array<string> | null;
};

export type GetGlobalOverviewCountriesPerformanceArgs = BaseArgs & {
    reportingYear: number;
    countryIds?: Array<string> | null;
    groupIds?: Array<string> | null;
};

export type GetGlobalOverviewGroupsPerformanceArgs = BaseArgs & {
    reportingYear: number;
    countryIds?: Array<string> | null;
    groupIds?: Array<string> | null;
};

export type GetGlobalOverviewContractsPerformanceArgs = BaseArgs & {
    reportingYear: number;
    countryIds?: Array<string> | null;
    groupIds?: Array<string> | null;
};

export type GetGlobalOverviewKpisArgs = BaseArgs & {
    reportingYear: number;
    countryIds?: Array<string> | null;
    groupIds?: Array<string> | null;
};

export type GetGlobalOverviewFiltersCountriesArgs = BaseArgs & {
    reportingYear: number;
};
export type GetGlobalOverviewFiltersGroupsArgs = BaseArgs & {
    reportingYear: number;
    countryIds?: Array<string> | null;
};

export type GetGlobalOverviewCountriesArgs = BaseArgs;
export type GetReportingPeriodsArgs = BaseArgs;

export type GetAssignmentRegionAssignmentByRegionIdByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/region-assignment/{regionId}"
>;

export type GetAssignmentRegionAssignmentByRegionIdByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/region-assignment/{regionId}"
>;

export type GetAssignmentRegionAssignmentByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/region-assignment"
>;

export type GetAssignmentRegionAssignmentByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/region-assignment"
>;

export type GetSmartAssignmentByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment/smart-assignment"
>;

export type GetSmartAssignmentByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment/smart-assignment"
>;

export type GetAssignmentTrackingInstrumentAssignmentByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment"
>;

export type GetAssignmentTrackingInstrumentAssignmentByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment"
>;

export type GetAssignmentTrackingInstrumentStateByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-state"
>;

export type GetAssignmentTrackingInstrumentStateByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-state"
>;

export type GetAssignmentReportingPeriodByYearByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/reporting-period/{year}"
>;

export type GetAssignmentReportingPeriodByYearByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/reporting-period/{year}"
>;

export type PostAssignmentTrackingInstrumentAssignmentByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment",
    "post"
>;

export type PostAssignmentTrackingInstrumentAssignmentByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment",
    "post",
    201
>;

export type GetPublicCountriesParams = InferApiFunctionParams<reportHubPaths, "/public/countries">;

export type GetPublicCountriesReturn = InferApiFunctionReturn<reportHubPaths, "/public/countries">;

export type GetPublicCountryNameByCountryIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/public/country-name/{countryId}"
>;
export type GetPublicCountryNameByCountryIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/public/country-name/{countryId}"
>;

export type GetPublicRegionsByRegionIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/public/regions/{regionId}"
>;
export type GetPublicRegionsByRegionIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/public/regions/{regionId}"
>;

export type GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdParams =
    InferApiFunctionParams<
        reportHubPaths,
        "/{tenantId}/assignment/tracking-instruments/available-filters"
    >;

export type GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdReturn =
    InferApiFunctionReturn<
        reportHubPaths,
        "/{tenantId}/assignment/tracking-instruments/available-filters"
    >;

export type GetLocalTenantParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/local-tenants"
>;

export type GetLocalTenantReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/local-tenants"
>;

export type GetConsumptionSiteGroupsByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/consumption-site-groups"
>;

export type GetConsumptionSiteGroupsByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/consumption-site-groups"
>;

export type PostAllocationsByTenantIdBody = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/allocations",
    "post"
>;

export type PostAllocationsByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/allocations",
    "post",
    201
>;

export type GetAssignmentCountryAssignmentReportingListByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/country-assignment/reporting-list",
    "get",
    200
>;

export type GetAssignmentCountryAssignmentReportingListByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/country-assignment/reporting-list",
    "get"
>;
export type GetReportingDashboardInsightsByReportingPeriodByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/reporting-dashboard/insights/{reportingPeriod}"
>;

export type GetReportingDashboardInsightsByReportingPeriodByTenantIdReturn =
    ReportHubDto.ReportingInsights;

export type GetReportingDashboardAssignmentInsightsByReportingPeriodByTenantIdParams =
    InferApiFunctionParams<
        reportHubPaths,
        "/{tenantId}/reporting-dashboard/assignment/insights/{reportingPeriod}"
    >;

export type GetReportingDashboardAssignmentInsightsByReportingPeriodByTenantIdReturn =
    ReportHubDto.ReportingInsights;

export type GetReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantIdParams =
    InferApiFunctionParams<
        reportHubPaths,
        "/{tenantId}/reporting-dashboard/countries/{countryId}/insights/{reportingPeriod}"
    >;

export type GetReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantIdReturn =
    ReportHubDto.ReportingInsights;

export type GetReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantIdParams =
    InferApiFunctionParams<
        reportHubPaths,
        "/{tenantId}/reporting-dashboard/assignment/regions/{regionId}/insights/{reportingPeriod}"
    >;

export type GetReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantIdReturn =
    ReportHubDto.ReportingInsights;

export type GetContractTrackingPpaKpisArgs = BaseArgs & {
    regionIds?: Array<string> | null;
    startMonthLocal: string;
    endMonthLocal: string;
};

export type GetContractTrackingDirectPpaKpisArgs = BaseArgs & {
    regionIds?: Array<string> | null;
    startMonthLocal: string;
    endMonthLocal: string;
};

export type GetContractTrackingGreenTariffKpisArgs = BaseArgs & {
    calendarYear: number;
    regionIds?: Array<string> | null;
};
export type GetContractTrackingUnbundledKpisArgs = BaseArgs & {
    reportingPeriodYear: number;
    unbundledContractIds?: Array<string> | null;
};

export type OverloadReportHubClient = {
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<string>>;
    getContractTrackingOverviewPpaByTenantId: (
        args: GetContractTrackingOverviewPpaArgs,
    ) => OverloadApiResponse<Array<ReportHubDto.ContractTrackingOverviewPpaRow>>;
    getContractTrackingOverviewDirectPpaByTenantId: (
        args: GetContractTrackingOverviewPpaArgs,
    ) => OverloadApiResponse<Array<ReportHubDto.DirectPpaContractTrackingOverviewRow>>;
    getContractTrackingOverviewGreenTariffByTenantId: (
        args: GetContractTrackingOverviewGreenTariffArgs,
    ) => OverloadApiResponse<Array<ReportHubDto.ContractTrackingOverviewGreenTariffRow>>;
    getContractTrackingOverviewUnbundledByTenantId: (
        args: GetContractTrackingOverviewUnbundledArgs,
    ) => OverloadApiResponse<Array<ReportHubDto.ContractTrackingOverviewUnbundledRow>>;
    getContractTrackingMonthlyPpaByTenantId: (
        args: GetContractTrackingMonthlyPpaArgs,
    ) => OverloadApiResponse<Array<ReportHubDto.ContractTrackingMonthlyPpaRow>>;
    getContractTrackingMonthlyDirectPpaByTenantId: (
        args: GetContractTrackingMonthlyDirectPpaArgs,
    ) => OverloadApiResponse<Array<ReportHubDto.ContractTrackingMonthlyDirectPpaRow>>;
    getContractTrackingMonthlyGreenTariffByTenantId: (
        args: GetContractTrackingMonthlyGreenTariffArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingMonthlyGreenTariff>;
    getContractTrackingRegionsPpaByTenantId: (
        args: GetContractTrackingRegionsPpaArgs,
    ) => OverloadApiResponse<ReportHubDto.GetRegions>;
    getContractTrackingRegionsDirectPpaByTenantId: (
        args: GetContractTrackingRegionsDirectPpaArgs,
    ) => OverloadApiResponse<ReportHubDto.GetRegions>;
    getContractTrackingRegionsGreenTariffByTenantId: (
        args: GetContractTrackingRegionsGreenTariffArgs,
    ) => OverloadApiResponse<ReportHubDto.GetRegions>;
    getContractTrackingFiltersUnbundledByTenantId: (
        args: GetContractTrackingFiltersUnbundledArgs,
    ) => OverloadApiResponse<Array<ReportHubDto.UnbundledContractOption>>;
    getContractTrackingContractsGreenTariffByTenantId: (
        args: GetContractTrackingGreenTariffContractsArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingContractOptions>;
    getContractTrackingContractsPpaByTenantId: (
        args: GetContractTrackingPpaContractsArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingContractOptions>;
    getContractTrackingContractsDirectPpaByTenantId: (
        args: GetContractTrackingPpaContractsArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingContractOptions>;
    getAllocationsByTenantId: (
        args: GetAllocationsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.GetAllocationsByTenantIdResponse>;
    getAllocationKpisByTenantId: (
        args: GetAllocationsSummaryByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.AllocationKpis>;
    postAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        params: PostAllocationsByTenantIdBody,
    ) => OverloadApiResponse<PostAllocationsByTenantIdReturn>;
    getContractsByTenantId: (
        args: GetContractsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.GetContractsResponse>;
    getLocalContractsByContractIdByTenantId: (
        args: GetLocalContractsByContractIdByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.LocalContract>;
    putLocalContractsByContractIdByTenantId: (
        args: PutLocalContractsByContractIdByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.LocalContract>;
    getEacCodesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => OverloadApiResponse<Array<ReportHubDto.EacCode>>;
    getAssignmentCountryAssignmentByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        countryId: string;
        year: number;
        page: number | null;
        sortField: ReportHubDto.CountryAssignmentsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
        groups: Array<string> | null;
    }) => OverloadApiResponse<ReportHubDto.CountryAssignmentsResponse>;
    getAssignmentKpisByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        groups: Array<string> | null;
    }) => OverloadApiResponse<ReportHubDto.GetReportingKpisResponse>;

    getAssignmentRegionAssignmentByRegionIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentRegionAssignmentByRegionIdByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentRegionAssignmentByRegionIdByTenantIdReturn>;

    getAssignmentRegionAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentRegionAssignmentByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentRegionAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentSmartAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSmartAssignmentByTenantIdParams,
    ) => OverloadApiResponse<GetSmartAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentStateByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentStateByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentTrackingInstrumentStateByTenantIdReturn>;

    postAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: PostAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => OverloadApiResponse<PostAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentReportingPeriodByYearByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentReportingPeriodByYearByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentReportingPeriodByYearByTenantIdReturn>;

    getPublicCountries: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountriesParams,
    ) => OverloadApiResponse<GetPublicCountriesReturn>;

    getPublicCountryNameByCountryId: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountryNameByCountryIdParams,
    ) => OverloadApiResponse<GetPublicCountryNameByCountryIdReturn>;

    getPublicRegionsByRegionId: (
        getAccessToken: () => Promise<string>,
        args: GetPublicRegionsByRegionIdParams,
    ) => OverloadApiResponse<GetPublicRegionsByRegionIdReturn>;

    getAssignmentTrackingInstrumentsAvailableFiltersByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdReturn>;

    getLocalTenantsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetLocalTenantParams,
    ) => OverloadApiResponse<GetLocalTenantReturn>;

    getAllocationFiltersByTenantId: (
        args: BaseArgs,
    ) => OverloadApiResponse<ReportHubDto.AllocationFilters>;

    getConsumptionSiteGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetConsumptionSiteGroupsByTenantIdParams,
    ) => OverloadApiResponse<GetConsumptionSiteGroupsByTenantIdReturn>;

    getGlobalOverviewFiltersCountriesByTenantId: (
        args: GetGlobalOverviewFiltersCountriesArgs,
    ) => OverloadApiResponse<ReportHubDto.GlobalOverviewFiltersCountriesOptions>;
    getGlobalOverviewFiltersGroupsByTenantId: (
        args: GetGlobalOverviewFiltersGroupsArgs,
    ) => OverloadApiResponse<ReportHubDto.GlobalOverviewFiltersGroupsOptions>;
    getGlobalOverviewKpisByTenantId: (
        args: GetGlobalOverviewKpisArgs,
    ) => OverloadApiResponse<ReportHubDto.GlobalOverviewKpis>;
    getGlobalOverviewCountriesPerformanceByTenantId: (
        args: GetGlobalOverviewCountriesPerformanceArgs,
    ) => OverloadApiResponse<ReportHubDto.GlobalOverviewCountriesPerformance>;
    getGlobalOverviewGroupsPerformanceByTenantId: (
        args: GetGlobalOverviewGroupsPerformanceArgs,
    ) => OverloadApiResponse<ReportHubDto.GlobalOverviewGroupsPerformance>;
    getGlobalOverviewContractsPerformanceByTenantId: (
        args: GetGlobalOverviewContractsPerformanceArgs,
    ) => OverloadApiResponse<ReportHubDto.GlobalOverviewContractsPerformance>;

    getReportingPeriodsByTenantId: (
        args: GetReportingPeriodsArgs,
    ) => OverloadApiResponse<ReportHubDto.GetReportingPeriods>;

    getReportingDashboardInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardInsightsByReportingPeriodByTenantIdParams,
    ) => OverloadApiResponse<GetReportingDashboardInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardAssignmentInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardAssignmentInsightsByReportingPeriodByTenantIdParams,
    ) => OverloadApiResponse<GetReportingDashboardAssignmentInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantIdParams,
    ) => OverloadApiResponse<GetReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantIdParams,
    ) => OverloadApiResponse<GetReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantIdReturn>;

    getContractTrackingKpisPpaByTenantId: (
        args: GetContractTrackingPpaKpisArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingKpisPpa>;
    getContractTrackingKpisDirectPpaByTenantId: (
        args: GetContractTrackingPpaKpisArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingDirectPpaKpis>;
    getContractTrackingKpisGreenTariffByTenantId: (
        args: GetContractTrackingGreenTariffKpisArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingKpisGreenTariff>;
    getContractTrackingKpisUnbundledByTenantId: (
        args: GetContractTrackingUnbundledKpisArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingKpisUnbundled>;

    getAssignmentCountryAssignmentReportingListByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentCountryAssignmentReportingListByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentCountryAssignmentReportingListByTenantIdReturn>;
} & ApiClient<reportHubPaths>;

export type ReportHubClient = {
    getPublicFeatureFlags: (getAccessToken: () => Promise<string>) => ApiResponse<Array<string>>;
    getContractTrackingOverviewPpaByTenantId: (
        args: GetContractTrackingOverviewPpaArgs,
    ) => ApiResponse<Array<ReportHubDto.ContractTrackingOverviewPpaRow>>;
    getContractTrackingOverviewDirectPpaByTenantId: (
        args: GetContractTrackingOverviewPpaArgs,
    ) => ApiResponse<Array<ReportHubDto.DirectPpaContractTrackingOverviewRow>>;
    getContractTrackingOverviewGreenTariffByTenantId: (
        args: GetContractTrackingOverviewGreenTariffArgs,
    ) => ApiResponse<Array<ReportHubDto.ContractTrackingOverviewGreenTariffRow>>;
    getContractTrackingOverviewUnbundledByTenantId: (
        args: GetContractTrackingOverviewUnbundledArgs,
    ) => ApiResponse<Array<ReportHubDto.ContractTrackingOverviewUnbundledRow>>;
    getContractTrackingMonthlyPpaByTenantId: (
        args: GetContractTrackingMonthlyPpaArgs,
    ) => ApiResponse<Array<ReportHubDto.ContractTrackingMonthlyPpaRow>>;
    getContractTrackingMonthlyDirectPpaByTenantId: (
        args: GetContractTrackingMonthlyDirectPpaArgs,
    ) => ApiResponse<Array<ReportHubDto.ContractTrackingMonthlyDirectPpaRow>>;
    getContractTrackingMonthlyGreenTariffByTenantId: (
        args: GetContractTrackingMonthlyGreenTariffArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingMonthlyGreenTariff>;
    getContractTrackingRegionsPpaByTenantId: (
        args: GetContractTrackingRegionsPpaArgs,
    ) => ApiResponse<ReportHubDto.GetRegions>;
    getContractTrackingRegionsDirectPpaByTenantId: (
        args: GetContractTrackingRegionsDirectPpaArgs,
    ) => ApiResponse<ReportHubDto.GetRegions>;
    getContractTrackingRegionsGreenTariffByTenantId: (
        args: GetContractTrackingRegionsGreenTariffArgs,
    ) => ApiResponse<ReportHubDto.GetRegions>;
    getContractTrackingFiltersUnbundledByTenantId: (
        args: GetContractTrackingFiltersUnbundledArgs,
    ) => ApiResponse<Array<ReportHubDto.UnbundledContractOption>>;
    getContractTrackingContractsPpaByTenantId: (
        args: GetContractTrackingPpaContractsArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingContractOptions>;
    getContractTrackingContractsDirectPpaByTenantId: (
        args: GetContractTrackingPpaContractsArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingContractOptions>;
    getContractTrackingContractsGreenTariffByTenantId: (
        args: GetContractTrackingGreenTariffContractsArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingContractOptions>;
    getAllocationsByTenantId: (
        args: GetAllocationsByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.GetAllocationsByTenantIdResponse>;
    getAllocationKpisByTenantId: (
        args: GetAllocationsSummaryByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.AllocationKpis>;
    postAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        params: PostAllocationsByTenantIdBody,
    ) => ApiResponse<PostAllocationsByTenantIdReturn>;
    getContractsByTenantId: (
        args: GetContractsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.GetContractsResponse>;
    getLocalContractsByContractIdByTenantId: (
        args: GetLocalContractsByContractIdByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.LocalContract>;
    putLocalContractsByContractIdByTenantId: (
        args: PutLocalContractsByContractIdByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.LocalContract>;
    getEacCodesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResponse<Array<ReportHubDto.EacCode>>;
    getAssignmentCountryAssignmentByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        countryId: string;
        year: number;
        page: number | null;
        sortField: ReportHubDto.CountryAssignmentsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
        groups: Array<string> | null;
    }) => ApiResponse<ReportHubDto.CountryAssignmentsResponse>;
    getAssignmentKpisByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        groups: Array<string> | null;
    }) => ApiResponse<ReportHubDto.GetReportingKpisResponse>;

    getAssignmentRegionAssignmentByRegionIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentRegionAssignmentByRegionIdByTenantIdParams,
    ) => ApiResponse<GetAssignmentRegionAssignmentByRegionIdByTenantIdReturn>;

    getAssignmentRegionAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentRegionAssignmentByTenantIdParams,
    ) => ApiResponse<GetAssignmentRegionAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentSmartAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSmartAssignmentByTenantIdParams,
    ) => ApiResponse<GetSmartAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => ApiResponse<GetAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentStateByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentStateByTenantIdParams,
    ) => ApiResponse<GetAssignmentTrackingInstrumentStateByTenantIdReturn>;

    postAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: PostAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => ApiResponse<PostAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentReportingPeriodByYearByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentReportingPeriodByYearByTenantIdParams,
    ) => ApiResponse<GetAssignmentReportingPeriodByYearByTenantIdReturn>;

    getPublicCountries: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountriesParams,
    ) => ApiResponse<GetPublicCountriesReturn>;

    getPublicCountryNameByCountryId: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountryNameByCountryIdParams,
    ) => ApiResponse<GetPublicCountryNameByCountryIdReturn>;

    getPublicRegionsByRegionId: (
        getAccessToken: () => Promise<string>,
        args: GetPublicRegionsByRegionIdParams,
    ) => ApiResponse<GetPublicRegionsByRegionIdReturn>;

    getAssignmentTrackingInstrumentsAvailableFiltersByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdParams,
    ) => ApiResponse<GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdReturn>;

    getLocalTenantsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetLocalTenantParams,
    ) => ApiResponse<GetLocalTenantReturn>;

    getReportingDashboardInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardInsightsByReportingPeriodByTenantIdParams,
    ) => ApiResponse<GetReportingDashboardInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardAssignmentInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardAssignmentInsightsByReportingPeriodByTenantIdParams,
    ) => ApiResponse<GetReportingDashboardAssignmentInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantIdParams,
    ) => ApiResponse<GetReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantIdParams,
    ) => ApiResponse<GetReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantIdReturn>;

    getAllocationFiltersByTenantId: (args: BaseArgs) => ApiResponse<ReportHubDto.AllocationFilters>;

    getConsumptionSiteGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetConsumptionSiteGroupsByTenantIdParams,
    ) => ApiResponse<GetConsumptionSiteGroupsByTenantIdReturn>;

    getGlobalOverviewFiltersCountriesByTenantId: (
        args: GetGlobalOverviewFiltersCountriesArgs,
    ) => ApiResponse<ReportHubDto.GlobalOverviewFiltersCountriesOptions>;
    getGlobalOverviewFiltersGroupsByTenantId: (
        args: GetGlobalOverviewFiltersGroupsArgs,
    ) => ApiResponse<ReportHubDto.GlobalOverviewFiltersGroupsOptions>;

    getGlobalOverviewKpisByTenantId: (
        args: GetGlobalOverviewKpisArgs,
    ) => ApiResponse<ReportHubDto.GlobalOverviewKpis>;
    getGlobalOverviewCountriesPerformanceByTenantId: (
        args: GetGlobalOverviewCountriesPerformanceArgs,
    ) => ApiResponse<ReportHubDto.GlobalOverviewCountriesPerformance>;
    getGlobalOverviewGroupsPerformanceByTenantId: (
        args: GetGlobalOverviewGroupsPerformanceArgs,
    ) => ApiResponse<ReportHubDto.GlobalOverviewGroupsPerformance>;
    getGlobalOverviewContractsPerformanceByTenantId: (
        args: GetGlobalOverviewContractsPerformanceArgs,
    ) => ApiResponse<ReportHubDto.GlobalOverviewContractsPerformance>;
    getReportingPeriodsByTenantId: (
        args: GetReportingPeriodsArgs,
    ) => ApiResponse<ReportHubDto.GetReportingPeriods>;

    getContractTrackingKpisPpaByTenantId: (
        args: GetContractTrackingPpaKpisArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingKpisPpa>;
    getContractTrackingKpisDirectPpaByTenantId: (
        args: GetContractTrackingPpaKpisArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingDirectPpaKpis>;
    getContractTrackingKpisGreenTariffByTenantId: (
        args: GetContractTrackingGreenTariffKpisArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingKpisGreenTariff>;
    getContractTrackingKpisUnbundledByTenantId: (
        args: GetContractTrackingUnbundledKpisArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingKpisUnbundled>;

    getAssignmentCountryAssignmentReportingListByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentCountryAssignmentReportingListByTenantIdParams,
    ) => ApiResponse<GetAssignmentCountryAssignmentReportingListByTenantIdReturn>;
} & ApiClient<reportHubPaths>;

export type ResultReportHubClient = {
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<string>>;
    getContractTrackingOverviewPpaByTenantId: (
        args: GetContractTrackingOverviewPpaArgs,
    ) => ApiResultResponse<Array<ReportHubDto.ContractTrackingOverviewPpaRow>>;
    getContractTrackingOverviewDirectPpaByTenantId: (
        args: GetContractTrackingOverviewPpaArgs,
    ) => ApiResultResponse<Array<ReportHubDto.DirectPpaContractTrackingOverviewRow>>;
    getContractTrackingOverviewGreenTariffByTenantId: (
        args: GetContractTrackingOverviewGreenTariffArgs,
    ) => ApiResultResponse<Array<ReportHubDto.ContractTrackingOverviewGreenTariffRow>>;
    getContractTrackingOverviewUnbundledByTenantId: (
        args: GetContractTrackingOverviewUnbundledArgs,
    ) => ApiResultResponse<Array<ReportHubDto.ContractTrackingOverviewUnbundledRow>>;
    getContractTrackingMonthlyPpaByTenantId: (
        args: GetContractTrackingMonthlyPpaArgs,
    ) => ApiResultResponse<Array<ReportHubDto.ContractTrackingMonthlyPpaRow>>;
    getContractTrackingMonthlyDirectPpaByTenantId: (
        args: GetContractTrackingMonthlyDirectPpaArgs,
    ) => ApiResultResponse<Array<ReportHubDto.ContractTrackingMonthlyDirectPpaRow>>;
    getContractTrackingMonthlyGreenTariffByTenantId: (
        args: GetContractTrackingMonthlyGreenTariffArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingMonthlyGreenTariff>;
    getContractTrackingRegionsPpaByTenantId: (
        args: GetContractTrackingRegionsPpaArgs,
    ) => ApiResultResponse<ReportHubDto.GetRegions>;
    getContractTrackingRegionsDirectPpaByTenantId: (
        args: GetContractTrackingRegionsDirectPpaArgs,
    ) => ApiResultResponse<ReportHubDto.GetRegions>;
    getContractTrackingRegionsGreenTariffByTenantId: (
        args: GetContractTrackingRegionsGreenTariffArgs,
    ) => ApiResultResponse<ReportHubDto.GetRegions>;
    getContractTrackingFiltersUnbundledByTenantId: (
        args: GetContractTrackingFiltersUnbundledArgs,
    ) => ApiResultResponse<Array<ReportHubDto.UnbundledContractOption>>;
    getContractTrackingContractsPpaByTenantId: (
        args: GetContractTrackingPpaContractsArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingContractOptions>;
    getContractTrackingContractsDirectPpaByTenantId: (
        args: GetContractTrackingPpaContractsArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingContractOptions>;
    getContractTrackingContractsGreenTariffByTenantId: (
        args: GetContractTrackingGreenTariffContractsArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingContractOptions>;
    getAllocationsByTenantId: (
        args: GetAllocationsByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.GetAllocationsByTenantIdResponse>;
    getAllocationKpisByTenantId: (
        args: GetAllocationsSummaryByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.AllocationKpis>;
    postAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        params: PostAllocationsByTenantIdBody,
    ) => OverloadApiResponse<PostAllocationsByTenantIdReturn>;
    getContractsByTenantId: (
        args: GetContractsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.GetContractsResponse>;
    getLocalContractsByContractIdByTenantId: (
        args: GetLocalContractsByContractIdByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.LocalContract>;
    putLocalContractsByContractIdByTenantId: (
        args: PutLocalContractsByContractIdByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.LocalContract>;
    getEacCodesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResultResponse<Array<ReportHubDto.EacCode>>;
    getAssignmentCountryAssignmentByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        countryId: string;
        year: number;
        page: number | null;
        sortField: ReportHubDto.CountryAssignmentsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
        groups: Array<string> | null;
    }) => ApiResultResponse<ReportHubDto.CountryAssignmentsResponse>;
    getAssignmentKpisByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        groups: Array<string> | null;
    }) => ApiResultResponse<ReportHubDto.GetReportingKpisResponse>;

    getAssignmentRegionAssignmentByRegionIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentRegionAssignmentByRegionIdByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentRegionAssignmentByRegionIdByTenantIdReturn>;

    getAssignmentRegionAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentRegionAssignmentByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentRegionAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentSmartAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSmartAssignmentByTenantIdParams,
    ) => ApiResultResponse<GetSmartAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentStateByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentStateByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentTrackingInstrumentStateByTenantIdReturn>;

    postAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: PostAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => ApiResultResponse<PostAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentReportingPeriodByYearByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentReportingPeriodByYearByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentReportingPeriodByYearByTenantIdReturn>;

    getPublicCountries: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountriesParams,
    ) => ApiResultResponse<GetPublicCountriesReturn>;

    getPublicCountryNameByCountryId: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountryNameByCountryIdParams,
    ) => ApiResultResponse<GetPublicCountryNameByCountryIdReturn>;

    getPublicRegionsByRegionId: (
        getAccessToken: () => Promise<string>,
        args: GetPublicRegionsByRegionIdParams,
    ) => ApiResultResponse<GetPublicRegionsByRegionIdReturn>;

    getAssignmentTrackingInstrumentsAvailableFiltersByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdReturn>;

    getLocalTenantsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetLocalTenantParams,
    ) => ApiResultResponse<GetLocalTenantReturn>;

    getReportingDashboardInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardInsightsByReportingPeriodByTenantIdParams,
    ) => ApiResultResponse<GetReportingDashboardInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardAssignmentInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardAssignmentInsightsByReportingPeriodByTenantIdParams,
    ) => ApiResultResponse<GetReportingDashboardAssignmentInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantIdParams,
    ) => ApiResultResponse<GetReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantIdReturn>;

    getReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantIdParams,
    ) => ApiResultResponse<GetReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantIdReturn>;

    getAllocationFiltersByTenantId: (
        args: BaseArgs,
    ) => ApiResultResponse<ReportHubDto.AllocationFilters>;
    getConsumptionSiteGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetConsumptionSiteGroupsByTenantIdParams,
    ) => ApiResultResponse<GetConsumptionSiteGroupsByTenantIdReturn>;

    getGlobalOverviewFiltersCountriesByTenantId: (
        args: GetGlobalOverviewFiltersCountriesArgs,
    ) => ApiResultResponse<ReportHubDto.GlobalOverviewFiltersCountriesOptions>;
    getGlobalOverviewFiltersGroupsByTenantId: (
        args: GetGlobalOverviewFiltersGroupsArgs,
    ) => ApiResultResponse<ReportHubDto.GlobalOverviewFiltersGroupsOptions>;
    getGlobalOverviewKpisByTenantId: (
        args: GetGlobalOverviewKpisArgs,
    ) => ApiResultResponse<ReportHubDto.GlobalOverviewKpis>;
    getGlobalOverviewCountriesPerformanceByTenantId: (
        args: GetGlobalOverviewCountriesPerformanceArgs,
    ) => ApiResultResponse<ReportHubDto.GlobalOverviewCountriesPerformance>;
    getGlobalOverviewGroupsPerformanceByTenantId: (
        args: GetGlobalOverviewGroupsPerformanceArgs,
    ) => ApiResultResponse<ReportHubDto.GlobalOverviewGroupsPerformance>;
    getGlobalOverviewContractsPerformanceByTenantId: (
        args: GetGlobalOverviewContractsPerformanceArgs,
    ) => ApiResultResponse<ReportHubDto.GlobalOverviewContractsPerformance>;
    getReportingPeriodsByTenantId: (
        args: GetReportingPeriodsArgs,
    ) => ApiResultResponse<ReportHubDto.GetReportingPeriods>;

    getContractTrackingKpisPpaByTenantId: (
        args: GetContractTrackingPpaKpisArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingKpisPpa>;
    getContractTrackingKpisDirectPpaByTenantId: (
        args: GetContractTrackingPpaKpisArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingDirectPpaKpis>;
    getContractTrackingKpisGreenTariffByTenantId: (
        args: GetContractTrackingGreenTariffKpisArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingKpisGreenTariff>;
    getContractTrackingKpisUnbundledByTenantId: (
        args: GetContractTrackingUnbundledKpisArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingKpisUnbundled>;

    getAssignmentCountryAssignmentReportingListByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentCountryAssignmentReportingListByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentCountryAssignmentReportingListByTenantIdReturn>;
} & ApiClient<reportHubPaths>;

export function getReportHubClient(config: { useRetries?: boolean }): ReportHubClient;
export function getReportHubClient(config: { useResult: boolean }): ResultReportHubClient;
export function getReportHubClient(config: {
    useResult?: boolean;
    useRetries?: boolean;
}): Omit<
    OverloadReportHubClient,
    | "getAdminByTenantIdConsumptionSiteGroupsByConsumptionSiteGroupId"
    | "getAdminInvoicedVolumesByTenantId"
    | "getAdminGlobalOverviewMatchingPendings"
    | "deleteAdminGlobalOverviewMatchingPendings"
    | "patchGlobalOverviewTransactionsByTenantId"
    | "getAdminGlobalOverviewTransactions"
    | "postAdminTriggerGlobalOverviewMatching"
    | "getGlobalOverviewTransactionsByTenantId"
    | "get1mGlobalOverviewSiteAggsByTenantId"
    | "getAdminByTenantIdUnbundledContractByUnbundledContractId"
    | "getAdminProjectedSiteMonthlyGridReadingsBySiteId"
    | "getAdminHistoricalSiteMonthlyGridReadingsBySiteId"
> {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        if (process.env.MOCK_REPORT_HUB == "true") {
            useMockData = true;
        }
        if (!process.env.REPORT_HUB_URL) {
            return crash("Missing REPORT_HUB_URL environment variable.");
        } else {
            url = process.env.REPORT_HUB_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buildQueries({ ...config, useMockData: useMockData }, url);

    const { get, put, post } = buildQueries({ ...config, useMockData: useMockData }, url);

    const filterObjectFieldsByUndefined = <T extends object>(obj: T): Partial<T> =>
        Object.entries(obj).reduce(
            (acc, [key, value]) => (value === undefined ? acc : { ...acc, [key]: value }),
            {},
        );

    const filterObjectFieldsByUndefinedOrNull = <T extends object>(obj: T): Partial<T> =>
        Object.entries(obj).reduce(
            (acc, [key, value]) => (value == null ? acc : { ...acc, [key]: value }),
            {},
        );

    return {
        getContractTrackingKpisPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            startMonthLocal,
            endMonthLocal,
            regionIds,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingKpisPpa> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/kpis-ppa`,
                decoder: reportHubDecoders.getContractTrackingKpisPpaDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    startMonthLocal,
                    endMonthLocal,
                    regionIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getContractTrackingKpisDirectPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            startMonthLocal,
            endMonthLocal,
            regionIds,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingDirectPpaKpis> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/kpis-direct-ppa`,
                decoder: reportHubDecoders.getContractTrackingKpisDirectPpaDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    startMonthLocal,
                    endMonthLocal,
                    regionIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getContractTrackingKpisGreenTariffByTenantId: async ({
            getAccessToken,
            tenantId,
            calendarYear,
            regionIds,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingKpisGreenTariff> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/kpis-green-tariff`,
                decoder: reportHubDecoders.getContractTrackingKpisGreenTariffDecoder,
                params: filterObjectFieldsByUndefinedOrNull({ calendarYear, regionIds }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getContractTrackingKpisUnbundledByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingPeriodYear,
            unbundledContractIds,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingKpisUnbundled> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/kpis-unbundled`,
                decoder: reportHubDecoders.getContractTrackingKpisUnbundledDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    reportingPeriodYear,
                    unbundledContractIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getContractTrackingOverviewPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            startMonthLocal,
            endMonthLocal,
            regionIds,
        }): OverloadApiResponse<Array<ReportHubDto.ContractTrackingOverviewPpaRow>> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/overview-ppa`,
                decoder: reportHubDecoders.getContractTrackingOverviewPpaDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    startMonthLocal,
                    endMonthLocal,
                    regionIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingOverviewDirectPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            startMonthLocal,
            endMonthLocal,
            regionIds,
        }): OverloadApiResponse<Array<ReportHubDto.DirectPpaContractTrackingOverviewRow>> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/overview-direct-ppa`,
                decoder: reportHubDecoders.getContractTrackingOverviewDirectPpaDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    startMonthLocal,
                    endMonthLocal,
                    regionIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingOverviewGreenTariffByTenantId: async ({
            getAccessToken,
            tenantId,
            calendarYear,
            regionIds,
        }): OverloadApiResponse<Array<ReportHubDto.ContractTrackingOverviewGreenTariffRow>> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/overview-green-tariff`,
                decoder: reportHubDecoders.getEacContractTrackingOverviewGreenTariffDecoder,
                params: filterObjectFieldsByUndefinedOrNull({ calendarYear, regionIds }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingOverviewUnbundledByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingPeriodYear,
            unbundledContractIds,
        }): OverloadApiResponse<Array<ReportHubDto.ContractTrackingOverviewUnbundledRow>> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/overview-unbundled`,
                decoder: reportHubDecoders.getEacContractTrackingOverviewUnbundledDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    reportingPeriodYear,
                    unbundledContractIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingMonthlyPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            startMonthLocal,
            endMonthLocal,
            regionIds,
            contract,
            site,
        }): OverloadApiResponse<Array<ReportHubDto.ContractTrackingMonthlyPpaRow>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/monthly-ppa`,
                decoder: reportHubDecoders.getContractTrackingMonthlyPpaDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    startMonthLocal,
                    endMonthLocal,
                    regionIds,
                    contract,
                    site,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingMonthlyDirectPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            startMonthLocal,
            endMonthLocal,
            regionIds,
            contract,
        }): OverloadApiResponse<Array<ReportHubDto.ContractTrackingMonthlyDirectPpaRow>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/monthly-direct-ppa`,
                decoder: reportHubDecoders.getContractTrackingMonthlyDirectPpaDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    startMonthLocal,
                    endMonthLocal,
                    regionIds,
                    contract,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingMonthlyGreenTariffByTenantId: async ({
            getAccessToken,
            tenantId,
            calendarYear,
            regionIds,
            contract,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingMonthlyGreenTariff> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/monthly-green-tariff`,
                decoder: reportHubDecoders.getContractTrackingMonthlyGreenTariffDecoder,
                params: filterObjectFieldsByUndefinedOrNull({ calendarYear, regionIds, contract }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingRegionsPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            startMonthLocal,
            endMonthLocal,
        }): OverloadApiResponse<ReportHubDto.GetRegions> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/regions-ppa`,
                decoder: reportHubDecoders.getRegions,
                params: filterObjectFieldsByUndefined({
                    startMonthLocal,
                    endMonthLocal,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingRegionsDirectPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            startMonthLocal,
            endMonthLocal,
        }): OverloadApiResponse<ReportHubDto.GetRegions> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/regions-direct-ppa`,
                decoder: reportHubDecoders.getRegions,
                params: filterObjectFieldsByUndefined({
                    startMonthLocal,
                    endMonthLocal,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingRegionsGreenTariffByTenantId: async ({
            getAccessToken,
            tenantId,
            calendarYear,
        }): OverloadApiResponse<ReportHubDto.GetRegions> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/regions-green-tariff`,
                params: { calendarYear },
                decoder: reportHubDecoders.getRegions,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingFiltersUnbundledByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingPeriodYear,
        }): OverloadApiResponse<Array<ReportHubDto.UnbundledContractOption>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/filters-unbundled`,
                params: { reportingPeriodYear },
                decoder: reportHubDecoders.getContractTrackingFiltersUnbundled,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingContractsPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            regionIds,
            startMonthLocal,
            endMonthLocal,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingContractOptions> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/contracts-ppa`,
                params: filterObjectFieldsByUndefinedOrNull({
                    regionIds,
                    startMonthLocal,
                    endMonthLocal,
                }),
                decoder: reportHubDecoders.contractTrackingContractOptions,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingContractsDirectPpaByTenantId: async ({
            getAccessToken,
            tenantId,
            regionIds,
            startMonthLocal,
            endMonthLocal,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingContractOptions> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/contracts-direct-ppa`,
                params: filterObjectFieldsByUndefinedOrNull({
                    regionIds,
                    startMonthLocal,
                    endMonthLocal,
                }),
                decoder: reportHubDecoders.contractTrackingContractOptions,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractTrackingContractsGreenTariffByTenantId: async ({
            getAccessToken,
            tenantId,
            regionIds,
            calendarYear,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingContractOptions> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-tracking/contracts-green-tariff`,
                params: filterObjectFieldsByUndefinedOrNull({ regionIds, calendarYear }),
                decoder: reportHubDecoders.contractTrackingContractOptions,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAllocationsByTenantId: async ({
            getAccessToken,
            tenantId,
            page,
            pageSize,
            orderBy,
            orderDirection,
            transactionIdFilter,
            productionSiteNameFilter,
            allocatedToNameFilter,
            productionCountryFilter,
            energySourceIdFilter,
            registryIdFilter,
        }): OverloadApiResponse<ReportHubDto.GetAllocationsByTenantIdResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/allocations`,
                decoder: reportHubDecoders.getAllocationsByTenantIdResponseDecoder,
                params: filterObjectFieldsByUndefined({
                    page,
                    pageSize,
                    orderBy,
                    orderDirection,
                    transactionIdFilter,
                    productionSiteNameFilter,
                    allocatedToNameFilter,
                    productionCountryFilter,
                    energySourceIdFilter,
                    registryIdFilter,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAllocationKpisByTenantId: async ({
            getAccessToken,
            tenantId,
        }): OverloadApiResponse<ReportHubDto.AllocationKpis> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/allocation-kpis`,
                decoder: reportHubDecoders.getAllocationKpisDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postAllocationsByTenantId: async (
            getAccessToken: () => Promise<string>,
            {
                body,
                params: {
                    path: { tenantId },
                },
            },
        ): ReturnType<ReportHubClient["postAllocationsByTenantId"]> => {
            const accessToken = await getAccessToken();
            return post({
                path: `/api/report-hub/${tenantId}/allocations`,
                decoder: postAllocationsByTenantIdResponseDecoder,
                data: body,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        putAdminEventsTrigger: async () => Promise.reject("Not for internal or UI usage"),

        getContractsByTenantId: async ({
            getAccessToken,
            tenantId,
        }: GetContractsByTenantIdArgs): OverloadApiResponse<ReportHubDto.GetContractsResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contracts`,
                decoder: reportHubDecoders.contractOptionsArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getLocalContractsByContractIdByTenantId: async ({
            getAccessToken,
            tenantId,
            contractId,
        }: GetLocalContractsByContractIdByTenantIdArgs): OverloadApiResponse<ReportHubDto.LocalContract> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/local-contracts/${contractId}`,
                decoder: reportHubDecoders.localContractDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putLocalContractsByContractIdByTenantId: async ({
            getAccessToken,
            tenantId,
            contractId,
            sourcingMethod,
            reportingYear,
            contractsAsProof,
        }: PutLocalContractsByContractIdByTenantIdArgs): OverloadApiResponse<ReportHubDto.LocalContract> => {
            const accessToken = await getAccessToken();

            return put({
                path: `/api/report-hub/${tenantId}/local-contracts/${contractId}`,
                decoder: reportHubDecoders.localContractDecoder,
                data: {
                    sourcingMethod,
                    reportingYear,
                    contractsAsProof,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacCodesByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
        ): OverloadApiResponse<Array<ReportHubDto.EacCode>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/eac-codes`,
                decoder: D.array(reportHubDecoders.eacCodeDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAdminInspectIndicators: async () => Promise.reject("Not for internal or UI usage"),
        postAdminBackgroundWorkerTrigger: async () =>
            Promise.reject("Not for internal or UI usage"),
        getAdminTenantsByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminSitesBySiteId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminContractsByContractId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminAttestations: async () => Promise.reject("Not for internal or UI usage"),
        getAdminEacCancellations: async () => Promise.reject("Not for internal or UI usage"),
        getTestRowLevelSecurityLocalTenantByTenantId: async () =>
            Promise.reject("Not for internal or UI usage"),

        getAdminConsumptionSiteGroupsByConsumptionSiteGroupId: async () =>
            Promise.reject("Not for internal or UI usage"),
        getAdminSitesBySiteIdSiteReadingsMonthly: async () =>
            Promise.reject("Not for internal or UI usage"),
        postAdminLocalTenants: async () => Promise.reject("Not for internal or UI usage"),
        getAssignmentRegionAssignmentByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query,
                },
            },
        ): ReturnType<OverloadReportHubClient["getAssignmentRegionAssignmentByTenantId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/region-assignment`,

                decoder: reportHubDecodersV2["/{tenantId}/assignment/region-assignment"]["get"],
                params: {
                    ...query,
                    ...(query.group ? { group: query.group } : {}),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentTrackingInstrumentAssignmentSmartAssignmentByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query,
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentTrackingInstrumentAssignmentSmartAssignmentByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instrument-assignment/smart-assignment`,

                decoder:
                    reportHubDecodersV2[
                        "/{tenantId}/assignment/tracking-instrument-assignment/smart-assignment"
                    ]["get"],
                params: {
                    ...query,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentRegionAssignmentByRegionIdByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, regionId },
                    query: params,
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentRegionAssignmentByRegionIdByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/region-assignment/${regionId}`,
                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/region-assignment/{regionId}"][
                        "get"
                    ],
                params,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentTrackingInstrumentAssignmentByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query: params,
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentTrackingInstrumentAssignmentByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instrument-assignment`,
                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/tracking-instrument-assignment"][
                        "get"
                    ],
                params,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentTrackingInstrumentStateByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query: params,
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentTrackingInstrumentStateByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instrument-state`,
                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/tracking-instrument-state"]["get"],
                params,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postAssignmentTrackingInstrumentAssignmentByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                },
                body: { assignments },
            },
        ): ReturnType<
            OverloadReportHubClient["postAssignmentTrackingInstrumentAssignmentByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await post({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instrument-assignment`,
                decoder: postAssignmentResponse,
                data: {
                    assignments,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentReportingPeriodByYearByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, year },
                    query,
                },
            },
        ): ReturnType<OverloadReportHubClient["getAssignmentReportingPeriodByYearByTenantId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/reporting-period/${year}`,
                params: query && query.group ? { group: query.group } : {},
                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/reporting-period/{year}"]["get"],
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicCountries: async (
            getAccessToken: () => Promise<string>,
        ): ReturnType<OverloadReportHubClient["getPublicCountries"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/public/countries`,
                decoder: D.array(reportHubDecoders.countryDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getPublicCountryNameByCountryId: async (
            getAccessToken,
            {
                params: {
                    path: { countryId },
                },
            },
        ): ReturnType<OverloadReportHubClient["getPublicCountryNameByCountryId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/public/country-name/${countryId}`,
                decoder: reportHubDecodersV2["/public/country-name/{countryId}"]["get"],
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getPublicRegionsByRegionId: async (
            getAccessToken,
            {
                params: {
                    path: { regionId },
                },
            },
        ): ReturnType<OverloadReportHubClient["getPublicRegionsByRegionId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/public/regions/${regionId}`,
                decoder: reportHubDecodersV2["/public/regions/{regionId}"]["get"],
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getPublicMarkets: async (
            getAccessToken: () => Promise<string>,
        ): OverloadApiResponse<Array<ReportHubDto.Market>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/public/markets`,
                decoder: D.array(reportHubDecoders.marketDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getPublicAnnualRegionEmissionFactors: async (
            getAccessToken: () => Promise<string>,
        ): OverloadApiResponse<Array<ReportHubDto.AnnualRegionEmissionFactor>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/public/annual-region-emission-factors`,
                decoder: D.array(reportHubDecoders.annualRegionEmissionFactorDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postReportingPeriodsByTenantId: async () => Promise.reject("Not yet implemented"),

        getAssignmentCountryAssignmentByTenantId: async ({
            getAccessToken,
            tenantId,
            countryId,
            year,
            page,
            sortField,
            sortOrder,
            groups,
        }): OverloadApiResponse<ReportHubDto.CountryAssignmentsResponse> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/assignment/country-assignment`,
                decoder: countryAssignmentResponseDecoder,
                params: {
                    countryId,
                    year,
                    ...(page && { page }),
                    ...(sortField && { sortField }),
                    ...(sortOrder && { sortOrder }),
                    ...(groups && { groups }),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getAssignmentCountryAssignmentReportingListByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query: { countryId, year, groups },
                },
            },
        ): OverloadApiResponse<GetAssignmentCountryAssignmentReportingListByTenantIdReturn> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/assignment/country-assignment/reporting-list`,
                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/country-assignment/reporting-list"]
                        .get,
                params: {
                    countryId,
                    year,
                    ...(groups && { groups }),
                } satisfies InferParams<
                    paths,
                    "/{tenantId}/assignment/country-assignment/reporting-list"
                >,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getAssignmentKpisByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            groups,
        }): OverloadApiResponse<ReportHubDto.GetReportingKpisResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/assignment/kpis`,
                decoder: reportingKpisDecoder,
                params: {
                    year,
                    ...(groups && { groups }),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAdminYearlyIntensities: async () => Promise.reject("Not yet implemented"),
        getAssignmentTrackingInstrumentsAvailableFiltersByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query: params,
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentTrackingInstrumentsAvailableFiltersByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instruments/available-filters`,
                decoder:
                    reportHubDecodersV2[
                        "/{tenantId}/assignment/tracking-instruments/available-filters"
                    ].get,
                params,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getLocalTenantsByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                },
            },
        ): ReturnType<OverloadReportHubClient["getLocalTenantsByTenantId"]> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/local-tenants`,
                decoder: reportHubDecodersV2["/{tenantId}/local-tenants"].get,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicFeatureFlags: async (getAccessToken): OverloadApiResponse<Array<string>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/public/feature-flags`,
                decoder: featureFlagDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAllocationFiltersByTenantId: async ({
            getAccessToken,
            tenantId,
        }): OverloadApiResponse<ReportHubDto.AllocationFilters> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/allocation-filters`,
                decoder: allocationFiltersDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getConsumptionSiteGroupsByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                },
            },
        ): ReturnType<OverloadReportHubClient["getConsumptionSiteGroupsByTenantId"]> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/consumption-site-groups`,
                decoder: reportHubDecodersV2["/{tenantId}/consumption-site-groups"].get,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getGlobalOverviewKpisByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingYear,
            countryIds,
            groupIds,
        }): OverloadApiResponse<ReportHubDto.GlobalOverviewKpis> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/kpis`,
                decoder: reportHubDecoders.getGlobalOverviewKpisDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    reportingYear,
                    countryIds,
                    groupIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getGlobalOverviewFiltersCountriesByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingYear,
        }): OverloadApiResponse<ReportHubDto.GlobalOverviewFiltersCountriesOptions> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/filters/countries`,
                decoder: reportHubDecoders.getGlobalOverviewFiltersCountriesOptions,
                params: { reportingYear },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getGlobalOverviewFiltersGroupsByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingYear,
            countryIds,
        }): OverloadApiResponse<ReportHubDto.GlobalOverviewFiltersGroupsOptions> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/filters/groups`,
                decoder: reportHubDecoders.getGlobalOverviewFiltersGroupsOptions,
                params: filterObjectFieldsByUndefinedOrNull({
                    reportingYear,
                    countryIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getGlobalOverviewCountriesPerformanceByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingYear,
            countryIds,
            groupIds,
        }): OverloadApiResponse<ReportHubDto.GlobalOverviewCountriesPerformance> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/countries-performance`,
                decoder: reportHubDecoders.getGlobalOverviewCountriesPerformanceDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    reportingYear,
                    countryIds,
                    groupIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getGlobalOverviewGroupsPerformanceByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingYear,
            countryIds,
            groupIds,
        }): OverloadApiResponse<ReportHubDto.GlobalOverviewGroupsPerformance> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/groups-performance`,
                decoder: reportHubDecoders.getGlobalOverviewGroupsPerformanceDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    reportingYear,
                    countryIds,
                    groupIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getGlobalOverviewContractsPerformanceByTenantId: async ({
            getAccessToken,
            tenantId,
            reportingYear,
            countryIds,
            groupIds,
        }): OverloadApiResponse<ReportHubDto.GlobalOverviewContractsPerformance> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/contracts-performance`,
                decoder: reportHubDecoders.getGlobalOverviewContractsPerformanceDecoder,
                params: filterObjectFieldsByUndefinedOrNull({
                    reportingYear,
                    countryIds,
                    groupIds,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getReportingPeriodsByTenantId: async ({
            getAccessToken,
            tenantId,
        }): OverloadApiResponse<ReportHubDto.GetReportingPeriods> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/reporting-periods`,
                decoder: reportHubDecoders.getReportingPeriodsDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        getReportingDashboardInsightsByReportingPeriodByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, reportingPeriod },
                    query,
                },
            },
        ) => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/reporting-dashboard/insights/${reportingPeriod}`,
                decoder:
                    reportHubDecodersV2[
                        "/{tenantId}/reporting-dashboard/insights/{reportingPeriod}"
                    ].get,
                params: query,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        getReportingDashboardAssignmentInsightsByReportingPeriodByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, reportingPeriod },
                    query,
                },
            },
        ) => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/reporting-dashboard/assignment/insights/${reportingPeriod}`,
                decoder:
                    reportHubDecodersV2[
                        "/{tenantId}/reporting-dashboard/assignment/insights/{reportingPeriod}"
                    ].get,
                params: query,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        getReportingDashboardCountriesByCountryIdInsightsByReportingPeriodByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, reportingPeriod, countryId },
                    query,
                },
            },
        ) => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/reporting-dashboard/countries/${countryId}/insights/${reportingPeriod}`,
                decoder:
                    reportHubDecodersV2[
                        "/{tenantId}/reporting-dashboard/countries/{countryId}/insights/{reportingPeriod}"
                    ].get,
                params: query,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        getReportingDashboardAssignmentRegionsByRegionIdInsightsByReportingPeriodByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, reportingPeriod, regionId },
                    query,
                },
            },
        ) => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/reporting-dashboard/assignment/regions/${regionId}/insights/${reportingPeriod}`,
                decoder:
                    reportHubDecodersV2[
                        "/{tenantId}/reporting-dashboard/assignment/regions/{regionId}/insights/{reportingPeriod}"
                    ].get,
                params: query,

                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
    };
}
