import { BimDto, Module } from "@flexidao/dto";
import { useAppUser } from "@flexidao/ui-lib/user-utils/hooks/use-app-user";

export const useTenantDisplay = (): BimDto.TenantDisplay => {
    const { user } = useAppUser();
    if (!user) {
        console.warn(
            "Using useTenantDisplay hook before user has been initialized. display options set as empty.\nWrap your page with UserInitializedComponent",
        );
    }
    return (
        user?.activeTenant.displaySettings ?? {
            homepage: null,
            modules: {
                [Module.CfeInventory]: {
                    isVisible: false,
                    landingPageId: null,
                    visibleNavigationPageIds: [],
                },
                [Module.CfeDiagnostic]: {
                    isVisible: false,
                    landingPageId: null,
                    visibleNavigationPageIds: [],
                },
                [Module.CfeConnect]: {
                    isVisible: false,
                    landingPageId: null,
                    visibleNavigationPageIds: [],
                },
                [Module.Settings]: {
                    isVisible: false,
                    landingPageId: null,
                    visibleNavigationPageIds: [],
                },
            },
        }
    );
};
