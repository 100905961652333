/**
 * This function is used to get the local time of the browser as an ISO8601 string that has the
 * local values but the offset is forced to 0 (Zulú).
 *
 * It is used because we use fake UTC when we need reporting behaviour.
 * See: https://flexidao.atlassian.net/wiki/spaces/SS/pages/2222653456/Time+zones+monitoring+VS+reporting
 *
 * @example
 *    "2020-01-01T00:00:00.000-08:00" -> "2020-01-01T00:00:00.000Z"
 *
 * @param date
 */
export const convertDateUTCToISOString = (date: Date): string => {
    return new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
    ).toISOString();
};
