import { MeterDataSimulationDto } from "@flexidao/dto";
import * as D from "schemawax";
import { isoTimestampDecoder } from "../utils";

export const simulatedConsumptionDecoder: D.Decoder<MeterDataSimulationDto.SimulatedConsumption> =
    D.object({
        required: {
            inboundWh: D.nullable(D.number),
            outboundWh: D.nullable(D.number),
            startTime: isoTimestampDecoder,
            endTime: isoTimestampDecoder,
        },
        optional: {},
    });

export const simulatedProductionDecoder: D.Decoder<MeterDataSimulationDto.SimulatedProduction> =
    D.object({
        required: {
            inboundWh: D.nullable(D.number),
            outboundWh: D.nullable(D.number),
            startTime: isoTimestampDecoder,
            endTime: isoTimestampDecoder,
        },
        optional: {},
    });

export const simulateConsumptionResponseDecoder: D.Decoder<MeterDataSimulationDto.SimulateConsumptionResponse> =
    D.array(simulatedConsumptionDecoder);

export const simulateProductionResponseDecoder: D.Decoder<MeterDataSimulationDto.SimulateProductionResponse> =
    D.array(simulatedProductionDecoder);
