import { Group, Title } from "@mantine/core";
import { ReactElement } from "react";

export type KpiSectionTitleProps = {
    title: string;
    dataId?: string;
};

export const KpiSectionTitle = ({ title, dataId }: KpiSectionTitleProps): ReactElement => (
    <Group position="apart" align="flex-start" px="xl" py={0} data-id={dataId}>
        <Title order={3}>{title}</Title>
    </Group>
);
