import { FetchError, isFetchError } from "@flexidao/api-client";
import { NotificationProps, notifications } from "@mantine/notifications";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactElement } from "react";
import { isJSXElement } from "../utils";
import { QueryWrapperSkeletonArgs } from "./types";

type TableQueryWrapperProps<T> = {
    query: UseQueryResult<T | FetchError>;
    errorNotification?: NotificationProps;
    skeleton: ReactElement | ((args: QueryWrapperSkeletonArgs) => ReactElement);
    content: (args: { data: T; showLoadingOverlay: boolean }) => ReactElement;
    error: ReactElement;
};

export const TableQueryWrapper = <T>({
    content,
    error,
    query,
    skeleton,
    errorNotification,
}: TableQueryWrapperProps<T>): ReactElement => {
    if (skeleton && query.isLoading) {
        if (isJSXElement(skeleton)) {
            return skeleton;
        }
        return skeleton(query);
    }

    if (
        query.isError ||
        isFetchError(query.data) ||
        query.data === undefined ||
        query.data === null
    ) {
        console.error("Query error", query.data);
        if (errorNotification) {
            notifications.show(errorNotification);
        }
        return error;
    }

    return content({
        data: query.data,
        showLoadingOverlay: query.isFetching && query.isPreviousData,
    });
};
