import { ActionIcon, CSSObject, Popover, Sx, Text, Title } from "@mantine/core";
import { FloatingPosition } from "@mantine/core/lib/Floating";
import { ReactNode } from "react";
import { IconInfoCircle } from "@tabler/icons-react";

export type InfoTooltipProps = {
    body: ReactNode;
    width?: number;
    title?: string;
    dataId?: string;
    floatingPosition?: FloatingPosition;
    sx?: Sx | (Sx | undefined)[];
    size?: "xs" | "sm" | "md" | "lg" | "xl" | number;
};

const DEFAULT_TOOLTIP_WIDTH = 300;

export const InfoTooltip = ({
    body,
    width = DEFAULT_TOOLTIP_WIDTH,
    title,
    dataId,
    sx,
    floatingPosition = "bottom-start",
    size = "md",
}: InfoTooltipProps): JSX.Element => {
    return (
        <Popover
            {...(dataId ? { dataId } : {})}
            width={width}
            position={floatingPosition}
            withArrow
            shadow="xs"
        >
            <Popover.Target>
                <ActionIcon
                    size={size}
                    radius="xl"
                    onClick={(e): void => e.stopPropagation()}
                    sx={sx}
                >
                    <IconInfoCircle size={20} />
                </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown
                sx={(theme): CSSObject => ({ color: theme.black, fontWeight: "normal" })}
                onClick={(e): void => e.stopPropagation()}
            >
                {title && (
                    <Title mb="md" order={5}>
                        {title}
                    </Title>
                )}
                <Text size="sm">{body}</Text>
            </Popover.Dropdown>
        </Popover>
    );
};
