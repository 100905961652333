import { SanityWarning } from "@flexidao/dto";

export const formatWarningType = (warningType: SanityWarning): string => {
    switch (warningType) {
        case SanityWarning.QuantityNotPositive:
            return "Quantity is not positive";
        case SanityWarning.QuantityNotNumber:
            return "Quantity is not a number";
        case SanityWarning.StartTimeNotValidDate:
            return "Start time is not a valid date";
        case SanityWarning.EndTimeNotValidDate:
            return "End time is not a valid date";
        case SanityWarning.StartTimeGreaterThanEndTime:
            return "Start time is greater than end time";
        case SanityWarning.StartTimeNotMultipleOf15:
            return "Start time is not multiple of 15 mins";
        case SanityWarning.EndTimeNotMultipleOf15:
            return "End time is not multiple of 15 mins";
        case SanityWarning.OverlappingReadings:
            return "Overlapping readings";
        case SanityWarning.QuantityEstimated:
            return "Quantity is estimated";
        case SanityWarning.BadFormattedReading:
            return "Badly formatted reading";
        case SanityWarning.MissingReading:
            return "Missing reading";
        case SanityWarning.ConnectionError:
            return "Connection error";
        case SanityWarning.BadTimestamps:
            return "Bad timestamps";
        case SanityWarning.MissingQuantity:
            return "Missing quantity";
        case SanityWarning.GranularityForIntervalNotSupported:
            return "Granularity for interval is not supported";
        case SanityWarning.GranularityForBillingNotSupported:
            return "Granularity for billing is not supported";
    }
};
