import { ReactElement, useMemo, useState } from "react";
import {
    Button,
    Checkbox,
    CSSObject,
    Divider,
    Group,
    MantineTheme,
    Paper,
    Popover,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { labelToDataId } from "@flexidao/ui-lib";
import { FilterOption } from "./types";
import { getNumMatchingOptions, getSortedCheckedKeys, sortByMatchAndAlphabetically } from "./utils";
import { SearchInput } from "./components/search-input";
import { ListItem } from "./components/list-item";
import { SearchResultsSummary } from "./components/search-results-summary";
import { SEARCH_QUERY_INITIAL_VALUE } from "./consts";

type MultiselectFilterProps<T extends string> = {
    title: string;
    selectAllLabel: string;
    initialValues: Array<FilterOption<T>>;
    numOptions: number;
    numAppliedOptions: number | null;
    handleApply: (appliedOptions: Array<T> | null) => void;
    dataIdPrefix: string;
    disabled?: boolean;
};

export const MultiselectFilter = <T extends string>({
    title,
    selectAllLabel,
    initialValues,
    numOptions,
    numAppliedOptions,
    handleApply,
    dataIdPrefix,
    disabled: disabled_ = false,
}: MultiselectFilterProps<T>): ReactElement => {
    const disabled: boolean = disabled_ || numOptions === 0;

    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>(SEARCH_QUERY_INITIAL_VALUE);

    const [selectedOptions, setSelectedOptions] = useState<Array<FilterOption<T>>>(initialValues);

    const numCheckedOptions: number = selectedOptions.filter((value) => value.checked).length;
    const areAllChecked: boolean = numCheckedOptions === selectedOptions.length;
    const areSomeChecked: boolean = numCheckedOptions > 0 && !areAllChecked;

    const initialCheckedOptionKeys: Array<T> = getSortedCheckedKeys(initialValues);
    const currentCheckedOptionKeys: Array<T> = getSortedCheckedKeys(selectedOptions);

    const disableApplyButton: boolean =
        currentCheckedOptionKeys.length === 0 ||
        initialCheckedOptionKeys.join() === currentCheckedOptionKeys.join();

    const handleChange = (opened: boolean): void => {
        setSelectedOptions(initialValues);

        setFilterOpen(opened);
    };

    const handleRegionsFilterApplied = (): void => {
        handleApply(areAllChecked ? null : currentCheckedOptionKeys);
        setSearchQuery(SEARCH_QUERY_INITIAL_VALUE);

        setFilterOpen(!filterOpen);
    };

    const handleSelectAll = (): void => {
        setSelectedOptions((prevSelectedOptions) =>
            prevSelectedOptions.map((option) => ({
                ...option,
                checked: !areAllChecked,
            })),
        );
    };

    const selectedOptionsJsx: Array<ReactElement> = sortByMatchAndAlphabetically(
        selectedOptions,
        searchQuery,
    ).map((option) => (
        <ListItem
            key={option.key}
            dataIdPrefix={dataIdPrefix}
            filterOption={option}
            onFilterOptionCheckToggle={(checked): void =>
                setSelectedOptions((prevSelectedOptions) =>
                    prevSelectedOptions.map((prevOption) =>
                        prevOption.key.localeCompare(option.key) === 0
                            ? {
                                  ...prevOption,
                                  checked,
                              }
                            : prevOption,
                    ),
                )
            }
            searchQuery={searchQuery}
        />
    ));

    const numMatchingOptions: number = useMemo(
        () => getNumMatchingOptions(selectedOptions, searchQuery),
        [selectedOptions, searchQuery],
    );

    return (
        <Popover
            opened={filterOpen}
            onChange={handleChange}
            position="bottom-start"
            closeOnClickOutside
            closeOnEscape
        >
            <Popover.Target>
                <Paper
                    onClick={
                        disabled
                            ? undefined
                            : (): void => {
                                  handleChange(!filterOpen);
                              }
                    }
                    px={12}
                    py={4}
                    bg={disabled ? "flexidaoGrey.1" : "white"}
                    sx={{
                        cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "target",
                    })}
                >
                    <Group position="apart" align="center">
                        <Stack spacing={2}>
                            <Title fz="12px" lh="14px" fw={600}>
                                {title}
                            </Title>
                            <Text fz="12px" lh="14px" size="sm" c="flexidaoGrey.8">
                                {numAppliedOptions ?? numOptions} of {numOptions} selected
                            </Text>
                        </Stack>

                        <IconChevronDown size={16} />
                    </Group>
                </Paper>
            </Popover.Target>

            <Popover.Dropdown
                p={0}
                sx={(theme: MantineTheme): CSSObject => ({
                    border: "none",
                    borderRadius: theme.radius.md,
                })}
            >
                <Paper
                    p={0}
                    sx={{
                        minWidth: "250px",
                        maxWidth: "400px",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "dropdown",
                    })}
                >
                    <Stack spacing={0}>
                        <Checkbox
                            checked={areAllChecked}
                            indeterminate={areSomeChecked}
                            label={selectAllLabel}
                            transitionDuration={0}
                            onChange={handleSelectAll}
                            sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "16px",
                                padding: "1rem",
                            }}
                            data-id={labelToDataId({
                                prefix: dataIdPrefix,
                                label: "select-all",
                            })}
                        />

                        <Stack spacing={0} justify="end" px="md" pb="md">
                            <SearchInput
                                searchQuery={searchQuery}
                                onSearchQueryChange={setSearchQuery}
                                disabled={disabled}
                            />
                            <SearchResultsSummary
                                searchQuery={searchQuery}
                                numMatches={numMatchingOptions}
                            />
                        </Stack>

                        <Divider />

                        <Stack
                            spacing={0}
                            pt="xs"
                            pb="xs"
                            sx={{
                                maxHeight: "300px",
                                overflowY: "auto",
                            }}
                        >
                            {selectedOptionsJsx}
                        </Stack>
                    </Stack>

                    <Divider />

                    <Group p="md" position="right">
                        <Button
                            onClick={handleRegionsFilterApplied}
                            disabled={disableApplyButton}
                            data-id={labelToDataId({
                                prefix: dataIdPrefix,
                                label: "apply",
                            })}
                            style={{
                                textTransform: "uppercase",
                            }}
                        >
                            Apply selection
                        </Button>
                    </Group>
                </Paper>
            </Popover.Dropdown>
        </Popover>
    );
};
