import { TrackingInstrumentType } from "@flexidao/dto";

export const formatTrackingInstrumentType = (
    trackingInstrumentType: TrackingInstrumentType,
): string => {
    switch (trackingInstrumentType) {
        case TrackingInstrumentType.EAC:
            return "EAC";
        case TrackingInstrumentType.Attestation:
            return "Attestation";
        default:
            return "Unknown";
    }
};
