import { ReactNode } from "react";
import { Stack, Text } from "@mantine/core";

export const getColor = (color: "red" | "green"): "flexidaoRed" | "flexidaoGreen" => {
    switch (color) {
        case "red":
            return "flexidaoRed";
        case "green":
            return "flexidaoGreen";
    }
};

export const getJsxFromContent = (content: Array<string> | ReactNode): ReactNode => {
    if (Array.isArray(content)) {
        return (
            <Stack spacing="16px">
                {content.map((item, i) => (
                    <Text key={i}>{item}</Text>
                ))}
            </Stack>
        );
    }

    return content;
};
