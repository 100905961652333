import { AuthPage } from "@flexidao/dto";
import { APP_PREFIX } from "./consts";

const basePath: string = `/${APP_PREFIX}/bim`;

export const authPaths = {
    [AuthPage.Login]: `${basePath}/${AuthPage.Login}`,
    [AuthPage.Verify]: `${basePath}/${AuthPage.Verify}`,
    [AuthPage.LoginError]: `${basePath}/${AuthPage.LoginError}`,
    [AuthPage.TenantPending]: `${basePath}/${AuthPage.TenantPending}`,
    [AuthPage.TenantCancelled]: `${basePath}/${AuthPage.TenantCancelled}`,
    [AuthPage.AuthCallback]: `${basePath}/${AuthPage.AuthCallback}`,
    [AuthPage.NoDisplayOptions]: `${basePath}/${AuthPage.NoDisplayOptions}`,
};
