import {
    AssetCommissioningDate,
    ContractStartDate,
    CfeDiagnosticContractType,
    EnergySource as EnergySourceEnum,
    EstimationStatus,
    IndustryType as IndustryTypeEnum,
    ZoneDataAccessLevel,
} from "./";

export type IKind<T> = {
    kind: T;
};

export enum CfeDiagnosticFeatureFlags {}

export namespace CfeDiagnosticDto {
    export type ContractType = {
        contractTypeId: CfeDiagnosticContractType;
        isCfeContract: boolean;
        name: string;
        energySources: Array<EnergySource>;
    };

    export type EnergySource = {
        energySourceId: EnergySourceEnum;
        isCfe: boolean;
        name: string;
    };

    export type Country = {
        countryId: string;
        name: string;
        enabled: boolean;
        zones: Array<{
            zoneId: string;
            name: string;
            enabled: boolean;
        }>;
    };

    export type Market = {
        startTime: Date;
        endTime: Date;
        countries: Array<string>;
    };

    export type IndustryType = {
        industryTypeId: IndustryTypeEnum;
        name: string;
    };

    export type Estimation = {
        estimationId: string;
        tenantId: string;
        name: string;
        status: EstimationStatus;
        input: EstimationInput;
        output?: EstimationOutput | null;
        updated: Date;
    };

    export type ZoneEstimation = {
        totalContracted_Wh: number;
        zoneId: string;
        zoneName: string;
        dataAccess: ZoneDataAccessLevel;
        countryId: string;
        countryName: string;
        cfeScore: number;
        contractedCfeScore: number;
        re100Score: number;
        totalConsumption_Wh: number;
        inducedEmissions_gCO2: number;
        avoidedEmissions_gCO2: number;
        hourlyAverages24h: Array<HourlyAverage>;
        hourlyCfeScores8760h: Array<number | null>;
        hourlyLocationBasedCo2Emissions_gCO2: number;
        yearlyLocationBasedCo2Emissions_gCO2: number;
        hourlyMarketBasedCo2Emissions_gCO2: number;
        yearlyMarketBasedCo2Emissions_gCO2: number;
    };

    export type EstimationOutput = {
        zones: Array<ZoneEstimation>;
    };

    export type EstimationBrief = {
        estimationId: string;
        name: string;
        status: EstimationStatus;
        updated: Date;
    };

    export type EstimationGetAll = {
        totalPages: number;
        estimations: Array<EstimationBrief>;
    };

    export type HourlyAverage = {
        contractedCfe_Wh: number;
        renewableMix_Wh: number;
        nonCfeGridMix_Wh: number;
        excess_Wh: number;
        consumption_Wh: number;
    };

    export enum DataSource {
        Estimated = "estimated",
        Uploaded = "uploaded",
    }

    export type ContractData =
        | (IKind<DataSource.Uploaded> & {
              uploadedProduction_kWh: ReadonlyArray<number>;
          })
        | (IKind<DataSource.Estimated> & {
              yearlyCoverageMWh: number;
          });

    export type Contract = {
        contractTypeId: CfeDiagnosticContractType;
        energySourceId: EnergySourceEnum;
        countryOfProductionId: string;
        zoneOfProductionId: string;
        data: ContractData;
        isKnownAsset: boolean;
        contractStartDate?: ContractStartDate;
        assetCommissioningDate?: AssetCommissioningDate;
    };

    export type ConsumptionData =
        | (IKind<DataSource.Uploaded> & {
              uploadedConsumption_kWh: ReadonlyArray<number>;
          })
        | (IKind<DataSource.Estimated> & {
              yearlyConsumptionMWh: number;
          });

    export type Consumption = {
        industryTypeId: IndustryTypeEnum;
        data: ConsumptionData;
    };

    export type ZoneInput = {
        countryId: string;
        zoneId: string;
        consumption: ReadonlyArray<Consumption>;
        contracts: ReadonlyArray<Contract>;
    };

    export type EstimationInput = {
        name: string;
        zones: ReadonlyArray<ZoneInput>;
    };

    export type ZoneCountry = {
        zoneId: string;
        name: string;
        enabled: boolean;
    };

    export type ZoneDto = {
        zoneId: string;
        name: string;
        country: Country;
        timezone: string;
        enabled: boolean;
    };

    export type ZoneDtoNoCountryZones = {
        zoneId: string;
        name: string;
        dataAccess: ZoneDataAccessLevel;
        country: Omit<Country, "zones">;
        timezone: string;
        enabled: boolean;
    };

    export type ContractTypeDto = {
        contractTypeId: CfeDiagnosticContractType;
        isCfeContract: boolean;
        name: string;
        energySources: Array<EnergySource>;
    };
}
