import { CfeDiagnosticPage, Module } from "@flexidao/dto";
import { APP_PREFIX } from "./consts";

const basePath = `/${APP_PREFIX}/${Module.CfeDiagnostic}`;

type CfeDiagnosticPaths = {
    [CfeDiagnosticPage.Estimations]: string;
    [CfeDiagnosticPage.EditEstimation]: (estimationId: string) => string;
    [CfeDiagnosticPage.EstimationResult]: (estimationId: string) => string;
    [CfeDiagnosticPage.NewEstimation]: string;
};

export const cfeDiagnosticPaths: CfeDiagnosticPaths = {
    // CFE Diagnostic
    [CfeDiagnosticPage.Estimations]: `${basePath}/${CfeDiagnosticPage.Estimations}`,
    [CfeDiagnosticPage.EditEstimation]: (estimationId) =>
        `${basePath}/${CfeDiagnosticPage.EditEstimation}/${estimationId}`,
    [CfeDiagnosticPage.EstimationResult]: (estimationId) =>
        `${basePath}/${CfeDiagnosticPage.EstimationResult}/${estimationId}`,
    [CfeDiagnosticPage.NewEstimation]: `${basePath}/${CfeDiagnosticPage.NewEstimation}`,
};
