import { ReactNode } from "react";
import { MRT_ColumnDef } from "mantine-react-table";
import {
    BooleanCell,
    CustomCell,
    DateCell,
    DatetimeCell,
    DivisionCell,
    NumberCell,
    StringCell,
} from "../../cells";
import {
    BooleanColumnType,
    Column,
    ColumnAlign,
    ColumnType,
    CustomColumnType,
    DateColumnType,
    DatetimeColumnType,
    DivisionColumnType,
    NumberColumnType,
    StringColumnType,
} from "../types";
import {
    DATE_COLUMN_DEFAULT_WIDTH,
    DATETIME_COLUMN_DEFAULT_WIDTH,
    NUMBER_COLUMN_DEFAULT_WIDTH,
} from "../consts";
import { alignColumn } from "./align-column";
import { RowData } from "../../types";

export const addCellToColumn = <T extends RowData>(
    columnDef: MRT_ColumnDef<T>,
    column: Column<T>,
): MRT_ColumnDef<T> => {
    switch (column.columnType) {
        case ColumnType.Number:
            return alignColumn(column.textAlign ?? ColumnAlign.End, {
                ...columnDef,
                size: columnDef.size ?? NUMBER_COLUMN_DEFAULT_WIDTH,
                minSize: columnDef.minSize ?? NUMBER_COLUMN_DEFAULT_WIDTH,
                maxSize: columnDef.maxSize ?? NUMBER_COLUMN_DEFAULT_WIDTH,
                Cell: ({ cell, row }) => {
                    const cellValue = cell.getValue<NumberColumnType>();
                    const { isSubRow } = row.original;

                    const { value, numDecimals, units, physicalRound, color, weight } =
                        cellValue ?? {
                            value: null,
                            numDecimals: null,
                            units: null,
                            physicalRound: false,
                            color: null,
                            weight: null,
                        };

                    return (
                        <NumberCell
                            value={value}
                            numDecimals={numDecimals}
                            physicalRound={physicalRound}
                            units={units}
                            color={color}
                            weight={weight}
                            isSubRow={isSubRow}
                        />
                    );
                },
            });
        case ColumnType.Division:
            return alignColumn(column.textAlign ?? ColumnAlign.End, {
                ...columnDef,
                Cell: ({ cell, row }) => {
                    const cellValue = cell.getValue<DivisionColumnType>();
                    const { isSubRow } = row.original;

                    const { value, total, units, color, numDecimals } = cellValue ?? {
                        value: null,
                        total: null,
                        units: null,
                        color: null,
                        numDecimals: null,
                    };

                    return (
                        <DivisionCell
                            value={value}
                            total={total}
                            units={units}
                            color={color}
                            numDecimals={numDecimals}
                            isSubRow={isSubRow}
                        />
                    );
                },
            });
        case ColumnType.Datetime:
            return alignColumn(column.textAlign ?? ColumnAlign.End, {
                ...columnDef,
                size: columnDef.size ?? DATETIME_COLUMN_DEFAULT_WIDTH,
                minSize: columnDef.minSize ?? DATETIME_COLUMN_DEFAULT_WIDTH,
                maxSize: columnDef.maxSize ?? DATETIME_COLUMN_DEFAULT_WIDTH,
                Cell: ({ cell, row }) => {
                    const cellValue = cell.getValue<DatetimeColumnType>();
                    const { isSubRow } = row.original;

                    const { date, timezone, timezoneLabel } = cellValue ?? {
                        date: null,
                        timezone: null,
                        timezoneLabel: null,
                    };

                    return (
                        <DatetimeCell
                            date={date}
                            timezone={timezone}
                            timezoneLabel={timezoneLabel}
                            isSubRow={isSubRow}
                        />
                    );
                },
            });
        case ColumnType.Date:
            return alignColumn(column.textAlign ?? ColumnAlign.End, {
                ...columnDef,
                size: columnDef.size ?? DATE_COLUMN_DEFAULT_WIDTH,
                minSize: columnDef.minSize ?? DATE_COLUMN_DEFAULT_WIDTH,
                maxSize: columnDef.maxSize ?? DATE_COLUMN_DEFAULT_WIDTH,
                Cell: ({ cell, row }) => {
                    const cellValue = cell.getValue<DateColumnType>();
                    const { isSubRow } = row.original;

                    const { date, timezone } = cellValue ?? {
                        date: null,
                        timezone: null,
                    };

                    return <DateCell date={date} timezone={timezone} isSubRow={isSubRow} />;
                },
            });
        case ColumnType.Boolean:
            return alignColumn(column.textAlign ?? ColumnAlign.Center, {
                ...columnDef,
                Cell: ({ cell, row }) => {
                    const value = cell.getValue<BooleanColumnType>();
                    const { isSubRow } = row.original;

                    return (
                        <BooleanCell
                            value={value}
                            textAlign={column.textAlign}
                            isSubRow={isSubRow}
                        />
                    );
                },
            });
        case ColumnType.String:
            return alignColumn(column.textAlign ?? ColumnAlign.Start, {
                ...columnDef,
                Cell: ({ cell, row }) => {
                    const value = cell.getValue<StringColumnType>();
                    const { isSubRow } = row.original;

                    return <StringCell value={value} isSubRow={isSubRow} />;
                },
            });
        case ColumnType.Custom:
            return alignColumn(column.textAlign ?? ColumnAlign.Start, {
                ...columnDef,
                Cell: ({ cell, row, table, column, renderedCellValue, rowRef }) => {
                    const value = cell.getValue<CustomColumnType<T>>();
                    const { isSubRow } = row.original;

                    const children: ReactNode =
                        typeof value === "function"
                            ? value({ cell, row, rowRef, table, column, renderedCellValue })
                                  .reactElement
                            : value.reactElement;

                    return <CustomCell isSubRow={isSubRow}>{children}</CustomCell>;
                },
            });
    }
};
