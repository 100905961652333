export enum EntryPointFeatureFlags {
    GridEmissions = "grid-emissions",
    CfeDiagnostic = "cfe-diagnostic",
    TwentyFourSeven = "twenty-four-seven",
    ReportHub = "report-hub",
    EacData = "eac-data",
    MeterData = "meter-data",
    /** Shows the reporting dashboard page from report hub.\
     * This should not be here but the menu was created in entry point
     * TODO: Wait for menu redesign from product and move shared menu to UI lib,
     * inject feature flags from each service as necessary
     * */
    ReportingDashboard = "reporting-dashboard",
}
