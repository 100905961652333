import {
    ColumnType,
    compareValues,
    getBooleanValue,
    getCustomValue,
    getDatetimeValue,
    getDateValue,
    getDivisionValue,
    getNumberValue,
    getStringValue,
    labelToDataId,
} from "@flexidao/ui-lib";
import { MRT_ColumnDef } from "mantine-react-table";
import { RowData } from "../../types";
import { Column, ColumnFilterProps, FilterType } from "../types";

const getFilterProps = <T extends RowData>(
    filterProps: ColumnFilterProps | null,
): Partial<MRT_ColumnDef<T>> => {
    const mrtFilterProps: Partial<MRT_ColumnDef<T>> = {
        enableColumnFilter: filterProps != null,
    };

    if (filterProps == null) {
        return mrtFilterProps;
    }

    switch (filterProps.filterType) {
        case FilterType.Text:
            mrtFilterProps.filterVariant = "text";
            break;
        case FilterType.Select:
            mrtFilterProps.filterVariant = "select";
            mrtFilterProps.mantineFilterSelectProps = {
                data: filterProps.options,
            };
            break;
        case FilterType.MultiSelect:
            mrtFilterProps.filterVariant = "multi-select";
            mrtFilterProps.mantineFilterMultiSelectProps = {
                data: filterProps.options,
            };
            break;
    }

    return mrtFilterProps;
};
export const getBaseColumn = <T extends RowData>({
    accessorKey,
    header,
    enableSorting = false,
    filterProps,
    size,
    minSize: minSize_,
    maxSize: maxSize_,
    grow,
    enableClickToCopy = false,
    columnType,
    Footer,
}: Column<T>): MRT_ColumnDef<T> => {
    const minSize: number | undefined = minSize_ ?? size;

    // If grow is defined and true, no maxSize is set
    const maxSize: number | undefined = grow ? undefined : (maxSize_ ?? size);

    const baseColumn: MRT_ColumnDef<T> = {
        mantineTableHeadCellProps: {
            id: labelToDataId({
                prefix: header,
                label: "table-header",
            }),
        },
        accessorKey,
        header,
        enableSorting,
        ...getFilterProps(filterProps ?? null),
        size,
        minSize,
        maxSize,
        enableClickToCopy,
        sortingFn: <
            TData extends {
                original: T;
            },
        >(
            { original: rowA }: TData,
            { original: rowB }: TData,
            columnId: string,
        ): number => {
            switch (columnType) {
                case ColumnType.Number:
                    return compareValues(
                        getNumberValue(rowA[columnId]),
                        getNumberValue(rowB[columnId]),
                    );
                case ColumnType.String:
                    return compareValues(
                        getStringValue(rowA[columnId]),
                        getStringValue(rowB[columnId]),
                    );
                case ColumnType.Boolean:
                    return compareValues(
                        getBooleanValue(rowA[columnId]),
                        getBooleanValue(rowB[columnId]),
                    );
                case ColumnType.Date:
                    return compareValues(
                        getDateValue(rowA[columnId]),
                        getDateValue(rowB[columnId]),
                    );
                case ColumnType.Datetime:
                    return compareValues(
                        getDatetimeValue(rowA[columnId]),
                        getDatetimeValue(rowB[columnId]),
                    );
                case ColumnType.Division:
                    return compareValues(
                        getDivisionValue(rowA[columnId]),
                        getDivisionValue(rowB[columnId]),
                    );
                case ColumnType.Custom:
                    return compareValues(
                        getCustomValue(rowA[columnId]),
                        getCustomValue(rowB[columnId]),
                    );
            }
        },
        Footer,
    };

    return baseColumn;
};
