import {
    EnergySource,
    IndustryType,
    MeterDataSimulationDto,
    meterDataSimulationPaths,
} from "@flexidao/dto";
import { ApiClient } from "../api-client";
import {
    simulateConsumptionResponseDecoder,
    simulateProductionResponseDecoder,
} from "../decoders/meter-data-simulation";
import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash } from "../utils";

export type MeterDataSimulationClient = {
    getPublicSimulateConsumption: (params: {
        industryType: IndustryType;
        totalEnergyKWh: number;
        startTime: Date;
        endTime: Date;
        meterDataSimulationKey: string;
    }) => ApiResponse<MeterDataSimulationDto.SimulateConsumptionResponse>;
    getPublicSimulateProduction: (params: {
        zone: string;
        totalEnergyKWh: number;
        energySource: EnergySource;
        startTime: Date;
        endTime: Date;
        meterDataSimulationKey: string;
    }) => ApiResponse<MeterDataSimulationDto.SimulateProductionResponse>;
} & ApiClient<meterDataSimulationPaths>;

export type OverloadMeterDataSimulationClient = {
    getPublicSimulateConsumption: (params: {
        industryType: IndustryType;
        totalEnergyKWh: number;
        startTime: Date;
        endTime: Date;
        meterDataSimulationKey: string;
    }) => OverloadApiResponse<MeterDataSimulationDto.SimulateConsumptionResponse>;
    getPublicSimulateProduction: (params: {
        zone: string;
        totalEnergyKWh: number;
        energySource: EnergySource;
        startTime: Date;
        endTime: Date;
        meterDataSimulationKey: string;
    }) => OverloadApiResponse<MeterDataSimulationDto.SimulateProductionResponse>;
} & ApiClient<meterDataSimulationPaths>;

export type ResultMeterDataSimulationClient = {
    getPublicSimulateConsumption: (params: {
        industryType: IndustryType;
        totalEnergyKWh: number;
        startTime: Date;
        endTime: Date;
        meterDataSimulationKey: string;
    }) => ApiResultResponse<MeterDataSimulationDto.SimulateConsumptionResponse>;
    getPublicSimulateProduction: (params: {
        zone: string;
        totalEnergyKWh: number;
        energySource: EnergySource;
        startTime: Date;
        endTime: Date;
        meterDataSimulationKey: string;
    }) => ApiResultResponse<MeterDataSimulationDto.SimulateProductionResponse>;
} & ApiClient<meterDataSimulationPaths>;

export function getMeterDataSimulationClient(config: {
    useMockData: boolean;
}): MeterDataSimulationClient;
export function getMeterDataSimulationClient(config: {
    useMockData: boolean;
    useResult: boolean;
}): ResultMeterDataSimulationClient;
export function getMeterDataSimulationClient(config: {
    useMockData: boolean;
    useResult?: boolean;
    useRetries?: boolean;
}): OverloadMeterDataSimulationClient {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        useMockData = process.env.MOCK_METER_DATA_SIMULATION === "true";

        if (!process.env.METER_DATA_SIMULATION_URL) {
            return crash("Missing METER_DATA_SIMULATION_URL environment variable.");
        } else {
            url = process.env.METER_DATA_SIMULATION_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }
    const { get } = buildQueries({ ...config, useMockData: useMockData }, url);

    return {
        getPublicSimulateConsumption: ({
            industryType,
            totalEnergyKWh,
            startTime,
            endTime,
            meterDataSimulationKey,
        }: {
            industryType: IndustryType;
            totalEnergyKWh: number;
            startTime: Date;
            endTime: Date;
            meterDataSimulationKey: string;
        }): OverloadApiResponse<MeterDataSimulationDto.SimulateConsumptionResponse> => {
            return get({
                path: "/api/meter-data-simulation/public/simulate-consumption",
                decoder: simulateConsumptionResponseDecoder,
                params: {
                    industryType,
                    totalEnergyKWh,
                    startTime: startTime.toISOString(),
                    endTime: endTime.toISOString(),
                },
                headers: {
                    "x-auth-key": meterDataSimulationKey,
                },
            });
        },
        getPublicSimulateProduction: ({
            zone,
            totalEnergyKWh,
            energySource,
            startTime,
            endTime,
            meterDataSimulationKey,
        }: {
            zone: string;
            totalEnergyKWh: number;
            energySource: EnergySource;
            startTime: Date;
            endTime: Date;
            meterDataSimulationKey: string;
        }): OverloadApiResponse<MeterDataSimulationDto.SimulateProductionResponse> => {
            return get({
                path: "/api/meter-data-simulation/public/simulate-production",
                decoder: simulateProductionResponseDecoder,
                params: {
                    zone,
                    totalEnergyKWh,
                    energySource,
                    startTime: startTime.toISOString(),
                    endTime: endTime.toISOString(),
                },
                headers: {
                    "x-auth-key": meterDataSimulationKey,
                },
            });
        },
    };
}
