import { AlertSeverity } from "./types";
import { IconAlertCircle, IconAlertTriangle, IconInfoCircle } from "@tabler/icons-react";
import { ReactElement } from "react";

export const getAlertColor = (severity: AlertSeverity): string => {
    switch (severity) {
        case AlertSeverity.Info:
            return "flexidaoGreen";
        case AlertSeverity.Warning:
            return "flexidaoOrange";
        case AlertSeverity.Error:
            return "flexidaoRed";
    }
};

export const getAlertIcon = (severity: AlertSeverity): ReactElement => {
    switch (severity) {
        case AlertSeverity.Info:
            return <IconInfoCircle />;
        case AlertSeverity.Warning:
            return <IconAlertTriangle />;
        case AlertSeverity.Error:
            return <IconAlertCircle />;
    }
};
