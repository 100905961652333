import { Space } from "@mantine/core";
import { ReactElement } from "react";

export type KpiSectionSpaceProps = {
    height: number;
    dataId?: string;
};

export const KpiSectionSpace = ({ height, dataId }: KpiSectionSpaceProps): ReactElement => {
    return <Space h={height} data-id={dataId} />;
};
