import { Group, Stack, Text } from "@mantine/core";
import { TooltipContainer } from "../../_utils/utils";
import { ReactElement } from "react";
import { AreaChartCurveSeries } from "../types";
import { formatNumber, labelToDataId } from "@flexidao/ui-lib";

type AreaChartTooltipProps = {
    tooltipLeft: number | undefined;
    tooltipTop: number | undefined;
    tooltipData?: AreaChartCurveSeries;
    tooltipDateFormatter: (date: Date) => string;
};

export const AreaChartTooltip = ({
    tooltipLeft,
    tooltipTop,
    tooltipData,
    tooltipDateFormatter,
}: AreaChartTooltipProps): ReactElement => {
    const showTooltip = tooltipTop != null && tooltipLeft != null;

    const date: Date | null = tooltipData?.seriesData[0]?.date ?? null;

    return showTooltip ? (
        <TooltipContainer tooltipTop={tooltipTop} tooltipLeft={tooltipLeft}>
            <Stack spacing="xs">
                <Text weight="bold" size="sm">
                    {date ? tooltipDateFormatter(date) : "-"}
                </Text>
                {tooltipData && (
                    <Group
                        key={tooltipData.seriesLabel}
                        data-id={labelToDataId({
                            prefix: "tooltip-item",
                            label: tooltipData.seriesLabel,
                        })}
                        spacing={4}
                    >
                        <svg width={12} height={12}>
                            <circle fill={tooltipData.seriesColor} r={6} cx={6} cy={6} />
                        </svg>
                        <Text size="sm" fw="bold">
                            {tooltipData.seriesLabel}:
                        </Text>
                        &nbsp;
                        <Text size="sm">
                            {formatNumber(tooltipData.seriesData[0]?.value ?? null, 0)}
                        </Text>
                    </Group>
                )}
            </Stack>
        </TooltipContainer>
    ) : (
        <></>
    );
};
