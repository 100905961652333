import { ReactElement } from "react";

export type KpiSectionCustomProps = {
    component: ReactElement;
    dataId?: string;
};

export const KpiSectionCustom = ({ component, dataId }: KpiSectionCustomProps): ReactElement => {
    return (
        <div
            data-id={dataId}
            style={{
                height: "100%",
                width: "100%",
            }}
        >
            {component}
        </div>
    );
};
