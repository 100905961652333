import { ReactElement } from "react";
import { TextInput } from "@mantine/core";
import { IconSearch } from "@flexidao/ui-lib";

type SearchInputProps = {
    searchQuery: string;
    onSearchQueryChange: (value: string) => void;
    disabled?: boolean;
};

export const SearchInput = ({
    searchQuery,
    onSearchQueryChange,
    disabled,
}: SearchInputProps): ReactElement => (
    <TextInput
        placeholder="Type to search..."
        icon={<IconSearch />}
        value={searchQuery}
        onChange={(event): void => onSearchQueryChange(event.currentTarget.value)}
        disabled={disabled}
    />
);
