import {
    AxisBottom as VisxAxisBottom,
    AxisLeft as VisxAxisLeft,
    AxisRight as VisxAxisRight,
    TickFormatter as VisxTickFormatter,
} from "@visx/axis";
import { NumberValue, ScaleBand, ScaleLinear, ScaleTime } from "d3-scale";
import { ReactElement } from "react";
import { AXIS_FONT_SIZE, getDefaultRightAxisTickLabelProps } from "../utils";

export type TickFormatter<T = any> = VisxTickFormatter<T>;

type AxisLeftProps = {
    axisColor: string;
    yScale: ScaleLinear<number, number, never>;
    numTicks: number;
    tickFormat?: TickFormatter<NumberValue>;
    hideTicks?: boolean;
    tickLabelProps?: (value: NumberValue, index: number, values: NumberValue[]) => any;
    hideZero?: boolean;
};

type AxisRightProps = AxisLeftProps & { innerWidth: number };
export const AxisLeft = ({
    axisColor,
    yScale,
    tickFormat,
    numTicks,
    hideZero = false,
}: AxisLeftProps): ReactElement => {
    return (
        <VisxAxisLeft
            stroke={axisColor}
            tickStroke={axisColor}
            numTicks={numTicks}
            scale={yScale}
            hideZero={hideZero}
            tickLabelProps={(): {
                fill: string;
                fontSize: number;
                textAnchor: "end";
                dy: string;
                dx: string;
            } => ({
                fill: axisColor,
                fontSize: AXIS_FONT_SIZE,
                textAnchor: "end",
                dy: "0.33em",
                dx: "-0.5em",
            })}
            {...(tickFormat ? { tickFormat: tickFormat } : {})}
        />
    );
};

export const AxisRight = ({
    innerWidth,
    axisColor,
    yScale,
    numTicks,
    tickFormat,
    hideTicks,
    tickLabelProps,
    hideZero = true,
}: AxisRightProps): ReactElement => {
    return (
        <VisxAxisRight
            left={innerWidth}
            stroke={axisColor}
            tickStroke={axisColor}
            numTicks={numTicks}
            hideZero={hideZero}
            hideTicks={hideTicks ?? false}
            scale={yScale}
            tickLabelProps={
                tickLabelProps !== undefined
                    ? (
                          value: NumberValue,
                          index,
                      ): {
                          fill: string;
                          fontSize: number;
                          dy: string;
                          dx: string;
                      } => tickLabelProps(value, index, [])
                    : (): {
                          fill: string;
                          fontSize: number;
                          textAnchor: string;
                          dy: string;
                          dx: string;
                      } => getDefaultRightAxisTickLabelProps(axisColor, AXIS_FONT_SIZE)
            }
            {...(tickFormat ? { tickFormat: tickFormat } : {})}
        />
    );
};

type AxisBottomProps = (AxisBottomTime | AxisBottomLinear | AxisBottomBand) & {
    axisColor: string;
    top: number;
    numTicks: number;
    tickFormat?: TickFormatter;
    textAnchor?: "end" | "start" | "middle" | "inherit";
    hideTicks?: boolean;
};

type AxisBottomTime = {
    kind: "time";
    xScale: ScaleTime<number, number, never>;
};

type AxisBottomLinear = {
    kind: "linear";
    xScale: ScaleLinear<number, number, never>;
};

type AxisBottomBand = {
    kind: "band";
    xScale: ScaleBand<number>;
};
export const AxisBottom = ({
    axisColor,
    numTicks,
    top,
    xScale,
    tickFormat,
    textAnchor,
    hideTicks = true,
}: AxisBottomProps): ReactElement => {
    return (
        <VisxAxisBottom
            stroke={axisColor}
            tickStroke={axisColor}
            top={top}
            hideTicks={hideTicks}
            numTicks={numTicks}
            scale={xScale}
            tickLabelProps={(): {
                fill: string;
                fontSize: number;
                textAnchor: "end" | "start" | "middle" | "inherit";
            } => ({
                fill: axisColor,
                fontSize: AXIS_FONT_SIZE,
                textAnchor: textAnchor ?? "middle",
            })}
            {...(tickFormat ? { tickFormat: tickFormat } : {})}
        />
    );
};
