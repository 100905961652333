import { PaperProps } from "@mantine/core";
import { flexidaoGrey } from "@flexidao/ui-lib/mantine-theme/colours/flexidao-colors";

export const paperDefaultProps: Partial<PaperProps> = {
    shadow: "xs",
    radius: "md",
    p: "xl",
    style: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: flexidaoGrey[2],
        overflow: "hidden",
    },
};
