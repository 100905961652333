import { ReactElement } from "react";
import { Text } from "@mantine/core";
import { getClassNameForSubRow } from "./utils";

type EmptyCellProps = {
    isSubRow?: boolean;
};
export const EmptyCell = ({ isSubRow = false }: EmptyCellProps): ReactElement => {
    const content: string = isSubRow ? "" : "-";

    return (
        <Text size={14} className={getClassNameForSubRow(isSubRow)} c="flexidaoGrey.8">
            {content}
        </Text>
    );
};
