import { AuthPage } from "@flexidao/dto";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authPaths } from "../../../index";
import { useAppUser } from "../../hooks/use-app-user";
import { AuthenticatedComponent } from "./components/authenticated-component";
import { BooleanDisplayComponent } from "./components/boolean-display-component";

type UserInitializedComponentProps = {
    content: ReactNode;
    fallback?: ReactNode;
    skeleton?: ReactNode;
};
/**
 * Contains logic for displaying `content`/`fallback`/`skeleton` components depending on whether user is loaded and current tenant is set.
 */
export const UserInitializedComponent = ({
    content,
    fallback = null,
    skeleton = null,
}: UserInitializedComponentProps): JSX.Element => {
    const { user, isLoading } = useAppUser();
    const navigate = useNavigate();
    const haveUser = !!user && !!user.activeTenant;

    useEffect(() => {
        if (haveUser && user.activeTenant.status === "pending") {
            navigate(authPaths[AuthPage.TenantPending]);
        }
        if (haveUser && user.activeTenant.status === "cancelled") {
            navigate(authPaths[AuthPage.TenantCancelled]);
        }
    }, [user, haveUser, navigate]);

    return (
        <AuthenticatedComponent
            skeleton={skeleton}
            content={
                <BooleanDisplayComponent
                    isLoading={isLoading}
                    condition={haveUser}
                    content={content}
                    fallback={fallback}
                    skeleton={skeleton}
                />
            }
        />
    );
};
