import { ButtonProps } from "@mantine/core";
import { flexidaoGrey } from "../colours/flexidao-colors";

export const buttonDefaultProps: Partial<ButtonProps> = {
    sx: {
        "&.outline:disabled, &.outline[data-disabled]": {
            backgroundColor: "transparent",
            color: flexidaoGrey[3],
            borderColor: flexidaoGrey[3],
            cursor: "not-allowed",
        },
    },
};
