import { AuthPage } from "@flexidao/dto";
import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    AppLoading,
    UserInitializedComponent,
    authPaths,
    useTenantDisplay,
} from "@flexidao/ui-lib";

const Redirect = (): JSX.Element => {
    const navigate = useNavigate();
    const displaySettings = useTenantDisplay();
    const homepage = displaySettings.homepage;
    useEffect(() => {
        const path = homepage
            ? `/app/${homepage.moduleId}/${homepage.pageId}`
            : authPaths[AuthPage.NoDisplayOptions];
        navigate(path);
    });
    return <></>;
};

export const HomepageRedirect = (): ReactElement => (
    <UserInitializedComponent skeleton={<AppLoading />} content={<Redirect />} />
);
