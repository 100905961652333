import { EnergySource } from "@flexidao/dto";

export const formatEnergySource = (energySourceId: EnergySource | null): string => {
    switch (energySourceId) {
        case EnergySource.Biomass:
            return "Biomass";
        case EnergySource.Coal:
            return "Coal";
        case EnergySource.Gas:
            return "Gas";
        case EnergySource.Geothermal:
            return "Geothermal";
        case EnergySource.Hydro:
            return "Hydro";
        case EnergySource.Nuclear:
            return "Nuclear";
        case EnergySource.Oil:
            return "Oil";
        case EnergySource.Other:
            return "Other";
        case EnergySource.Solar:
            return "Solar";
        case EnergySource.Wind:
            return "Wind";
        case EnergySource.RenewableUnknown:
            return "Renewable Unknown";
        default:
            return "Unknown";
    }
};
