import { ReactElement, ReactNode } from "react";
import { Group, MantineTheme, useMantineTheme } from "@mantine/core";
import { getColorFromType, getFormattedChildren, getIconFromType } from "./utils";
import { ChipType } from "./types";

type ChipProps = {
    type: ChipType;
    children: ReactNode;
};

export const Chip = ({ type, children }: ChipProps): ReactElement => {
    const theme: MantineTheme = useMantineTheme();

    const color: string = getColorFromType(type, theme);
    const icon: ReactElement = getIconFromType(type, color);

    const formattedChildren: ReactElement = getFormattedChildren(children);

    return (
        <Group
            noWrap
            spacing="4px"
            sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: color,
                borderRadius: "32px",
            }}
            px="8px"
            py="4px"
            h="24px"
            align="center"
        >
            {icon}
            {formattedChildren}
        </Group>
    );
};
