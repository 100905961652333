import { flexidaoGrey } from "@flexidao/ui-lib/mantine-theme/colours/flexidao-colors";

export const STROKE_COLOR = flexidaoGrey[9];
export const STROKE_WIDTH = 1;
export const STROKE_OPACITY = 0.5;

export const FILL_COLOR = flexidaoGrey[9];
export const FILL_OPACITY = 0.2;

export const BRUSH_HEIGHT = 60;
export const BRUSH_MARGIN = { top: 0, right: 24, bottom: 0, left: 0 };
export const CHART_BRUSH_MARGIN = { top: 0, right: 0, bottom: 0, left: 0 };
export const CHART_BRUSH_PADDING = { bottom: 0, left: 0, right: 24, top: 0 };
export const BRUSH_HANDLE_WIDTH = 8;
export const BRUSH_HANDLE_HEIGHT = 15;

export const TWO = 2;
