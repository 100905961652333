import { notifications } from "@mantine/notifications";
import { Input, stringify } from "csv-stringify/browser/esm";

const LINK_DESTROY_TIMEOUT_MS = 500;

/**
 * Creates a CSV and starts download, errors logged to console and notification shown
 * @param data Input data to create the CSV
 * @param filename the filename for the download
 */
export const downloadCsv = (data: Input, filename: string): void => {
    try {
        stringify(data, (err, csvData) => {
            if (err) {
                throw err;
            }
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            link.click();
            link.addEventListener("click", () => {
                setTimeout(() => {
                    URL.revokeObjectURL(url);
                    link.remove();
                }, LINK_DESTROY_TIMEOUT_MS);
            });
        });
    } catch (err) {
        console.error(err);
        notifications.show({ color: "flexidaoRed", message: "Error creating CSV" });
    }
};
