import { LoadingOverlay, LoadingOverlayProps } from "@mantine/core";
import { ReactElement, RefAttributes } from "react";

export const LoadingOverlayFullscreen = (
    props: LoadingOverlayProps & RefAttributes<HTMLDivElement>,
): ReactElement => {
    return (
        <LoadingOverlay
            sx={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh" }}
            {...props}
        />
    );
};
