import { GridEmissionsDto } from "@flexidao/dto";
import * as D from "schemawax";
import { isoTimestampDecoder } from "../utils";
import { dataAccessDecoder, sourceTypeDecoder } from "./misc";

export const hourlyIntensitiesResponseDecoder: D.Decoder<
    Record<string, Array<GridEmissionsDto.ZoneIntensityDto>>
> = D.record(
    D.array(
        D.object({
            required: {
                endTime: isoTimestampDecoder,
                startTime: isoTimestampDecoder,
                gridIntensity: D.number,
                cfeShare: D.number,
                reShare: D.number,
                totalProduction: D.number,
            },
        }),
    ),
);

export const zoneResponseDecoder: D.Decoder<Array<GridEmissionsDto.ZoneDto>> = D.array(
    D.object({
        required: {
            zoneId: D.string,
            name: D.string,
            countryISO: D.string,
            enabled: D.boolean,
            initialTimeToCheck: D.nullable(isoTimestampDecoder),
            endTimeToCheck: D.nullable(isoTimestampDecoder),
        },
    }),
);

export const electricityMapDataResponseDecoder: D.Decoder<
    Array<GridEmissionsDto.ElectricityMapDataDto>
> = D.array(
    D.object({
        required: {
            url: D.string,
            path: D.string,
            parameters: D.unknown,
            response: D.unknown,
        },
    }),
);

export const zonesEmissionsRatesResponseDecoder: D.Decoder<Array<GridEmissionsDto.EmissionRate>> =
    D.array(
        D.object({
            required: {
                zoneId: D.string,
                year: D.number,
                rateId: D.string,
                value: D.number,
            },
        }),
    );

export const sourceDecoder = D.object({
    required: {
        sourceId: D.string,
        name: D.string,
        sourceType: sourceTypeDecoder,
    },
});

export const sourceArrayDecoder = D.array(sourceDecoder);

export const countryDecoder: D.Decoder<GridEmissionsDto.Country> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
    },
});

export const biddingZoneDecoder: D.Decoder<GridEmissionsDto.BiddingZone> = D.object({
    required: {
        zoneId: D.string,
        name: D.string,
        country: countryDecoder,
        timezones: D.array(D.string), //timeZoneDecoder,
        enabled: D.boolean,
        dataAccess: dataAccessDecoder,
    },
});

export const co2EmissionFactorDecoder: D.Decoder<GridEmissionsDto.Co2EmissionFactor> = D.object({
    required: {
        tenantId: D.string,
        co2EmissionFactorId: D.string,
        country: countryDecoder,
        biddingZone: biddingZoneDecoder,
        year: D.number,
        annualDataSourceLocationBased: D.nullable(D.string),
        annualDataSourceMarketBased: D.nullable(D.string),
        hourlyDataSourceLocationBased: D.nullable(D.string),
        hourlyDataSourceMarketBased: D.nullable(D.string),
        emissionalityDataSource: D.nullable(D.string),
    },
});

export const co2EmissionFactorsArrayDecoder: D.Decoder<Array<GridEmissionsDto.Co2EmissionFactor>> =
    D.array(co2EmissionFactorDecoder);

export const getCo2EmissionFactorsDecoder: D.Decoder<GridEmissionsDto.GetCo2EmissionFactorsResponse> =
    D.object({
        required: {
            totalPages: D.number,
            co2EmissionFactors: co2EmissionFactorsArrayDecoder,
        },
    });

export const biddingZoneInCountryDecoder: D.Decoder<GridEmissionsDto.BiddingZoneInCountry> =
    D.object({
        required: {
            zoneId: D.string,
            name: D.string,
            enabled: D.boolean,
            timezones: D.array(D.string),
        },
    });

export const countryWithZonesDecoder: D.Decoder<GridEmissionsDto.CountryWithZones> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
        biddingZones: D.array(biddingZoneInCountryDecoder),
    },
});

export const countriesWithZonesArrayDecoder: D.Decoder<Array<GridEmissionsDto.CountryWithZones>> =
    D.array(countryWithZonesDecoder);
