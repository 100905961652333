import { ReactElement } from "react";
import { Title } from "@mantine/core";

type HeaderProps = {
    dataId: string;
    title: string;
};
export const Header = ({ dataId, title }: HeaderProps): ReactElement => (
    <Title order={1} data-id={dataId}>
        {title}
    </Title>
);
