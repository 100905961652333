import {
    MeterDataDto,
    SanityWarning,
    SiteLevelWarning,
    validateStrictDecoder,
} from "@flexidao/dto";
import * as D from "schemawax";
import { isoTimestampDecoder } from "../utils";
import {
    expectedIntervalReadingTypeDecoder,
    expectedReadingGranularityDecoder,
    providerDecoder,
    siteTypeDecoder,
} from "./misc";

export const basicMeterDecoder: D.Decoder<MeterDataDto.BasicMeter> =
    validateStrictDecoder<MeterDataDto.BasicMeter>()(
        D.object({
            required: {
                meterId: D.number,
                meterProviderCode: D.string,
                tenantId: D.string,
                providerId: providerDecoder,
                enabled: D.boolean,
                initialTimeToCheckUtc: isoTimestampDecoder,
                siteId: D.nullable(D.string),
                endTimeToCheckUtc: D.nullable(isoTimestampDecoder),
                expectedIntervalReadingType: expectedIntervalReadingTypeDecoder,
                expectedReadingGranularity: expectedReadingGranularityDecoder,
                expectedDelayDays: D.nullable(D.number),
                shareOfEnergyAtProvider: D.number,
            },
        }),
    );

export const basicMeterArrayDecoder: D.Decoder<Array<MeterDataDto.BasicMeter>> =
    validateStrictDecoder<Array<MeterDataDto.BasicMeter>>()(D.array(basicMeterDecoder));

export const intervalMeterDecoder: D.Decoder<MeterDataDto.IntervalMeter> =
    validateStrictDecoder<MeterDataDto.IntervalMeter>()(
        D.object({
            required: {
                meterId: D.number,
                meterCode: D.string,
                siteName: D.nullable(D.string),
                regionName: D.nullable(D.string),
                dataProvider: D.string,
                expectedIntervalReadingType: expectedIntervalReadingTypeDecoder,
                expectedReadingGranularity: expectedReadingGranularityDecoder,
                expectedDelayDays: D.nullable(D.number),
                initialTimeToCheckUtc: isoTimestampDecoder,
                endTimeToCheckUtc: D.nullable(isoTimestampDecoder),
                shareOfEnergyAtProvider: D.number,
            },
        }),
    );

export const getIntervalMetersResponseDecoder: D.Decoder<MeterDataDto.GetIntervalMetersResponse> =
    validateStrictDecoder<MeterDataDto.GetIntervalMetersResponse>()(
        D.object({
            required: {
                meters: D.array(intervalMeterDecoder),
                totalCount: D.number,
            },
        }),
    );

export const getIntervalMetersFiltersResponseDecoder: D.Decoder<MeterDataDto.GetIntervalMetersFiltersResponse> =
    validateStrictDecoder<MeterDataDto.GetIntervalMetersFiltersResponse>()(
        D.object({
            required: {
                sites: D.array(
                    D.nullable(
                        D.object({
                            required: {
                                siteId: D.string,
                                siteName: D.string,
                            },
                        }),
                    ),
                ),
                regions: D.array(
                    D.nullable(
                        D.object({
                            required: {
                                regionId: D.string,
                                regionName: D.string,
                            },
                        }),
                    ),
                ),
                meterTypes: D.array(
                    D.object({
                        required: {
                            meterType: expectedIntervalReadingTypeDecoder,
                        },
                    }),
                ),
            },
        }),
    );

export const billingMeterDecoder: D.Decoder<MeterDataDto.BillingMeter> =
    validateStrictDecoder<MeterDataDto.BillingMeter>()(
        D.object({
            required: {
                meterId: D.number,
                meterCode: D.string,
                siteName: D.nullable(D.string),
                regionName: D.nullable(D.string),
                dataProvider: D.string,
                initialTimeToCheckUtc: isoTimestampDecoder,
                endTimeToCheckUtc: D.nullable(isoTimestampDecoder),
                shareOfEnergyAtProvider: D.number,
            },
        }),
    );

export const getBillingMetersResponseDecoder: D.Decoder<MeterDataDto.GetBillingMetersResponse> =
    validateStrictDecoder<MeterDataDto.GetBillingMetersResponse>()(
        D.object({
            required: {
                meters: D.array(billingMeterDecoder),
                totalCount: D.number,
            },
        }),
    );

export const getBillingMetersFiltersResponseDecoder: D.Decoder<MeterDataDto.GetBillingMetersFiltersResponse> =
    validateStrictDecoder<MeterDataDto.GetBillingMetersFiltersResponse>()(
        D.object({
            required: {
                sites: D.array(
                    D.nullable(
                        D.object({
                            required: {
                                siteId: D.string,
                                siteName: D.string,
                            },
                        }),
                    ),
                ),
                regions: D.array(
                    D.nullable(
                        D.object({
                            required: {
                                regionId: D.string,
                                regionName: D.string,
                            },
                        }),
                    ),
                ),
            },
        }),
    );

const dataPerMeterRowDecoder: D.Decoder<MeterDataDto.MeterDataMeterBreakdownRow> =
    validateStrictDecoder<MeterDataDto.MeterDataMeterBreakdownRow>()(
        D.object({
            required: {
                meterCode: D.string,
                consumptionWh: D.nullable(D.number),
                productionWh: D.nullable(D.number),
                numReadings: D.number,
                expectedNumReadings: D.number,
                dataCompleteness: D.number,
                dataProvider: D.string,
                lastDataUpdate: D.nullable(isoTimestampDecoder),
                dateOfFirstReading: D.nullable(isoTimestampDecoder),
                dateOfLastReading: D.nullable(isoTimestampDecoder),
                currentDelayDays: D.nullable(D.number),
                expectedDelayDays: D.nullable(D.number),
                initialTimeToCheckLocal: isoTimestampDecoder,
                endTimeToCheckLocal: D.nullable(isoTimestampDecoder),
            },
        }),
    );

export const meterDataSiteBreakdownRowDecoder: D.Decoder<MeterDataDto.MeterDataSiteBreakdownRow> =
    validateStrictDecoder<MeterDataDto.MeterDataSiteBreakdownRow>()(
        D.object({
            required: {
                siteName: D.string,
                siteType: siteTypeDecoder,
                energySource: D.nullable(D.string),
                region: D.string,
                biddingZone: D.string,
                timeZone: D.string,
                consumptionWh: D.nullable(D.number),
                productionWh: D.nullable(D.number),
                numReadings: D.number,
                expectedNumReadings: D.number,
                dataCompleteness: D.number,
                dataProvider: D.string,
                lastDataUpdate: D.nullable(isoTimestampDecoder),
                dateOfFirstReading: D.nullable(isoTimestampDecoder),
                dateOfLastReading: D.nullable(isoTimestampDecoder),
                currentDelayDays: D.nullable(D.number),
                expectedDelayDays: D.nullable(D.number),
            },
            optional: {
                dataPerMeter: D.nullable(D.array(dataPerMeterRowDecoder)),
            },
        }),
    );

export const meterDataSiteBreakdownDecoder: D.Decoder<MeterDataDto.MeterDataSiteBreakdown> =
    validateStrictDecoder<MeterDataDto.MeterDataSiteBreakdown>()(
        D.object({
            required: {
                rows: D.array(meterDataSiteBreakdownRowDecoder),
                rowCount: D.number,
            },
        }),
    );

export const activeSitesKpiDecoder: D.Decoder<MeterDataDto.ActiveSitesKpi> =
    validateStrictDecoder<MeterDataDto.ActiveSitesKpi>()(
        D.object({
            required: {
                totalActiveSites: D.number,
                activeConsumptionSites: D.number,
                activeProductionSites: D.number,
            },
        }),
    );

export const totalConsumptionAndProductionKpiDecoder: D.Decoder<MeterDataDto.TotalConsumptionAndProductionKpi> =
    validateStrictDecoder<MeterDataDto.TotalConsumptionAndProductionKpi>()(
        D.object({
            required: {
                totalConsumptionWh: D.nullable(D.number),
                totalProductionWh: D.nullable(D.number),
            },
        }),
    );

export const regionOptionDecoder: D.Decoder<MeterDataDto.RegionOption> =
    validateStrictDecoder<MeterDataDto.RegionOption>()(
        D.object({
            required: {
                regionId: D.string,
                regionName: D.string,
            },
        }),
    );

export const regionOptionArrayDecoder: D.Decoder<Array<MeterDataDto.RegionOption>> =
    validateStrictDecoder<Array<MeterDataDto.RegionOption>>()(D.array(regionOptionDecoder));

export const biddingZoneOptionDecoder: D.Decoder<MeterDataDto.BiddingZoneOption> =
    validateStrictDecoder<MeterDataDto.BiddingZoneOption>()(
        D.object({
            required: {
                biddingZoneId: D.string,
                biddingZoneName: D.string,
            },
        }),
    );

export const biddingZoneOptionArrayDecoder: D.Decoder<Array<MeterDataDto.BiddingZoneOption>> =
    validateStrictDecoder<Array<MeterDataDto.BiddingZoneOption>>()(
        D.array(biddingZoneOptionDecoder),
    );

export const siteOptionDecoder: D.Decoder<MeterDataDto.SiteOption> =
    validateStrictDecoder<MeterDataDto.SiteOption>()(
        D.object({
            required: {
                siteId: D.string,
                siteName: D.string,
                regionName: D.string,
                biddingZoneName: D.string,
                timezone: D.string,
            },
        }),
    );

export const siteOptionArrayDecoder: D.Decoder<Array<MeterDataDto.SiteOption>> =
    validateStrictDecoder<Array<MeterDataDto.SiteOption>>()(D.array(siteOptionDecoder));

export const providerOptionDecoder: D.Decoder<MeterDataDto.ProviderOption> =
    validateStrictDecoder<MeterDataDto.ProviderOption>()(
        D.object({
            required: {
                providerId: D.string,
                providerName: D.string,
            },
        }),
    );

export const providerOptionArrayDecoder: D.Decoder<Array<MeterDataDto.ProviderOption>> =
    validateStrictDecoder<Array<MeterDataDto.ProviderOption>>()(D.array(providerOptionDecoder));

export const hourlyAggregatedMeterDataDecoder: D.Decoder<MeterDataDto.HourlyAggregatedMeterData> =
    validateStrictDecoder<MeterDataDto.HourlyAggregatedMeterData>()(
        D.object({
            required: {
                startTime: isoTimestampDecoder,
                consumptionWh: D.nullable(D.number),
                productionWh: D.nullable(D.number),
            },
        }),
    );

export const hourlyAggregatedMeterDataArrayDecoder: D.Decoder<
    Array<MeterDataDto.HourlyAggregatedMeterData>
> = validateStrictDecoder<Array<MeterDataDto.HourlyAggregatedMeterData>>()(
    D.array(hourlyAggregatedMeterDataDecoder),
);

export const dataCompletenessDecoder: D.Decoder<MeterDataDto.DataCompleteness> =
    validateStrictDecoder<MeterDataDto.DataCompleteness>()(
        D.object({
            required: {
                dataCompletenessRatio: D.number,
            },
        }),
    );

export const siteWarningDecoder: D.Decoder<SiteLevelWarning> =
    validateStrictDecoder<SiteLevelWarning>()(D.literalUnion(...Object.values(SiteLevelWarning)));

export const sanityWarningDecoder: D.Decoder<SanityWarning> =
    validateStrictDecoder<SanityWarning>()(D.literalUnion(...Object.values(SanityWarning)));

export const aggregatedWarningsPerSiteDecoder: D.Decoder<MeterDataDto.AggregatedWarningsPerSite> =
    validateStrictDecoder<MeterDataDto.AggregatedWarningsPerSite>()(
        D.object({
            required: {
                siteId: D.string,
                siteName: D.string,
                region: D.string,
                biddingZone: D.string,
                provider: D.string,
                hourlyReadingsWithWarningsRatio: D.nullable(D.number),
                numOutliers: D.number,
                numStaleness: D.number,
                numExceededInstalledCapacity: D.number,
                warnings: D.array(
                    D.object({
                        required: {
                            warningType: sanityWarningDecoder,
                            count: D.number,
                        },
                    }),
                ),
            },
        }),
    );

export const hourlyAggregatedWarningsPerSiteArrayDecoder: D.Decoder<
    Array<MeterDataDto.AggregatedWarningsPerSite>
> = validateStrictDecoder<Array<MeterDataDto.AggregatedWarningsPerSite>>()(
    D.array(aggregatedWarningsPerSiteDecoder),
);

export const consumptionBySiteDecoder: D.Decoder<MeterDataDto.ConsumptionBySite> =
    validateStrictDecoder<MeterDataDto.ConsumptionBySite>()(
        D.object({
            required: {
                page: D.array(
                    D.object({
                        required: {
                            siteId: D.string,
                            siteName: D.string,
                            countryName: D.string,
                            consumptionWh: D.number,
                        },
                    }),
                ),
                totalSites: D.number,
            },
        }),
    );

export const warningTypeArrayDecoder: D.Decoder<Array<SanityWarning>> = validateStrictDecoder<
    Array<SanityWarning>
>()(D.array(sanityWarningDecoder));

const meterOptionDecoder: D.Decoder<MeterDataDto.MeterOption> =
    validateStrictDecoder<MeterDataDto.MeterOption>()(
        D.object({
            required: {
                meterId: D.number,
                meterCode: D.string,
                dataProvider: D.string,
                expectedReadingGranularity: expectedReadingGranularityDecoder,
            },
        }),
    );

export const meterOptionArrayDecoder: D.Decoder<Array<MeterDataDto.MeterOption>> =
    validateStrictDecoder<Array<MeterDataDto.MeterOption>>()(D.array(meterOptionDecoder));

const meterLevelWarningsBreakdownDecoder: D.Decoder<MeterDataDto.MeterLevelWarningsBreakdown> =
    validateStrictDecoder<MeterDataDto.MeterLevelWarningsBreakdown>()(
        D.object({
            required: {
                total: D.number,
                totalPerWarningType: D.array(
                    D.object({
                        required: {
                            warningType: sanityWarningDecoder,
                            total: D.number,
                        },
                    }),
                ),
            },
        }),
    );

const siteLevelWarningsBreakdownDecoder: D.Decoder<MeterDataDto.SiteLevelWarningsBreakdown> =
    validateStrictDecoder<MeterDataDto.SiteLevelWarningsBreakdown>()(
        D.object({
            required: {
                total: D.number,
                totalPerWarningType: D.array(
                    D.object({
                        required: {
                            warningType: siteWarningDecoder,
                            total: D.number,
                        },
                    }),
                ),
            },
        }),
    );

export const monitoringSiteDetailsKpisDecoder: D.Decoder<MeterDataDto.MonitoringSiteDetailsKpis> =
    validateStrictDecoder<MeterDataDto.MonitoringSiteDetailsKpis>()(
        D.object({
            required: {
                meterLevelWarningsBreakdown: meterLevelWarningsBreakdownDecoder,
                siteLevelWarningsBreakdown: siteLevelWarningsBreakdownDecoder,
            },
        }),
    );

const hourlyMeterWarningsDecoder: D.Decoder<MeterDataDto.SiteHourlyMeterWarnings> =
    validateStrictDecoder<MeterDataDto.SiteHourlyMeterWarnings>()(
        D.object({
            required: {
                meterId: D.number,
                meterCode: D.string,
                date: isoTimestampDecoder,
                warningsPerType: D.array(
                    D.object({
                        required: {
                            warningType: sanityWarningDecoder,
                            count: D.number,
                        },
                    }),
                ),
            },
        }),
    );

export const estimatedMeterDataDecoder: D.Decoder<{
    predictions: Array<{
        startTime: Date;
        estimatedConsumptionWh: number;
    }>;
}> = validateStrictDecoder<{
    predictions: Array<{
        startTime: Date;
        estimatedConsumptionWh: number;
    }>;
}>()(
    D.object({
        required: {
            predictions: D.array(
                D.object({
                    required: {
                        startTime: isoTimestampDecoder,
                        estimatedConsumptionWh: D.number,
                    },
                }),
            ),
        },
    }),
);

export const hourlyMeterWarningsArrayDecoder: D.Decoder<
    Array<MeterDataDto.SiteHourlyMeterWarnings>
> = validateStrictDecoder<Array<MeterDataDto.SiteHourlyMeterWarnings>>()(
    D.array(hourlyMeterWarningsDecoder),
);

const hourlySiteWarningsDecoder: D.Decoder<MeterDataDto.SiteHourlySiteWarnings> =
    validateStrictDecoder<MeterDataDto.SiteHourlySiteWarnings>()(
        D.object({
            required: {
                date: isoTimestampDecoder,
                numOutliers: D.number,
                numStaleness: D.number,
                numExceededInstalledCapacity: D.number,
            },
        }),
    );

export const hourlySiteWarningsArrayDecoder: D.Decoder<Array<MeterDataDto.SiteHourlySiteWarnings>> =
    validateStrictDecoder<Array<MeterDataDto.SiteHourlySiteWarnings>>()(
        D.array(hourlySiteWarningsDecoder),
    );
