import { Skeleton, Stack, SystemProp } from "@mantine/core";
import { CSSProperties } from "react";

export const InputSkeleton = ({
    maw,
}: {
    maw?: SystemProp<CSSProperties["maxWidth"]>;
}): JSX.Element => (
    <Stack spacing={0} {...(maw && { maw })}>
        <Skeleton my="0.375rem" height="0.75rem" width="5rem" radius="sm" />
        <Skeleton height="2.25rem" radius="sm" />
    </Stack>
);
