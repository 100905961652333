import { FetchError, isFetchError } from "@flexidao/api-client";
import { NotificationProps } from "@mantine/notifications";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactElement } from "react";

type QueriesWrapperProps<T extends ReadonlyArray<any>> = {
    queries: T;
    skeleton: ReactElement;
    errorNotification?: NotificationProps;
    content: (
        data: [
            ...{
                [K in keyof T]: T[K] extends UseQueryResult<infer S | FetchError, unknown>
                    ? S
                    : never;
            },
        ],
    ) => ReactElement;
    error: ReactElement;
};

export const QueriesWrapper = <T extends ReadonlyArray<any>>({
    queries,
    skeleton,
    content,
    error,
}: QueriesWrapperProps<T>): ReactElement => {
    if (
        queries.some((query) => query.data === undefined && (query.isLoading || query.isFetching))
    ) {
        return skeleton;
    }

    const isQueryWithoutData = <T>(query: { data: T }): boolean =>
        query.data === null || query.data === undefined;

    if (
        queries.some((query) => query.isError) ||
        queries.some((query) => isFetchError(query.data)) ||
        queries.some((query) => isQueryWithoutData(query))
    ) {
        const errs: ReactElement[] = [];
        for (const query of queries) {
            if (isFetchError(query.data)) {
                const { kind, payload } = query.data;
                console.error(`useHandleQueries - '${kind}' FetchError\n`, payload);
                errs.push(error);
            }
            if (query.isError) {
                console.error(`useHandleQueries - generic error\n`, query.error);
                errs.push(error);
            }
            if (isQueryWithoutData(query)) {
                console.error("useHandleQueries - Unexpected no data for query.");
                errs.push(error);
            }
        }
        return error;
    }
    const queriesData = queries.map((query) => query.data);
    return content(queriesData as [...{ [K in keyof T]: T[K] }]);
};
