import { ReactElement, useCallback, useEffect, useState } from "react";
import { Box, Skeleton, Stack } from "@mantine/core";
import { ParentSize } from "@visx/responsive";
import { StackedBarChartData, StackedBarChartSeries } from "./types";
import { StackedBarChart } from "./chart/chart";
import { CHART_HEIGHT } from "./chart/consts";
import { StackedBarChartLegend } from "./legend/legend";
import { StackedBarChartBrush } from "./brush/brush";

type StackedBarChartProps<T extends Record<string, number>> = {
    dataId: string;
    height?: number;
    showSkeleton?: boolean;
    data: StackedBarChartData<T>;
    series: StackedBarChartSeries<T>;
    period: [Date, Date];
    onBrushChange: (domain: [Date, Date] | null) => void;
};

export const StackedBarChartWithBrush = <T extends Record<string, number>>({
    dataId,
    data,
    series,
    height = CHART_HEIGHT,
    showSkeleton = false,
    period,
    onBrushChange,
}: StackedBarChartProps<T>): ReactElement => {
    const [filteredPeriod, setFilteredPeriod] = useState<[Date, Date]>(period);
    const [filteredData, setFilteredData] = useState<StackedBarChartData<T>>(data);
    const [activeSeries, setActiveSeries] = useState<StackedBarChartSeries<T>>(series);

    useEffect(() => {
        setFilteredPeriod(period);
    }, [period]);
    useEffect(() => {
        setFilteredData(data);
    }, [data]);
    useEffect(() => {
        setActiveSeries(series);
    }, [series]);

    // Brush variables and methods
    const onBrushClick = useCallback((): void => {
        setFilteredData(data);
        onBrushChange(null);
    }, [data]);

    const handleOnBrushChange = useCallback(
        (newPeriod: [Date, Date] | null): void => {
            if (!newPeriod) {
                setFilteredPeriod(period);
                onBrushChange(null);
                return;
            }

            setFilteredPeriod(newPeriod);

            const filteredData: StackedBarChartData<T> = data.filter(
                (d) =>
                    d.date.getTime() >= newPeriod[0].getTime() &&
                    d.date.getTime() <= newPeriod[1].getTime(),
            );

            setFilteredData(filteredData);

            const firstSeriesDate: Date | undefined = filteredData[0]?.date;
            const lastSeriesDate: Date | undefined = filteredData[filteredData.length - 1]?.date;

            if (firstSeriesDate && lastSeriesDate) {
                onBrushChange(newPeriod);
            } else {
                onBrushChange(null);
            }
        },
        [data, period],
    );

    const onToggleActiveSeries = useCallback((key: string): void => {
        setActiveSeries((prevSeries) =>
            prevSeries.map((s) =>
                s.dataKey === key
                    ? {
                          ...s,
                          isSeriesActive: !s.isSeriesActive,
                      }
                    : s,
            ),
        );
    }, []);

    if (showSkeleton) {
        return <Skeleton height={height} />;
    }

    return (
        <Box data-id={dataId} px="24px">
            <ParentSize>
                {({ width }): ReactElement => (
                    <Stack data-id={dataId} spacing="xs" pos="relative">
                        <StackedBarChart
                            chartHeight={height}
                            data={filteredData}
                            series={activeSeries}
                            period={filteredPeriod}
                        />

                        <StackedBarChartLegend
                            activeSeries={activeSeries}
                            onToggleActiveSeries={onToggleActiveSeries}
                        />

                        <StackedBarChartBrush
                            width={width}
                            data={data}
                            series={activeSeries}
                            period={period}
                            onBrushClick={onBrushClick}
                            onBrushChange={handleOnBrushChange}
                        />
                    </Stack>
                )}
            </ParentSize>
        </Box>
    );
};
