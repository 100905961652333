import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { readFileSync } from "fs";

function join(...args: string[]): string {
    let parts: string[] = [];
    for (const arg of args) {
        parts = parts.concat(arg.split("/"));
    }
    const newParts: string[] = [];
    for (let i = 0, l = parts.length; i < l; i++) {
        const part = parts[i];
        if (!part || part === ".") {
            continue;
        }
        if (part === "..") {
            newParts.pop();
        } else {
            newParts.push(part);
        }
    }
    if (parts[0] === "") {
        newParts.unshift("");
    }
    return newParts.join("/") || (newParts.length ? "/" : ".");
}

export const mockAxiosClient = {
    get: (url: string): unknown => {
        const file = readFileSync(join(__dirname, `./${url}/get.json`));
        const mockedData = JSON.parse(file.toString());
        const result: AxiosResponse = {
            data: mockedData.data,
            status: mockedData.status,
            statusText: mockedData.statusText,
            headers: mockedData.headers,
            config: {} as InternalAxiosRequestConfig,
        };

        return result;
    },

    /**
     *
     * @param url
     * @param body
     * Dynamically set mocked data from authorization middleware based
     */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    post: (url: string, body: any): unknown => {
        let mockedData;
        const clientCredentialScope: Array<{ kind: string; scopeStrings: Array<string> }> =
            body.schemes.filter(
                (scheme: { kind: string; scopeStrings: Array<string> }) =>
                    scheme.kind === "client-credentials",
            );

        const isAdmin = clientCredentialScope.some((scope) =>
            scope.scopeStrings.some((scopeString) => scopeString.split(":")[1] === "admin"),
        );

        if (isAdmin) {
            const file = readFileSync(join(__dirname, `./${url}/postAdminRole.json`)); // admin permissions
            mockedData = JSON.parse(file.toString()); // here we are mocking the permissions from bim
        } else {
            const file = readFileSync(join(__dirname, `./${url}/postTenantAdminRole.json`)); // tenant permissions
            mockedData = JSON.parse(file.toString()); // here we are mocking the permissions from bim
        }

        const result: AxiosResponse = {
            data: mockedData.data,
            status: mockedData.status,
            statusText: mockedData.statusText,
            headers: mockedData.headers,
            config: {} as InternalAxiosRequestConfig,
        };

        return result;
    },
};
