/**
 * we have move this file to dto package because it is the only package that is common to both,  node and browser
 * packages/dto/src/uuid.ts
 * packages/helper/src/uuid.ts
 */
import { validate } from "uuid";
import * as D from "schemawax";

declare const _type: "uuid_7m61itm4141x";
export type Uuid = string & { [_type]: never };

export const validateUuid = (u: string): u is Uuid => validate(u);

export const brandUuidUnsafe = (u: string): Uuid => u as Uuid;

export const brandUuid = (u: string): Uuid => {
    if (validateUuid(u)) {
        return u;
    }
    throw Error("This is not an uuid ");
};

export const uuidDecoder = D.string.andThen((data) => brandUuid(data));
