import { Group } from "@visx/group";

import { HeatmapRect } from "@visx/heatmap";
import { ReactElement, useCallback } from "react";
import { useTooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Box, Center } from "@mantine/core";
import { HeatmapLegend } from "./legend";
import { AxisBottom, AxisLeft, AxisRight } from "../_utils/axes";
import { HeatmapTooltipData, Tooltip } from "./tooltip";
import { RectCell } from "@visx/heatmap/lib/heatmaps/HeatmapRect";
import {
    AM_LABEL_HOUR,
    AXIS_FONT_SIZE,
    ChartDimensions,
    ChartDisplayOptions,
    getDefaultRightAxisTickLabelProps,
    PM_LABEL_HOUR,
} from "../_utils/utils";
import {
    heatmapDaysToMonthTickFormatter,
    heatmapPartOfTheDayTickFormatter,
} from "../tick-formatters";
import { ScaleLinear } from "d3-scale";
import { flexidaoGrey } from "../../../../mantine-theme/colours/flexidao-colors";
import { Bin, Bins } from "@visx/mock-data/lib/generators/genBins";
import { HeatmapGradient } from "./heatmap";

type HeatmapTemplateProps = {
    dimensions: ChartDimensions;
    xScale: ScaleLinear<number, number, never>;
    yScale: ScaleLinear<number, number, never>;
    colorScale: ScaleLinear<string, string, never>;
    noDataColor: string;
    data: Array<Bins>;
    gradient: HeatmapGradient;
    bucketSizeMax: number;
    binWidth: number;
    binHeight: number;
    axisColor: string;
    displayOptions: ChartDisplayOptions;
};

export const HeatmapTemplate = ({
    dimensions: { margin, parentHeight, parentWidth, innerHeight, innerWidth },
    xScale,
    yScale,
    colorScale,
    gradient,
    noDataColor,
    data,
    bucketSizeMax,
    binWidth,
    binHeight,
    axisColor,
    displayOptions: { showAxes, showLegend, showTooltip },
}: HeatmapTemplateProps): ReactElement => {
    //tooltip
    const {
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip: showTooltip_,
    } = useTooltip<HeatmapTooltipData>();

    const handleTooltip = useCallback(
        (
            event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>,
            cellBin: { column: number; row: number; bin: Bin },
        ) => {
            const { x, y } = localPoint(event) || { x: 0, y: 0 };
            const { column, row, bin } = cellBin;
            // eslint-disable-next-line no-magic-numbers
            const tooltipTop = row / bucketSizeMax > 0.2 ? y + 20 : y - 40;

            showTooltip_({
                tooltipData: { data: { row: row, column: column, count: bin.count } },
                tooltipLeft: x,
                tooltipTop: tooltipTop,
            });
        },
        [bucketSizeMax, showTooltip_],
    );

    return (
        <Box pos="relative">
            <svg width={parentWidth} height={parentHeight}>
                <Group left={margin.left} onMouseLeave={hideTooltip} top={margin.top}>
                    {data.length === 0 ? (
                        <text fontSize="14px" fill={flexidaoGrey[9]} x="40%" y="30%">
                            No data
                        </text>
                    ) : (
                        <HeatmapRect
                            data={data}
                            xScale={(d: number): number => xScale(d) ?? 0}
                            yScale={(d: number): number => yScale(d) ?? 0}
                            colorScale={colorScale}
                            binWidth={binWidth}
                            binHeight={binHeight}
                            gap={0}
                            strokeWidth={0.1}
                        >
                            {(columns: RectCell<Bins, Bin>[][]): JSX.Element[][] =>
                                columns.map((column: RectCell<Bins, Bin>[]) =>
                                    column.map((reading: RectCell<Bins, Bin>) => (
                                        <rect
                                            key={`heatmap-rect-${reading.row}-${reading.column}`}
                                            width={reading.width}
                                            height={reading.height}
                                            x={reading.x}
                                            y={reading.y}
                                            fill={reading.color}
                                            stroke={reading.color}
                                            onMouseOver={(event): void => {
                                                showTooltip && handleTooltip(event, reading);
                                            }}
                                        />
                                    )),
                                )
                            }
                        </HeatmapRect>
                    )}
                    {showAxes.left && (
                        <AxisLeft yScale={yScale} axisColor={axisColor} numTicks={2} />
                    )}
                    {showAxes.right && (
                        <AxisRight
                            innerWidth={innerWidth}
                            yScale={yScale}
                            axisColor={axisColor}
                            numTicks={10}
                            tickFormat={heatmapPartOfTheDayTickFormatter}
                            hideTicks={true}
                            tickLabelProps={(
                                value,
                            ):
                                | {
                                      fill: string;
                                      fontSize: number;
                                      textAnchor: string;
                                      dy: string;
                                      dx: string;
                                  }
                                | {} => {
                                if (value === AM_LABEL_HOUR || value === PM_LABEL_HOUR) {
                                    return getDefaultRightAxisTickLabelProps(
                                        axisColor,
                                        AXIS_FONT_SIZE,
                                    );
                                } else {
                                    return {};
                                }
                            }}
                        />
                    )}
                    {showAxes.bottom && (
                        <AxisBottom
                            kind="linear"
                            top={innerHeight}
                            xScale={xScale}
                            axisColor={axisColor}
                            numTicks={4}
                            textAnchor="start"
                            tickFormat={heatmapDaysToMonthTickFormatter}
                        />
                    )}
                </Group>
            </svg>

            {showTooltip && (
                <Tooltip
                    tooltipData={tooltipData}
                    tooltipTop={tooltipTop}
                    tooltipLeft={tooltipLeft}
                />
            )}
            {showLegend && (
                <Center w="100%" pos="absolute" bottom="1rem">
                    <HeatmapLegend
                        noDataColor={noDataColor}
                        gradient={gradient}
                        height={12}
                        width={120}
                    />
                </Center>
            )}
        </Box>
    );
};
