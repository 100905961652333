import { ReactElement } from "react";
import { Text } from "@mantine/core";
import { EmptyCell } from "./empty-cell";
import { getClassNameForSubRow } from "./utils";
import { StringColumnType } from "@flexidao/ui-lib";

type StringCellProps = {
    value: StringColumnType;
    isSubRow?: boolean;
};

const getWeight = (
    fontWeight?: "normal" | "semibold" | "bold",
): "bold" | "normal" | number | undefined => {
    switch (fontWeight) {
        case "bold":
            return "bold";
        case "semibold":
            // eslint-disable-next-line no-magic-numbers
            return 600;
        case "normal":
            return "normal";
        default:
            return undefined;
    }
};

export const StringCell = ({ value, isSubRow = false }: StringCellProps): ReactElement => {
    let renderedValue: string | null | undefined;
    let fontWeight: "bold" | "semibold" | "normal" | undefined;
    let color: string | undefined;

    if (typeof value === "string") {
        renderedValue = value;
    } else {
        renderedValue = value?.value;
        fontWeight = value?.fontWeight;
        color = value?.color;
    }

    if (renderedValue == null) {
        return <EmptyCell isSubRow={isSubRow} />;
    }

    return (
        <Text
            size={14}
            fw={getWeight(fontWeight)}
            className={getClassNameForSubRow(isSubRow)}
            sx={{ wordBreak: "break-word" }}
            c={color}
        >
            {renderedValue}
        </Text>
    );
};
