import { DeletePayload } from "@flexidao/api-client";
import {
    CfeDiagnosticContractType,
    CfeDiagnosticDto,
    ContractType,
    EnergySource,
    ExpectedIntervalReadingType,
    ExpectedReadingGranularity,
    GlobalRegion,
    IndustryType,
    MatchingLogic,
    ProvidersEnum,
    ReportHubDto,
    SiteType,
    SourceType,
    SourcingMethod,
    TrackingInstrumentType,
    ZoneDataAccessLevel,
} from "@flexidao/dto";
import { exhaustiveCheck } from "@flexidao/helpers";
import * as D from "schemawax";

export const deleteResponseDecoder: D.Decoder<DeletePayload> = D.object({
    required: {
        success: D.boolean,
    },
    optional: {
        error: D.string,
    },
});

export const successDecoder: D.Decoder<{ success: boolean }> = D.object({
    required: {
        success: D.boolean,
    },
});

export const noContentDecoder: D.Decoder<undefined> = D.unknown.andThen((value) => {
    if (value != null && Object.keys(value).length > 0) {
        throw new Error(`Expected no content, got '${value}'.`);
    }

    return undefined;
});

export const parseDate = (value: string | number | Date | null | undefined): Date => {
    if (value == null) {
        throw new Error(`Invalid date '${value}'.`);
    }

    const parsedDate: Date = new Date(value);

    if (!parsedDate || isNaN(parsedDate.getTime())) {
        throw new Error(`Invalid date '${value}'.`);
    }

    return parsedDate;
};
export const dateDecoder: D.Decoder<Date> = D.string.andThen((value) => {
    return parseDate(value);
});

export const featureFlagDecoder: D.Decoder<Array<string>> = D.array(D.string);

export const energySourceDecoder: D.Decoder<EnergySource> = D.string.andThen(
    (value: string): EnergySource => {
        const castedValue = value as EnergySource;
        switch (castedValue) {
            case EnergySource.Biomass:
            case EnergySource.Coal:
            case EnergySource.Gas:
            case EnergySource.Geothermal:
            case EnergySource.Hydro:
            case EnergySource.Nuclear:
            case EnergySource.Oil:
            case EnergySource.Other:
            case EnergySource.Solar:
            case EnergySource.Wind:
            case EnergySource.RenewableUnknown:
                return castedValue;
            default:
                return exhaustiveCheck(castedValue, `Unknown energy source '${castedValue}'`);
        }
    },
);

export const sourcingMethodDecoder: D.Decoder<SourcingMethod> = D.literalUnion(
    ...Object.values(SourcingMethod),
);

export const contractEnergySourceDecoder = D.object({
    required: { energySourceId: energySourceDecoder, isCfe: D.boolean, name: D.string },
});

export const contractTypeDecoder: D.Decoder<ContractType> = D.literalUnion(
    ContractType.VPPA,
    ContractType.PPA,
    ContractType.GreenTariff,
    ContractType.UnbundledEACs,
);

export const cfeDiagnosticContractTypeDecoder: D.Decoder<CfeDiagnosticContractType> =
    D.literalUnion(
        CfeDiagnosticContractType.PPA,
        CfeDiagnosticContractType.GreenTariff,
        CfeDiagnosticContractType.UnbundledEACs,
        CfeDiagnosticContractType.NonCfe,
    );

export const matchingLogicDecoder: D.Decoder<MatchingLogic> = D.literalUnion(
    MatchingLogic.PayAsConsumed,
    MatchingLogic.PayAsProduced,
);

export const contractTypeArrayDecoder = D.array(
    D.object({
        required: {
            contractTypeId: cfeDiagnosticContractTypeDecoder,
            isCfeContract: D.boolean,
            name: D.string,
            energySources: D.array(contractEnergySourceDecoder),
        },
    }),
);

export const industryTypeDecoder: D.Decoder<IndustryType> = D.literalUnion(
    IndustryType.Automotive,
    IndustryType.ConsumerGoods,
    IndustryType.DataCenters,
    IndustryType.Education,
    IndustryType.FinancialServices,
    IndustryType.Healthcare,
    IndustryType.Manufacturing,
    IndustryType.Office,
    IndustryType.Pharma,
    IndustryType.RealState,
    IndustryType.Retail,
    IndustryType.Telecoms,
);

export const industryTypeArrayDecoder = D.array(
    D.object({
        required: { industryTypeId: industryTypeDecoder, name: D.string },
    }),
);

export const countriesDecoder: D.Decoder<CfeDiagnosticDto.Country> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
        zones: D.array(
            D.object({
                required: { zoneId: D.string, name: D.string, enabled: D.boolean },
            }),
        ),
    },
});

export const countriesArrayDecoder: D.Decoder<Array<CfeDiagnosticDto.Country>> =
    D.array(countriesDecoder);

export const dataAccessDecoder: D.Decoder<ZoneDataAccessLevel> = D.literalUnion(
    ZoneDataAccessLevel.Accessible,
    ZoneDataAccessLevel.Moderate,
    ZoneDataAccessLevel.Challenging,
    ZoneDataAccessLevel.Difficult,
    ZoneDataAccessLevel.ExtremelyDifficult,
    ZoneDataAccessLevel.NoData,
);

export const sourceTypeDecoder: D.Decoder<SourceType> = D.literalUnion(
    SourceType.AnnualLocationBased,
    SourceType.AnnualMarketBased,
    SourceType.HourlyLocationBased,
    SourceType.HourlyMarketBased,
    SourceType.Emissionality,
);

export const providerDecoder: D.Decoder<ProvidersEnum> = D.literalUnion(
    ...Object.values(ProvidersEnum),
);

export const expectedIntervalReadingTypeDecoder: D.Decoder<ExpectedIntervalReadingType> =
    D.literalUnion(
        ExpectedIntervalReadingType.IntervalProduction,
        ExpectedIntervalReadingType.IntervalConsumption,
        ExpectedIntervalReadingType.IntervalProductionAndConsumption,
        ExpectedIntervalReadingType.NetMetering,
        ExpectedIntervalReadingType.None,
    );

export const expectedReadingGranularityDecoder: D.Decoder<ExpectedReadingGranularity> =
    D.literalUnion(
        ExpectedReadingGranularity.HalfHourly,
        ExpectedReadingGranularity.QuarterHourly,
        ExpectedReadingGranularity.Monthly,
        ExpectedReadingGranularity.Hourly,
        ExpectedReadingGranularity.AnyGranularity,
    );

export const siteTypeDecoder: D.Decoder<SiteType> = D.literalUnion(
    SiteType.Consumer,
    SiteType.Producer,
);

export const trackingInstrumentTypeDecoder: D.Decoder<TrackingInstrumentType> = D.literalUnion(
    TrackingInstrumentType.EAC,
    TrackingInstrumentType.Attestation,
);

export const globalRegionIdDecoder: D.Decoder<GlobalRegion> = D.string.andThen(
    (value: string): GlobalRegion => {
        const castedValue = value as GlobalRegion;
        switch (castedValue) {
            case GlobalRegion.EMEA:
            case GlobalRegion.APAC:
            case GlobalRegion.NA:
            case GlobalRegion.LATAM:
                return castedValue;
            default:
                return exhaustiveCheck(castedValue, `Unknown global region '${castedValue}'.`);
        }
    },
);

export const countryNameDecoder: D.Decoder<ReportHubDto.CountryName> = D.object({
    required: {
        countryName: D.string,
    },
});
