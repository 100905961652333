import { SortingDirection, SortingState } from "./types";
import { MRT_SortingState } from "mantine-react-table";
import { RowData } from "../types";

export const sortingToMrtSorting = <T extends RowData>(
    sortingState?: SortingState<T> | null,
): MRT_SortingState => {
    if (sortingState == null) {
        return [];
    }

    const mrtSortingState: MRT_SortingState = [
        {
            id: sortingState.columnId,
            desc: sortingState.direction === SortingDirection.Desc,
        },
    ];

    return mrtSortingState;
};

export const mrtSortingToSorting = <T extends RowData>(
    mrtSortingState?: MRT_SortingState,
): SortingState<T> | null => {
    if (mrtSortingState == null || mrtSortingState.length === 0 || mrtSortingState[0] == null) {
        return null;
    }

    const sortingState: SortingState<T> = {
        columnId: mrtSortingState[0].id as SortingState<T>["columnId"],
        direction: mrtSortingState[0].desc ? SortingDirection.Desc : SortingDirection.Asc,
    };

    return sortingState;
};
