import { financialDataComponents } from "./";
import { InvoicedVolumeTypeEnum, PowerExchangesEnum } from "./enum";

export type IKind<T> = {
    kind: T;
};

export namespace FinancialDataDto {
    export type BaseInvoicedVolume = {
        invoicedVolumeId: string;
        executionId: string;
        contractId: string;
        startPeriodLocal: Date | null;
        endPeriodLocal: Date | null;
        invoicedVolumeWh: number;
        invoicedVolumeCharged: number | null;
        reportingPeriod: number | null;
        sitesVolumeWh: Record<string, number> | null;
        type: InvoicedVolumeTypeEnum;
        invoicedVolumeDocumentId: number | null;
        tenantId: string;
    };

    export type InvoicedVolumeWithMeta = BaseInvoicedVolume & {
        created: Date;
        updated: Date;
        deleted: Date | null;
    };

    type BaseRateLimit = {
        group?: string;
    };

    export type RateLimitEnum =
        | "RateLimitOnSeconds"
        | "RateLimitOnMinutes"
        | "RateLimitOnHours"
        | "RateLimitOnDays"
        | "NoRateLimit";

    export type RateLimitOnSeconds = {
        seconds: number;
        limit: number;
    } & IKind<"RateLimitOnSeconds"> &
        BaseRateLimit;

    export type RateLimitOnMinutes = {
        minutes: number;
        limit: number;
    } & IKind<"RateLimitOnMinutes"> &
        BaseRateLimit;
    export type RateLimitOnHours = {
        hours: number;
        limit: number;
    } & IKind<"RateLimitOnHours"> &
        BaseRateLimit;
    export type RateLimitOnDays = {
        days: number;
        limit: number;
    } & IKind<"RateLimitOnDays"> &
        BaseRateLimit;
    export type NoRateLimit = IKind<"NoRateLimit"> & BaseRateLimit;

    export type RateLimit =
        | NoRateLimit
        | RateLimitOnSeconds
        | RateLimitOnMinutes
        | RateLimitOnHours
        | RateLimitOnDays;

    type CredentialsLevel = "Flexidao" | "TenantPowerExchange" | "NoCredentials";

    export type PowerExchangeDto = {
        powerExchangeId: PowerExchangesEnum;
        credentialLevel: Array<CredentialsLevel>;
        rateLimits: Array<RateLimit>;
    };
    export enum MarketGranularityEnum {
        Hourly = "hourly",
        HalfHourly = "half-hourly",
        QuarterHourly = "quarter-hourly",
    }
    export type ContractTesting = financialDataComponents["schemas"]["ContractTesting"];
}
