import { ReactElement } from "react";
import { Text } from "@mantine/core";
import { EmptyCell } from "./empty-cell";
import { getClassNameForSubRow } from "./utils";
import { ColumnAlign } from "@flexidao/ui-lib";

type BooleanCellProps = {
    value: boolean | null;
    textAlign?: ColumnAlign;
    isSubRow?: boolean;
};

export const BooleanCell = ({
    value,
    textAlign = ColumnAlign.Center,
    isSubRow = false,
}: BooleanCellProps): ReactElement => {
    if (value == null) {
        return <EmptyCell isSubRow={isSubRow} />;
    }

    const valueStr: string = value ? "Yes" : "No";

    return (
        <Text size={14} ta={textAlign} className={getClassNameForSubRow(isSubRow)}>
            {valueStr}
        </Text>
    );
};
