/* eslint-disable no-magic-numbers */
import { Tuple } from "@mantine/core";

export const flexidaoGreen: Tuple<string, 10> = [
    "#E6F7F4",
    "#CCEEE8",
    "#99DDD2",
    "#66CDBB",
    "#33BCA5",
    "#00AB8E",
    "#058E77",
    "#09725E",
    "#0E5447",
    "#12382E",
];

export const contractedCfe: Tuple<string, 10> = [
    "#e7f7f4",
    "#c2ebe4",
    "#99ded2",
    "#70d0c0",
    "#52c6b3",
    "#33bca5",
    "#2eb69d",
    "#27ad93",
    "#20a58a",
    "#149779",
];
export const gridMixCfe: Tuple<string, 10> = [
    "#f3fbfa",
    "#e0f5f2",
    "#cceee9",
    "#b8e7e0",
    "#a8e2d9",
    "#99ddd2",
    "#91d9cd",
    "#86d4c7",
    "#7ccfc1",
    "#6bc7b6",
];

export const flexidaoOrange: Tuple<string, 10> = [
    "#FFF6E9",
    "#FEEBD3",
    "#FCD7A7",
    "#FBC47A",
    "#F9B04E",
    "#F89C22",
    "#CB8220",
    "#9E691D",
    "#714E1C",
    "#443519",
];

export const flexidaoRed: Tuple<string, 10> = [
    "#FBEEE9",
    "#F6DDD3",
    "#EDBBA7",
    "#E4997B",
    "#DA774F",
    "#D15523",
    "#AC4921",
    "#873E1E",
    "#62321C",
    "#3C2719",
];

export const flexidaoBlue: Tuple<string, 10> = [
    "#E7F5FC",
    "#CFE9F8",
    "#9ED3F1",
    "#6EBDEB",
    "#3DA7E4",
    "#0D91DD",
    "#0F79B6",
    "#11628E",
    "#134A66",
    "#15333E",
];

export const gridMixNonCfe: Tuple<string, 10> = [
    "#fafafa",
    "#f2f2f2",
    "#e9eae9",
    "#e0e1e0",
    "#dadada",
    "#d3d4d3",
    "#cecfce",
    "#c8c9c8",
    "#c2c3c2",
    "#b7b9b7",
];

export const flexidaoGrey: Tuple<string, 10> = [
    "#F3F4F3",
    "#E9E9E9",
    "#D3D4D3",
    "#BCBEBC",
    "#A6A9A6",
    "#909390",
    "#7A7D7A",
    "#646864",
    "#4D524D",
    "#333933",
];

export const flexidaoYellow: Tuple<string, 10> = [
    "#FAF5DB",
    "#F5EBB7",
    "#EFE092",
    "#EBD76E",
    "#E2C840",
    "#DDBC0D",
    "#C2A50C",
    "#A9900C",
    "#7D6B09",
    "#5F5108",
];

export const flexidaoBlack: Tuple<string, 10> = [
    "#e3e4e3",
    "#b9bbb9",
    "#8b8d8b",
    "#5d5f5d",
    "#3a3d3a",
    "#171b17",
    "#141814",
    "#111411",
    "#0d100d",
    "#070807",
];
