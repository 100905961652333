import { ReportHubDto, SourcingMethod } from "@flexidao/dto";

export const SOURCING_METHODS: Array<ReportHubDto.SourcingMethod> =
    Object.values(SourcingMethod).sort();

export const formatSourcingMethod = (sourcingMethod: ReportHubDto.SourcingMethod): string => {
    switch (sourcingMethod) {
        case SourcingMethod.OnSitePpa:
            return "Purchase from an on-site installation owned by a third party (on-site PPA)";
        case SourcingMethod.DirectLinePpa:
            return "Direct line to an off-site generator owned by a third party with no grid transfers (direct-line PPA)";
        case SourcingMethod.PhysicalPpa:
            return "Physical power purchase agreement (physical PPA) with a grid-connected generator";
        case SourcingMethod.VirtualPpa:
            return "Financial (virtual) power purchase agreement (VPPA)";
        case SourcingMethod.ProjectSpecificContract:
            return "Project-specific contract with an electricity supplier";
        case SourcingMethod.RetailGreenElectricity:
            return "Retail supply contract with an electricity supplier (retail green electricity)";
        case SourcingMethod.UnbundledEacs:
            return "Unbundled procurement of Energy Attribute Certificates (EACs)";
        case SourcingMethod.DefaultRenewableElectricityFromGridWithEacs:
            return "Default delivered renewable electricity from the grid, supported by energy attribute certificates";
        case SourcingMethod.DefaultRenewableElectricityFromGrid95NoAllocations:
            return "Default delivered renewable electricity from the grid in a market with 95% or more renewable electricity capacity and where there is no mechanism for specifically allocating renewable electricity";
        case SourcingMethod.Unknown:
        default:
            return "Unknown";
    }
};
