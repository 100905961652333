import {
    ReportHubDto,
    reportHubPaths,
    TrackingInstrumentType,
    validateStrictDecoder,
} from "@flexidao/dto";
import { dateDecoder, uuidDecoder } from "@flexidao/helpers";
import * as D from "schemawax";
import { InferResponse } from "../api-client";
import { useTypeDecoder } from "./eac-data";
import {
    contractTypeDecoder,
    energySourceDecoder,
    globalRegionIdDecoder,
    sourcingMethodDecoder,
    trackingInstrumentForAssignmentTypeDecoder,
    trackingInstrumentTypeDecoder,
} from "./misc";

export const re100ContributionDecoder = validateStrictDecoder<ReportHubDto.RE100Contribution>()(
    D.literalUnion(...Object.values(ReportHubDto.RE100Contribution)),
);

export const getContractTrackingOverviewPpaRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewPpaRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingOverviewPpaRow>()(
        D.object({
            required: {
                contractName: D.string,
                contractId: D.string,
                contractPeriodStartLocal: dateDecoder,
                contractPeriodEndLocal: dateDecoder,
                contractType: contractTypeDecoder,
                intervalWh: D.nullable(D.number),
                invoicedWh: D.nullable(D.number),
                allocatedTrackingInstrumentsWh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
                allocatedVsIntervalPercentage: D.nullable(D.number),
                allocatedVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );
export const getContractTrackingOverviewDirectPpaRowDecoder: D.Decoder<ReportHubDto.DirectPpaContractTrackingOverviewRow> =
    validateStrictDecoder<ReportHubDto.DirectPpaContractTrackingOverviewRow>()(
        D.object({
            required: {
                contractName: D.string,
                contractId: D.string,
                contractPeriodStartLocal: dateDecoder,
                contractPeriodEndLocal: dateDecoder,
                intervalWh: D.nullable(D.number),
                invoicedWh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );

export const getContractTrackingOverviewPpaDecoder: D.Decoder<
    Array<ReportHubDto.ContractTrackingOverviewPpaRow>
> = validateStrictDecoder<Array<ReportHubDto.ContractTrackingOverviewPpaRow>>()(
    D.array(getContractTrackingOverviewPpaRowDecoder),
);
export const getContractTrackingOverviewDirectPpaDecoder: D.Decoder<
    Array<ReportHubDto.DirectPpaContractTrackingOverviewRow>
> = validateStrictDecoder<Array<ReportHubDto.DirectPpaContractTrackingOverviewRow>>()(
    D.array(getContractTrackingOverviewDirectPpaRowDecoder),
);

export const getEacContractTrackingOverviewGreenTariffRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewGreenTariffRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingOverviewGreenTariffRow>()(
        D.object({
            required: {
                contractName: D.string,
                contractId: D.string,
                contractPeriodStartLocal: dateDecoder,
                contractPeriodEndLocal: dateDecoder,
                intervalWh: D.nullable(D.number),
                invoicedWh: D.nullable(D.number),
                allocatedTrackingInstrumentsWh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
                allocatedVsIntervalPercentage: D.nullable(D.number),
                allocatedVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );

export const getEacContractTrackingOverviewGreenTariffDecoder: D.Decoder<
    Array<ReportHubDto.ContractTrackingOverviewGreenTariffRow>
> = validateStrictDecoder<Array<ReportHubDto.ContractTrackingOverviewGreenTariffRow>>()(
    D.array(getEacContractTrackingOverviewGreenTariffRowDecoder),
);

export const getEacContractTrackingOverviewUnbundledRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewUnbundledRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingOverviewUnbundledRow>()(
        D.object({
            required: {
                transactionName: D.string,
                transactionId: D.string,
                unbundledContractName: D.string,
                unbundledContractId: D.string,
                counterparty: D.string,
                deliveryDate: dateDecoder,
                purchasedTrackingInstrumentsMWh: D.number,
                currencyUnit: D.string,
                pricePerMWh: D.number,
                totalPrice: D.number,
                allocatedTrackingInstrumentsWh: D.nullable(D.number),
                missingTrackingInstrumentsWh: D.number,
                surplusAllocationWh: D.number,
            },
        }),
    );
export const getEacContractTrackingOverviewUnbundledDecoder: D.Decoder<
    Array<ReportHubDto.ContractTrackingOverviewUnbundledRow>
> = validateStrictDecoder<Array<ReportHubDto.ContractTrackingOverviewUnbundledRow>>()(
    D.array(getEacContractTrackingOverviewUnbundledRowDecoder),
);

export const region: D.Decoder<ReportHubDto.Region> = D.object({
    required: {
        regionId: D.string,
        name: D.string,
    },
});

export const getRegions: D.Decoder<ReportHubDto.GetRegions> = D.array(region);

export const unbundledContractOption: D.Decoder<ReportHubDto.UnbundledContractOption> =
    validateStrictDecoder<ReportHubDto.UnbundledContractOption>()(
        D.object({
            required: {
                unbundledContractId: D.string,
                name: D.string,
            },
        }),
    );

export const getContractTrackingFiltersUnbundled: D.Decoder<
    Array<ReportHubDto.UnbundledContractOption>
> = validateStrictDecoder<Array<ReportHubDto.UnbundledContractOption>>()(
    D.array(unbundledContractOption),
);

export const contractOption: D.Decoder<ReportHubDto.ContractOption> = D.object({
    required: {
        contractId: D.string,
        name: D.string,
    },
});

export const contractTrackingContractOptions: D.Decoder<ReportHubDto.ContractTrackingContractOptions> =
    D.array(contractOption);

export const getContractTrackingMonthlyPpaRowDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyPpaRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingMonthlyPpaRow>()(
        D.object({
            required: {
                year: D.number,
                month: D.number,
                intervalWh: D.nullable(D.number),
                invoicedWh: D.nullable(D.number),
                allocatedTrackingInstrumentsWh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
                allocatedVsIntervalPercentage: D.nullable(D.number),
                allocatedVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );
export const getContractTrackingMonthlyDirectPpaRowDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyDirectPpaRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingMonthlyDirectPpaRow>()(
        D.object({
            required: {
                year: D.number,
                month: D.number,
                intervalWh: D.nullable(D.number),
                invoicedWh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );

export const getContractTrackingMonthlyPpaDecoder: D.Decoder<
    Array<ReportHubDto.ContractTrackingMonthlyPpaRow>
> = validateStrictDecoder<Array<ReportHubDto.ContractTrackingMonthlyPpaRow>>()(
    D.array(getContractTrackingMonthlyPpaRowDecoder),
);

export const getContractTrackingMonthlyDirectPpaDecoder: D.Decoder<
    Array<ReportHubDto.ContractTrackingMonthlyDirectPpaRow>
> = validateStrictDecoder<Array<ReportHubDto.ContractTrackingMonthlyDirectPpaRow>>()(
    D.array(getContractTrackingMonthlyDirectPpaRowDecoder),
);

export const getContractTrackingMonthlyGreenTariffRowDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyGreenTariffRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingMonthlyGreenTariffRow>()(
        D.object({
            required: {
                year: D.number,
                month: D.number,
                intervalWh: D.nullable(D.number),
                invoicedWh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );

export const getContractTrackingMonthlyGreenTariffDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyGreenTariff> =
    validateStrictDecoder<ReportHubDto.ContractTrackingMonthlyGreenTariff>()(
        D.array(getContractTrackingMonthlyGreenTariffRowDecoder),
    );

const registryDecoder: D.Decoder<ReportHubDto.Registry> = D.object({
    required: {
        registryId: D.string,
        name: D.string,
    },
});

const eacAllocationDecoder: D.Decoder<ReportHubDto.AllocationWithInstrumentDetails> = D.object({
    required: {
        trackingInstrumentType: D.literal(TrackingInstrumentType.EAC),
        eacSchemeId: D.string,
        transactionFromName: D.nullable(D.string),
        productionSiteName: D.string,
        productionSiteId: D.string,
        consumptionSiteId: D.nullable(D.string),
        consumptionPeriodStart: dateDecoder,
        consumptionPeriodEnd: dateDecoder,
        transactionId: D.string,
        uploadDate: dateDecoder,
        allocatedTo: D.nullable(D.string),
        allocatedToName: D.nullable(D.string),
        consumptionOrganizationName: D.nullable(D.string),
        volumeWh: D.number,
        energySourceId: D.nullable(energySourceDecoder),
        productionCountryId: D.nullable(D.string),
        consumptionTimezone: D.string,
        registry: registryDecoder,
        productionPeriodStart: dateDecoder,
        productionPeriodEnd: dateDecoder,
        productionTimezone: D.string,
    },
});

const attestationAllocationDecoder: D.Decoder<ReportHubDto.AllocationWithInstrumentDetails> =
    D.object({
        required: {
            trackingInstrumentType: D.literal(TrackingInstrumentType.Attestation),
            eacSchemeId: D.nullable(D.string),
            transactionFromName: D.nullable(D.string),
            productionSiteName: D.nullable(D.string),
            productionSiteId: D.nullable(D.string),
            consumptionSiteId: D.nullable(D.string),
            consumptionPeriodStart: dateDecoder,
            consumptionPeriodEnd: dateDecoder,
            transactionId: D.string,
            uploadDate: dateDecoder,
            allocatedTo: D.nullable(D.string),
            allocatedToName: D.nullable(D.string),
            consumptionOrganizationName: D.nullable(D.string),
            volumeWh: D.number,
            energySourceId: D.nullable(energySourceDecoder),
            productionCountryId: D.nullable(D.string),
            consumptionTimezone: D.string,
            registry: D.null,
            productionPeriodStart: D.null,
            productionPeriodEnd: D.null,
            productionTimezone: D.null,
        },
    });

const allocationDecoder: D.Decoder<ReportHubDto.AllocationWithInstrumentDetails> = D.oneOf(
    eacAllocationDecoder,
    attestationAllocationDecoder,
);

export const getAllocationKpisDecoder: D.Decoder<ReportHubDto.AllocationKpis> =
    validateStrictDecoder<ReportHubDto.AllocationKpis>()(
        D.object({
            required: {
                receivedEacCancellationsWh: D.number,
                receivedAttestationsWh: D.number,
                allocatedTrackingInstrumentsWh: D.number,
            },
        }),
    );

export const getAllocationsByTenantIdResponseDecoder: D.Decoder<ReportHubDto.GetAllocationsByTenantIdResponse> =
    D.object({
        required: {
            allocations: D.array(allocationDecoder),
            totalAllocations: D.number,
        },
    });

type PostAllocationsByTenantIdResponse = InferResponse<
    reportHubPaths,
    "/{tenantId}/allocations",
    "post",
    201
>;

export const allocationPayloadDecoder: D.Decoder<PostAllocationsByTenantIdResponse[number]> =
    D.object({
        required: {
            trackingInstrumentId: D.string,
            trackingInstrumentType: trackingInstrumentTypeDecoder,
            contractId: uuidDecoder,
        },
    });

export const postAllocationsByTenantIdResponseDecoder: D.Decoder<PostAllocationsByTenantIdResponse> =
    D.array(allocationPayloadDecoder);

export const contractAsProofDecoder: D.Decoder<ReportHubDto.ContractAsProof> = D.object({
    required: {
        reportingYear: D.number,
        productionCountryId: D.string,
        energySourceId: energySourceDecoder,
        commissioningDate: D.nullable(dateDecoder),
        trackingInstrumentId: D.string,
    },
});

export const localContractDecoder: D.Decoder<ReportHubDto.LocalContract> = D.object({
    required: {
        contractId: D.string,
        sourcingMethod: sourcingMethodDecoder,
        reportingYear: D.nullable(D.number),
        contractsAsProof: D.array(contractAsProofDecoder),
    },
});

export const eacCodeDecoder: D.Decoder<ReportHubDto.EacCode> = D.object({
    required: {
        tenantId: D.string,
        eacCodeId: D.number,
        eacCode: D.string,
        registryId: D.string,
        useType: useTypeDecoder,
    },
});

/* TODO: Components and GetComponent should be on dtos */
type Components = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schemas: Record<string, any>;
};
export type GetComponent<C extends Components, Name extends keyof C["schemas"]> = C extends {
    schemas: {
        // eslint-disable-next-line no-unused-vars
        [N in Name]: infer Q;
    };
}
    ? Q
    : never;

export const countryDecoder: D.Decoder<ReportHubDto.Country> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
        passiveProcurement: D.boolean,
    },
});

export const marketDecoder: D.Decoder<ReportHubDto.Market> = D.object({
    required: {
        marketId: D.string,
        name: D.string,
        description: D.string,
    },
});

export const annualRegionEmissionFactorDecoder: D.Decoder<ReportHubDto.AnnualRegionEmissionFactor> =
    validateStrictDecoder<ReportHubDto.AnnualRegionEmissionFactor>()(
        D.object({
            required: {
                regionId: D.string,
                reportingYear: D.number,
                marketBasedEmissions_g_Wh: D.number,
                locationBasedEmissions_g_Wh: D.number,
            },
        }),
    );

const assignmentDetailDecoder = validateStrictDecoder<ReportHubDto.AssignmentDetail>()(
    D.object({
        required: {
            trackingInstrumentType: D.nullable(trackingInstrumentForAssignmentTypeDecoder),
            trackingInstrumentId: D.nullable(D.string),
            assignedWh: D.number,
            contributionToRe100Wh: D.number,
            productionPeriodStart: D.nullable(dateDecoder),
            productionPeriodEnd: D.nullable(dateDecoder),
            productionTimezone: D.nullable(D.string),
            eacSchemeId: D.nullable(D.string),
            productionCountryId: D.nullable(D.string),
            productionCountryName: D.nullable(D.string),
            energySourceId: D.nullable(energySourceDecoder),
            productionSiteCommissioningDateLocal: D.nullable(dateDecoder),
            sourcingMethod: sourcingMethodDecoder,
            contractId: D.nullable(D.string),
            contractStartTimeLocal: D.nullable(dateDecoder),
            documentId: D.nullable(D.number),
        },
    }),
);

export const countryAssignmentResponseDecoder =
    validateStrictDecoder<ReportHubDto.CountryAssignmentsResponse>()(
        D.object({
            required: {
                totalCount: D.number,
                assignments: D.array(assignmentDetailDecoder),
            },
        }),
    );

export const postAssignmentResponse = D.string;
export const countryAssignmentKpiDecoder: D.Decoder<ReportHubDto.CountryAssignmentKpis> =
    validateStrictDecoder<ReportHubDto.CountryAssignmentKpis>()(
        D.object({
            required: {
                countryId: D.string,
                countryName: D.string,
                passiveProcurement: D.boolean,
                globalRegionId: globalRegionIdDecoder,
                totalConsumptionWh: D.number,
                fromGridWh: D.number,
                fromDirectPpaWh: D.number,
                locationBasedEmissionsT: D.number,
                cdpMarketBasedEmissionsT: D.number,
                re100MarketBasedEmissionsT: D.number,
                regionIds: D.array(D.string),
                cdpMethodology: D.object({
                    required: {
                        totalAssignedWh: D.number,
                        renewableCoverage: D.number,
                        assignedFullContributionWh: D.number,
                        assignedPartialContributionWh: D.number,
                        assignedNoContributionWh: D.number,
                        assignedNoContractInfoWh: D.number,
                    },
                }),
                re100Methodology: D.object({
                    required: {
                        totalContributionToRe100Wh: D.number,
                        re100Score: D.number,
                        contributionFullContributionWh: D.number,
                        contributionPartialContributionWh: D.number,
                        contributionNoContributionWh: D.number,
                        contributionNoContractInfoWh: D.number,
                    },
                }),
            },
        }),
    );

export const reportingKpisDecoder: D.Decoder<ReportHubDto.GetReportingKpisResponse> =
    validateStrictDecoder<ReportHubDto.GetReportingKpisResponse>()(
        D.object({
            required: {
                totals: D.object({
                    required: {
                        totalConsumptionWh: D.number,
                        fromGridWh: D.number,
                        fromDirectPpaWh: D.number,
                        cdpMethodology: D.object({
                            required: {
                                assignedWh: D.number,
                                renewableCoverage: D.number,
                                locationBasedEmissionsT: D.number,
                                marketBasedEmissionsT: D.number,
                            },
                        }),
                        re100Methodology: D.object({
                            required: {
                                contributionToRe100Wh: D.number,
                                re100Score: D.number,
                                locationBasedEmissionsT: D.number,
                                marketBasedEmissionsT: D.number,
                            },
                        }),
                    },
                }),
                byCountry: D.array(countryAssignmentKpiDecoder),
            },
        }),
    );

export const contractOptionDecoder: D.Decoder<ReportHubDto.ContractOption> = D.object({
    required: {
        contractId: D.string,
        name: D.string,
    },
});

export const contractOptionsArrayDecoder: D.Decoder<Array<ReportHubDto.ContractOption>> =
    D.array(contractOptionDecoder);

export const allocationFiltersDecoder: D.Decoder<ReportHubDto.AllocationFilters> = D.object({
    required: {
        transactionIds: D.array(D.string),
        productionSiteNames: D.array(D.nullable(D.string)),
        allocatedToNames: D.array(D.nullable(D.string)),
        productionCountries: D.array(D.nullable(D.string)),
        energySources: D.array(D.nullable(energySourceDecoder)),
        registries: D.array(D.nullable(registryDecoder)),
    },
});

export const getGlobalOverviewKpisDecoder: D.Decoder<ReportHubDto.GlobalOverviewKpis> = D.object({
    required: {
        consumption: D.object({
            required: {
                totalWh: D.number,
                coveredByDirectPpasWh: D.number,
                coveredByTrackingInstrumentsWh: D.number,
                notCoveredByTrackingInstrumentsWh: D.number,
            },
        }),
        purchasedTrackingInstruments: D.object({
            required: {
                totalWh: D.number,
                greenTariffWh: D.number,
                ppaWh: D.number,
                vppaWh: D.number,
                unbundledWh: D.number,
            },
        }),
        totalTrackingInstrumentsLeftAfterCoverage: D.number,
    },
});

const getGlobalOverviewCountryCoverageDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewCountryCoverageDetail> =
    D.object({
        required: {
            tenantId: D.string,
            countryId: D.string,
            countryName: D.string,
            consumptionWh: D.number,
            purchasedVolumeWh: D.number,
            trackingInstrumentWh: D.number,
            coveredShare: D.number,
            uncoveredWh: D.number,
        },
    });

export const getGlobalOverviewCountriesCoverageDecoder: D.Decoder<ReportHubDto.GlobalOverviewCountriesCoverage> =
    D.object({
        required: {
            countriesCoverage: D.array(getGlobalOverviewCountryCoverageDetailDecoder),
        },
    });

export const getGlobalOverviewSiteDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewSiteDetail> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewSiteDetail>()(
        D.object({
            required: {
                siteName: D.string,
                consumptionWh: D.nullable(D.number),
                coveredByDirectPpasWh: D.nullable(D.number),
                coveredByTrackingInstrumentsWh: D.nullable(D.number),
                notCoveredByTrackingInstrumentsWh: D.nullable(D.number),
            },
        }),
    );

const getGlobalOverviewCountryPerformanceDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewCountryDetail> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewCountryDetail>()(
        D.object({
            required: {
                countryName: D.string,
                consumptionWh: D.number,
                coveredByDirectPpasWh: D.number,
                coveredByTrackingInstrumentsWh: D.number,
                notCoveredByTrackingInstrumentsWh: D.number,
                detailPerSite: D.array(getGlobalOverviewSiteDetailDecoder),
            },
        }),
    );
export const getGlobalOverviewCountriesPerformanceDecoder: D.Decoder<ReportHubDto.GlobalOverviewCountriesPerformance> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewCountriesPerformance>()(
        D.array(getGlobalOverviewCountryPerformanceDetailDecoder),
    );

const getGlobalOverviewGroupPerformanceDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewGroupDetail> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewGroupDetail>()(
        D.object({
            required: {
                groupName: D.string,
                consumptionWh: D.number,
                coveredByDirectPpasWh: D.number,
                coveredByTrackingInstrumentsWh: D.number,
                notCoveredByTrackingInstrumentsWh: D.number,
                detailPerSite: D.array(getGlobalOverviewSiteDetailDecoder),
            },
        }),
    );

export const getGlobalOverviewGroupsPerformanceDecoder: D.Decoder<ReportHubDto.GlobalOverviewGroupsPerformance> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewGroupsPerformance>()(
        D.array(getGlobalOverviewGroupPerformanceDetailDecoder),
    );

const getGlobalOverviewContractPerformanceDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewContractDetail> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewContractDetail>()(
        D.object({
            required: {
                contractId: D.string,
                contractName: D.string,
                contractType: contractTypeDecoder,
                totalPurchasedTrackingInstrumentsWh: D.number,
                totalUsedTrackingInstrumentsForCoverageWh: D.number,
                usedTrackingInstrumentsForCoverageForAppliedFiltersWh: D.number,
                usedTrackingInstrumentsForCoverageBeyondAppliedFiltersWh: D.number,
                totalTrackingInstrumentsLeftAfterCoverageWh: D.number,
            },
        }),
    );

export const getGlobalOverviewContractsPerformanceDecoder: D.Decoder<ReportHubDto.GlobalOverviewContractsPerformance> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewContractsPerformance>()(
        D.array(getGlobalOverviewContractPerformanceDetailDecoder),
    );

const reportingPeriodDecoder: D.Decoder<ReportHubDto.ReportingPeriod> = D.object({
    required: {
        tenantId: D.string,
        year: D.number,
        startReportingPeriodLocal: dateDecoder,
        endReportingPeriodLocal: dateDecoder,
    },
});
export const getReportingPeriodsDecoder: D.Decoder<ReportHubDto.GetReportingPeriods> =
    D.array(reportingPeriodDecoder);

export const getContractTrackingKpisPpaDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisPpa> =
    validateStrictDecoder<ReportHubDto.ContractTrackingKpisPpa>()(
        D.object({
            required: {
                intervalWh: D.number,
                invoicedWh: D.number,
                allocatedTrackingInstrumentsWh: D.number,
            },
        }),
    );
export const getContractTrackingKpisDirectPpaDecoder: D.Decoder<ReportHubDto.ContractTrackingDirectPpaKpis> =
    validateStrictDecoder<ReportHubDto.ContractTrackingDirectPpaKpis>()(
        D.object({
            required: {
                intervalWh: D.number,
                invoicedWh: D.number,
            },
        }),
    );
export const getContractTrackingKpisGreenTariffDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisGreenTariff> =
    validateStrictDecoder<ReportHubDto.ContractTrackingKpisGreenTariff>()(
        D.object({
            required: {
                intervalWh: D.number,
                invoicedWh: D.number,
                allocatedTrackingInstrumentsWh: D.number,
            },
        }),
    );

export const getContractTrackingKpisUnbundledDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisUnbundled> =
    validateStrictDecoder<ReportHubDto.ContractTrackingKpisUnbundled>()(
        D.object({
            required: {
                purchasedTrackingInstrumentsMWh: D.number,
                allocatedTrackingInstrumentsWh: D.number,
                missingTrackingInstrumentsWh: D.number,
                surplusAllocationWh: D.number,
            },
        }),
    );

const countryOptionDecoder: D.Decoder<ReportHubDto.CountryOption> =
    validateStrictDecoder<ReportHubDto.CountryOption>()(
        D.object({
            required: {
                countryId: D.string,
                name: D.string,
            },
        }),
    );

export const getGlobalOverviewFiltersCountriesOptions: D.Decoder<ReportHubDto.GlobalOverviewFiltersCountriesOptions> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewFiltersCountriesOptions>()(
        D.array(countryOptionDecoder),
    );

const consumptionSiteGroupOptionDecoder: D.Decoder<ReportHubDto.ConsumptionSiteGroupOption> =
    validateStrictDecoder<ReportHubDto.ConsumptionSiteGroupOption>()(
        D.object({
            required: {
                groupId: D.string,
                name: D.string,
            },
        }),
    );

export const getGlobalOverviewFiltersGroupsOptions: D.Decoder<ReportHubDto.GlobalOverviewFiltersGroupsOptions> =
    validateStrictDecoder<ReportHubDto.GlobalOverviewFiltersGroupsOptions>()(
        D.array(consumptionSiteGroupOptionDecoder),
    );

export const countryNameDecoder: D.Decoder<ReportHubDto.CountryName> = D.object({
    required: {
        countryName: D.string,
    },
});

export const regionWithCountryDecoder: D.Decoder<ReportHubDto.RegionWithCountry> =
    validateStrictDecoder<ReportHubDto.RegionWithCountry>()(
        D.object({
            required: {
                regionId: D.string,
                name: D.string,
                globalRegionId: globalRegionIdDecoder,
                country: countryDecoder,
            },
        }),
    );

export const reportingPeriodResponseDecoder: D.Decoder<ReportHubDto.ReportingPeriodResponse> =
    validateStrictDecoder<ReportHubDto.ReportingPeriodResponse>()(
        D.object({
            required: {
                startReportingPeriodLocal: dateDecoder,
                endReportingPeriodLocal: dateDecoder,
                totalUnassigned: D.number,
                totalConsumptionWh: D.number,
                fromGridWh: D.number,
                fromDirectPpaWh: D.number,
                cdpMethodology: D.object({
                    required: {
                        totalAssignedWh: D.number,
                        assignedFullContributionWh: D.number,
                        assignedPartialContributionWh: D.number,
                        assignedNoContributionWh: D.number,
                        assignedNoContractInfoWh: D.number,
                    },
                }),
                re100Methodology: D.object({
                    required: {
                        totalContributionToRe100Wh: D.number,
                        contributionFullContributionWh: D.number,
                        contributionPartialContributionWh: D.number,
                        contributionNoContributionWh: D.number,
                        contributionNoContractInfoWh: D.number,
                    },
                }),
            },
        }),
    );
