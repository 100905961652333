import { AxisBottom, AxisRight } from "@visx/axis";
import { Group } from "@visx/group";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { ParentSize } from "@visx/responsive";
import { scaleOrdinal } from "@visx/scale";
import { BarGroup } from "@visx/shape";
import { NumberValue, ScaleBand, ScaleLinear, ScaleOrdinal } from "d3-scale";
import { formatNumber, mantineTheme } from "@flexidao/ui-lib";
import { Stack } from "@mantine/core";

export type BarGroupChartTemplateProps<K extends string> = {
    width: number;
    height: number;
    data: ({ [group in K]: number } & { date: Date })[];
    dateScale: ScaleBand<Date>;
    formatDate: (date: Date) => string;
    groupScale: ScaleBand<K>;
    yScale: ScaleLinear<number, number, never>;
    colorScale?: ScaleOrdinal<string, string, never>;
    unit?: string;
    id: string;
};

const SoftGray = "#BCBEBC";
const Gray = "#646864";

export default function BarGroupChartTemplate<K extends string>({
    height: outerHeight,
    unit = "",
    data,
    dateScale,
    formatDate,
    groupScale,
    yScale,
    colorScale,
    id,
}: BarGroupChartTemplateProps<K>): JSX.Element {
    const axisRightWidth = 100;
    const axisBottomHeight = 21;

    const margin = { bottom: axisBottomHeight, left: 0, right: 0, top: 0 };
    const padding = { bottom: 0, left: 0, right: axisRightWidth, top: 20 };

    // bounds
    const yMax = outerHeight - margin.top - margin.bottom;

    // update scale output dimensions

    const keys = groupScale.domain();

    colorScale ??= scaleOrdinal<string, string>({
        domain: keys,
        range: [
            mantineTheme.colors?.flexidaoGreen![5]!,
            mantineTheme.colors?.flexidaoGreen![3]!,
            mantineTheme.colors?.flexidaoOrange![5]!,
        ],
    });
    return (
        <Stack data-id={id} spacing="md" className="w-screen" pb="md">
            <ParentSize style={{ width: "100%", height: outerHeight }}>
                {(parent): JSX.Element => {
                    const xMax = parent.width - margin.left - margin.right;

                    dateScale.range([padding.left, xMax - padding.right]);
                    groupScale.rangeRound([0, dateScale.bandwidth()]);
                    yScale.range([yMax - padding.bottom, padding.top]);

                    return (
                        <svg height={outerHeight} style={{ width: xMax }}>
                            <Group top={margin.top} left={margin.left}>
                                <BarGroup
                                    data={data}
                                    keys={keys}
                                    height={yMax}
                                    x0={(x): Date => x.date}
                                    x0Scale={dateScale}
                                    x1Scale={groupScale}
                                    yScale={yScale}
                                    color={colorScale!}
                                >
                                    {(barGroups): React.ReactNode =>
                                        barGroups.map((barGroup, i) => (
                                            <Group
                                                key={`bar-group-${barGroup.index}-${i}`}
                                                left={barGroup.x0}
                                            >
                                                {barGroup.bars.map((bar) => (
                                                    <rect
                                                        key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                                                        x={bar.x}
                                                        y={bar.y}
                                                        width={bar.width}
                                                        height={bar.height}
                                                        fill={bar.color}
                                                    />
                                                ))}
                                            </Group>
                                        ))
                                    }
                                </BarGroup>
                            </Group>
                            <AxisRight
                                scale={yScale}
                                numTicks={2}
                                hideZero
                                left={xMax - padding.right}
                                tickFormat={(x: NumberValue): string =>
                                    formatNumber(x.valueOf(), 0, unit)
                                }
                                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                tickLabelProps={() => ({
                                    fontSize: 12,
                                    fill: Gray,
                                    textAnchor: "start",
                                    x: "14",
                                    dy: "0.3em",
                                })}
                                tickLineProps={{
                                    stroke: SoftGray,
                                }}
                                stroke={SoftGray}
                            />
                            <AxisBottom
                                top={yMax + margin.top}
                                left={margin.left}
                                tickFormat={formatDate}
                                scale={dateScale}
                                tickLineProps={{ display: "none" }}
                                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                tickLabelProps={() => ({
                                    fontSize: 10,
                                    fontWeight: 400,
                                    fill: Gray,
                                    textAnchor: "middle",
                                })}
                                stroke={SoftGray}
                            />
                        </svg>
                    );
                }}
            </ParentSize>
            <LegendOrdinal scale={colorScale}>
                {(labels): JSX.Element => (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {labels.map((label, i) => (
                            <LegendItem
                                id={`${id}-legend-${label.text.replace(/\W/g, "-").toLowerCase()}`}
                                key={`legend-quantile-${i}`}
                                margin="0 5px"
                            >
                                <svg width={12} height={12}>
                                    <circle fill={label.value} cx="6" cy="6" r="6" />
                                </svg>
                                <LegendLabel align="left" margin="0 0 0 8px">
                                    <span style={{ fontSize: 12 }}>{label.text}</span>
                                </LegendLabel>
                            </LegendItem>
                        ))}
                    </div>
                )}
            </LegendOrdinal>
        </Stack>
    );
}
