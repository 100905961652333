export type CurrencyDefinition = { name: string; symbol: string; code: string };

export const currencyMap: Map<string, CurrencyDefinition> = new Map([
    ["USD", { name: "United States Dollar", code: "USD", symbol: "$" }],
    ["EUR", { name: "Euro", code: "EUR", symbol: "€" }],
    ["JPY", { name: "Japanese Yen", code: "JPY", symbol: "¥" }],
    ["GBP", { name: "British Pound Sterling", code: "GBP", symbol: "£" }],
    ["AUD", { name: "Australian Dollar", code: "AUD", symbol: "A$" }],
    ["CAD", { name: "Canadian Dollar", code: "CAD", symbol: "C$" }],
    ["CHF", { name: "Swiss Franc", code: "CHF", symbol: "CHF" }],
    ["CNY", { name: "Chinese Yuan", code: "CNY", symbol: "¥" }],
    ["SEK", { name: "Swedish Krona", code: "SEK", symbol: "kr" }],
    ["NZD", { name: "New Zealand Dollar", code: "NZD", symbol: "NZ$" }],
    ["MXN", { name: "Mexican Peso", code: "MXN", symbol: "$" }],
    ["SGD", { name: "Singapore Dollar", code: "SGD", symbol: "S$" }],
    ["HKD", { name: "Hong Kong Dollar", code: "HKD", symbol: "HK$" }],
    ["NOK", { name: "Norwegian Krone", code: "NOK", symbol: "kr" }],
    ["KRW", { name: "South Korean Won", code: "KRW", symbol: "₩" }],
    ["TRY", { name: "Turkish Lira", code: "TRY", symbol: "₺" }],
    ["RUB", { name: "Russian Ruble", code: "RUB", symbol: "₽" }],
    ["INR", { name: "Indian Rupee", code: "INR", symbol: "₹" }],
    ["BRL", { name: "Brazilian Real", code: "BRL", symbol: "R$" }],
    ["ZAR", { name: "South African Rand", code: "ZAR", symbol: "R" }],
    ["DKK", { name: "Danish Krone", code: "DKK", symbol: "kr" }],
    ["PLN", { name: "Polish Zloty", code: "PLN", symbol: "zł" }],
    ["THB", { name: "Thai Baht", code: "THB", symbol: "฿" }],
    ["ILS", { name: "Israeli New Shekel", code: "ILS", symbol: "₪" }],
    ["HUF", { name: "Hungarian Forint", code: "HUF", symbol: "Ft" }],
    ["CZK", { name: "Czech Koruna", code: "CZK", symbol: "Kč" }],
    ["CLP", { name: "Chilean Peso", code: "CLP", symbol: "$" }],
    ["PHP", { name: "Philippine Peso", code: "PHP", symbol: "₱" }],
    ["IDR", { name: "Indonesian Rupiah", code: "IDR", symbol: "Rp" }],
    ["MYR", { name: "Malaysian Ringgit", code: "MYR", symbol: "RM" }],
    ["COP", { name: "Colombian Peso", code: "COP", symbol: "$" }],
    ["SAR", { name: "Saudi Riyal", code: "SAR", symbol: "﷼" }],
    ["ARS", { name: "Argentine Peso", code: "ARS", symbol: "$" }],
    ["AED", { name: "United Arab Emirates Dirham", code: "AED", symbol: "د.إ" }],
    ["PEN", { name: "Peruvian Sol", code: "PEN", symbol: "S/." }],
    ["VES", { name: "Venezuelan Bolívar Soberano", code: "VES", symbol: "Bs.S" }],
    ["PKR", { name: "Pakistani Rupee", code: "PKR", symbol: "₨" }],
    ["EGP", { name: "Egyptian Pound", code: "EGP", symbol: "£" }],
    ["BDT", { name: "Bangladeshi Taka", code: "BDT", symbol: "৳" }],
    ["VND", { name: "Vietnamese Dong", code: "VND", symbol: "₫" }],
    ["IQD", { name: "Iraqi Dinar", code: "IQD", symbol: "ع.د" }],
    ["KWD", { name: "Kuwaiti Dinar", code: "KWD", symbol: "د.ك" }],
    ["OMR", { name: "Omani Rial", code: "OMR", symbol: "﷼" }],
    ["QAR", { name: "Qatari Riyal", code: "QAR", symbol: "﷼" }],
    ["BHD", { name: "Bahraini Dinar", code: "BHD", symbol: ".د.ب" }],
    ["JOD", { name: "Jordanian Dinar", code: "JOD", symbol: "د.ا" }],
    ["LBP", { name: "Lebanese Pound", code: "LBP", symbol: "ل.ل" }],
    ["LKR", { name: "Sri Lankan Rupee", code: "LKR", symbol: "Rs" }],
    ["AFN", { name: "Afghan Afghani", code: "AFN", symbol: "؋" }],
    ["ALL", { name: "Albanian Lek", code: "ALL", symbol: "L" }],
    ["AMD", { name: "Armenian Dram", code: "AMD", symbol: "֏" }],
    ["ANG", { name: "Netherlands Antillean Guilder", code: "ANG", symbol: "ƒ" }],
    ["AOA", { name: "Angolan Kwanza", code: "AOA", symbol: "Kz" }],
    ["AWG", { name: "Aruban Florin", code: "AWG", symbol: "ƒ" }],
    ["AZN", { name: "Azerbaijani Manat", code: "AZN", symbol: "₼" }],
    ["BAM", { name: "Bosnia and Herzegovina Convertible Mark", code: "BAM", symbol: "KM" }],
    ["BBD", { name: "Barbadian Dollar", code: "BBD", symbol: "Bds$" }],
    ["BIF", { name: "Burundian Franc", code: "BIF", symbol: "FBu" }],
    ["BMD", { name: "Bermudian Dollar", code: "BMD", symbol: "$" }],
    ["BND", { name: "Brunei Dollar", code: "BND", symbol: "B$" }],
    ["BOB", { name: "Bolivian Boliviano", code: "BOB", symbol: "Bs." }],
    ["BSD", { name: "Bahamian Dollar", code: "BSD", symbol: "B$" }],
    ["BTN", { name: "Bhutanese Ngultrum", code: "BTN", symbol: "Nu." }],
    ["BWP", { name: "Botswana Pula", code: "BWP", symbol: "P" }],
    ["BYN", { name: "Belarusian Ruble", code: "BYN", symbol: "Br" }],
    ["BZD", { name: "Belize Dollar", code: "BZD", symbol: "BZ$" }],
    ["CDF", { name: "Congolese Franc", code: "CDF", symbol: "FC" }],
    ["CRC", { name: "Costa Rican Colón", code: "CRC", symbol: "₡" }],
    ["CUC", { name: "Cuban Convertible Peso", code: "CUC", symbol: "$" }],
    ["CUP", { name: "Cuban Peso", code: "CUP", symbol: "$" }],
    ["CVE", { name: "Cape Verdean Escudo", code: "CVE", symbol: "$" }],
    ["DJF", { name: "Djiboutian Franc", code: "DJF", symbol: "Fdj" }],
    ["DOP", { name: "Dominican Peso", code: "DOP", symbol: "RD$" }],
    ["DZD", { name: "Algerian Dinar", code: "DZD", symbol: "دج" }],
    ["ERN", { name: "Eritrean Nakfa", code: "ERN", symbol: "Nfk" }],
    ["ETB", { name: "Ethiopian Birr", code: "ETB", symbol: "Br" }],
    ["FJD", { name: "Fijian Dollar", code: "FJD", symbol: "FJ$" }],
    ["FKP", { name: "Falkland Islands Pound", code: "FKP", symbol: "£" }],
    ["GEL", { name: "Georgian Lari", code: "GEL", symbol: "₾" }],
    ["GHS", { name: "Ghanaian Cedi", code: "GHS", symbol: "₵" }],
    ["GIP", { name: "Gibraltar Pound", code: "GIP", symbol: "£" }],
    ["GMD", { name: "Gambian Dalasi", code: "GMD", symbol: "D" }],
    ["GNF", { name: "Guinean Franc", code: "GNF", symbol: "FG" }],
    ["GTQ", { name: "Guatemalan Quetzal", code: "GTQ", symbol: "Q" }],
    ["GYD", { name: "Guyanese Dollar", code: "GYD", symbol: "G$" }],
    ["HNL", { name: "Honduran Lempira", code: "HNL", symbol: "L" }],
    ["HRK", { name: "Croatian Kuna", code: "HRK", symbol: "kn" }],
    ["HTG", { name: "Haitian Gourde", code: "HTG", symbol: "G" }],
    ["ISK", { name: "Icelandic Króna", code: "ISK", symbol: "kr" }],
    ["JMD", { name: "Jamaican Dollar", code: "JMD", symbol: "J$" }],
    ["KES", { name: "Kenyan Shilling", code: "KES", symbol: "KSh" }],
    ["KGS", { name: "Kyrgyzstani Som", code: "KGS", symbol: "лв" }],
    ["KHR", { name: "Cambodian Riel", code: "KHR", symbol: "៛" }],
    ["KMF", { name: "Comorian Franc", code: "KMF", symbol: "CF" }],
    ["KPW", { name: "North Korean Won", code: "KPW", symbol: "₩" }],
    ["LAK", { name: "Laotian Kip", code: "LAK", symbol: "₭" }],
    ["LSL", { name: "Lesotho Loti", code: "LSL", symbol: "L" }],
    ["LRD", { name: "Liberian Dollar", code: "LRD", symbol: "L$" }],
    ["LYD", { name: "Libyan Dinar", code: "LYD", symbol: "ل.د" }],
    ["MDL", { name: "Moldovan Leu", code: "MDL", symbol: "L" }],
    ["MGA", { name: "Malagasy Ariary", code: "MGA", symbol: "Ar" }],
    ["MKD", { name: "Macedonian Denar", code: "MKD", symbol: "ден" }],
    ["MMK", { name: "Myanmar Kyat", code: "MMK", symbol: "K" }],
    ["MNT", { name: "Mongolian Tugrik", code: "MNT", symbol: "₮" }],
    ["MOP", { name: "Macanese Pataca", code: "MOP", symbol: "MOP$" }],
    ["MRU", { name: "Mauritanian Ouguiya", code: "MRU", symbol: "UM" }],
    ["MUR", { name: "Mauritian Rupee", code: "MUR", symbol: "₨" }],
    ["MVR", { name: "Maldivian Rufiyaa", code: "MVR", symbol: "Rf" }],
    ["MWK", { name: "Malawian Kwacha", code: "MWK", symbol: "MK" }],
    ["NAD", { name: "Namibian Dollar", code: "NAD", symbol: "N$" }],
    ["NGN", { name: "Nigerian Naira", code: "NGN", symbol: "₦" }],
    ["NIO", { name: "Nicaraguan Córdoba", code: "NIO", symbol: "C$" }],
    ["NPR", { name: "Nepalese Rupee", code: "NPR", symbol: "₨" }],
    ["PAB", { name: "Panamanian Balboa", code: "PAB", symbol: "B/." }],
    ["PGK", { name: "Papua New Guinean Kina", code: "PGK", symbol: "K" }],
    ["PYG", { name: "Paraguayan Guarani", code: "PYG", symbol: "₲" }],
    ["RON", { name: "Romanian Leu", code: "RON", symbol: "lei" }],
    ["RSD", { name: "Serbian Dinar", code: "RSD", symbol: "дин" }],
    ["RWF", { name: "Rwandan Franc", code: "RWF", symbol: "FRw" }],
    ["SBD", { name: "Solomon Islands Dollar", code: "SBD", symbol: "SI$" }],
    ["SCR", { name: "Seychellois Rupee", code: "SCR", symbol: "₨" }],
    ["SDG", { name: "Sudanese Pound", code: "SDG", symbol: "ج.س." }],
    ["SHP", { name: "Saint Helena Pound", code: "SHP", symbol: "£" }],
    ["SLL", { name: "Sierra Leonean Leone", code: "SLL", symbol: "Le" }],
    ["SOS", { name: "Somali Shilling", code: "SOS", symbol: "Sh" }],
    ["SRD", { name: "Surinamese Dollar", code: "SRD", symbol: "$" }],
    ["SSP", { name: "South Sudanese Pound", code: "SSP", symbol: "£" }],
    ["STN", { name: "São Tomé and Príncipe Dobra", code: "STN", symbol: "Db" }],
    ["SVC", { name: "Salvadoran Colón", code: "SVC", symbol: "₡" }],
    ["SYP", { name: "Syrian Pound", code: "SYP", symbol: "£" }],
    ["SZL", { name: "Eswatini Lilangeni", code: "SZL", symbol: "E" }],
    ["TJS", { name: "Tajikistani Somoni", code: "TJS", symbol: "SM" }],
    ["TMT", { name: "Turkmenistani Manat", code: "TMT", symbol: "m" }],
    ["TND", { name: "Tunisian Dinar", code: "TND", symbol: "د.ت" }],
    ["TOP", { name: "Tongan Paʻanga", code: "TOP", symbol: "T$" }],
    ["TTD", { name: "Trinidad and Tobago Dollar", code: "TTD", symbol: "TT$" }],
    ["TZS", { name: "Tanzanian Shilling", code: "TZS", symbol: "Sh" }],
    ["UAH", { name: "Ukrainian Hryvnia", code: "UAH", symbol: "₴" }],
    ["UGX", { name: "Ugandan Shilling", code: "UGX", symbol: "USh" }],
    ["UYU", { name: "Uruguayan Peso", code: "UYU", symbol: "$U" }],
    ["UZS", { name: "Uzbekistani So'm", code: "UZS", symbol: "so'm" }],
    ["VUV", { name: "Vanuatu Vatu", code: "VUV", symbol: "VT" }],
    ["WST", { name: "Samoan Tala", code: "WST", symbol: "WS$" }],
    ["XAF", { name: "Central African CFA Franc", code: "XAF", symbol: "FCFA" }],
    ["XCD", { name: "East Caribbean Dollar", code: "XCD", symbol: "$" }],
    ["XOF", { name: "West African CFA Franc", code: "XOF", symbol: "CFA" }],
    ["XPF", { name: "CFP Franc", code: "XPF", symbol: "₣" }],
    ["YER", { name: "Yemeni Rial", code: "YER", symbol: "﷼" }],
    ["ZMW", { name: "Zambian Kwacha", code: "ZMW", symbol: "ZK" }],
    ["ZWB", { name: "RTGS Dollar", code: "ZWB", symbol: "$" }],
    ["ZWL", { name: "Zimbabwean Dollar", code: "ZWL", symbol: "$" }],
]);
