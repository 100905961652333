export const arrayEquals = <T>(
    a: Array<T>,
    b: Array<T>,
    compareFn: (a: T, b: T) => boolean,
): boolean => {
    // If the two arrays are the same object, or they are both null or undefined, they are equal
    if (a === b) {
        return true;
    }

    // If one of the arrays is null or undefined, they are not equal
    if (a == null || b == null) {
        return false;
    }

    // If the arrays have different lengths, they are not equal
    if (a.length !== b.length) {
        return false;
    }

    // Else, compare each element of the arrays
    // If all elements are equal, the arrays are equal
    const sortedA: Array<T> = [...a].sort();
    const sortedB: Array<T> = [...b].sort();

    for (let i = 0; i < sortedA.length; i++) {
        if (!compareFn(sortedA[i]!, sortedB[i]!)) {
            return false;
        }
    }

    return true;
};
