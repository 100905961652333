export const FlexidaoLogoLightFill = ({ height }: { height: number }): JSX.Element => (
    <svg
        height={height}
        style={{ marginTop: 6 }}
        viewBox="0 0 200 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M177.307 19.9987C177.307 18.1418 176.647 16.556 175.335 15.2396C174.018 13.9271 172.433 13.268 170.574 13.268C168.716 13.268 167.133 13.9271 165.82 15.2396C164.502 16.556 163.848 18.1418 163.848 19.9987C163.848 21.8564 164.502 23.4418 165.82 24.7551C167.133 26.0711 168.716 26.728 170.574 26.728C172.433 26.728 174.018 26.0711 175.335 24.7551C176.647 23.4418 177.307 21.8564 177.307 19.9987ZM177.241 13.3338C179.079 15.1764 180 17.3947 180 19.9987C180 22.6009 179.079 24.8227 177.241 26.66C175.398 28.4991 173.18 29.42 170.574 29.42C167.973 29.42 165.752 28.4991 163.913 26.66C162.076 24.8227 161.155 22.6009 161.155 19.9987C161.155 17.3947 162.076 15.1764 163.913 13.3338C165.752 11.4965 167.973 10.5747 170.574 10.5747C173.18 10.5747 175.398 11.4965 177.241 13.3338"
            fill="#FAFBFA"
        />
        <path
            d="M143.175 28.6253C143.113 28.7697 143.023 28.9004 142.906 29.0164C142.637 29.2849 142.319 29.4204 141.951 29.4204C141.583 29.4204 141.266 29.2871 141.002 29.0222C140.737 28.7569 140.604 28.4413 140.604 28.072C140.604 27.8502 140.653 27.6431 140.753 27.4551L147.362 11.5867H147.374C147.428 11.3609 147.55 11.1542 147.739 10.9667C147.997 10.7053 148.311 10.576 148.68 10.576C149.048 10.576 149.368 10.7053 149.636 10.9667C149.834 11.1733 149.954 11.4009 149.999 11.6533L150.053 11.6391L156.702 27.6431H156.69C156.734 27.7769 156.756 27.9222 156.756 28.072C156.756 28.4413 156.623 28.7569 156.359 29.0222C156.094 29.2871 155.778 29.4204 155.41 29.4204C155.042 29.4204 154.728 29.2849 154.469 29.0164C154.29 28.8462 154.171 28.6537 154.119 28.4364L148.694 15.3689L143.188 28.6253H143.175Z"
            fill="#FAFBFA"
        />
        <path
            d="M121.401 13.2684C121.032 13.2684 120.717 13.1364 120.452 12.8715C120.187 12.6075 120.054 12.2902 120.054 11.9222C120.054 11.5538 120.187 11.2373 120.452 10.9724C120.717 10.7084 121.032 10.576 121.401 10.576H126.785C129.389 10.576 131.609 11.4964 133.448 13.3342C135.288 15.1742 136.206 17.3964 136.206 19.9982C136.206 22.6013 135.288 24.8222 133.448 26.6604C131.609 28.5004 129.389 29.4204 126.785 29.4204H121.401C121.032 29.4204 120.717 29.2871 120.452 29.0222C120.187 28.7573 120.054 28.4413 120.054 28.0724V17.3071C120.054 16.9369 120.187 16.6226 120.452 16.3569C120.717 16.0924 121.032 15.9595 121.401 15.9595C121.769 15.9595 122.085 16.0924 122.35 16.3569C122.616 16.6226 122.747 16.9369 122.747 17.3071V26.7284H126.785C128.644 26.7284 130.227 26.0715 131.543 24.7569C132.859 23.4418 133.514 21.8555 133.514 19.9982C133.514 18.1404 132.859 16.5551 131.543 15.24C130.227 13.9253 128.644 13.2684 126.785 13.2684H121.401Z"
            fill="#FAFBFA"
        />
        <path
            d="M110.813 11.9222C110.813 11.5533 110.945 11.2373 111.209 10.9724C111.474 10.7084 111.79 10.576 112.159 10.576C112.526 10.576 112.842 10.7084 113.107 10.9724C113.372 11.2373 113.503 11.5533 113.503 11.9222V28.072C113.503 28.4413 113.372 28.7569 113.107 29.0222C112.842 29.2871 112.526 29.4204 112.159 29.4204C111.79 29.4204 111.474 29.2871 111.209 29.0222C110.945 28.7569 110.813 28.4413 110.813 28.072V11.9222Z"
            fill="#FAFBFA"
        />
        <path
            d="M97.9888 20.0053L103.976 12.8569C104.465 12.2706 104.39 11.3982 103.804 10.9071C103.217 10.4151 102.345 10.4933 101.854 11.0791L88.3942 27.1529C87.904 27.7386 87.98 28.6115 88.5662 29.1031C88.8257 29.32 89.14 29.4253 89.4533 29.4253C89.8502 29.4253 90.2413 29.2555 90.5155 28.9306L96.1839 22.16L101.854 28.9306C102.129 29.2555 102.521 29.4253 102.914 29.4253C103.229 29.4253 103.544 29.32 103.804 29.1031C104.39 28.6115 104.465 27.7386 103.976 27.1529L97.9888 20.0053Z"
            fill="#FAFBFA"
        />
        <path
            d="M92.6438 17.1576C92.2487 17.1576 91.8562 16.9896 91.5824 16.6625L88.394 12.8567C87.9038 12.2705 87.9802 11.3985 88.5664 10.9069C89.1522 10.4154 90.0242 10.4932 90.5153 11.0789L93.7047 14.886C94.1949 15.4723 94.1175 16.3447 93.5318 16.8363C93.2727 17.0523 92.9575 17.1576 92.6438 17.1576Z"
            fill="#00AB8E"
        />
        <path
            d="M66.7509 13.2684C66.3834 13.2684 66.0683 13.1364 65.8029 12.8715C65.5371 12.6075 65.4038 12.2902 65.4038 11.9222C65.4038 11.5537 65.5371 11.2373 65.8029 10.9724C66.0683 10.7084 66.3834 10.5759 66.7509 10.5759H80.2113C80.5793 10.5759 80.8949 10.7084 81.1607 10.9724C81.426 11.2373 81.5571 11.5537 81.5571 11.9222C81.5571 12.2902 81.426 12.6075 81.1607 12.8715C80.8949 13.1364 80.5793 13.2684 80.2113 13.2684H66.7509Z"
            fill="#FAFBFA"
        />
        <path
            d="M80.211 18.6519C80.5795 18.6519 80.895 18.7839 81.1604 19.0493C81.4257 19.3141 81.5568 19.6293 81.5568 19.9981C81.5568 20.3657 81.4257 20.6821 81.1604 20.9475C80.895 21.2124 80.5795 21.3444 80.211 21.3444H66.7506C66.383 21.3444 66.0684 21.2124 65.8026 20.9475C65.5368 20.6821 65.4039 20.3657 65.4039 19.9981C65.4039 19.6293 65.5368 19.3141 65.8026 19.0493C66.0684 18.7839 66.383 18.6519 66.7506 18.6519H80.211Z"
            fill="#FAFBFA"
        />
        <path
            d="M66.7509 29.4202C66.3834 29.4202 66.0683 29.2873 65.8029 29.0219C65.5371 28.7571 65.4038 28.4411 65.4038 28.0722C65.4038 27.7073 65.5371 27.3904 65.8029 27.1259C66.0683 26.8606 66.3834 26.7282 66.7509 26.7282H80.2113C80.5793 26.7282 80.8949 26.8606 81.1607 27.1259C81.426 27.3904 81.5571 27.7073 81.5571 28.0722C81.5571 28.4411 81.426 28.7571 81.1607 29.0219C80.8949 29.2873 80.5793 29.4202 80.2113 29.4202H66.7509Z"
            fill="#FAFBFA"
        />
        <path
            d="M42.7026 11.9222C42.7026 11.5533 42.8346 11.2373 43.1009 10.9724C43.3644 10.7084 43.6804 10.576 44.0489 10.576C44.4164 10.576 44.7324 10.7084 44.9973 10.9724C45.2622 11.2373 45.3955 11.5533 45.3955 11.9222V26.7284H57.5084C57.8764 26.7284 58.1928 26.8604 58.4573 27.1257C58.7213 27.3906 58.8528 27.7071 58.8528 28.072C58.8528 28.4413 58.7213 28.7569 58.4573 29.0222C58.1928 29.2871 57.8764 29.4204 57.5084 29.4204H44.0489C43.6804 29.4204 43.3644 29.2871 43.1009 29.0222C42.8346 28.7569 42.7026 28.4413 42.7026 28.072V11.9222Z"
            fill="#FAFBFA"
        />
        <path
            d="M34.8053 10.576C35.1733 10.576 35.4902 10.7084 35.7546 10.9724C36.0195 11.2373 36.1515 11.5533 36.1515 11.9222C36.1515 12.2902 36.0195 12.6076 35.7546 12.8716C35.4902 13.1364 35.1733 13.2684 34.8053 13.2684H22.6933V28.072C22.6933 28.4413 22.5596 28.7569 22.2947 29.0222C22.0298 29.2871 21.7133 29.42 21.3458 29.42C20.9773 29.42 20.6618 29.2871 20.396 29.0222C20.1324 28.7569 20 28.4413 20 28.072V11.9222C20 11.5533 20.1324 11.2373 20.396 10.9724C20.6618 10.7084 20.9773 10.576 21.3458 10.576H34.8053Z"
            fill="#FAFBFA"
        />
        <path
            d="M26.7217 21.3438C26.3524 21.3438 26.0364 21.2131 25.7733 20.9473C25.5084 20.6829 25.3755 20.3673 25.3755 19.9989C25.3755 19.6295 25.5084 19.3149 25.7733 19.0491C26.0364 18.7846 26.3524 18.6518 26.7217 18.6518H32.1159C32.4826 18.6518 32.7995 18.7846 33.063 19.0491C33.327 19.3149 33.4599 19.6295 33.4599 19.9989C33.4599 20.3673 33.327 20.6829 33.063 20.9473C32.7995 21.2131 32.4826 21.3438 32.1159 21.3438H26.7217Z"
            fill="#FAFBFA"
        />
    </svg>
);
