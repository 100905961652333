import { SourceType } from "./enum";
import { ZoneDataAccessLevel } from "./";

export enum GridEmissionsFeatureFlags {}

export namespace GridEmissionsDto {
    export type ZoneDto = {
        zoneId: string;
        name: string;
        countryISO: string;
        enabled: boolean;
        initialTimeToCheck: Date | null;
        endTimeToCheck: Date | null;
    };

    export type ElectricityMapDataDto = {
        url: string;
        path: string;
        parameters: unknown;
        response: unknown;
    };

    export type ZoneIntensityDto = {
        endTime: Date;
        startTime: Date;
        /**
         * gCO2eq/kWh
         */
        gridIntensity: number;
        cfeShare: number;
        reShare: number;
        /**
         * MWh
         */
        totalProduction: number;
    };

    export type ZoneIntensityDtoWithUnits = {
        endTime: Date;
        startTime: Date;
        /**
         * gCO2eq/kWh
         */
        gridIntensity_gCO2eqkWh: number;
        cfeShare: number;
        reShare: number;
        /**
         * MWh
         */
        totalProduction_MWh: number;
    };

    export type EmissionRate = {
        zoneId: string;
        year: number;
        rateId: string;
        /** Emissions rate gCO2/kWh */
        value: number;
    };

    export type Source = {
        sourceId: string;
        name: string;
        sourceType: SourceType;
    };

    export type BiddingZone = {
        zoneId: string;
        name: string;
        country: Country;
        timezones: Array<string>;
        enabled: boolean;
        dataAccess: ZoneDataAccessLevel;
    };

    export type Country = {
        countryId: string;
        name: string;
        enabled: boolean;
    };

    export type BiddingZoneInCountry = {
        zoneId: string;
        name: string;
        enabled: boolean;
        timezones: Array<string>;
    };

    export type CountryWithZones = {
        countryId: string;
        name: string;
        enabled: boolean;
        biddingZones: Array<BiddingZoneInCountry>;
    };

    export type Co2EmissionFactor = {
        co2EmissionFactorId: string;
        country: Country;
        biddingZone: BiddingZone;
        year: number;
        annualDataSourceMarketBased: string | null;
        annualDataSourceLocationBased: string | null;
        hourlyDataSourceMarketBased: string | null;
        hourlyDataSourceLocationBased: string | null;
        emissionalityDataSource: string | null;
    };

    export type GetCo2EmissionFactorsResponse = {
        totalPages: number;
        co2EmissionFactors: Array<Co2EmissionFactor>;
    };

    export type CreateCo2EmissionFactorPayload = {
        country: string;
        biddingZone: string;
        year: number;
        annualDataSourceMarketBased?: string | null;
        annualDataSourceLocationBased?: string | null;
        hourlyDataSourceMarketBased?: string | null;
        hourlyDataSourceLocationBased?: string | null;
        emissionalityDataSource?: string | null;
    };

    export type UpdateCo2EmissionFactorPayload = Partial<CreateCo2EmissionFactorPayload>;

    export type BaseAnnualFactor = {
        factorId: string;
        biddingZoneId: string;
        sourceId: string;
        sourceYear: number;
        factorType: SourceType;
        value_g_Wh: number;
    };
}
