import { TooltipContainer } from "../_utils/utils";
import { StackedAreasReading, StackedAreasSeries } from "./stacked-areas";
import { Box, Group, Stack, Text } from "@mantine/core";
import { ReactElement } from "react";
import { physicalRoundForElectricity } from "@flexidao/ui-lib";

type StackedAreasTooltipData<T> = StackedAreasReading<T> | undefined;
type StackedAreasTooltipProps<T> = {
    tooltipLeft: number | undefined;
    tooltipTop: number | undefined;
    tooltipData: StackedAreasTooltipData<T> | undefined;
    series: StackedAreasSeries<T>[];
};
export const Tooltip = <T extends Record<string, number>>({
    tooltipLeft,
    tooltipTop,
    tooltipData,
    series,
}: StackedAreasTooltipProps<T>): ReactElement => {
    const showTooltip = tooltipData && tooltipTop && tooltipLeft && series.length > 0;
    const tooltipHeaderText = tooltipData ? tooltipData.date : "";
    const tooltipContent = tooltipData
        ? series.map((s: StackedAreasSeries<T>) => {
              const seriesCode = s.key;
              const value_: number = (tooltipData && tooltipData.values[seriesCode as string]) || 0;
              const decimalPlaces = 2;
              const [value, magnitude] = physicalRoundForElectricity(value_, decimalPlaces);

              return {
                  label: s.label,
                  color: s.fill,
                  value: value + " " + magnitude + "Wh",
              };
          })
        : [];
    return showTooltip ? (
        <TooltipContainer tooltipTop={tooltipTop} tooltipLeft={tooltipLeft}>
            <Stack spacing="xs">
                <Text weight="bold" size="sm">
                    {tooltipHeaderText}
                </Text>

                {tooltipContent.map(({ label, color, value }, i) => (
                    <Group key={i} position="apart">
                        <Group spacing="md" align="center">
                            <Box
                                component="span"
                                w={12}
                                h={12}
                                sx={{ borderRadius: "100%" }}
                                bg={color}
                            />
                            <Text size="sm">{label}</Text>
                        </Group>
                        <Text size="sm">{value}</Text>
                    </Group>
                ))}
            </Stack>
        </TooltipContainer>
    ) : (
        <></>
    );
};
