import { CfeDiagnosticDto, cfeDiagnosticPaths } from "@flexidao/dto";
import { ApiClient } from "../api-client";
import {
    contractTypeArrayDecoder,
    countriesArrayDecoder,
    estimationDecoder,
    featureFlagDecoder,
    getAllEstimationsDecoder,
    industryTypeArrayDecoder,
    successDecoder,
} from "../decoders";
import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash } from "../utils";

export type OverloadCfeDiagnosticClient = {
    getPublicIndustryTypes: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<CfeDiagnosticDto.IndustryType>>;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<CfeDiagnosticDto.Country>>;
    getPublicContractTypes: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<CfeDiagnosticDto.ContractType>>;
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<string>>;

    getEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
    ) => OverloadApiResponse<CfeDiagnosticDto.Estimation>;
    getEstimationsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        page: number,
    ) => OverloadApiResponse<CfeDiagnosticDto.EstimationGetAll>;
    deleteEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
    ) => OverloadApiResponse<{ success: boolean }>;
    putEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
        body: CfeDiagnosticDto.EstimationInput,
    ) => OverloadApiResponse<{ success: boolean }>;
    postEstimationsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: CfeDiagnosticDto.EstimationInput,
    ) => OverloadApiResponse<{ success: boolean }>;
} & ApiClient<cfeDiagnosticPaths>;

export type ResultCfeDiagnosticClient = {
    getPublicIndustryTypes: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<CfeDiagnosticDto.IndustryType>>;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<CfeDiagnosticDto.Country>>;
    getPublicContractTypes: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<CfeDiagnosticDto.ContractType>>;
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<string>>;

    getEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
    ) => ApiResultResponse<CfeDiagnosticDto.Estimation>;
    getEstimationsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        page: number,
    ) => ApiResultResponse<CfeDiagnosticDto.EstimationGetAll>;
    deleteEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
    ) => ApiResultResponse<{ success: boolean }>;
    putEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
        body: CfeDiagnosticDto.EstimationInput,
    ) => ApiResponse<{ success: boolean }>;
    postEstimationsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: CfeDiagnosticDto.EstimationInput,
    ) => ApiResultResponse<{ success: boolean }>;
} & ApiClient<cfeDiagnosticPaths>;

export type CfeDiagnosticClient = {
    getPublicIndustryTypes: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<CfeDiagnosticDto.IndustryType>>;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<CfeDiagnosticDto.Country>>;
    getPublicContractTypes: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<CfeDiagnosticDto.ContractType>>;
    getPublicFeatureFlags: (getAccessToken: () => Promise<string>) => ApiResponse<Array<string>>;

    getEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
    ) => ApiResponse<CfeDiagnosticDto.Estimation>;
    getEstimationsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        page: number,
    ) => ApiResponse<CfeDiagnosticDto.EstimationGetAll>;
    deleteEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
    ) => ApiResponse<{ success: boolean }>;
    putEstimationsByEstimationIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        estimationId: string,
        body: CfeDiagnosticDto.EstimationInput,
    ) => ApiResponse<{ success: boolean }>;
    postEstimationsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: CfeDiagnosticDto.EstimationInput,
    ) => ApiResponse<{ success: boolean }>;
} & ApiClient<cfeDiagnosticPaths>;

export function getCfeDiagnosticClient(config: Record<string, never>): CfeDiagnosticClient;
export function getCfeDiagnosticClient(config: { useResult: boolean }): ResultCfeDiagnosticClient;
export function getCfeDiagnosticClient(config: {
    useResult?: boolean;
}): OverloadCfeDiagnosticClient {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        useMockData = process.env.MOCK_CFE_DIAGNOSTIC === "true";
        if (!process.env.CFE_DIAGNOSTIC_URL) {
            return crash("Missing CFE_DIAGNOSTIC_URL environment variable.");
        } else {
            url = process.env.CFE_DIAGNOSTIC_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }

    const { get, post, del, put } = buildQueries({ ...config, useMockData: useMockData }, url);

    return {
        // PUBLIC
        getPublicIndustryTypes: async (
            getAccessToken,
        ): OverloadApiResponse<Array<CfeDiagnosticDto.IndustryType>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/cfe-diagnostic/public/industry-types`,
                decoder: industryTypeArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicCountries: async (
            getAccessToken,
        ): OverloadApiResponse<Array<CfeDiagnosticDto.Country>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/cfe-diagnostic/public/countries`,
                decoder: countriesArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicContractTypes: async (
            getAccessToken,
        ): OverloadApiResponse<Array<CfeDiagnosticDto.ContractType>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/cfe-diagnostic/public/contract-types`,
                decoder: contractTypeArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicFeatureFlags: async (getAccessToken): OverloadApiResponse<Array<string>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/cfe-diagnostic/public/feature-flags`,
                decoder: featureFlagDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // ESTIMATIONS
        getEstimationsByEstimationIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            estimationId: string,
        ): OverloadApiResponse<CfeDiagnosticDto.Estimation> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/cfe-diagnostic/${tenantId}/estimations/${estimationId}`,
                decoder: estimationDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEstimationsByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            page: number,
        ): OverloadApiResponse<CfeDiagnosticDto.EstimationGetAll> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/cfe-diagnostic/${tenantId}/estimations`,
                decoder: getAllEstimationsDecoder,
                params: { page },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        deleteEstimationsByEstimationIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            estimationId: string,
        ): OverloadApiResponse<{ success: boolean }> => {
            const accessToken = await getAccessToken();

            return del({
                path: `/api/cfe-diagnostic/${tenantId}/estimations/${estimationId}`,
                decoder: successDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putEstimationsByEstimationIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            estimationId: string,
            data: CfeDiagnosticDto.EstimationInput,
        ): OverloadApiResponse<{ success: boolean }> => {
            const accessToken = await getAccessToken();
            return put({
                path: `/api/cfe-diagnostic/${tenantId}/estimations/${estimationId}`,
                decoder: successDecoder,
                data,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postEstimationsByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            data: CfeDiagnosticDto.EstimationInput,
        ): OverloadApiResponse<{ success: boolean }> => {
            const accessToken = await getAccessToken();
            return post({
                path: `/api/cfe-diagnostic/${tenantId}/estimations`,
                decoder: successDecoder,
                data,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
    };
}
