import { ReactElement } from "react";
import { formatNumber } from "@flexidao/ui-lib";
import { Group, Text } from "@mantine/core";
import { getClassNameForSubRow } from "./utils";

type DivisionCellProps = {
    value: number | null;
    total: number | null;
    units?: string;
    color?: string;
    numDecimals?: number;
    isSubRow?: boolean;
};

export const DivisionCell = ({
    value,
    total,
    units,
    color = "flexidaoGrey.9",
    numDecimals = 0,
    isSubRow = false,
}: DivisionCellProps): ReactElement => {
    const valueString: string = formatNumber(value, numDecimals);
    const totalString: string = formatNumber(total, numDecimals);

    return (
        <Group
            noWrap
            align="baseline"
            spacing={4}
            sx={{
                justifyContent: "flex-end",
            }}
            className={getClassNameForSubRow(isSubRow)}
        >
            <Text size={14} weight={600} c={color}>
                {valueString}
            </Text>

            <Text size={11}>/</Text>

            <Text size={11}>{totalString}</Text>

            {units && <Text size={11}>{units}</Text>}
        </Group>
    );
};
