import { ReportHubDto } from "@flexidao/dto";

export const formatRe100Contribution = (
    re100Contribution: ReportHubDto.RE100Contribution,
): string => {
    switch (re100Contribution) {
        case ReportHubDto.RE100Contribution.NoContribution:
            return "No contribution";
        case ReportHubDto.RE100Contribution.PartialContribution:
            return "Partial contribution";
        case ReportHubDto.RE100Contribution.FullContribution:
            return "Full contribution";
        case ReportHubDto.RE100Contribution.NoContractInfo:
            return "No contract info";
    }
};

export const getColorFromRe100Contribution = (
    re100Contribution: ReportHubDto.RE100Contribution,
): string => {
    switch (re100Contribution) {
        case ReportHubDto.RE100Contribution.NoContribution:
            return "flexidaoRed";
        case ReportHubDto.RE100Contribution.PartialContribution:
            return "flexidaoYellow";
        case ReportHubDto.RE100Contribution.FullContribution:
            return "flexidaoGreen";
        case ReportHubDto.RE100Contribution.NoContractInfo:
            return "flexidaoGrey.6";
    }
};
