import { SiteLevelWarning } from "@flexidao/dto";

export const formatSiteWarningType = (warningType: SiteLevelWarning): string => {
    switch (warningType) {
        case SiteLevelWarning.Outlier:
            return "Outlier";
        case SiteLevelWarning.Staleness:
            return "Staleness";
        case SiteLevelWarning.ExceededInstalledCapacity:
            return "Exceeded installed capacity";
    }
};
