import { formatNumber } from "@flexidao/ui-lib";
import { Group, Skeleton, Text } from "@mantine/core";
import { ReactElement } from "react";
import { ComponentState } from "@flexidao/dto";
import { PERCENTAGE_SMALL_VALUE_DEFAULT_WIDTH, SKELETON_SMALL_VALUE_DEFAULT_WIDTH } from "./consts";

export type KpiSectionSmallWithPercentageProps = {
    label: string;
    value: {
        value: number | null;
        numDecimals: number;
        unit: string | null;
        width?: number;
    };
    percentage: {
        value: number | null;
        numDecimals: number;
        unit: string | null;
        width?: number;
    };
    dataId: string;
    state: ComponentState;
};

export const KpiSectionSmallWithPercentage = ({
    label,
    value,
    percentage,
    dataId,
    state,
}: KpiSectionSmallWithPercentageProps): ReactElement => (
    <Group
        position="apart"
        align="flex-start"
        px="xl"
        py={8}
        mih={40}
        data-id={dataId}
        spacing="8px"
        noWrap
    >
        {!label ? (
            <Skeleton height={21} width={100} />
        ) : (
            <Text size="sm" weight={600}>
                {label}
            </Text>
        )}

        <Group spacing="8px" align="flex-start" noWrap>
            {getDisplayValue({
                state,
                ...value,
            })}

            {getDisplayValue({
                state,
                ...percentage,
                width: percentage.width ?? PERCENTAGE_SMALL_VALUE_DEFAULT_WIDTH,
            })}
        </Group>
    </Group>
);

const getDisplayValue = ({
    state,
    value,
    numDecimals,
    unit,
    width,
}: {
    state: ComponentState;
    value: number | null;
    numDecimals: number;
    unit: string | null;
    width?: number;
}): ReactElement => {
    if (state === ComponentState.Loading) {
        return <Skeleton height={21} width={width ?? SKELETON_SMALL_VALUE_DEFAULT_WIDTH} />;
    }

    if (state === ComponentState.Error) {
        return (
            <Text size="xs" color="flexidaoRed" ta="right">
                Error
            </Text>
        );
    }

    const valueString: string = formatNumber(value, numDecimals);

    return (
        <Text size="sm" weight={600} w={width} miw={width} ta="right">
            {valueString}
            {unit === "%" ? "" : " "}
            {value !== null && unit !== null ? unit : ""}
        </Text>
    );
};
