import { TooltipContainer } from "../_utils/utils";
import { Magnitude, MagnitudeOptions, physicalRoundForElectricity } from "../../../../utils";
import { TimeSeriesBaseReading, TimeSeriesSeries } from "./time-series-chart";
import { Box, Group, Stack, Text } from "@mantine/core";
import { ReactElement } from "react";

export type TimeSeriesChartTooltipData<T extends string> = {
    data: TimeSeriesBaseReading<T> | undefined;
};

type TimeSeriesChartTooltipProps<T extends string> = {
    tooltipLeft: number | undefined;
    tooltipTop: number | undefined;
    tooltipData: TimeSeriesChartTooltipData<T> | undefined;
    activeSeries: TimeSeriesSeries<T>[];
    tooltipDateFormatter: (startTime: Date, endTime: Date) => string;
    tooltipMagnitudeOptions?: MagnitudeOptions;
};

export const Tooltip = <T extends string>({
    tooltipLeft,
    tooltipTop,
    tooltipData,
    activeSeries,
    tooltipDateFormatter,
    tooltipMagnitudeOptions = {
        minMagnitude: Magnitude.Kilo,
        maxMagnitude: Magnitude.Mega,
    },
}: TimeSeriesChartTooltipProps<T>): ReactElement => {
    const showTooltip = tooltipData && tooltipTop && tooltipLeft && activeSeries.length > 0;
    const tooltipHeaderText = tooltipData
        ? tooltipDateFormatter(
              new Date(tooltipData.data?.startTimeLocal ?? ""),
              new Date(tooltipData.data?.endTimeLocal ?? ""),
          )
        : "";

    const tooltipContent = tooltipData
        ? activeSeries.map((s: TimeSeriesSeries<T>) => {
              const seriesCode = s.key;
              const value_: number = (tooltipData.data && tooltipData.data.values[seriesCode]) || 0;
              const decimalPlaces = 2;
              const [value, magnitude] = physicalRoundForElectricity(
                  value_,
                  decimalPlaces,
                  tooltipMagnitudeOptions,
              );

              return {
                  label: s.label,
                  color: s.color,
                  value: value + " " + magnitude + "Wh",
              };
          })
        : [];

    return showTooltip ? (
        <TooltipContainer tooltipTop={tooltipTop} tooltipLeft={tooltipLeft}>
            <Stack spacing="xs">
                <Text weight="bold" size="sm">
                    {tooltipHeaderText}
                </Text>

                {tooltipContent.map(({ label, color, value }, i) => (
                    <Group key={i} position="apart">
                        <Group spacing="md" align="center">
                            <Box
                                component="span"
                                w={12}
                                h={12}
                                sx={{ borderRadius: "100%" }}
                                bg={color}
                            />
                            <Text size="sm">{label}</Text>
                        </Group>
                        <Text size="sm">{value}</Text>
                    </Group>
                ))}
            </Stack>
        </TooltipContainer>
    ) : (
        <></>
    );
};
