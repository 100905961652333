/* eslint no-magic-numbers: 0 */
// TODO: This is a hack until the following issues are fixed:
//  - https://github.com/KevinVandy/mantine-react-table/issues/306
//  - https://github.com/TanStack/table/issues/5110

import { round } from "@flexidao/helpers";
import { MRT_Row, MRT_RowModel } from "mantine-react-table";
import { RowData } from "./types";

type NoInfer<T> = [T][T extends any ? 0 : never];

const memo = <TDeps extends readonly any[], TDepArgs, TResult>(
    getDeps: (depArgs?: TDepArgs) => [...TDeps],
    fn: (...args: NoInfer<[...TDeps]>) => TResult,
    opts: {
        key: any;
        debug?: () => any;
        onChange?: (result: TResult) => void;
    },
): ((depArgs?: TDepArgs) => TResult) => {
    let deps: any[] = [];
    let result: TResult | undefined;

    return (depArgs) => {
        let depTime: number;
        if (opts.key && opts.debug) {
            depTime = Date.now();
        }

        const newDeps = getDeps(depArgs);

        const depsChanged =
            newDeps.length !== deps.length ||
            newDeps.some((dep: any, index: number) => deps[index] !== dep);

        if (!depsChanged) {
            return result!;
        }

        deps = newDeps;

        let resultTime: number;
        if (opts.key && opts.debug) {
            resultTime = Date.now();
        }

        result = fn(...newDeps);
        opts?.onChange?.(result);

        if (opts.key && opts.debug) {
            if (opts?.debug()) {
                const depEndTime = round((Date.now() - depTime!) * 100) / 100;
                const resultEndTime = round((Date.now() - resultTime!) * 100) / 100;
                const resultFpsPercentage = resultEndTime / 16;

                const pad = (str: number | string, num: number): string => {
                    str = String(str);
                    while (str.length < num) {
                        str = " " + str;
                    }
                    return str;
                };

                console.info(
                    `%c⏱ ${pad(resultEndTime, 5)} /${pad(depEndTime, 5)} ms`,
                    `
            font-size: .6rem;
            font-weight: bold;
            color: hsl(${Math.max(
                0,
                Math.min(120 - 120 * resultFpsPercentage, 120),
            )}deg 100% 31%);`,
                    opts?.key,
                );
            }
        }

        return result!;
    };
};

const expandRows = <T extends RowData>(rowModel: MRT_RowModel<T>): MRT_RowModel<T> => {
    const expandedRows: Array<MRT_Row<T>> = [];

    const handleRow = (row: MRT_Row<T>): void => {
        expandedRows.push(row);

        if (row.subRows?.length && row.getIsExpanded()) {
            row.subRows.forEach(handleRow);
        }
    };

    rowModel.rows.forEach(handleRow);

    return {
        rows: expandedRows,
        flatRows: rowModel.flatRows,
        rowsById: rowModel.rowsById,
    };
};

// The table parameter should have type MRT_Table, but it is not exported
// @ts-ignore
export const getExpandedRowModel = (table: any): any =>
    memo(
        () => [
            table.getState().expanded,
            table.getPreExpandedRowModel(),
            table.options.paginateExpandedRows,
            table.options.manualPagination,
        ],
        (expanded, rowModel, paginateExpandedRows, manualPagination) => {
            if (
                !rowModel.rows.length ||
                (expanded !== true && !Object.keys(expanded ?? {}).length)
            ) {
                return rowModel;
            }

            // Here is the fix
            if (!paginateExpandedRows && !manualPagination) {
                // Only expand rows at this point if they are being paginated
                return rowModel;
            }

            return expandRows(rowModel);
        },
        {
            key: "getExpandedRowModel",
            debug: () => table.options.debugAll ?? table.options.debugTable,
        },
    );
