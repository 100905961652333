import { ApiClient } from "../api-client";
import { featureFlagDecoder } from "../decoders";
import { entryPointPaths } from "@flexidao/dto";
import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash } from "../utils";

export type OverloadEntryPointClient = {
    getPublicFeatureFlags: () => OverloadApiResponse<Array<string>>;
} & ApiClient<entryPointPaths>;

export type EntryPointClient = {
    getPublicFeatureFlags: () => ApiResponse<Array<string>>;
} & ApiClient<entryPointPaths>;

export type ResultEntryPointClient = {
    getPublicFeatureFlags: () => ApiResultResponse<Array<string>>;
} & ApiClient<entryPointPaths>;

export function getEntryPointClient(config: { useRetries?: boolean }): EntryPointClient;
export function getEntryPointClient(config: { useResult: boolean }): ResultEntryPointClient;
export function getEntryPointClient(config: {
    useResult?: boolean;
    useRetries?: boolean;
}): OverloadEntryPointClient {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        if (process.env.MOCK_ENTRY_POINT == "true") {
            useMockData = true;
        }
        if (!process.env.ENTRY_POINT_URL) {
            return crash("Missing ENTRY_POINT_URL environment variable.");
        } else {
            url = process.env.ENTRY_POINT_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }

    const { get } = buildQueries({ ...config, useMockData: useMockData }, url);

    return {
        getPublicFeatureFlags: (): OverloadApiResponse<Array<string>> =>
            get({
                path: `/api/entry-point/public/feature-flags`,
                decoder: featureFlagDecoder,
            }),
    };
}
