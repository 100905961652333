import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import BarGroupChartTemplate, { BarGroupChartTemplateProps } from "./template";

type BarGroupChartProps<K extends string> = {
    colors: string[];
    formatDate: (d: Date) => string;
    id: string;
} & Pick<BarGroupChartTemplateProps<K>, "width" | "height" | "unit" | "data">;
export const BarGroupChart = <K extends string>({
    data,
    colors,
    ...templateProps
}: BarGroupChartProps<K>): JSX.Element => {
    const keys = Object.keys(data[0]!).filter((d): d is K => d !== "date");

    const dateScale = scaleBand<Date>({
        domain: data.map(({ date }) => date),
        paddingInner: 0.2,
        paddingOuter: 0,
    });
    const groupScale = scaleBand<K>({
        domain: keys,
        paddingInner: 0.16,
        paddingOuter: 0,
    });
    const yScale = scaleLinear<number>({
        domain: [
            0,
            Math.max(...data.map((d) => Math.max(...keys.map((key) => Number(d[key])).concat(1)))),
        ],
    });

    const colorScale = scaleOrdinal<string, string>({
        domain: keys,
        range: colors,
    });

    return (
        <BarGroupChartTemplate
            data={data}
            dateScale={dateScale}
            groupScale={groupScale}
            yScale={yScale}
            colorScale={colorScale}
            {...templateProps}
        />
    );
};
