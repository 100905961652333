import { Flex, Text } from "@mantine/core";
import { ReactElement } from "react";
import { labelToDataId } from "@flexidao/ui-lib";

type BottomToolbarCustomActionsProps = {
    pagination?: {
        pageIndex: number;
        pageSize: number;
    };
    numRows: number;
    dataId: string;
};

export const BottomToolbarCustomActions = ({
    pagination,
    numRows,
    dataId,
}: BottomToolbarCustomActionsProps): ReactElement | null => {
    if (pagination == null) {
        return null;
    }

    const { pageIndex, pageSize } = pagination;
    const firstIndex: number = pageIndex * pageSize + 1;
    const lastIndex: number = Math.min((pageIndex + 1) * pageSize, numRows);

    return (
        <Flex
            mr="12px"
            data-id={labelToDataId({
                prefix: dataId,
                label: "indexes-current-pages",
            })}
            className="indexes-current-pages"
        >
            <Text size="sm" color="flexidaoGrey.8">
                {firstIndex} - {lastIndex} of {numRows}
            </Text>
        </Flex>
    );
};
