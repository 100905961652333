import { TabType } from "@flexidao/ui-lib";
import { MantineColor } from "@mantine/core";

export const getStylesFromTabType = (
    tabType: TabType,
): {
    color: MantineColor | undefined;
    backgroundColor: MantineColor;
} => {
    switch (tabType) {
        case TabType.Colored:
            return {
                color: "flexidaoGreen",
                backgroundColor: "white",
            };
        case TabType.Neutral:
            return {
                color: undefined,
                backgroundColor: "flexidaoGrey.1",
            };
    }
};
