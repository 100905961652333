import { ReactElement, ReactNode } from "react";
import { MantineTheme, Text } from "@mantine/core";
import { ChipType, IconAlertTriangle, IconInfoCircle, IconX } from "@flexidao/ui-lib";

export const getColorFromType = (type: ChipType, theme: MantineTheme): string => {
    switch (type) {
        case ChipType.Error:
            return theme.colors.flexidaoRed![5];
        case ChipType.Warning:
            return theme.colors.flexidaoOrange![5];
        case ChipType.Info:
            return theme.colors.flexidaoBlue![5];
        case ChipType.Success:
            return theme.colors.flexidaoGreen![5];
    }
};

export const getIconFromType = (type: ChipType, color?: string): ReactElement => {
    switch (type) {
        case ChipType.Error:
            return <IconX color={color} size={16} />;
        case ChipType.Warning:
            return <IconAlertTriangle color={color} size={16} />;
        case ChipType.Info:
            return <IconInfoCircle color={color} size={16} />;
        case ChipType.Success:
            return <IconInfoCircle color={color} size={16} />;
    }
};

export const getFormattedChildren = (children: ReactNode): ReactElement => {
    if (typeof children === "string") {
        return (
            <Text size="xs" lh="12px">
                {children}
            </Text>
        );
    }

    return <>{children}</>;
};
