import { DateValue } from "@visx/mock-data/lib/generators/genDateValue";
import { ScaleTime } from "d3-scale";

export const getX = (d: DateValue): Date => d.date;
export const getY = (d: DateValue): number => d.value;

export const getNearestPoint = (
    seriesData: Array<DateValue>,
    xScale: ScaleTime<number, number>,
    x: number,
): DateValue =>
    seriesData.reduce(
        (
            acc: { nearestPoint: DateValue; distanceToNearestPoint: number },
            currentPoint: DateValue,
        ) => {
            const distanceToCurrentPoint: number = Math.abs(xScale(getX(currentPoint)) - x);

            return distanceToCurrentPoint < acc.distanceToNearestPoint
                ? {
                      nearestPoint: currentPoint,
                      distanceToNearestPoint: distanceToCurrentPoint,
                  }
                : acc;
        },
        {
            nearestPoint: seriesData[0] ?? { date: new Date(), value: 0 },
            distanceToNearestPoint: Number.POSITIVE_INFINITY,
        },
    ).nearestPoint;
