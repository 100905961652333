import { ReactElement, useMemo } from "react";
import { SegmentedControl, SegmentedControlItem, Stack, Text } from "@mantine/core";
import { DateRangeOption } from "@flexidao/ui-lib";
import { getDateRangeOptionLabel } from "../utils";

type DateRangePresetsProps = {
    selectedDateRange: [Date | null, Date | null];
    dateRangeOptions: Array<DateRangeOption>;
    selectedDateRangeOption: DateRangeOption;
    handleDateRangeOptionSelected: (dateRangeOption: DateRangeOption) => void;
};

export const DateRangePresets = ({
    selectedDateRange,
    dateRangeOptions,
    selectedDateRangeOption,
    handleDateRangeOptionSelected,
}: DateRangePresetsProps): ReactElement => {
    // If both dates are defined or both dates are null, the date range picker is enabled
    const areDateRangeOptionsEnabled: boolean =
        (selectedDateRange[0] !== null && selectedDateRange[1] !== null) ||
        (selectedDateRange[0] === null && selectedDateRange[1] === null);

    const dateRangeOptionList: Array<SegmentedControlItem> = useMemo(
        () => [
            ...dateRangeOptions.map((dateRangeOption: DateRangeOption) => ({
                value: dateRangeOption,
                label: getDateRangeOptionLabel(dateRangeOption),
            })),
        ],
        [dateRangeOptions],
    );

    const value = useMemo(
        () => (selectedDateRangeOption === DateRangeOption.Custom ? null : selectedDateRangeOption),
        [selectedDateRangeOption],
    );

    return (
        <Stack p="16px" spacing="8px">
            <Text size="14px" weight={600}>
                Presets
            </Text>

            <SegmentedControl
                data-id="date-range-picker--presets"
                name="date-range-picker--presets"
                orientation="vertical"
                color="flexidaoGreen"
                disabled={!areDateRangeOptionsEnabled}
                data={dateRangeOptionList}
                value={value!}
                onChange={handleDateRangeOptionSelected}
            />
        </Stack>
    );
};
