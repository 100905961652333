import { RowData } from "../types";
import { MrtRowActionsRenderer, RowActionsRenderer } from "./types";

export const rowActionsToMrtRowActions = <T extends RowData>(
    rowActionsRenderer?: RowActionsRenderer<T>,
): MrtRowActionsRenderer<T> | undefined => {
    if (!rowActionsRenderer) {
        return undefined;
    }

    const mrtRowActionsRenderer: MrtRowActionsRenderer<T> = ({ row }) => {
        return rowActionsRenderer({ rowData: row.original });
    };

    return mrtRowActionsRenderer;
};
