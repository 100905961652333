import React, { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { max } from "@visx/vendor/d3-array";
import { curveLinear } from "@visx/curve";
import { Group } from "@visx/group";
import { AreaClosed, Bar, Line, LinePath } from "@visx/shape";
import { ScaleInput, scaleLinear, scaleTime, ScaleTypeToD3Scale } from "@visx/scale";
import { DateValue } from "@visx/mock-data/lib/generators/genDateValue";
import { ParentSize } from "@visx/responsive";
import { AxisBottom, AxisRight, TickFormatter } from "@visx/axis";
import { NumberValue } from "d3-scale";
import { formatDateTime, formatNumber, labelToDataId } from "@flexidao/ui-lib";
import { flexidaoGrey } from "@flexidao/ui-lib/mantine-theme/colours/flexidao-colors";
import { useTooltip } from "@visx/tooltip";
import { AreaChartTooltip } from "../tooltip/areaChartTooltip";
import { localPoint } from "@visx/event";
import { CHART_MARGIN, CHART_PADDING, FILL_OPACITY, STROKE_OPACITY, STROKE_WIDTH } from "./consts";
import { AreaChartCurveSeries } from "../types";
import { getNearestPoint, getX, getY } from "../utils";

type AreaChartProps = {
    chartDataId: string;
    chartHeight: number;
    series: AreaChartCurveSeries;
    xTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["time"]>>;
    period: [Date, Date];
    hideAxes?: boolean;
    enableTooltip?: boolean;
    showLine?: boolean;
    chartPadding?: { top: number; right: number; bottom: number; left: number };
    chartMargin?: { top: number; right: number; bottom: number; left: number };
    children?: ReactNode;
};

export const AreaChart = ({
    chartDataId,
    chartHeight,
    series,
    xTickFormatter,
    period: [startDate, endDate],
    hideAxes = false,
    enableTooltip = true,
    showLine = true,
    chartPadding = CHART_PADDING,
    chartMargin = CHART_MARGIN,
    children,
}: AreaChartProps): ReactElement => {
    // ----- ----- CHART BODY VARIABLES START ----- -----

    // We get the data only from the active series
    // because we need to calculate the scales with only the data that will be displayed (active series)
    const allDataFromActiveSeries: Array<DateValue> = series.seriesData;

    // Ranges
    const xDomain: [Date, Date] = useMemo(() => [startDate, endDate], [startDate, endDate]);
    const yDomain: [number, number] = useMemo(
        () => [0, max(allDataFromActiveSeries, getY) as number],
        [allDataFromActiveSeries],
    );

    // Scales
    const xScale = useMemo(
        () =>
            scaleTime<number>({
                domain: xDomain,
            }),
        [xDomain],
    );
    const yScale = useMemo(
        () =>
            scaleLinear<number>({
                domain: yDomain,
            }),
        [yDomain],
    );
    // ----- ----- CHART BODY VARIABLES END ----- -----

    // ----- ----- TOOLTIP VARIABLES START ----- -----
    const shouldShowToolip: boolean = enableTooltip;
    const { tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
        useTooltip<AreaChartCurveSeries>();

    const handleTooltip = useCallback(
        (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
            const { x } = localPoint(event) ?? { x: 0 };

            const nearestPoint: DateValue = getNearestPoint(allDataFromActiveSeries, xScale, x);
            const tooltipData: AreaChartCurveSeries = {
                ...series,
                seriesData: series.seriesData.filter(
                    (data: DateValue): boolean => getX(data) === getX(nearestPoint),
                ),
            };

            const tooltipLeft: number = xScale(getX(nearestPoint)) ?? 0;
            const tooltipTop: number = yScale(getY(nearestPoint)) ?? 0;

            if (tooltipData) {
                showTooltip({
                    tooltipData,
                    tooltipLeft,
                    tooltipTop,
                });
            } else {
                hideTooltip();
            }
        },
        [hideTooltip, series, showTooltip, xScale, yScale, allDataFromActiveSeries],
    );
    // ----- ----- TOOLTIP VARIABLES END ----- -----

    return (
        <ParentSize style={{ width: "100%", height: chartHeight }}>
            {(parent): ReactElement => {
                // Update bounds and scales
                const chartWidth: number = parent.width - chartMargin.left - chartMargin.right;

                if (chartWidth <= 0) {
                    return <></>;
                }

                xScale.range([chartPadding.left, chartWidth - chartPadding.right]);
                yScale.range([
                    chartHeight - chartMargin.top - chartMargin.bottom - chartPadding.bottom,
                    chartPadding.top,
                ]);

                // Data accessors
                const x = (d: DateValue): number => xScale(getX(d)) ?? 0;
                const y = (d: DateValue): number => yScale(getY(d)) ?? 0;

                // Formatters
                const yTickFormat = (value: NumberValue): string =>
                    formatNumber(value.valueOf(), 0);

                return (
                    <>
                        <svg
                            width={chartWidth}
                            height={chartHeight}
                            style={{ overflow: "visible" }}
                        >
                            <Group top={chartMargin.top} left={chartMargin.left}>
                                {((): ReactElement | null => {
                                    if (!series.isSeriesActive) {
                                        return null;
                                    }

                                    const seriesDataId: string = labelToDataId({
                                        prefix: `${chartDataId}-series`,
                                        label: series.seriesLabel,
                                    });

                                    return (
                                        <Group key={seriesDataId} data-id={seriesDataId}>
                                            {/* Line chart */}
                                            {showLine && (
                                                <LinePath<DateValue>
                                                    data={series.seriesData}
                                                    x={x}
                                                    y={y}
                                                    stroke={series.seriesColor}
                                                    strokeWidth={STROKE_WIDTH}
                                                    strokeOpacity={STROKE_OPACITY}
                                                    shapeRendering="geometricPrecision"
                                                    curve={curveLinear}
                                                />
                                            )}

                                            {/* Bar chart */}
                                            <AreaClosed<DateValue>
                                                data={series.seriesData}
                                                x={x}
                                                y={y}
                                                yScale={yScale}
                                                fill={series.seriesColor}
                                                fillOpacity={FILL_OPACITY}
                                            />
                                        </Group>
                                    );
                                })()}
                                {/* ----- Series end ----- */}

                                {/* ----- Tooltip start ----- */}
                                {shouldShowToolip && (
                                    <Bar
                                        width={
                                            chartWidth -
                                            chartMargin.left -
                                            chartMargin.right -
                                            chartPadding.left -
                                            chartPadding.right
                                        }
                                        height={
                                            chartHeight -
                                            chartMargin.top -
                                            chartMargin.bottom -
                                            chartPadding.top -
                                            chartPadding.bottom
                                        }
                                        fill="transparent"
                                        onMouseMove={handleTooltip}
                                        onMouseLeave={hideTooltip}
                                    />
                                )}

                                {shouldShowToolip && tooltipLeft != null && tooltipTop != null && (
                                    <>
                                        <circle
                                            cx={tooltipLeft}
                                            cy={tooltipTop}
                                            r={4}
                                            fill="black"
                                            fillOpacity={FILL_OPACITY}
                                            stroke="black"
                                            strokeOpacity={FILL_OPACITY}
                                            strokeWidth={STROKE_WIDTH}
                                            pointerEvents="none"
                                        />

                                        <Line
                                            from={{
                                                x: tooltipLeft,
                                                y: 0,
                                            }}
                                            to={{
                                                x: tooltipLeft,
                                                y:
                                                    chartHeight -
                                                    chartMargin.top -
                                                    chartMargin.bottom,
                                            }}
                                            stroke={flexidaoGrey[3]}
                                            strokeWidth={1}
                                            pointerEvents="none"
                                        />
                                    </>
                                )}
                                {/* ----- Tooltip end ----- */}

                                {/* ----- Children start ----- */}
                                {children}
                                {/* ----- Children end ----- */}
                            </Group>

                            {/* ----- Axes start ----- */}
                            {!hideAxes && (
                                <AxisRight
                                    hideZero
                                    numTicks={4}
                                    tickFormat={yTickFormat}
                                    scale={yScale}
                                    left={chartWidth - chartPadding.right}
                                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                    tickLabelProps={() => ({
                                        fontSize: 10,
                                        fontWeight: 400,
                                        fill: flexidaoGrey[7],
                                        textAnchor: "start",
                                        x: "12",
                                        dy: "3px",
                                    })}
                                    tickLineProps={{
                                        stroke: flexidaoGrey[3],
                                    }}
                                    tickLength={4}
                                    stroke={flexidaoGrey[3]}
                                />
                            )}

                            {!hideAxes && (
                                <AxisBottom
                                    numTicks={4}
                                    tickFormat={xTickFormatter}
                                    scale={xScale}
                                    top={
                                        chartHeight +
                                        chartMargin.top -
                                        chartMargin.bottom -
                                        chartPadding.bottom
                                    }
                                    left={chartMargin.left}
                                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                    tickLabelProps={() => ({
                                        fontSize: 10,
                                        fontWeight: 400,
                                        fill: flexidaoGrey[7],
                                        textAnchor: "middle",
                                        y: "18",
                                    })}
                                    tickLineProps={{
                                        stroke: flexidaoGrey[3],
                                    }}
                                    tickLength={4}
                                    stroke={flexidaoGrey[3]}
                                />
                            )}
                            {/* ----- Axes end ----- */}
                        </svg>

                        {/* ----- Tooltip start ----- */}
                        {shouldShowToolip && (
                            <AreaChartTooltip
                                tooltipLeft={tooltipLeft}
                                tooltipTop={tooltipTop}
                                tooltipData={tooltipData}
                                tooltipDateFormatter={(date: Date): string =>
                                    formatDateTime(date, "UTC")
                                }
                            />
                        )}
                        {/* ----- Tooltip end ----- */}
                    </>
                );
            }}
        </ParentSize>
    );
};
