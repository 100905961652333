import { ReactElement } from "react";
import { Text } from "@mantine/core";
import { getNormalizedParts, normalizeString } from "../utils";

type LabelProps = {
    label: string;
    searchQuery: string;
};

export const Label = ({ label, searchQuery }: LabelProps): ReactElement => {
    // If we are not searching, we can just return the label
    if (searchQuery.length === 0) {
        return <Text>{label}</Text>;
    }

    const normalizedSearchQuery: string = normalizeString(searchQuery);
    const normalizedLabel: string = normalizeString(label);

    // If we are searching, and the label does not contain the search query
    if (!normalizedLabel.includes(normalizedSearchQuery)) {
        return <Text color="gray">{label}</Text>;
    }

    // If we are searching, and the label contains the search query
    const normalizedLabelParts: Array<string> = getNormalizedParts(label, searchQuery);

    let currentIndex: number = 0;
    return (
        <Text>
            {normalizedLabelParts.map((normalizedPart, index): ReactElement => {
                currentIndex += normalizedPart.length;

                return (
                    <Text
                        key={index}
                        span
                        fw={
                            normalizedPart.localeCompare(normalizedSearchQuery) === 0
                                ? "bold"
                                : "normal"
                        }
                    >
                        {label.slice(currentIndex - normalizedPart.length, currentIndex)}
                    </Text>
                );
            })}
        </Text>
    );
};
