import { ReactElement, useMemo, useState } from "react";
import { ScaleInput, scaleLinear, scaleOrdinal, scaleTime, ScaleTypeToD3Scale } from "@visx/scale";
import { timeParse } from "d3-time-format";
import { TickFormatter } from "@visx/axis";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { ChartTemplate } from "./template";
import {
    ChartDimensions,
    ChartDisplayOptions,
    ChartMargin,
    defaultChartDisplayOptions,
} from "../_utils/utils";
import { MantineTheme, useMantineTheme } from "@mantine/core";
import { MagnitudeOptions } from "@flexidao/ui-lib/utils";

export type TimeSeriesBaseReading<T extends string> = {
    startTimeLocal: string;
    endTimeLocal: string;
    values: Record<T, number>;
};

export enum SeriesType {
    Bar = "bar",
    Line = "line",
}
export type SeriesTypeType = SeriesType.Bar | SeriesType.Line;

export type TimeSeriesSeries<T extends string> = {
    key: T;
    label: string;
    color: string;
    active: boolean;
    seriesType: SeriesTypeType;
};

const getEnergyTotals = <T extends string>(
    data: Array<TimeSeriesBaseReading<T>>,
    barSeriesKeys: Array<T>,
    lineSeriesKeys: Array<T>,
): Array<number> =>
    data.map((reading) => {
        const totalBarsEnergy = barSeriesKeys.reduce((dailyTotal, k) => {
            dailyTotal += Number(reading.values[k]);
            return dailyTotal;
        }, 0);
        const totalLinesEnergy = lineSeriesKeys.reduce((dailyTotal, k) => {
            return dailyTotal > Number(reading.values[k]) ? dailyTotal : Number(reading.values[k]);
        }, 0);
        return Math.max(totalLinesEnergy, totalBarsEnergy);
    }, [] as number[]);

export type TimeSeriesChartControllerProps<T extends string> = {
    axisColor: string;
    dimensions: ChartDimensions;
    data: Array<TimeSeriesBaseReading<T>>;
    barSeries?: Array<TimeSeriesSeries<T>>;
    lineSeries?: Array<TimeSeriesSeries<T>>;
    minStartDate: string;
    maxEndDate: string;
    barSeriesSpacing: number;
    timeParserSpecifier: string;
    displayOptions: ChartDisplayOptions;
    xTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["time"]>>;
    yTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["linear"]>>;
    tooltipDateFormatter: (startTime: Date, endTime: Date) => string;
};

export const TimeSeriesChartController = <T extends string>({
    dimensions,
    data,
    barSeries = [],
    lineSeries = [],
    minStartDate,
    maxEndDate,
    timeParserSpecifier,
    ...rest
}: TimeSeriesChartControllerProps<T>): ReactElement | null => {
    const { parentWidth, margin, innerHeight, innerWidth } = dimensions;
    const allSeries = [...lineSeries, ...barSeries];

    const [series, setSeries] = useState<Array<TimeSeriesSeries<T>>>(allSeries);
    // scales

    const dateScale = useMemo(() => {
        const startDate = timeParse(timeParserSpecifier)(minStartDate) || new Date("1970-01-01");
        const endDate = timeParse(timeParserSpecifier)(maxEndDate) || new Date("1970-12-31");
        return scaleTime({
            range: [margin.left, innerWidth + margin.left],
            domain: [startDate, endDate],
        });
    }, [innerWidth, margin.left, maxEndDate, minStartDate, timeParserSpecifier]);

    const minWidthToRender = 10;
    if (parentWidth < minWidthToRender) {
        return null;
    }

    // series
    const toggleActiveSeries = (label: string): void => {
        setSeries((prevState: Array<TimeSeriesSeries<T>>) =>
            prevState.map((series) =>
                series.key === label ? { ...series, active: !series.active } : series,
            ),
        );
    };

    const activeSeries = series.filter((s: TimeSeriesSeries<T>) => s.active);
    const activeSeriesKeys = activeSeries.map((x: TimeSeriesSeries<T>) => x.key);

    const activeBarSeries = barSeries.filter((bar: TimeSeriesSeries<T>) =>
        activeSeriesKeys.includes(bar.key),
    );

    const activeLineSeries = lineSeries.filter((line: TimeSeriesSeries<T>) =>
        activeSeriesKeys.includes(line.key),
    );

    const activeBarSeriesKeys = activeBarSeries.map(({ key }) => key);
    const activeLineSeriesKeys = activeLineSeries.map(({ key }) => key);

    const energyScale = scaleLinear<number>({
        domain: [0, Math.max(...getEnergyTotals(data, activeBarSeriesKeys, activeLineSeriesKeys))],
        nice: true,
    });

    const colorScale = scaleOrdinal<string, string>({
        domain: allSeries.map(({ key }) => key),
        range: allSeries.map(({ color }) => color),
    });

    dateScale.rangeRound([0, innerWidth]);
    energyScale.range([innerHeight, 0]);

    return (
        <ChartTemplate
            dateScale={dateScale}
            energyScale={energyScale}
            colorScale={colorScale}
            activeSeriesKeys={activeSeriesKeys}
            data={data}
            dimensions={dimensions}
            toggleActiveSeries={toggleActiveSeries}
            allSeries={allSeries}
            barSeries={barSeries}
            activeBarSeriesKeys={activeBarSeriesKeys}
            activeSeries={activeSeries}
            timeParserSpecifier={timeParserSpecifier}
            lineSeries={lineSeries}
            activeLineSeries={activeLineSeries}
            {...rest}
        />
    );
};

export type TimeSeriesChartProps<T extends string> = {
    margin?: ChartMargin;
    data: Array<TimeSeriesBaseReading<T>>;
    barSeries?: Array<TimeSeriesSeries<T>>;
    lineSeries?: Array<TimeSeriesSeries<T>>;
    minStartDate: string;
    maxEndDate: string;
    barSeriesSpacing?: number;
    timeParserSpecifier: string;
    displayOptions?: ChartDisplayOptions;
    xTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["time"]>>;
    yTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["linear"]>>;
    tooltipDateFormatter: (startTime: Date, endTime: Date) => string;
    tooltipMagnitudeOptions?: MagnitudeOptions;
};

const DEFAULT_BAR_SERIES_SPACING = 2;

export const TimeSeriesChart = <T extends string>({
    margin = { top: 8, right: 0, bottom: 50, left: 60 },
    barSeriesSpacing = DEFAULT_BAR_SERIES_SPACING,
    displayOptions = defaultChartDisplayOptions,
    ...props
}: TimeSeriesChartProps<T>): ReactElement => {
    const theme: MantineTheme = useMantineTheme();
    const axisColor: string = theme.colors.flexidaoGrey![5];
    return (
        <ParentSize>
            {({ width: parentWidth, height: parentHeight }): JSX.Element => {
                const innerWidth = parentWidth - margin.left - margin.right;
                const innerHeight = parentHeight - margin.top - margin.bottom;
                return (
                    <TimeSeriesChartController
                        dimensions={{ parentWidth, parentHeight, margin, innerHeight, innerWidth }}
                        barSeriesSpacing={barSeriesSpacing}
                        displayOptions={displayOptions}
                        axisColor={axisColor}
                        {...props}
                    />
                );
            }}
        </ParentSize>
    );
};
