import { BimDto } from "@flexidao/dto";
import { createContext } from "react";

export type UserContextApi = {
    user: BimDto.AppUser | undefined;
    isLoading: boolean;
    isError: boolean;
    invalidateSelfQuery: () => Promise<void>;
};

export const UserContext = createContext<UserContextApi>({
    user: undefined,
    isLoading: false,
    isError: false,
    invalidateSelfQuery: () => Promise.reject(),
});
