import { Group } from "@mantine/core";
import { ReactElement } from "react";
import { LEGEND_OPACITY } from "./consts";
import { StackedBarChartSeries } from "../types";
import { LegendDotWithLabel } from "../../_utils/utils";

type StackedBarChartLegendProps<T extends Record<string, number>> = {
    activeSeries: StackedBarChartSeries<T>;
    onToggleActiveSeries: (dataKey: string) => void;
};

export const StackedBarChartLegend = <T extends Record<string, number>>({
    activeSeries,
    onToggleActiveSeries,
}: StackedBarChartLegendProps<T>): ReactElement => (
    <Group w="100%" spacing="8px" mt="8px">
        {activeSeries.map(({ isSeriesActive, dataKey, color }) => (
            <LegendDotWithLabel
                key={`legend-${dataKey}`}
                sx={{
                    opacity: isSeriesActive ? LEGEND_OPACITY.ENABLED : LEGEND_OPACITY.DISABLED,
                    cursor: "pointer",
                    alignItems: "center",
                }}
                onClick={(): void => onToggleActiveSeries(dataKey)}
                text={dataKey}
                color={color}
            />
        ))}
    </Group>
);
