import { ReactElement, useMemo } from "react";
import { Button, Group } from "@mantine/core";
import { getShouldEnableButtons } from "../utils";

type DateRangeActionsProps = {
    selectedDateRange: [Date | null, Date | null];
    initialDateRange: [Date, Date];
    handleDateCleared: () => void;
    handleDateApplied: () => void;
};

export const DateRangeActions = ({
    selectedDateRange,
    initialDateRange,
    handleDateCleared,
    handleDateApplied,
}: DateRangeActionsProps): ReactElement => {
    // Enable apply button if the selected date range is different from the applied date range
    const { enableApplyButton, enableClearButton } = useMemo(
        () => getShouldEnableButtons(selectedDateRange, initialDateRange),
        [selectedDateRange, initialDateRange],
    );

    const onApplyButtonClick = (): void => {
        if (!enableApplyButton) {
            return;
        }

        handleDateApplied();
    };

    const onClearButtonClick = (): void => {
        if (!enableClearButton) {
            return;
        }

        handleDateCleared();
    };

    return (
        <Group p="md" position="right">
            <Button
                onClick={onClearButtonClick}
                disabled={!enableClearButton}
                data-id="date-range-picker--clear"
                variant="outline"
                className="outline"
                style={{
                    textTransform: "uppercase",
                }}
            >
                Clear
            </Button>

            <Button
                onClick={onApplyButtonClick}
                disabled={!enableApplyButton}
                data-id="date-range-picker--apply"
                style={{
                    textTransform: "uppercase",
                }}
            >
                Apply selection
            </Button>
        </Group>
    );
};
