import { ReactElement, ReactNode, useMemo } from "react";
import { Alert as MantineAlert, Button, Group, Text } from "@mantine/core";
import { mantineTheme } from "@flexidao/ui-lib/mantine-theme/mantine-theme";
import { AlertSeverity } from "./types";
import { getAlertColor, getAlertIcon } from "./utils";

type AlertProps = {
    title?: string;
    description: string;
    severity: AlertSeverity;
    cta?: {
        onClick: () => void;
        children: ReactNode;
    };
};

/**
 * Alert component
 *
 * @param {string?} title - The title of the alert
 * @param {string} description - The description of the alert
 * @param {AlertSeverity} severity - The severity of the alert
 * @param {object?} cta - The Call To Action of the alert
 * @param {() => void} cta.onClick - The function to be called when the CTA is clicked
 * @param {ReactNode} cta.children - The children of the CTA
 *
 * @returns {ReactElement} - The alert component
 */
export const Alert = ({ title, description, severity, cta }: AlertProps): ReactElement => {
    const color = useMemo(() => getAlertColor(severity), [severity]);
    const icon = useMemo(() => getAlertIcon(severity), [severity]);

    return (
        <MantineAlert
            data-id={`alert-${severity}-${title}`}
            styles={{
                message: {
                    display: "flex",
                    gap: "6rem",
                    overflow: "visible",
                },
                title: {
                    color: mantineTheme.colors?.flexidaoGrey?.[8] || "black",
                },
                root: {
                    backgroundColor: "transparent",
                },
            }}
            w="100%"
            variant="outline"
            radius="md"
            color={color}
            icon={icon}
            title={title}
        >
            <Group align="start" position="apart">
                {<Text>{description}</Text>}
            </Group>

            {cta && (
                <div
                    style={{
                        marginTop: "-32px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Button color={color} variant="outline" onClick={cta.onClick}>
                        {cta.children}
                    </Button>
                </div>
            )}
        </MantineAlert>
    );
};
