import {
    EnergySource,
    PathsDecodersGet,
    ReportHubDto,
    reportHubPaths,
    validateStrictDecoder,
} from "@flexidao/dto";
import * as D from "schemawax";
import { components } from "../../../dto/src/schemas/report-hub-api-schema";
import {
    dateDecoder,
    energySourceDecoder,
    sourcingMethodDecoder,
    trackingInstrumentForAssignmentTypeDecoder,
} from "../decoders/misc";
import {
    countryNameDecoder,
    re100ContributionDecoder,
    regionWithCountryDecoder,
    reportingPeriodResponseDecoder,
} from "../decoders/report-hub";
import { GetComponent } from "./dto";

const regionAssignmentResponseDecoder = validateStrictDecoder<
    GetComponent<components, "RegionAssignmentResponse">
>()(
    D.object({
        required: {
            market: D.nullable(D.string),
            countryName: D.string,
            countryId: D.string,
            regionName: D.string,
            regionId: D.string,
            carbonIntensityGCO2_Wh: D.number,
            totalConsumptionWh: D.number,
            fromGridWh: D.number,
            fromDirectPpaWh: D.number,
            cdpMethodology: D.object({
                required: {
                    totalAssignedWh: D.number,
                    assignedFullContributionWh: D.number,
                    assignedPartialContributionWh: D.number,
                    assignedNoContributionWh: D.number,
                    assignedNoContractInfoWh: D.number,
                    renewableCoverage: D.number,
                },
            }),
            re100Methodology: D.object({
                required: {
                    totalContributionToRe100Wh: D.number,
                    contributionFullContributionWh: D.number,
                    contributionPartialContributionWh: D.number,
                    contributionNoContributionWh: D.number,
                    contributionNoContractInfoWh: D.number,
                    re100Score: D.number,
                },
            }),
            passiveProcurement: D.boolean,
        },
    }) satisfies D.Decoder<GetComponent<components, "RegionAssignmentResponse">>,
);

const trackingInstrumentCommonDecoder = validateStrictDecoder<
    GetComponent<components, "TrackingInstrumentCommon">
>()(
    D.object({
        required: {
            trackingInstrumentId: D.string,
            contractId: D.nullable(D.string),
            consumptionPeriodStart: dateDecoder,
            consumptionPeriodEnd: dateDecoder,
            type: trackingInstrumentForAssignmentTypeDecoder,
            productionCountry: D.nullable(D.string),
            source: D.nullable(D.literalUnion(...Object.values(EnergySource))),
            productionSite: D.nullable(D.string),
            unassigned: D.number,
            assigned: D.number,

            productionFrom: D.nullable(dateDecoder),
            productionTo: D.nullable(dateDecoder),
            transactionDate: D.nullable(dateDecoder),
            market: D.nullable(D.string),
            commissioningDate: D.nullable(dateDecoder),
            certificateIdFrom: D.nullable(D.string),
            certificateIdTo: D.nullable(D.string),
            documentId: D.nullable(D.number),
            scheme: D.nullable(D.string),

            registryId: D.nullable(D.string),
            registryName: D.nullable(D.string),
            transactionFromId: D.nullable(D.string),
            transactionFromName: D.nullable(D.string),
            consumptionOrganizationName: D.nullable(D.string),

            consumptionTimezone: D.string,
            productionTimezone: D.nullable(D.string),

            uploadDate: dateDecoder,
            sourcingMethod: sourcingMethodDecoder,
            registryTransactionId: D.nullable(D.string),

            re100Contribution: re100ContributionDecoder,
            contributionToRe100Wh: D.number,
        },
    }) satisfies D.Decoder<GetComponent<components, "TrackingInstrumentCommon">>,
);

const trackingInstrumentResponseDecoder = validateStrictDecoder<
    GetComponent<components, "TrackingInstrumentAssignmentResponse">
>()(
    D.object({
        required: {
            rows: D.array(trackingInstrumentCommonDecoder),
            totalNumRows: D.number,
        },
    }) satisfies D.Decoder<GetComponent<components, "TrackingInstrumentAssignmentResponse">>,
);

const singleSmartAssignmentDecoder = validateStrictDecoder<ReportHubDto.SingleSmartAssignment>()(
    D.object({
        required: {
            regionId: D.string,
            consumptionSiteGroupId: D.string,
            year: D.number,
            trackingInstrumentId: D.string,
            trackingInstrumentType: trackingInstrumentForAssignmentTypeDecoder,
            quantityAssigned: D.number,
            contributionToRe100Wh: D.number,
        },
    }) satisfies D.Decoder<ReportHubDto.SingleSmartAssignment>,
);

const smartAssignmentDecoder = validateStrictDecoder<ReportHubDto.SmartAssignment>()(
    D.object({
        required: {
            regionName: D.string,
            consumptionSiteGroupName: D.string,
            renewableCoverageBefore: D.number,
            renewableCoverageAfter: D.number,
            smartAssignedVolumesWh: D.number,
            assignments: D.array(singleSmartAssignmentDecoder),
        },
    }) satisfies D.Decoder<ReportHubDto.SmartAssignment>,
);

const smartAssignmentResponseDecoder =
    validateStrictDecoder<ReportHubDto.SmartAssignmentResponse>()(
        D.object({
            required: {
                rows: D.array(smartAssignmentDecoder),
                totalNumRows: D.number,
            },
        }) satisfies D.Decoder<ReportHubDto.SmartAssignmentResponse>,
    );

const trackingInstrumentStateResponseDecoder = validateStrictDecoder<
    GetComponent<components, "TrackingInstrumentStateResponse">
>()(
    D.object({
        required: {
            rows: D.array(trackingInstrumentCommonDecoder),
            totalNumRows: D.number,
            totalNumAssignments: D.number,
        },
    }) satisfies D.Decoder<GetComponent<components, "TrackingInstrumentStateResponse">>,
);

const assignmentDetailAggregatedDecoder = validateStrictDecoder<
    GetComponent<components, "AssignmentDetailAggregated">
>()(
    D.object({
        required: {
            sourcingMethod: sourcingMethodDecoder,
            assignedWh: D.number,
            energySourceId: D.nullable(energySourceDecoder),
            eacSchemeId: D.nullable(D.string),
            productionCountryName: D.nullable(D.string),
            productionStartYear: D.nullable(D.number),
            trackingInstrumentType: D.nullable(trackingInstrumentForAssignmentTypeDecoder),
            productionSiteCommissioningYear: D.nullable(D.number),
            contractStartYear: D.nullable(D.number),
        },
    }),
);

// No validateStrictDecoder since this type is MORE specific than the api schema one.
const reportingInsightsDecoder = D.oneOf<ReportHubDto.ReportingInsights>(
    D.object({
        required: {
            kind: D.literalUnion(
                ReportHubDto.ReportingInsightsKind.UnassignedCertificatesGap,
                ReportHubDto.ReportingInsightsKind
                    .TechnicalCriteriaGapNoContributionTransactionsAssigned,
                ReportHubDto.ReportingInsightsKind
                    .TechnicalCriteriaGapTooManyPartialContributionTransactionsAssigned,
                ReportHubDto.ReportingInsightsKind.CoverageGap,
                ReportHubDto.ReportingInsightsKind.NoContractInfoGap,

                ReportHubDto.ReportingInsightsKind.UnassignedCertificatesGapCountry,
                ReportHubDto.ReportingInsightsKind
                    .TechnicalCriteriaGapNoContributionTransactionsAssignedCountry,
                ReportHubDto.ReportingInsightsKind
                    .TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedCountry,
            ),
        },
    }),
    D.object({
        required: {
            kind: D.literalUnion(
                ReportHubDto.ReportingInsightsKind
                    .TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedRegion,
                ReportHubDto.ReportingInsightsKind
                    .TechnicalCriteriaGapTooManyPartialContributionTransactionsAssignedAssignment,
            ),
            contributionPartialContributionWh: D.number,
            maxPartialContributionWh: D.number,
        },
    }),
);

const reportingInsightsResponseDecoder = D.array(reportingInsightsDecoder);

export const reportHubDecoders = {
    "/{tenantId}/assignment/region-assignment/{regionId}": {
        get: regionAssignmentResponseDecoder,
    },
    "/{tenantId}/assignment/region-assignment": {
        get: D.object({
            required: {
                rows: D.array(regionAssignmentResponseDecoder),
                totalNumRows: D.number,
            },
        }),
    },
    "/{tenantId}/assignment/tracking-instrument-assignment": {
        get: trackingInstrumentResponseDecoder,
    },
    "/{tenantId}/assignment/tracking-instrument-assignment/smart-assignment": {
        get: smartAssignmentResponseDecoder,
    },
    "/{tenantId}/assignment/tracking-instrument-state": {
        get: trackingInstrumentStateResponseDecoder,
    },
    "/{tenantId}/assignment/reporting-period/{year}": {
        get: reportingPeriodResponseDecoder,
    },

    "/{tenantId}/assignment/tracking-instruments/available-filters": {
        get: D.object({
            required: {
                countries: D.array(
                    D.object({
                        required: {
                            name: D.string,
                            countryId: D.string,
                            enabled: D.boolean,
                            passiveProcurement: D.boolean,
                        },
                    }),
                ),
                trackingInstrumentTypes: D.array(trackingInstrumentForAssignmentTypeDecoder),
                schemes: D.array(D.nullable(D.string)),
                sources: D.array(D.nullable(energySourceDecoder)),
                markets: D.array(
                    D.object({
                        required: {
                            description: D.string,
                            marketId: D.string,
                            name: D.string,
                        },
                    }),
                ),
                productionSites: D.array(D.nullable(D.string)),
                sourcingMethods: D.array(sourcingMethodDecoder),
            },
        }),
    },
    "/{tenantId}/local-tenants": {
        get: D.object({
            required: {
                tenantId: D.string,
                assignmentByGroups: D.boolean,
            },
        }),
    },
    "/{tenantId}/consumption-site-groups": {
        get: D.array(D.object({ required: { name: D.string, consumptionSiteGroupId: D.string } })),
    },
    "/public/country-name/{countryId}": {
        get: countryNameDecoder,
    },
    "/public/regions/{regionId}": {
        get: regionWithCountryDecoder,
    },
    "/{tenantId}/assignment/country-assignment/reporting-list": {
        get: D.array(assignmentDetailAggregatedDecoder),
    },
    "/{tenantId}/reporting-dashboard/insights/{reportingPeriod}": {
        get: reportingInsightsResponseDecoder,
    },
    "/{tenantId}/reporting-dashboard/assignment/insights/{reportingPeriod}": {
        get: reportingInsightsResponseDecoder,
    },
    "/{tenantId}/reporting-dashboard/countries/{countryId}/insights/{reportingPeriod}": {
        get: reportingInsightsResponseDecoder,
    },
    "/{tenantId}/reporting-dashboard/assignment/regions/{regionId}/insights/{reportingPeriod}": {
        get: reportingInsightsResponseDecoder,
    },
} satisfies Pick<
    PathsDecodersGet<reportHubPaths>,
    | "/{tenantId}/assignment/region-assignment"
    | "/{tenantId}/assignment/tracking-instrument-assignment"
    | "/{tenantId}/assignment/tracking-instrument-assignment/smart-assignment"
    | "/{tenantId}/assignment/tracking-instrument-state"
    | "/{tenantId}/assignment/reporting-period/{year}"
    | "/{tenantId}/assignment/region-assignment/{regionId}"
    | "/{tenantId}/assignment/tracking-instruments/available-filters"
    | "/{tenantId}/local-tenants"
    | "/{tenantId}/consumption-site-groups"
    | "/public/country-name/{countryId}"
    | "/public/regions/{regionId}"
    | "/{tenantId}/assignment/country-assignment/reporting-list"
    | "/{tenantId}/reporting-dashboard/insights/{reportingPeriod}"
    | "/{tenantId}/reporting-dashboard/assignment/insights/{reportingPeriod}"
    | "/{tenantId}/reporting-dashboard/countries/{countryId}/insights/{reportingPeriod}"
    | "/{tenantId}/reporting-dashboard/assignment/regions/{regionId}/insights/{reportingPeriod}"
>;
