import { ChangeEvent, ReactElement } from "react";
import { FocusTrap, MantineTheme, Paper, TextInput, useMantineTheme } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";

type SearchBarProps = {
    searchQuery: string;
    setSearchQuery: (query: string) => void;
};

export const SearchBar = ({ searchQuery, setSearchQuery }: SearchBarProps): ReactElement => {
    const theme: MantineTheme = useMantineTheme();
    return (
        <Paper py="md">
            <FocusTrap active>
                <TextInput
                    placeholder="Search"
                    icon={<IconSearch color={theme.colors.flexidaoGrey![4]} />}
                    rightSection={
                        searchQuery.length > 0 && (
                            <IconX
                                color={theme.colors.flexidaoGrey![4]}
                                onClick={(): void => setSearchQuery("")}
                            />
                        )
                    }
                    value={searchQuery}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                        setSearchQuery(event.currentTarget.value)
                    }
                    data-autofocus
                />
            </FocusTrap>
        </Paper>
    );
};
