import { ActionIcon, CopyButton, Group, SystemProp, TextInput } from "@mantine/core";
import { IconClipboardCheck, IconCopy } from "@tabler/icons-react";
import { CSSProperties } from "react";

type InputWithCopyButtonProps = {
    width: SystemProp<CSSProperties["width"]>;
    value: string;
    label: string;
};

export const InputWithCopyButton = ({
    width,
    value,
    label,
}: InputWithCopyButtonProps): JSX.Element => (
    <Group align="flex-end" w={width}>
        <TextInput label={label} value={value} disabled sx={{ flex: 1 }} />{" "}
        <CopyButton value={value}>
            {({ copied, copy }): JSX.Element => (
                <ActionIcon
                    size="lg"
                    color={copied ? "green" : "gray"}
                    variant={copied ? "filled" : "default"}
                    onClick={copy}
                >
                    {copied ? <IconClipboardCheck /> : <IconCopy />}
                </ActionIcon>
            )}
        </CopyButton>
    </Group>
);
