import { Group, Text } from "@mantine/core";
import { InfoTooltip, InfoTooltipProps } from "./info-tooltip";

type LabelWithInfoTooltipProps = {
    label: string;
    tooltip: InfoTooltipProps;
    required?: boolean;
};

export const LabelWithInfoTooltip = ({
    label,
    tooltip,
    required = false,
}: LabelWithInfoTooltipProps): JSX.Element => {
    return (
        <Group spacing="0.25rem">
            <Group spacing="0.25rem">
                <Text>{label}</Text>
                {required && <Text color="flexidaoRed">*</Text>}
            </Group>

            <InfoTooltip {...tooltip} size={21} />
        </Group>
    );
};
