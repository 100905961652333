import { DeletePayload } from "@flexidao/api-client";
import {
    CfeDiagnosticContractType,
    CfeDiagnosticDto,
    ContractType,
    EnergySource,
    ExpectedIntervalReadingType,
    ExpectedReadingGranularity,
    GlobalRegion,
    IndustryType,
    MatchingLogic,
    ProvidersEnum,
    EacProvidersEnum,
    SiteType,
    SourceType,
    SourcingMethod,
    TrackingInstrumentType,
    ZoneDataAccessLevel,
    TrackingInstrumentForAssignmentType,
} from "@flexidao/dto";
import * as D from "schemawax";

export const deleteResponseDecoder: D.Decoder<DeletePayload> = D.object({
    required: {
        success: D.boolean,
    },
    optional: {
        error: D.string,
    },
});

export const successDecoder: D.Decoder<{ success: boolean }> = D.object({
    required: {
        success: D.boolean,
    },
});

export const noContentDecoder: D.Decoder<undefined> = D.unknown.andThen((value) => {
    if (value != null && Object.keys(value).length > 0) {
        throw new Error(`Expected no content, got '${value}'.`);
    }

    return undefined;
});

export const parseDate = (value: string | number | Date | null | undefined): Date => {
    if (value == null) {
        throw new Error(`Invalid date '${value}'.`);
    }

    const parsedDate: Date = new Date(value);

    if (!parsedDate || isNaN(parsedDate.getTime())) {
        throw new Error(`Invalid date '${value}'.`);
    }

    return parsedDate;
};
export const dateDecoder: D.Decoder<Date> = D.string.andThen((value) => {
    return parseDate(value);
});

export const featureFlagDecoder: D.Decoder<Array<string>> = D.array(D.string);

export const energySourceDecoder: D.Decoder<EnergySource> = D.literalUnion(
    ...Object.values(EnergySource),
);

export const sourcingMethodDecoder: D.Decoder<SourcingMethod> = D.literalUnion(
    ...Object.values(SourcingMethod),
);

export const contractEnergySourceDecoder = D.object({
    required: { energySourceId: energySourceDecoder, isCfe: D.boolean, name: D.string },
});

export const contractTypeDecoder: D.Decoder<ContractType> = D.literalUnion(
    ...Object.values(ContractType),
);

export const cfeDiagnosticContractTypeDecoder: D.Decoder<CfeDiagnosticContractType> =
    D.literalUnion(...Object.values(CfeDiagnosticContractType));

export const matchingLogicDecoder: D.Decoder<MatchingLogic> = D.literalUnion(
    ...Object.values(MatchingLogic),
);

export const contractTypeArrayDecoder = D.array(
    D.object({
        required: {
            contractTypeId: cfeDiagnosticContractTypeDecoder,
            isCfeContract: D.boolean,
            name: D.string,
            energySources: D.array(contractEnergySourceDecoder),
        },
    }),
);

export const industryTypeDecoder: D.Decoder<IndustryType> = D.literalUnion(
    ...Object.values(IndustryType),
);

export const industryTypeArrayDecoder = D.array(
    D.object({
        required: { industryTypeId: industryTypeDecoder, name: D.string },
    }),
);

export const countriesDecoder: D.Decoder<CfeDiagnosticDto.Country> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
        zones: D.array(
            D.object({
                required: { zoneId: D.string, name: D.string, enabled: D.boolean },
            }),
        ),
    },
});

export const countriesArrayDecoder: D.Decoder<Array<CfeDiagnosticDto.Country>> =
    D.array(countriesDecoder);

export const dataAccessDecoder: D.Decoder<ZoneDataAccessLevel> = D.literalUnion(
    ...Object.values(ZoneDataAccessLevel),
);

export const sourceTypeDecoder: D.Decoder<SourceType> = D.literalUnion(
    ...Object.values(SourceType),
);

export const providerDecoder: D.Decoder<ProvidersEnum> = D.literalUnion(
    ...Object.values(ProvidersEnum),
);

export const eacProviderDecoder: D.Decoder<EacProvidersEnum> = D.literalUnion(
    ...Object.values(EacProvidersEnum),
);

export const expectedIntervalReadingTypeDecoder: D.Decoder<ExpectedIntervalReadingType> =
    D.literalUnion(...Object.values(ExpectedIntervalReadingType));

export const expectedReadingGranularityDecoder: D.Decoder<ExpectedReadingGranularity> =
    D.literalUnion(...Object.values(ExpectedReadingGranularity));

export const siteTypeDecoder: D.Decoder<SiteType> = D.literalUnion(...Object.values(SiteType));

export const trackingInstrumentTypeDecoder: D.Decoder<TrackingInstrumentType> = D.literalUnion(
    ...Object.values(TrackingInstrumentType),
);
export const trackingInstrumentForAssignmentTypeDecoder: D.Decoder<TrackingInstrumentForAssignmentType> =
    D.literalUnion(...Object.values(TrackingInstrumentForAssignmentType));

export const globalRegionIdDecoder: D.Decoder<GlobalRegion> = D.literalUnion(
    ...Object.values(GlobalRegion),
);
