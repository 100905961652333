import { ReactElement } from "react";
import { CSSObject, Flex, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import { formatDate, IconCalendar, labelToDataId } from "@flexidao/ui-lib";

type DateOrPlaceholderProps = {
    date: Date | null;
    dataId: string;
};
const DateOrPlaceholder = ({ date, dataId }: DateOrPlaceholderProps): ReactElement => {
    return (
        <Flex w="85px">
            {date && (
                <Text weight={600} size="14px" data-id={dataId}>
                    {formatDate(date)}
                </Text>
            )}
        </Flex>
    );
};

type DateRangeInputTextProps = {
    selectedDateRange: [Date | null, Date | null];
    dataId: string;
};
const DateRangeInputText = ({
    selectedDateRange,
    dataId,
}: DateRangeInputTextProps): ReactElement => {
    const [startTime, endTime] = selectedDateRange;

    return (
        <Group spacing="8px" align="center">
            <Group spacing="4px" align="center">
                <Text c="flexidaoGrey.3" size="14px">
                    From
                </Text>

                <DateOrPlaceholder
                    date={startTime}
                    dataId={labelToDataId({
                        prefix: dataId,
                        label: "start-date",
                    })}
                />
            </Group>

            <Group spacing="4px" align="center">
                <Text c="flexidaoGrey.3" size="14px">
                    to
                </Text>

                <DateOrPlaceholder
                    date={endTime}
                    dataId={labelToDataId({
                        prefix: dataId,
                        label: "end-date",
                    })}
                />
            </Group>
        </Group>
    );
};

type DateRangeHeaderProps = {
    dataId: string;
    selectedDateRange: [Date | null, Date | null];
};

export const DateRangeHeader = ({
    selectedDateRange,
    dataId,
}: DateRangeHeaderProps): ReactElement => {
    const mantineTheme = useMantineTheme();

    return (
        <Stack spacing={0} m="md">
            <Group
                data-id={labelToDataId({
                    prefix: dataId,
                    label: "value",
                })}
                sx={(theme): CSSObject => ({
                    paddingBlock: "6px",
                    paddingInline: "8px",
                    borderColor: theme.colors.flexidaoGrey![3],
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: theme.radius.sm,
                    justifyContent: "space-between",
                })}
                w="100%"
                align="center"
            >
                <DateRangeInputText dataId={dataId} selectedDateRange={selectedDateRange} />

                <IconCalendar color={mantineTheme.colors.flexidaoGrey![3]} stroke={1.25} />
            </Group>
        </Stack>
    );
};
