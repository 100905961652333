import { ReactElement } from "react";
import { Text } from "@mantine/core";

type SearchResultsSummaryProps = {
    searchQuery: string;
    numMatches: number;
};
export const SearchResultsSummary = ({
    searchQuery,
    numMatches,
}: SearchResultsSummaryProps): ReactElement => {
    // If there is no search query, we don't need to show anything
    if (searchQuery.length === 0) {
        return <></>;
    }

    // If there are no matches
    if (numMatches === 0) {
        return (
            <Text color="flexidaoRed" size="xs" ta="end">
                No matches
            </Text>
        );
    }

    // If there is exactly one match
    if (numMatches === 1) {
        return (
            <Text color="flexidaoGrey" size="xs" ta="end">
                1 match
            </Text>
        );
    }

    // If there is more than one match
    return (
        <Text color="flexidaoGrey" size="xs" ta="end">
            {numMatches} matches
        </Text>
    );
};
