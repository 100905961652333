import { BrushHandleRenderProps } from "@visx/brush/lib/BrushHandle";
import { ReactElement } from "react";
import { Group as VisxGroup } from "@visx/group";
import {
    BRUSH_HANDLE_HEIGHT,
    BRUSH_HANDLE_WIDTH,
    FILL_COLOR,
    FILL_OPACITY,
    STROKE_COLOR,
    STROKE_OPACITY,
    STROKE_WIDTH,
    TWO,
} from "./consts";

export const BrushHandle = ({
    x,
    height,
    isBrushActive,
}: BrushHandleRenderProps): ReactElement | null => {
    if (!isBrushActive) {
        return null;
    }

    const left: number = x + BRUSH_HANDLE_WIDTH / TWO;
    const top: number = (height - BRUSH_HANDLE_HEIGHT) / TWO;

    return (
        <VisxGroup left={left} top={top}>
            <path
                fill={FILL_COLOR}
                fillOpacity={FILL_OPACITY}
                d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
                stroke={STROKE_COLOR}
                strokeWidth={STROKE_WIDTH}
                strokeOpacity={STROKE_OPACITY}
                style={{ cursor: "ew-resize" }}
            />
        </VisxGroup>
    );
};
