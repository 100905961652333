import { useDebouncedValue } from "@mantine/hooks";

const DEBOUNCED_INPUT_DELAY_MS = 500;
export const useDebouncedSearch = (
    value: string,
    wait: number = DEBOUNCED_INPUT_DELAY_MS,
    options?: { leading: boolean },
): readonly [string, () => void] => {
    return useDebouncedValue(value, value === "" ? 0 : wait, options);
};
