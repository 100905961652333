import { ReactElement, useMemo } from "react";
import { Brush as VisxBrush } from "@visx/brush";
import { scaleLinear, scaleTime } from "@visx/scale";
import { StackedBarChart } from "../chart/chart";
import { StackedBarChartData, StackedBarChartSeries } from "../types";
import {
    BRUSH_HANDLE_WIDTH,
    BRUSH_HEIGHT,
    BRUSH_MARGIN,
    CHART_BRUSH_MARGIN,
    CHART_BRUSH_PADDING,
    FILL_COLOR,
    FILL_OPACITY,
    STROKE_COLOR,
    STROKE_OPACITY,
    STROKE_WIDTH,
} from "./consts";
import { BrushHandle } from "./brush-handle";
import { getYDomain, roundDateToClosestHour } from "../utils";
import { Bounds, PartialBrushStartEnd } from "@visx/brush/lib/types";

type StackBarChartBrushProps<T extends Record<string, number>> = {
    width: number;
    height?: number;
    data: StackedBarChartData<T>;
    series: StackedBarChartSeries<T>;
    period: [Date, Date];
    onBrushChange: (newPeriod: [Date, Date] | null) => void;
    onBrushClick: () => void;
};

export const StackedBarChartBrush = <T extends Record<string, number>>({
    width,
    height = BRUSH_HEIGHT,
    data,
    series,
    period,
    onBrushChange,
    onBrushClick,
}: StackBarChartBrushProps<T>): ReactElement => {
    const activeKeys = useMemo(
        () => series.filter(({ isSeriesActive }) => isSeriesActive).map(({ dataKey }) => dataKey),
        [series],
    );

    const innerWidth: number = useMemo(
        () => Math.max(width - BRUSH_MARGIN.right - BRUSH_MARGIN.left, 0),
        [width],
    );

    // ----- Scales and domains start -----
    const xScale = useMemo(
        () =>
            scaleTime<number>({
                range: [0, innerWidth],
                domain: period,
            }),
        [period, innerWidth],
    );
    const yScale = useMemo(
        () =>
            scaleLinear<number>({
                domain: getYDomain(data, activeKeys),
                nice: true,
            }),
        [data, activeKeys],
    );
    // ----- Scales and domains end -----

    const initialBrushPosition: PartialBrushStartEnd | undefined = useMemo(() => {
        if (data.length === 0) {
            return undefined;
        }

        return {
            start: { x: xScale(period[0]) },
            end: { x: xScale(period[1]) },
        };
    }, [data, xScale, period]);

    const handleOnBrushChange = (domain: Bounds | null): void => {
        if (!domain) {
            onBrushChange(null);
            return;
        }

        const { x0, x1 } = domain;

        const newPeriod: [Date, Date] = [
            roundDateToClosestHour(new Date(x0)),
            roundDateToClosestHour(new Date(x1)),
        ];
        onBrushChange(newPeriod);
    };

    return (
        <StackedBarChart
            chartHeight={height}
            data={data}
            series={series}
            period={period}
            chartMargin={CHART_BRUSH_MARGIN}
            chartPadding={CHART_BRUSH_PADDING}
            hideAxes
            disableTooltip
        >
            <VisxBrush
                xScale={xScale}
                yScale={yScale}
                width={innerWidth}
                height={height}
                margin={BRUSH_MARGIN}
                handleSize={BRUSH_HANDLE_WIDTH}
                resizeTriggerAreas={["left", "right"]}
                brushDirection="horizontal"
                initialBrushPosition={initialBrushPosition}
                onChange={handleOnBrushChange}
                onClick={onBrushClick}
                selectedBoxStyle={{
                    fill: FILL_COLOR,
                    fillOpacity: FILL_OPACITY,
                    stroke: STROKE_COLOR,
                    strokeWidth: STROKE_WIDTH,
                    strokeOpacity: STROKE_OPACITY,
                }}
                useWindowMoveEvents
                renderBrushHandle={BrushHandle}
            />
        </StackedBarChart>
    );
};
