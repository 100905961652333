import { MRT_ColumnFiltersState } from "mantine-react-table";
import { FiltersState } from "@flexidao/ui-lib";
import { RowData } from "../types";

export const filtersToMrtFilters = <T extends RowData>(
    filtersState?: FiltersState<T> | null,
): MRT_ColumnFiltersState => {
    if (filtersState == null) {
        return [];
    }

    const mrtFiltersState: MRT_ColumnFiltersState = Object.entries(filtersState).map(
        ([columnId, value]) => ({
            id: columnId,
            value,
        }),
    );

    return mrtFiltersState;
};

export const mrtFiltersToFilters = <T extends RowData>(
    mrtFiltersState?: MRT_ColumnFiltersState,
): FiltersState<T> | null => {
    if (mrtFiltersState == null || mrtFiltersState.length === 0) {
        return null;
    }

    const filtersState: FiltersState<T> = mrtFiltersState.reduce(
        (acc: FiltersState<T>, { id, value }) => ({
            ...acc,
            [id]: value,
        }),
        {} as FiltersState<T>,
    );

    return filtersState;
};
