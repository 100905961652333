import { Anchor, Button, Container, Group, Paper, Stack, Title } from "@mantine/core";
import { IconExternalLink, IconReload } from "@tabler/icons-react";
import { ReactElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { labelToDataId } from "@flexidao/ui-lib";

type ErrorPageProps = {
    title: string;
    content: ReactNode;
    showReloadButton?: boolean;
    showContactUsButton?: boolean;
};

export const ErrorPage = ({
    title,
    content,
    showReloadButton,
    showContactUsButton,
}: ErrorPageProps): ReactElement => {
    const navigate = useNavigate();
    const showButtons = showReloadButton || showContactUsButton;
    return (
        <Container
            size="lg"
            mt="xl"
            data-id={labelToDataId({ prefix: "error-page", label: title })}
        >
            <Paper>
                <Title color="flexidaoRed" order={1} mb="xl">
                    {title}
                </Title>
                <Stack>
                    {content}
                    {showButtons && (
                        <Group mt="xl">
                            {showReloadButton && (
                                <Button
                                    rightIcon={<IconReload size="1rem" />}
                                    uppercase
                                    variant="outline"
                                    onClick={(): void => navigate(0)}
                                >
                                    Reload page
                                </Button>
                            )}
                            {showContactUsButton && (
                                <Anchor
                                    href="https://flexidao.atlassian.net/servicedesk/customer/portal/1"
                                    target="_blank"
                                >
                                    <Button
                                        rightIcon={<IconExternalLink size="1rem" />}
                                        uppercase
                                        color="flexidaoBlue"
                                    >
                                        Contact us
                                    </Button>
                                </Anchor>
                            )}
                        </Group>
                    )}
                </Stack>
            </Paper>
        </Container>
    );
};
