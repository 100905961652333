import { ReactNode } from "react";
import { RowData } from "../types";
import { MRT_Cell, MRT_Row, MRT_TableInstance } from "mantine-react-table";

export enum RowActionColumnPosition {
    First = "first",
    Last = "last",
}

export type RowActionsRendererProps<T extends RowData> = { rowData: T };

export type RowActionsRenderer<T extends RowData> = (
    props: RowActionsRendererProps<T>,
) => ReactNode;

export type MrtRowActionsRenderer<T extends RowData> = (props: {
    cell: MRT_Cell<T>;
    row: MRT_Row<T>;
    table: MRT_TableInstance<T>;
}) => ReactNode;
