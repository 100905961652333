import { NullableDateValue } from "@flexidao/ui-lib";
import { ONE_DAY_MS, ONE_HOUR_MS, ONE_MONTH_MS } from "@flexidao/helpers";
import { DataGranularity } from "@flexidao/dto";

export const getMillisecondsFromGranularity = (granularity: DataGranularity): number => {
    switch (granularity) {
        case DataGranularity.Hourly:
            return ONE_HOUR_MS;
        case DataGranularity.Daily:
            return ONE_DAY_MS;
        case DataGranularity.Monthly:
            return ONE_MONTH_MS;
    }
};

export const getTruncatedDate = (date: Date, granularity: DataGranularity): Date => {
    const utcYear: number = date.getUTCFullYear();
    const utcMonth: number = date.getUTCMonth();
    const utcDay: number =
        granularity === DataGranularity.Daily || granularity === DataGranularity.Hourly
            ? date.getUTCDate()
            : 1;
    const utcHours: number = granularity === DataGranularity.Hourly ? date.getUTCHours() : 0;

    return new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHours, 0, 0, 0));
};

export const getAllDatesFromPeriod = (
    [startTime, endTime]: [Date, Date],
    granularity: DataGranularity,
): Array<Date> => {
    // Convert period to milliseconds
    const startTimeMs: number = startTime.getTime();
    const endTimeMs: number = endTime.getTime();

    // Get multiplier from granularity
    const granularityMs: number = getMillisecondsFromGranularity(granularity);

    // Initialize array to store all dates in between the period
    const allDates: Array<Date> = [];

    // Initialize current date
    let currentDateMs: number = startTimeMs;

    // Loop through all dates in between the period and add them to the array
    while (currentDateMs < endTimeMs) {
        allDates.push(new Date(currentDateMs));
        currentDateMs += granularityMs;
    }

    return allDates;
};

export const getSeriesDataForLineChart = (
    seriesData_: Array<NullableDateValue>,
    granularity: DataGranularity,
): Array<NullableDateValue> => {
    const lastValue: NullableDateValue | null =
        seriesData_.length > 0 ? seriesData_[seriesData_.length - 1]! : null;

    const extraTimeMs: number = getMillisecondsFromGranularity(granularity);

    const seriesData: Array<NullableDateValue> =
        seriesData_.length > 0
            ? [
                  ...seriesData_,
                  {
                      date: new Date(lastValue!.date.getTime() + extraTimeMs),
                      value: lastValue!.value,
                  },
              ]
            : [];

    return seriesData;
};

export const isPointDefined = ({
    currentPoint,
    previousPoint,
}: {
    currentPoint: NullableDateValue;
    previousPoint: NullableDateValue | null;
}): boolean => {
    const isCurrentValueNull: boolean = currentPoint.value == null;
    const isPreviousValueNull: boolean = previousPoint?.value == null;

    // If it is the first point, we should draw the line if the value is not null
    if (previousPoint == null) {
        return !isCurrentValueNull;
    }

    // Else, we should draw the line if we have one of the following cases:
    // - If the current value is not null
    // - If the current value is null and the previous value was not null

    return !isCurrentValueNull || (isCurrentValueNull && !isPreviousValueNull);
};
