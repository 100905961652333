/* eslint-disable no-magic-numbers */
import { DefaultMantineColor, Tuple } from "@mantine/core";
import { DEFAULT_COLORS } from "./default-colours";
import {
    flexidaoBlue,
    flexidaoGreen,
    flexidaoGrey,
    flexidaoOrange,
    flexidaoRed,
    flexidaoYellow,
    gridMixCfe,
    contractedCfe,
    gridMixNonCfe,
    flexidaoBlack,
} from "./flexidao-colors";

// Colours are an array of 10 hex colours going from lightest to darkest

/*  | default colours in Mantine:| FlexiDAO Override? |
    |  dark                      |                    |
    |  gray                      | yes                |
    |  red                       | yes                |
    |  pink                      |                    |
    |  grape                     |                    |
    |  violet                    |                    |
    |  indigo                    |                    |
    |  blue                      | yes                |
    |  cyan                      |                    |
    |  green                     | yes                |
    |  lime                      |                    |
    |  yellow                    | yes                |
    |  orange                    | yes                |
    |  teal                      |                    |
    
*/

export const colours: Record<CustomColours, Tuple<string, 10>> = {
    ...DEFAULT_COLORS, // Add defaults as fallback

    // BASE COLOURS
    flexidaoGreen,
    green: flexidaoGreen, // override default

    flexidaoOrange,
    orange: flexidaoOrange, // override default

    flexidaoRed,
    red: flexidaoRed, // override default

    flexidaoBlue,
    blue: flexidaoBlue, // override default

    flexidaoGrey,
    gray: flexidaoGrey, // override default
    grey: flexidaoGrey, // add non yank spelling for normal ppl

    flexidaoYellow,
    yellow: flexidaoYellow,

    flexidaoBlack,

    // ENERGY TYPES
    hydro: flexidaoBlue,
    wind: DEFAULT_COLORS.teal,
    solar: flexidaoYellow,
    unknown: flexidaoGrey,
    hydrogen: flexidaoGreen,
    nuclear: DEFAULT_COLORS.cyan,
    biomass: DEFAULT_COLORS.pink,

    // GRAPH COLOURS
    contractedCfe: contractedCfe,
    gridMixCfe: gridMixCfe,
    gridMixNonCfe: gridMixNonCfe,
    excessCfe: flexidaoOrange,
    consumption: flexidaoBlue,
    locationBasedCo2: flexidaoBlue,
    marketBasedCo2: flexidaoOrange,
    fromGrid: flexidaoRed,
    toGrid: flexidaoOrange,
    powerGenerated: flexidaoGreen,
    fromBattery: flexidaoBlue,
    toBattery: flexidaoGrey,
};

export type CustomColours =
    | "flexidaoBlue"
    | "flexidaoGreen"
    | "flexidaoGrey"
    | "flexidaoYellow"
    | "flexidaoOrange"
    | "flexidaoRed"
    | "hydro"
    | "wind"
    | "solar"
    | "unknown"
    | "hydrogen"
    | "nuclear"
    | "biomass"
    | "contractedCfe"
    | "gridMixCfe"
    | "gridMixNonCfe"
    | "excessCfe"
    | "consumption"
    | "locationBasedCo2"
    | "marketBasedCo2"
    | "fromGrid"
    | "toGrid"
    | "powerGenerated"
    | "fromBattery"
    | "toBattery"
    | DefaultMantineColor;
