import { Group, Stack } from "@mantine/core";
import { LegendOrdinal } from "@visx/legend";
import { ScaleOrdinal } from "d3-scale";
import { ChartDimensions, LegendDotWithLabel } from "../_utils/utils";
import { PieChartItem } from "./piechart";
import { ReactElement } from "react";
import { halve } from "../../../../utils";

type PieChartLegendProps = {
    dimensions: ChartDimensions;
    useVerticalLayout: boolean;
    colorScale: ScaleOrdinal<string, string, never>;
    data: PieChartItem[];
};

const getPosition = (
    useVerticalLayout: boolean,
    dimensions: ChartDimensions,
):
    | {
          bottom: number;
          width: string;
          left?: undefined;
          top?: undefined;
          transform?: undefined;
      }
    | {
          left: number;
          top: string;
          transform: string;
          bottom?: undefined;
          width?: undefined;
      } => {
    const bottomOffset = 10;
    if (useVerticalLayout) {
        return { bottom: halve(dimensions.margin.bottom) - bottomOffset, width: "100%" };
    }
    const leftPadding = 16;
    return {
        left: Math.max(dimensions.innerHeight, dimensions.innerWidth) + leftPadding,
        top: "50%",
        transform: "translateY(-50%)",
    };
};
export const Legend = ({
    dimensions,
    colorScale,
    data,
    useVerticalLayout,
}: PieChartLegendProps): ReactElement => {
    return (
        <div
            style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                ...getPosition(useVerticalLayout, dimensions),
            }}
        >
            <LegendOrdinal
                scale={colorScale}
                labelFormat={(label): string => `${label.toUpperCase()}`}
            >
                {(labels): JSX.Element =>
                    useVerticalLayout ? (
                        <Group spacing="lg">
                            {labels.map((label, i) => {
                                const labelText = data.find((x) => x.key === label.datum)?.label;

                                return (
                                    <LegendDotWithLabel
                                        key={i}
                                        text={labelText ?? ""}
                                        color={label.value ?? "black"}
                                    />
                                );
                            })}
                        </Group>
                    ) : (
                        <Stack spacing="lg">
                            {labels.map((label, i) => {
                                const labelText = data.find((x) => x.key === label.datum)?.label;

                                return (
                                    <LegendDotWithLabel
                                        key={i}
                                        text={labelText ?? ""}
                                        color={label.value ?? "black"}
                                    />
                                );
                            })}
                        </Stack>
                    )
                }
            </LegendOrdinal>
        </div>
    );
};
