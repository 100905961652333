export const labelToDataId = ({
    prefix,
    suffix,
    label,
    separator = "--",
}: {
    prefix?: string | null;
    suffix?: string | null;
    label: string;
    separator?: string;
}): string => {
    const dataIdBody: string = label.toLowerCase().replace(/\s+/g, "-");

    const dataIdPrefix: string | null = prefix ? prefix.toLowerCase().replace(/\s+/g, "-") : null;
    const dataIdSuffix: string | null = suffix ? suffix.toLowerCase().replace(/\s+/g, "-") : null;

    const dataIdPrefixWithSeparator: string = dataIdPrefix ? `${dataIdPrefix}${separator}` : "";
    const dataIdSuffixWithSeparator: string = dataIdSuffix ? `${separator}${dataIdSuffix}` : "";

    return `${dataIdPrefixWithSeparator}${dataIdBody}${dataIdSuffixWithSeparator}`;
};
