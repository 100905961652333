import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { IconChevronRight, labelToDataId } from "@flexidao/ui-lib";
import { Anchor, Breadcrumbs as MantineBreadcrumbs } from "@mantine/core";
import { Header } from "./header";

type BreadcrumbsProps = {
    dataId: string;
    currentPageTitle: string;
    parents: Array<{
        title: string;
        pathname: string;
        search?: string;
    }>;
};
export const Breadcrumbs = ({
    dataId,
    currentPageTitle,
    parents,
}: BreadcrumbsProps): ReactElement => (
    <MantineBreadcrumbs separator={<IconChevronRight />} data-id={dataId}>
        {parents.map(({ title, pathname, search }) => (
            <Anchor
                component={Link}
                to={{
                    pathname,
                    search,
                }}
            >
                <Header
                    dataId={labelToDataId({
                        prefix: dataId,
                        label: title,
                    })}
                    title={title}
                />
            </Anchor>
        ))}

        <Header
            dataId={labelToDataId({
                prefix: dataId,
                label: currentPageTitle,
            })}
            title={currentPageTitle}
        />
    </MantineBreadcrumbs>
);
