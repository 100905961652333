import { ReactElement, ReactNode } from "react";
import { Breadcrumbs } from "./components/breadcrumbs";
import { Group, Stack, Text } from "@mantine/core";
import { Header } from "./components/header";
import { labelToDataId } from "@flexidao/ui-lib";

type PageHeaderProps = {
    dataId: string;
    title: string;
    description: string;
    parents?: Array<{
        title: string;
        pathname: string;
        search?: string;
    }>;
    dataCompletenessChip?: ReactNode;
    cta?: ReactNode;
};

export const PageHeader = ({
    dataId,
    title,
    description,
    dataCompletenessChip,
    parents,
    cta,
}: PageHeaderProps): ReactElement => {
    return (
        <Group position="apart" align="flex-start" data-id={dataId}>
            <Stack spacing={0}>
                <Group spacing={8} align="center">
                    {parents ? (
                        <Breadcrumbs
                            dataId={labelToDataId({
                                prefix: dataId,
                                label: "breadcrumbs",
                            })}
                            currentPageTitle={title}
                            parents={parents}
                        />
                    ) : (
                        <Header
                            dataId={labelToDataId({
                                prefix: dataId,
                                label: "header",
                            })}
                            title={title}
                        />
                    )}

                    {dataCompletenessChip}
                </Group>

                <Text
                    component="p"
                    mt={0}
                    mb={0}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "description",
                    })}
                >
                    {description}
                </Text>
            </Stack>

            {cta}
        </Group>
    );
};
