import {
    DateRangeOption,
    DAY_MS,
    getCurrentDate,
    getFirstDayOfCurrentYear,
    getFirstDayOfLastYear,
    getLastDayOfLastYear,
    ONE_WEEK_MS,
    TWO_WEEKS_MS,
} from "@flexidao/ui-lib";

export const fixDate = <T extends Date | string | number>(date_: T): Date => {
    const date: Date = new Date(date_);
    date.setHours(0, 0, 0, 0);
    return date;
};

export const fixDateRange = <T extends Date | string | number>(
    dateRange_: [T, T],
): [Date, Date] => {
    return [fixDate(dateRange_[0]), fixDate(dateRange_[1])];
};

const substractFromDate = (date: Date | null, ms: number): Date | null => {
    if (date == null) {
        return null;
    }

    const newDateMs: number = date.getTime() - ms;
    const newDate: Date = new Date(newDateMs);

    return newDate;
};

const addToDate = (date: Date | null, ms: number, maxDate?: Date): Date | null => {
    if (date == null) {
        return null;
    }

    const newDateMs: number = date.getTime() + ms;
    const newDate: Date = new Date(newDateMs);

    if (maxDate != null && newDate.getTime() > maxDate.getTime()) {
        return maxDate;
    }

    return newDate;
};

export const getMinAndMaxDatesFromPeriod = (
    [startDate, endDate]: [Date | null, Date | null],
    maxRangeMs: number,
): [Date | null, Date | null] => {
    const currentDay: Date = getCurrentDate();

    const startDateMs: number | null = startDate != null ? startDate?.getTime() : null;
    const endDateMs: number | null = endDate != null ? endDate?.getTime() : null;

    // No dates have been selected
    if (startDateMs == null && endDateMs == null) {
        return [null, currentDay];
    }

    // Only start date has been selected
    if (endDateMs == null) {
        const minDate: Date | null = substractFromDate(startDate, maxRangeMs);
        const maxDate: Date | null = addToDate(startDate, maxRangeMs, currentDay);

        return [minDate, maxDate];
    }

    // Start and end dates have been selected
    return [null, currentDay];
};

export const getShouldEnableButtons = (
    selectedDateRange: [Date | null, Date | null],
    initialDateRange: [Date, Date],
): {
    enableApplyButton: boolean;
    enableClearButton: boolean;
} => {
    const selectedStartDateMs: number | null = selectedDateRange[0]?.getTime() ?? null;
    const selectedEndDateMs: number | null = selectedDateRange[1]?.getTime() ?? null;

    const initialStartDateMs: number = initialDateRange[0].getTime();
    const initialEndDateMs: number = initialDateRange[1].getTime();

    const enableApplyButton: boolean =
        selectedStartDateMs != null &&
        selectedEndDateMs != null &&
        (selectedStartDateMs !== initialStartDateMs || selectedEndDateMs !== initialEndDateMs);

    const enableClearButton: boolean = selectedStartDateMs != null && selectedEndDateMs != null;

    return { enableApplyButton, enableClearButton };
};

export const getDateRangeOptionLabel = (dateRangeOption: DateRangeOption): string => {
    switch (dateRangeOption) {
        case DateRangeOption.YearToDate:
            return "Year to date";
        case DateRangeOption.LastYear:
            return "Last year";
        case DateRangeOption.Last7Days:
            return "Last 7 days";
        case DateRangeOption.Last14Days:
            return "Last 14 days";
        case DateRangeOption.Custom:
            return "Custom";
    }
};

export const getDateRangeOptionFromDateRange = (
    dateRange_: [Date | null, Date | null],
): DateRangeOption => {
    if (dateRange_[0] == null || dateRange_[1] == null) {
        return DateRangeOption.Custom;
    }

    const dateRange: [Date, Date] = fixDateRange([dateRange_[0], dateRange_[1]]);

    if (isDateRangeLastYear(dateRange)) {
        return DateRangeOption.LastYear;
    }

    if (isDateRangeYearToDate(dateRange)) {
        return DateRangeOption.YearToDate;
    }

    if (isDateRangeLast7Days(dateRange)) {
        return DateRangeOption.Last7Days;
    }

    if (isDateRangeLast14Days(dateRange)) {
        return DateRangeOption.Last14Days;
    }

    return DateRangeOption.Custom;
};

export const getDateRangeFromDateRangeOption = (
    dateRangeOption:
        | DateRangeOption.YearToDate
        | DateRangeOption.LastYear
        | DateRangeOption.Last7Days
        | DateRangeOption.Last14Days,
): [Date, Date] => {
    switch (dateRangeOption) {
        case DateRangeOption.YearToDate:
            return getDateRangeForCurrentYear();
        case DateRangeOption.LastYear:
            return getDateRangeForLastYear();
        case DateRangeOption.Last7Days:
            return getDateRangeForLast7Days();
        case DateRangeOption.Last14Days:
            return getDateRangeForLast14Days();
    }
};

const getDateRangeForCurrentYear = (): [Date, Date] => {
    const currentDay: Date = getCurrentDate();

    const firstDayOfCurrentYear: Date = getFirstDayOfCurrentYear();
    firstDayOfCurrentYear.setHours(0, 0, 0, 0);

    return [firstDayOfCurrentYear, currentDay];
};

const getDateRangeForLastYear = (): [Date, Date] => {
    const firstDayOfLastYear: Date = getFirstDayOfLastYear();
    firstDayOfLastYear.setHours(0, 0, 0, 0);

    const lastDayOfLastYear: Date = getLastDayOfLastYear();
    lastDayOfLastYear.setHours(0, 0, 0, 0);

    return [firstDayOfLastYear, lastDayOfLastYear];
};

const getDateRangeForLast7Days = (): [Date, Date] => {
    const currentDay: Date = getCurrentDate();

    // Add 1 extra day to get the last 7 days, because the current day is included in the range
    const sevenDaysAgoMs: number = currentDay.getTime() - ONE_WEEK_MS + DAY_MS;
    const sevenDaysAgo: Date = new Date(sevenDaysAgoMs);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    return [sevenDaysAgo, currentDay];
};

const getDateRangeForLast14Days = (): [Date, Date] => {
    const currentDay: Date = getCurrentDate();

    // Add 1 extra day to get the last 14 days, because the current day is included in the range
    const fourteenDaysAgoMs: number = currentDay.getTime() - TWO_WEEKS_MS + DAY_MS;
    const fourteenDaysAgo: Date = new Date(fourteenDaysAgoMs);
    fourteenDaysAgo.setHours(0, 0, 0, 0);

    return [fourteenDaysAgo, currentDay];
};

const isDateRangeLastYear = (dateRange: [Date, Date]): boolean => {
    const firstDayOfLastYear: Date = getFirstDayOfLastYear();
    firstDayOfLastYear.setHours(0, 0, 0, 0);

    const lastDayOfLastYear: Date = getLastDayOfLastYear();
    lastDayOfLastYear.setHours(0, 0, 0, 0);

    return (
        dateRange[0].getTime() === firstDayOfLastYear.getTime() &&
        dateRange[1].getTime() === lastDayOfLastYear.getTime()
    );
};

const isDateRangeYearToDate = (dateRange: [Date, Date]): boolean => {
    const firstDayOfCurrentYear: Date = getFirstDayOfCurrentYear();
    firstDayOfCurrentYear.setHours(0, 0, 0, 0);

    const currentDay: Date = getCurrentDate();

    return (
        dateRange[0].getTime() === firstDayOfCurrentYear.getTime() &&
        dateRange[1].getTime() === currentDay.getTime()
    );
};

const isDateRangeLast7Days = (dateRange: [Date, Date]): boolean => {
    const currentDay: Date = getCurrentDate();

    // Add 1 extra day to get the last 7 days, because the current day is included in the range
    const sevenDaysAgoMs: number = currentDay.getTime() - ONE_WEEK_MS + DAY_MS;
    const sevenDaysAgo: Date = new Date(sevenDaysAgoMs);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    return (
        dateRange[0].getTime() === sevenDaysAgo.getTime() &&
        dateRange[1].getTime() === currentDay.getTime()
    );
};

const isDateRangeLast14Days = (dateRange: [Date, Date]): boolean => {
    const currentDay: Date = getCurrentDate();

    // Add 1 extra day to get the last 14 days, because the current day is included in the range
    const fourteenDaysAgoMs: number = currentDay.getTime() - TWO_WEEKS_MS + DAY_MS;
    const fourteenDaysAgo: Date = new Date(fourteenDaysAgoMs);
    fourteenDaysAgo.setHours(0, 0, 0, 0);

    return (
        dateRange[0].getTime() === fourteenDaysAgo.getTime() &&
        dateRange[1].getTime() === currentDay.getTime()
    );
};
