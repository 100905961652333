import { ReactElement, useEffect, useMemo, useState } from "react";
import { Group, Stack } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { fixDate, getMinAndMaxDatesFromPeriod } from "../utils";

type DateRangeCalendarProps = {
    selectedDateRange: [Date | null, Date | null];
    maxRangeMs: number;
    handleSelectedDateRangeChange: (value: [Date | null, Date | null]) => void;
};

export const DateRangeCalendar = ({
    selectedDateRange,
    maxRangeMs,
    handleSelectedDateRangeChange,
}: DateRangeCalendarProps): ReactElement => {
    const initialDate: Date | null = selectedDateRange[1] ?? selectedDateRange[0];

    const [date, setDate] = useState<Date | null>(initialDate);

    const [minDate, maxDate] = useMemo(
        () => getMinAndMaxDatesFromPeriod(selectedDateRange, maxRangeMs),
        [selectedDateRange, maxRangeMs],
    );

    const handleOnChange = (newDateRange: [Date | null, Date | null]): void => {
        const fixedStartTime: Date | null = newDateRange[0] ? fixDate(newDateRange[0]) : null;
        const fixedEndTime: Date | null = newDateRange[1] ? fixDate(newDateRange[1]) : null;

        const newDate: Date | null = fixedEndTime ?? fixedStartTime;

        handleSelectedDateRangeChange([fixedStartTime, fixedEndTime]);
        setDate(newDate);
    };

    useEffect(() => {
        setDate(initialDate);
    }, [initialDate]);

    return (
        <Stack p="md">
            <Group position="center" align="baseline">
                <Stack spacing="4px" data-id="date-range-picker--calendar">
                    <DatePicker
                        type="range"
                        allowSingleDateInRange
                        minDate={minDate ?? undefined}
                        maxDate={maxDate ?? undefined}
                        value={selectedDateRange}
                        onChange={handleOnChange}
                        date={date ?? undefined}
                        onDateChange={setDate}
                    />
                </Stack>
            </Group>
        </Stack>
    );
};
