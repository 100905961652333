import { Text } from "@mantine/core";
import { TooltipContainer } from "../_utils/utils";
import { ReactElement } from "react";

export type HeatmapTooltipData = {
    data: { row: number; column: number; count: number | null };
};

type HeatmapTooltipProps = {
    tooltipLeft: number | undefined;
    tooltipTop: number | undefined;
    tooltipData: HeatmapTooltipData | undefined;
};
export const Tooltip = ({
    tooltipLeft,
    tooltipTop,
    tooltipData,
}: HeatmapTooltipProps): ReactElement | null => {
    const showTooltip = tooltipData && tooltipLeft && tooltipTop;
    return showTooltip ? (
        <TooltipContainer tooltipTop={tooltipTop} tooltipLeft={tooltipLeft}>
            <Text size="xs">{tooltipData.data.row}</Text>
            <Text size="xs">{tooltipData.data.column}</Text>
            <Text size="xs">{tooltipData.data.count}</Text>
        </TooltipContainer>
    ) : null;
};
