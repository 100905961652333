import { Column } from "./types";
import { MRT_ColumnDef } from "mantine-react-table";
import { addCellToColumn, addHeaderToColumn, getBaseColumn } from "./utils";
import { RowData } from "../types";

export const getMRTColumnsFromColumns = <T extends RowData>(
    columns: Array<Column<T>>,
): Array<MRT_ColumnDef<T>> => {
    const mrtColumns: Array<MRT_ColumnDef<T>> = columns.map(
        (column: Column<T>): MRT_ColumnDef<T> => {
            const baseColumn: MRT_ColumnDef<T> = getBaseColumn(column);
            const columnWithCell: MRT_ColumnDef<T> = addCellToColumn(baseColumn, column);
            const finalColumn: MRT_ColumnDef<T> = addHeaderToColumn(columnWithCell, column);

            return finalColumn;
        },
    );

    return mrtColumns;
};
