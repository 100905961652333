import { ReactNode } from "react";
import { labelToDataId } from "@flexidao/ui-lib";
import { CSSObject, Group, MantineNumberSize, Paper, Sx, Text } from "@mantine/core";
import { TooltipWithBounds } from "@visx/tooltip";

export const INACTIVE_LEGEND_OPACITY = 0.3;
export const AM_LABEL_HOUR = 6;
export const PM_LABEL_HOUR = 18;

export type ChartMargin = {
    top: number;
    right: number;
    bottom: number;
    left: number;
};

export type ChartDimensions = {
    margin: ChartMargin;
    parentHeight: number;
    parentWidth: number;
    innerHeight: number;
    innerWidth: number;
};

export type ChartDisplayOptions = {
    showAxes: {
        left: boolean;
        right: boolean;
        bottom: boolean;
    };
    showTooltip: boolean;
    showLegend: boolean;
    justifyLegend: "start" | "center" | "end";
    showGrid: boolean;
};

export const defaultChartDisplayOptions: ChartDisplayOptions = {
    showAxes: {
        left: true,
        bottom: true,
        right: false,
    },
    showTooltip: true,
    showLegend: true,
    justifyLegend: "start",
    showGrid: false,
};
export const AXIS_FONT_SIZE = 12;
export const LEGEND_FONT_SIZE: MantineNumberSize = 10;

type LegendDotWithLabelProps = {
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    sx?: Sx;
    text: string;
    color: string;
};
export const LegendDotWithLabel = ({
    onClick,
    text,
    color,
    sx = {},
}: LegendDotWithLabelProps): JSX.Element => {
    return (
        <Group
            key={`legend-${text.replace(" ", "-")}`}
            onClick={onClick}
            sx={sx}
            spacing={4}
            data-id={labelToDataId({
                prefix: "legend-item-dot",
                label: text,
            })}
        >
            <svg width={12} height={12}>
                <circle fill={color} r={6} cx={6} cy={6} />
            </svg>
            <Text fz={LEGEND_FONT_SIZE}>{text}</Text>
        </Group>
    );
};

type LegendLineWithLabelProps = {
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    sx?: Sx;
    text: string;
    color: string;
};
export const LegendLineWithLabel = ({
    onClick,
    text,
    color,
    sx = {},
}: LegendLineWithLabelProps): JSX.Element => {
    return (
        <Group
            key={`legend-${text.replace(" ", "-")}`}
            onClick={onClick}
            sx={sx}
            spacing={4}
            data-id={labelToDataId({
                prefix: "histogram-chart--legend-item-line",
                label: text,
            })}
        >
            <svg width={12} height={12}>
                <rect fill={color} height={2} width={3} x={0} y={5} rx={1} />
                <rect fill={color} height={2} width={4} x={4} y={5} rx={1} />
                <rect fill={color} height={2} width={3} x={9} y={5} rx={1} />
            </svg>
            <Text fz={LEGEND_FONT_SIZE}>{text}</Text>
        </Group>
    );
};

type TooltipContainerProps = {
    children: ReactNode;
    tooltipTop: number;
    tooltipLeft: number;
    pad?: boolean;
};

const TOOLTIP_TOP_OFFSET = 12;
const TOOLTIP_LEFT_OFFSET = 2;
const TOOLTIP_MIN_WIDTH = 120;
const TOOLTIP_Z_INDEX = 101;

export const TooltipContainer = ({
    children,
    tooltipTop,
    tooltipLeft,
    pad = true,
}: TooltipContainerProps): JSX.Element => {
    return (
        <TooltipWithBounds
            key={Math.random()}
            top={tooltipTop - TOOLTIP_TOP_OFFSET}
            left={tooltipLeft + TOOLTIP_LEFT_OFFSET}
            style={{
                position: "absolute",
                minWidth: 60,
                minHeight: 80,
                pointerEvents: "none",
            }}
        >
            <Paper
                sx={(theme): CSSObject => ({
                    backgroundColor: theme.white,
                    padding: pad ? theme.spacing.lg : 0,
                    zIndex: TOOLTIP_Z_INDEX,
                })}
                shadow="xl"
                miw={TOOLTIP_MIN_WIDTH}
                data-id="chart--tooltip"
            >
                {children}
            </Paper>
        </TooltipWithBounds>
    );
};

export const getDefaultRightAxisTickLabelProps = (
    axisColor: string,
    axisFontSize: number,
): {
    fill: string;
    fontSize: number;
    textAnchor: string;
    dy: string;
    dx: string;
} => {
    return {
        fill: axisColor,
        fontSize: axisFontSize,
        textAnchor: "start",
        dy: "0.33em",
        dx: "0.5em",
    };
};
