import { useAuth0 } from "@auth0/auth0-react";
import { Anchor, Button, Center, Container, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconBrandGmail, IconBrandWindows, IconHelp, IconLogout } from "@tabler/icons-react";
import { useSearchParams } from "react-router-dom";
import { ReactElement } from "react";

type LoginErrorCustomPageProps = {
    message: string;
};

export const LoginErrorCustomPage = ({ message }: LoginErrorCustomPageProps): ReactElement => {
    const { logout } = useAuth0();
    const [searchParams] = useSearchParams();

    const errorQueryParam = searchParams.get("error");

    const error =
        message.includes("403") || errorQueryParam === "access_denied" ? "Unauthorized" : message;

    return (
        <Container size="sm" h="calc(100vh - 4rem)">
            <Center h="100%">
                <Paper>
                    <Stack align="flex-start">
                        <Title order={1}>Login Error</Title>
                        <Text>Error: {error}</Text>
                        <Text>Please contact the Flexidao team if problems persist.</Text>
                        <Group>
                            <Button
                                uppercase
                                variant="outline"
                                onClick={(): void => logout()}
                                leftIcon={<IconLogout size={20} />}
                            >
                                Logout
                            </Button>
                            <Anchor
                                href="https://flexidao.atlassian.net/servicedesk/customer/portal/1"
                                target="_blank"
                            >
                                <Button
                                    variant="outline"
                                    uppercase
                                    color="blue"
                                    leftIcon={<IconHelp />}
                                >
                                    Contact Us
                                </Button>
                            </Anchor>
                            <Anchor href="https://mail.google.com" target="_blank">
                                <Button uppercase leftIcon={<IconBrandGmail />} variant="outline">
                                    Gmail
                                </Button>
                            </Anchor>
                            <Anchor href="https://outlook.office.com" target="_blank">
                                <Button uppercase leftIcon={<IconBrandWindows />} variant="outline">
                                    Outlook
                                </Button>
                            </Anchor>
                        </Group>
                    </Stack>
                </Paper>
            </Center>
        </Container>
    );
};
