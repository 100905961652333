import * as D from "schemawax";

/**
 * This type is a readonly version of the given array type.
 *
 * @param First The first element of the array.
 * @param Rest The rest of the elements of the array.
 *
 * @returns The readonly version of the given array type.
 */
type ReadonlyArray<First, Rest extends unknown[]> = readonly [
    DeepReadonly<First>,
    ...DeepReadonly<Rest>,
];

/**
 * This type is a readonly version of the given object type.
 *
 * @param T The object type to make readonly.
 *
 * @returns The readonly version of the given object type.
 */
type ReadonlyObject<T> = {
    readonly [Key in keyof T]: DeepReadonly<T[Key]>;
};

/**
 * This type is a readonly version of the given function type.
 *
 * @param T The function type to make readonly.
 *
 * @returns The readonly version of the given function type.
 */
type ReadonlyFunction<T> = T;

/**
 * This type is a deep readonly version of the given type.
 *
 * @param T The type to make readonly.
 *
 * @returns The readonly version of the given type.
 */
type DeepReadonly<T> = T extends object
    ? T extends [infer First, ...infer Rest]
        ? ReadonlyArray<First, Rest>
        : T extends (...args: never[]) => unknown
          ? ReadonlyFunction<T>
          : ReadonlyObject<T>
    : T;

/**
 * This type is a prettified version of the given type.
 *
 * @param T The type to prettify.
 *
 * @returns The prettified version of the given type.
 */
type Prettify<T> = {
    [K in keyof T]: T[K];
};

/**
 * This type checks if the two given types are equal.
 * If they are equal, it returns the first type.
 * If they are not equal, it returns an error object.
 *
 * Two types are equal if their DeepReadonly versions are assignable to each other.
 *
 * @param Left The first type to check.
 * @param Right The second type to check.
 * @param FailLeftExtendsRight The error object to return if the first type is not assignable to the second type.
 * @param FailRightExtendsLeft The error object to return if the second type is not assignable to the first type.
 *
 * @returns The first type if the two types are equal, otherwise an error object.
 */
export type TypesAreEqual<
    Left,
    Right,
    FailLeftExtendsRight = {
        error: "The first type cannot be assigned to the second type";
        firstType: Prettify<Left>;
        secondType: Prettify<Right>;
    },
    FailRightExtendsLeft = {
        error: "The second type cannot be assigned to the first type";
        firstType: Prettify<Left>;
        secondType: Prettify<Right>;
    },
> = [DeepReadonly<Left>] extends [DeepReadonly<Right>] //
    ? [DeepReadonly<Right>] extends [DeepReadonly<Left>]
        ? Left
        : FailRightExtendsLeft
    : FailLeftExtendsRight;

/**
 * This type is a strict version of the given decoder type.
 * It checks if the decoder output and the given type are mutually assignable.
 *
 * @param T The type to decode.
 * @param DI The decoder implementation to validate.
 *
 * @returns T if the decoder output and the given type are mutually assignable, otherwise an error object.
 */
export type StrictDecoder<T, DI> =
    DI extends D.Decoder<infer U>
        ? D.Decoder<
              TypesAreEqual<
                  T,
                  U,
                  {
                      error: "Decoder output is not assignable to given type";
                      decoderOutput: Prettify<T>;
                      typeToDecode: Prettify<U>;
                  },
                  {
                      error: "Given type is not assignable to decoder output";
                      decoderOutput: Prettify<U>;
                      typeToDecode: Prettify<T>;
                  }
              >
          >
        : never;

/**
 * This function validates a strict decoder.
 * It checks if the decoder output and the given type are mutually assignable in compile time.
 *
 * @returns A function that validates a strict decoder.
 */
export const validateStrictDecoder = <TypeToValidate>() => {
    return <DecoderImplementation>(
        v: StrictDecoder<DecoderImplementation, D.Decoder<TypeToValidate>>,
    ): StrictDecoder<DecoderImplementation, D.Decoder<TypeToValidate>> => v;
};
