import { Text } from "@mantine/core";
import { TooltipContainer } from "../_utils/utils";
import { PieChartItem } from "./piechart";
import { ReactElement } from "react";

export type PieChartTooltipData = {
    data: PieChartItem | undefined;
};

type PieChartTooltipProps = {
    tooltipLeft: number | undefined;
    tooltipTop: number | undefined;
    tooltipData: PieChartTooltipData | undefined;
};
export const Tooltip = ({
    tooltipLeft,
    tooltipTop,
    tooltipData,
}: PieChartTooltipProps): ReactElement | null => {
    const showTooltip = tooltipData && tooltipTop && tooltipLeft;
    return showTooltip ? (
        <TooltipContainer tooltipTop={tooltipTop} tooltipLeft={tooltipLeft}>
            <Text size="xs">{tooltipData.data?.label}</Text>
            <Text size="xs">{tooltipData.data?.value}</Text>
        </TooltipContainer>
    ) : null;
};
