import { ReactElement, ReactNode } from "react";
import { getClassNameForSubRow } from "./utils";

type CustomCellProps = {
    children: ReactNode;
    isSubRow?: boolean;
};

export const CustomCell = ({ children, isSubRow = false }: CustomCellProps): ReactElement => {
    return <div className={getClassNameForSubRow(isSubRow)}>{children}</div>;
};
