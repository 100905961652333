const US_ENGLISH_LOCALE = "en-US";

const addUnitsToFormattedValue = (formattedValue: string, units?: string): string => {
    return units ? `${formattedValue} ${units}` : formattedValue;
};

export const formatNumber = (
    value: number | null,
    numDecimals: number = 0,
    units?: string,
): string => {
    if (value == null || isNaN(value)) {
        return "-";
    }

    const formattedValue: string = value.toLocaleString(US_ENGLISH_LOCALE, {
        minimumFractionDigits: numDecimals,
        maximumFractionDigits: numDecimals,
    });

    // If the formatted value is -0 (with N decimals), we want to return 0 (with N decimals)
    const zeroString: string = numDecimals === 0 ? "0" : `0.${"0".repeat(numDecimals)}`;
    const negativeZeroString: string = `-${zeroString}`;
    if (formattedValue === negativeZeroString) {
        return addUnitsToFormattedValue(zeroString, units);
    }

    // Else, return the formatted value
    return addUnitsToFormattedValue(formattedValue, units);
};
