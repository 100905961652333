import { formatDateHour, formatNumber, HOUR_MS, MINUTES_IN_HOUR, round } from "@flexidao/ui-lib";
import { NumberValue } from "@visx/vendor/d3-scale";
import { StackedBarChartData } from "@flexidao/ui-lib/components/data-display/charts/stacked-bar-chart/types";

export const formatDate = ({ date }: { date: Date }): string => formatDateHour(date, "Etc/UTC");

export const yTickFormatter = (value: NumberValue): string => formatNumber(value.valueOf(), 0);

export const getXDomain = ([start, end]: [Date, Date]): Array<string> => {
    const hours: Array<string> = [];

    for (let i = start.getTime(); i <= end.getTime(); i += HOUR_MS) {
        hours.push(formatDate({ date: new Date(i) }));
    }

    return hours;
};

export const getYDomain = <T extends Record<string, number>>(
    data: StackedBarChartData<T>,
    activeKeys: Array<Extract<string, keyof T>>,
): [number, number] => [
    0,
    Math.max(...data.map((d) => activeKeys.reduce((acc, key) => acc + (d.values[key] ?? 0), 0))),
];

export const roundDateToClosestHour = (date: Date): Date => {
    const roundedDate = new Date(date);

    roundedDate.setHours(
        roundedDate.getHours() + round(roundedDate.getMinutes() / MINUTES_IN_HOUR),
    );
    roundedDate.setMinutes(0, 0, 0);

    return roundedDate;
};
