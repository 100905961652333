export const ONE_HUNDRED = 100;
export const ONE_THOUSAND = 1_000;
export const ONE_MILLION = 1_000_000;
export const ONE_BILLION = 1_000_000_000;
export const ONE_TRILLION = 1_000_000_000_000;

export const DAYS_IN_YEAR = 365;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_TWO_WEEKS = 14;
export const WEEKS_IN_MONTH = 4;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const MONTHS_IN_YEAR = 12;

export const SECOND_MS = ONE_THOUSAND;
export const MINUTE_MS = SECONDS_IN_MINUTE * SECOND_MS;
export const HOUR_MS = MINUTES_IN_HOUR * MINUTE_MS;
export const DAY_MS = HOURS_IN_DAY * HOUR_MS;
export const ONE_WEEK_MS = DAYS_IN_WEEK * DAY_MS;
export const TWO_WEEKS_MS = DAYS_IN_TWO_WEEKS * DAY_MS;

export const FOUR_WEEKS_MS = WEEKS_IN_MONTH * ONE_WEEK_MS;
export const ONE_YEAR_MS = DAYS_IN_YEAR * DAY_MS;

export const TWO_YEARS = 2;
export const TWO_YEAR_MS = TWO_YEARS * ONE_YEAR_MS;

export const NAVBAR_WIDTH = 240;
export const H1_FONT_SIZE_PX = 30;
export const H2_FONT_SIZE_PX = 24;
export const H3_FONT_SIZE_PX = 20;
export const H4_FONT_SIZE_PX = 18;

export const NO_DECIMALS = 0;
export const ENERGY_NUM_DECIMALS = 2;
export const CURRENCY_NUM_DECIMALS = 2;
export const PERCENTAGE_NUM_DECIMALS = 1;
