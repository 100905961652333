import {
    DataGranularity,
    MeterDataDto,
    meterDataPaths,
    MeterReadingTypeEnum,
    SanityWarning,
} from "@flexidao/dto";
import { convertDateUTCToISOString, ONE_DAY_MS, voidDecoder } from "@flexidao/helpers";
import { ApiClient } from "../api-client";
import {
    activeSitesKpiDecoder,
    basicMeterArrayDecoder,
    biddingZoneOptionArrayDecoder,
    consumptionBySiteDecoder,
    dataCompletenessDecoder,
    getBillingMetersFiltersResponseDecoder,
    getBillingMetersResponseDecoder,
    getIntervalMetersFiltersResponseDecoder,
    getIntervalMetersResponseDecoder,
    hourlyAggregatedMeterDataArrayDecoder,
    hourlyAggregatedWarningsPerSiteArrayDecoder,
    hourlyMeterWarningsArrayDecoder,
    hourlySiteWarningsArrayDecoder,
    meterDataSiteBreakdownDecoder,
    meterOptionArrayDecoder,
    monitoringSiteDetailsKpisDecoder,
    providerOptionArrayDecoder,
    regionOptionArrayDecoder,
    siteOptionArrayDecoder,
    totalConsumptionAndProductionKpiDecoder,
    warningTypeArrayDecoder,
} from "../decoders";
import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash } from "../utils";

type GetAccessToken = () => Promise<string>;

type PutSitesBySiteIdMetersByTenantId = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    siteId: string;
    meterIds: Array<number>;
};

type GetAvailableMetersByTenantId = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    siteId?: string;
};

export type GetMeterDataByTenantIdSiteBreakdownArgs<T> = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    regionIds: Array<string> | null;
    biddingZoneIds: Array<string> | null;
    siteIds: Array<string> | null;
    page: number;
    pageSize: number;
    sorting?: {
        columnId: Extract<keyof T, string>;
        direction: "asc" | "desc";
    } | null;
    filters?: Record<string, string | null | Array<string | null>> | null;
};

export type GetActiveSitesKpiByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    regionIds: Array<string> | null;
    biddingZoneIds: Array<string> | null;
    siteIds: Array<string> | null;
};

export type GetTotalConsumptionAndProductionKpiByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    regionIds: Array<string> | null;
    biddingZoneIds: Array<string> | null;
    siteIds: Array<string> | null;
};

export type GetHourlyAggregatedMeterDataByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    regionIds: Array<string> | null;
    biddingZoneIds: Array<string> | null;
    siteIds: Array<string> | null;
    granularity: DataGranularity | null;
};

export type GetDataCompletenessByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    regionIds: Array<string> | null;
    countryIds: Array<string> | null;
    biddingZoneIds: Array<string> | null;
    siteIds: Array<string> | null;
};

export type GetAggregatedWarningsPerSiteByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    siteIds: Array<string>;
};

export type GetRegionsByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
};

export type GetBiddingZonesByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    regionIds?: Array<string>;
};

export type GetSitesByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    regionIds?: Array<string>;
    biddingZoneIds?: Array<string>;
};

export type GetProvidersByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTime: Date;
    endTime: Date;
    regionIds?: Array<string>;
    biddingZoneIds?: Array<string>;
    siteIds?: Array<string>;
};

export type GetConsumptionBySiteByTenantIdArgs = {
    getAccessToken: GetAccessToken;
    tenantId: string;
    startTimeLocal: Date;
    endTimeLocal: Date;
    page: number;
    biddingZoneIds?: Array<string>;
    consumptionSiteGroupIds?: Array<string>;
    orderBy?: "consumptionWh" | "siteName";
    orderDir?: "asc" | "desc";
    readingTypes?: Array<MeterReadingTypeEnum>;
};

export type GetIntervalMetersByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetIntervalMetersPayload;

export type GetIntervalMetersFiltersByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetIntervalMetersFiltersPayload;

export type GetBillingMetersByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetBillingMetersPayload;

export type GetBillingMetersFiltersByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetBillingMetersFiltersPayload;

export type GetWarningTypeOptionsByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetWarningTypeOptionsPayload;

export type GetMeterOptionsByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetMeterOptionsPayload;

export type GetMonitoringSiteDetailsKpisByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetMonitoringSiteDetailsKpisByTenantIdPayload;

export type GetMonitoringSiteDetailsKpisByTenantIdResponse = MeterDataDto.MonitoringSiteDetailsKpis;

export type GetHourlyMeterWarningsByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetHourlyMeterWarningsPayload;

export type GetHourlySiteWarningsByTenantIdArgs = {
    getAccessToken: GetAccessToken;
} & MeterDataDto.GetHourlySiteWarningsPayload;

type MeterDataPaths = Pick<
    meterDataPaths,
    | "/{tenantId}/meters"
    | "/{tenantId}/interval-meters"
    | "/{tenantId}/interval-meters-filters"
    | "/{tenantId}/billing-meters"
    | "/{tenantId}/billing-meters-filters"
    | "/{tenantId}/meters/available"
    | "/{tenantId}/sites/{siteId}/meters"
    | "/{tenantId}/sites/active-kpi"
    | "/{tenantId}/total-consumption-and-production-kpi"
    | "/{tenantId}/site-breakdown"
    | "/{tenantId}/regions"
    | "/{tenantId}/bidding-zones"
    | "/{tenantId}/sites"
    | "/{tenantId}/providers"
    | "/{tenantId}/hourly-aggregated-meter-data"
    | "/{tenantId}/data-completeness"
    | "/{tenantId}/aggregated-warnings-per-site"
    | "/{tenantId}/consumption-by-site"
    | "/{tenantId}/warning-type-options"
    | "/{tenantId}/meter-options"
    | "/{tenantId}/hourly-meter-warnings"
    | "/{tenantId}/hourly-site-warnings"
>;

export type OverloadMeterDataClient = {
    getMetersAvailableByTenantId: (
        args: GetAvailableMetersByTenantId,
    ) => OverloadApiResponse<Array<MeterDataDto.BasicMeter>>;
    getIntervalMetersByTenantId: (
        args: GetIntervalMetersByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.GetIntervalMetersResponse>;
    getIntervalMetersFiltersByTenantId: (
        args: GetIntervalMetersFiltersByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.GetIntervalMetersFiltersResponse>;
    getBillingMetersByTenantId: (
        args: GetBillingMetersByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.GetBillingMetersResponse>;
    getBillingMetersFiltersByTenantId: (
        args: GetBillingMetersFiltersByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.GetBillingMetersFiltersResponse>;
    putSitesBySiteIdMetersByTenantId: (
        args: PutSitesBySiteIdMetersByTenantId,
    ) => OverloadApiResponse<void>;
    getSiteBreakdownByTenantId: <T>(
        args: GetMeterDataByTenantIdSiteBreakdownArgs<T>,
    ) => OverloadApiResponse<MeterDataDto.MeterDataSiteBreakdown>;
    getSitesActiveKpiByTenantId: (
        args: GetActiveSitesKpiByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.ActiveSitesKpi>;
    getTotalConsumptionAndProductionKpiByTenantId: (
        args: GetTotalConsumptionAndProductionKpiByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.TotalConsumptionAndProductionKpi>;
    getRegionsByTenantId: (
        args: GetRegionsByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.RegionOption>>;
    getBiddingZonesByTenantId: (
        args: GetBiddingZonesByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.BiddingZoneOption>>;
    getSitesByTenantId: (
        args: GetSitesByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.SiteOption>>;
    getProvidersByTenantId: (
        args: GetProvidersByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.ProviderOption>>;
    getHourlyAggregatedMeterDataByTenantId: (
        args: GetHourlyAggregatedMeterDataByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.HourlyAggregatedMeterData>>;
    getDataCompletenessByTenantId: (
        args: GetDataCompletenessByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.DataCompleteness>;
    getAggregatedWarningsPerSiteByTenantId: (
        args: GetAggregatedWarningsPerSiteByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.AggregatedWarningsPerSite>>;
    getConsumptionBySiteByTenantId: (
        args: GetConsumptionBySiteByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.ConsumptionBySite>;
    getWarningTypeOptionsByTenantId: (
        args: GetWarningTypeOptionsByTenantIdArgs,
    ) => OverloadApiResponse<Array<SanityWarning>>;
    getMeterOptionsByTenantId: (
        args: GetMeterOptionsByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.MeterOption>>;
    getMonitoringSiteDetailsKpisByTenantId: (
        args: GetMonitoringSiteDetailsKpisByTenantIdArgs,
    ) => OverloadApiResponse<MeterDataDto.MonitoringSiteDetailsKpis>;
    getHourlyMeterWarningsByTenantId: (
        args: GetHourlyMeterWarningsByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.SiteHourlyMeterWarnings>>;
    getHourlySiteWarningsByTenantId: (
        args: GetHourlySiteWarningsByTenantIdArgs,
    ) => OverloadApiResponse<Array<MeterDataDto.SiteHourlySiteWarnings>>;
} & ApiClient<MeterDataPaths>;

export type MeterDataClient = {
    getMetersAvailableByTenantId: (
        args: GetAvailableMetersByTenantId,
    ) => ApiResponse<Array<MeterDataDto.BasicMeter>>;
    getIntervalMetersByTenantId: (
        args: GetIntervalMetersByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.GetIntervalMetersResponse>;
    getIntervalMetersFiltersByTenantId: (
        args: GetIntervalMetersFiltersByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.GetIntervalMetersFiltersResponse>;
    getBillingMetersByTenantId: (
        args: GetBillingMetersByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.GetBillingMetersResponse>;
    getBillingMetersFiltersByTenantId: (
        args: GetBillingMetersFiltersByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.GetBillingMetersFiltersResponse>;
    putSitesBySiteIdMetersByTenantId: (args: PutSitesBySiteIdMetersByTenantId) => ApiResponse<void>;
    getSiteBreakdownByTenantId: <T>(
        args: GetMeterDataByTenantIdSiteBreakdownArgs<T>,
    ) => ApiResponse<MeterDataDto.MeterDataSiteBreakdown>;
    getSitesActiveKpiByTenantId: (
        args: GetActiveSitesKpiByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.ActiveSitesKpi>;
    getTotalConsumptionAndProductionKpiByTenantId: (
        args: GetTotalConsumptionAndProductionKpiByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.TotalConsumptionAndProductionKpi>;
    getRegionsByTenantId: (
        args: GetRegionsByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.RegionOption>>;
    getBiddingZonesByTenantId: (
        args: GetBiddingZonesByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.BiddingZoneOption>>;
    getSitesByTenantId: (
        args: GetSitesByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.SiteOption>>;
    getProvidersByTenantId: (
        args: GetProvidersByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.ProviderOption>>;
    getHourlyAggregatedMeterDataByTenantId: (
        args: GetHourlyAggregatedMeterDataByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.HourlyAggregatedMeterData>>;
    getDataCompletenessByTenantId: (
        args: GetDataCompletenessByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.DataCompleteness>;
    getAggregatedWarningsPerSiteByTenantId: (
        args: GetAggregatedWarningsPerSiteByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.AggregatedWarningsPerSite>>;
    getConsumptionBySiteByTenantId: (
        args: GetConsumptionBySiteByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.ConsumptionBySite>;
    getWarningTypeOptionsByTenantId: (
        args: GetWarningTypeOptionsByTenantIdArgs,
    ) => ApiResponse<Array<SanityWarning>>;
    getMeterOptionsByTenantId: (
        args: GetMeterOptionsByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.MeterOption>>;
    getMonitoringSiteDetailsKpisByTenantId: (
        args: GetMonitoringSiteDetailsKpisByTenantIdArgs,
    ) => ApiResponse<MeterDataDto.MonitoringSiteDetailsKpis>;
    getHourlyMeterWarningsByTenantId: (
        args: GetHourlyMeterWarningsByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.SiteHourlyMeterWarnings>>;
    getHourlySiteWarningsByTenantId: (
        args: GetHourlySiteWarningsByTenantIdArgs,
    ) => ApiResponse<Array<MeterDataDto.SiteHourlySiteWarnings>>;
} & ApiClient<MeterDataPaths>;

export type ResultMeterDataClient = {
    getMetersAvailableByTenantId: (
        args: GetAvailableMetersByTenantId,
    ) => ApiResultResponse<Array<MeterDataDto.BasicMeter>>;
    getIntervalMetersByTenantId: (
        args: GetIntervalMetersByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.GetIntervalMetersResponse>;
    getIntervalMetersFiltersByTenantId: (
        args: GetIntervalMetersFiltersByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.GetIntervalMetersFiltersResponse>;
    getBillingMetersByTenantId: (
        args: GetBillingMetersByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.GetBillingMetersResponse>;
    getBillingMetersFiltersByTenantId: (
        args: GetBillingMetersFiltersByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.GetBillingMetersFiltersResponse>;
    putSitesBySiteIdMetersByTenantId: (
        args: PutSitesBySiteIdMetersByTenantId,
    ) => ApiResultResponse<void>;
    getSiteBreakdownByTenantId: <T>(
        args: GetMeterDataByTenantIdSiteBreakdownArgs<T>,
    ) => ApiResultResponse<MeterDataDto.MeterDataSiteBreakdown>;
    getSitesActiveKpiByTenantId: (
        args: GetActiveSitesKpiByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.ActiveSitesKpi>;
    getProvidersByTenantId: (
        args: GetProvidersByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.ProviderOption>>;
    getTotalConsumptionAndProductionKpiByTenantId: (
        args: GetTotalConsumptionAndProductionKpiByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.TotalConsumptionAndProductionKpi>;
    getRegionsByTenantId: (
        args: GetRegionsByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.RegionOption>>;
    getBiddingZonesByTenantId: (
        args: GetBiddingZonesByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.BiddingZoneOption>>;
    getSitesByTenantId: (
        args: GetSitesByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.SiteOption>>;
    getHourlyAggregatedMeterDataByTenantId: (
        args: GetHourlyAggregatedMeterDataByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.HourlyAggregatedMeterData>>;
    getDataCompletenessByTenantId: (
        args: GetDataCompletenessByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.DataCompleteness>;
    getAggregatedWarningsPerSiteByTenantId: (
        args: GetAggregatedWarningsPerSiteByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.AggregatedWarningsPerSite>>;
    getConsumptionBySiteByTenantId: (
        args: GetConsumptionBySiteByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.ConsumptionBySite>;
    getWarningTypeOptionsByTenantId: (
        args: GetWarningTypeOptionsByTenantIdArgs,
    ) => ApiResultResponse<Array<SanityWarning>>;
    getMeterOptionsByTenantId: (
        args: GetMeterOptionsByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.MeterOption>>;
    getMonitoringSiteDetailsKpisByTenantId: (
        args: GetMonitoringSiteDetailsKpisByTenantIdArgs,
    ) => ApiResultResponse<MeterDataDto.MonitoringSiteDetailsKpis>;
    getHourlyMeterWarningsByTenantId: (
        args: GetHourlyMeterWarningsByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.SiteHourlyMeterWarnings>>;
    getHourlySiteWarningsByTenantId: (
        args: GetHourlySiteWarningsByTenantIdArgs,
    ) => ApiResultResponse<Array<MeterDataDto.SiteHourlySiteWarnings>>;
} & ApiClient<MeterDataPaths>;

export function getMeterDataClient(config: { useRetries?: boolean }): MeterDataClient;
export function getMeterDataClient(config: { useResult: boolean }): ResultMeterDataClient;
export function getMeterDataClient(config: {
    useResult?: boolean;
    useRetries?: boolean;
}): OverloadMeterDataClient {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        if (process.env.MOCK_METER_DATA == "true") {
            useMockData = true;
        }
        if (!process.env.METER_DATA_URL) {
            return crash("Missing METER_DATA_URL environment variable.");
        } else {
            url = process.env.METER_DATA_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buildQueries({ ...config, useMockData: useMockData }, url);

    const { get, put } = buildQueries({ ...config, useMockData: useMockData }, url);

    return {
        getMetersByTenantId: async () => Promise.reject("Not used in UI"),
        postMetersByTenantId: async () => Promise.reject("Not used in UI"),
        getHourlyMeterWarningsByTenantId: async ({
            getAccessToken,
            tenantId,
            startTimeLocal,
            endTimeLocal,
            siteId,
        }: GetHourlyMeterWarningsByTenantIdArgs): OverloadApiResponse<
            Array<MeterDataDto.SiteHourlyMeterWarnings>
        > => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/hourly-meter-warnings`,
                decoder: hourlyMeterWarningsArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTimeLocal),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTimeLocal.getTime() + ONE_DAY_MS),
                    ),
                    siteId,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getHourlySiteWarningsByTenantId: async ({
            getAccessToken,
            tenantId,
            startTimeLocal,
            endTimeLocal,
            siteId,
        }: GetHourlySiteWarningsByTenantIdArgs): OverloadApiResponse<
            Array<MeterDataDto.SiteHourlySiteWarnings>
        > => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/hourly-site-warnings`,
                decoder: hourlySiteWarningsArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTimeLocal),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTimeLocal.getTime() + ONE_DAY_MS),
                    ),
                    siteId,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getMonitoringSiteDetailsKpisByTenantId: async ({
            getAccessToken,
            tenantId,
            startTimeLocal,
            endTimeLocal,
            siteId,
        }: GetMonitoringSiteDetailsKpisByTenantIdArgs): OverloadApiResponse<MeterDataDto.MonitoringSiteDetailsKpis> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/monitoring-site-details-kpis`,
                decoder: monitoringSiteDetailsKpisDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTimeLocal),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTimeLocal.getTime() + ONE_DAY_MS),
                    ),
                    siteId,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getMeterOptionsByTenantId: async ({
            getAccessToken,
            tenantId,
            siteId,
            startTimeLocal,
            endTimeLocal,
            warningType,
        }: GetMeterOptionsByTenantIdArgs): OverloadApiResponse<Array<MeterDataDto.MeterOption>> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/meter-options`,
                decoder: meterOptionArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTimeLocal),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTimeLocal.getTime() + ONE_DAY_MS),
                    ),
                    siteId,
                    ...(warningType && { warningType }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getWarningTypeOptionsByTenantId: async ({
            getAccessToken,
            tenantId,
            siteId,
            startTimeLocal,
            endTimeLocal,
            meterId,
        }: GetWarningTypeOptionsByTenantIdArgs): OverloadApiResponse<Array<SanityWarning>> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/warning-type-options`,
                decoder: warningTypeArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTimeLocal),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTimeLocal.getTime() + ONE_DAY_MS),
                    ),
                    siteId,
                    ...(meterId && { meterId }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getMetersAvailableByTenantId: async ({
            getAccessToken,
            tenantId,
            siteId,
        }: GetAvailableMetersByTenantId): OverloadApiResponse<Array<MeterDataDto.BasicMeter>> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/meters/available`,
                decoder: basicMeterArrayDecoder,
                params: {
                    ...(siteId && { siteId }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getIntervalMetersByTenantId: async ({
            getAccessToken,
            tenantId,
            ...params
        }: GetIntervalMetersByTenantIdArgs): OverloadApiResponse<MeterDataDto.GetIntervalMetersResponse> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/interval-meters`,
                decoder: getIntervalMetersResponseDecoder,
                params,
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getIntervalMetersFiltersByTenantId: async ({
            getAccessToken,
            tenantId,
        }: GetIntervalMetersFiltersByTenantIdArgs): OverloadApiResponse<MeterDataDto.GetIntervalMetersFiltersResponse> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/interval-meters-filters`,
                decoder: getIntervalMetersFiltersResponseDecoder,
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getBillingMetersByTenantId: async ({
            getAccessToken,
            tenantId,
            ...params
        }: GetBillingMetersByTenantIdArgs): OverloadApiResponse<MeterDataDto.GetBillingMetersResponse> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/billing-meters`,
                decoder: getBillingMetersResponseDecoder,
                params,
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getBillingMetersFiltersByTenantId: async ({
            getAccessToken,
            tenantId,
        }: GetBillingMetersFiltersByTenantIdArgs): OverloadApiResponse<MeterDataDto.GetBillingMetersFiltersResponse> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/billing-meters-filters`,
                decoder: getBillingMetersFiltersResponseDecoder,
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        putSitesBySiteIdMetersByTenantId: async ({
            getAccessToken,
            tenantId,
            siteId,
            meterIds,
        }: PutSitesBySiteIdMetersByTenantId): OverloadApiResponse<void> => {
            const accessToken: string = await getAccessToken();

            return put({
                path: `/api/meter-data/${tenantId}/sites/${siteId}/meters`,
                data: { meterIds },
                decoder: voidDecoder,
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getSiteBreakdownByTenantId: async <T>({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
            biddingZoneIds,
            siteIds,
            page,
            pageSize,
            sorting,
            filters,
        }: GetMeterDataByTenantIdSiteBreakdownArgs<T>): OverloadApiResponse<MeterDataDto.MeterDataSiteBreakdown> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/site-breakdown`,
                decoder: meterDataSiteBreakdownDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    ...(regionIds && { regionIds }),
                    ...(biddingZoneIds && { biddingZoneIds }),
                    ...(siteIds && { siteIds }),
                    page,
                    pageSize,
                    ...(sorting && {
                        orderBy: sorting.columnId,
                        orderDirection: sorting.direction,
                    }),
                    ...(filters && { filters }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getSitesActiveKpiByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
            biddingZoneIds,
            siteIds,
        }: GetActiveSitesKpiByTenantIdArgs): OverloadApiResponse<MeterDataDto.ActiveSitesKpi> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/sites/active-kpi`,
                decoder: activeSitesKpiDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    ...(regionIds && { regionIds }),
                    ...(biddingZoneIds && { biddingZoneIds }),
                    ...(siteIds && { siteIds }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getTotalConsumptionAndProductionKpiByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
            biddingZoneIds,
            siteIds,
        }: GetTotalConsumptionAndProductionKpiByTenantIdArgs): OverloadApiResponse<MeterDataDto.TotalConsumptionAndProductionKpi> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/total-consumption-and-production-kpi`,
                decoder: totalConsumptionAndProductionKpiDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    ...(regionIds && { regionIds }),
                    ...(biddingZoneIds && { biddingZoneIds }),
                    ...(siteIds && { siteIds }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getHourlyAggregatedMeterDataByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
            biddingZoneIds,
            siteIds,
            granularity,
        }: GetHourlyAggregatedMeterDataByTenantIdArgs): OverloadApiResponse<
            Array<MeterDataDto.HourlyAggregatedMeterData>
        > => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/hourly-aggregated-meter-data`,
                decoder: hourlyAggregatedMeterDataArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    ...(regionIds && { regionIds }),
                    ...(biddingZoneIds && { biddingZoneIds }),
                    ...(siteIds && { siteIds }),
                    ...(granularity && { granularity }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getDataCompletenessByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
            countryIds,
            biddingZoneIds,
            siteIds,
        }: GetDataCompletenessByTenantIdArgs): OverloadApiResponse<MeterDataDto.DataCompleteness> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/data-completeness`,
                decoder: dataCompletenessDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    ...(regionIds && { regionIds }),
                    ...(countryIds && { countryIds }),
                    ...(biddingZoneIds && { biddingZoneIds }),
                    ...(siteIds && { siteIds }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getAggregatedWarningsPerSiteByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            siteIds,
        }: GetAggregatedWarningsPerSiteByTenantIdArgs): OverloadApiResponse<
            Array<MeterDataDto.AggregatedWarningsPerSite>
        > => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/aggregated-warnings-per-site`,
                decoder: hourlyAggregatedWarningsPerSiteArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    siteIds,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getRegionsByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
        }: GetRegionsByTenantIdArgs): OverloadApiResponse<Array<MeterDataDto.RegionOption>> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/regions`,
                decoder: regionOptionArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getBiddingZonesByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
        }: GetBiddingZonesByTenantIdArgs): OverloadApiResponse<
            Array<MeterDataDto.BiddingZoneOption>
        > => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/bidding-zones`,
                decoder: biddingZoneOptionArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    ...(regionIds && { regionIds }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getSitesByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
            biddingZoneIds,
        }: GetSitesByTenantIdArgs): OverloadApiResponse<Array<MeterDataDto.SiteOption>> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/sites`,
                decoder: siteOptionArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    ...(regionIds && { regionIds }),
                    ...(biddingZoneIds && { biddingZoneIds }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getProvidersByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
            biddingZoneIds,
            siteIds,
        }: GetProvidersByTenantIdArgs): OverloadApiResponse<Array<MeterDataDto.ProviderOption>> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/meter-data/${tenantId}/providers`,
                decoder: providerOptionArrayDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTime),
                    endTimeLocal: convertDateUTCToISOString(
                        new Date(endTime.getTime() + ONE_DAY_MS),
                    ),
                    ...(regionIds && { regionIds }),
                    ...(biddingZoneIds && { biddingZoneIds }),
                    ...(siteIds && { siteIds }),
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
        getConsumptionBySiteByTenantId: async ({
            getAccessToken,
            tenantId,
            startTimeLocal,
            endTimeLocal,
            biddingZoneIds,
            consumptionSiteGroupIds,
            page,
            orderBy,
            orderDir,
            readingTypes,
        }: GetConsumptionBySiteByTenantIdArgs): OverloadApiResponse<MeterDataDto.ConsumptionBySite> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/meter-data/${tenantId}/consumption-by-site`,
                decoder: consumptionBySiteDecoder,
                params: {
                    startTimeLocal: convertDateUTCToISOString(startTimeLocal),
                    endTimeLocal: convertDateUTCToISOString(endTimeLocal),
                    ...(orderBy && { orderBy }),
                    ...(biddingZoneIds && { biddingZoneIds }),
                    ...(orderDir && { orderDir }),
                    ...(consumptionSiteGroupIds && { consumptionSiteGroupIds }),
                    ...(readingTypes && { readingTypes }),
                    page,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },
    };
}
