import { ReactElement } from "react";
import { ScaleOrdinal } from "d3-scale";
import { LegendOrdinal } from "@visx/legend";
import { CSSObject, Flex, Group, MantineTheme } from "@mantine/core";
import { HistogramCurveSeries } from "@flexidao/ui-lib";
import { INACTIVE_LEGEND_OPACITY, LegendDotWithLabel } from "../_utils/utils";

type HistogramLegendProps = {
    colorScale: ScaleOrdinal<string, string>;
    activeSeries: Record<string, boolean>;
    allSeries: Array<HistogramCurveSeries>;
    toggleActiveSeries: (label: string) => void;
    justifyLegend: "start" | "center" | "end";
};

export const HistogramLegend = ({
    colorScale,
    activeSeries,
    allSeries,
    toggleActiveSeries,
    justifyLegend,
}: HistogramLegendProps): ReactElement => {
    return (
        <Flex w="100%" justify={justifyLegend}>
            <LegendOrdinal scale={colorScale}>
                {(labels): ReactElement => (
                    <Group
                        sx={(theme: MantineTheme): CSSObject => ({
                            columnGap: theme.spacing.lg,
                            rowGap: "0.5rem",
                        })}
                    >
                        {labels.map((label, i) => {
                            const isActiveSeries: boolean = activeSeries[label.text] ?? false;

                            const series = allSeries.find(
                                ({ seriesLabel }: HistogramCurveSeries): boolean =>
                                    seriesLabel === label.text,
                            );
                            const labelText: string = series?.seriesLabel ?? "";

                            return (
                                <LegendDotWithLabel
                                    onClick={(): void => {
                                        toggleActiveSeries(label.text);
                                    }}
                                    key={i}
                                    sx={{
                                        opacity: isActiveSeries ? 1 : INACTIVE_LEGEND_OPACITY,
                                        cursor: "pointer",
                                    }}
                                    text={labelText}
                                    color={label.value ?? "black"}
                                />
                            );
                        })}
                    </Group>
                )}
            </LegendOrdinal>
        </Flex>
    );
};
