import { ReactElement, ReactNode } from "react";
import { labelToDataId } from "@flexidao/ui-lib";
import { Button, Group, Modal as MantineModal, Text } from "@mantine/core";
import { getColor, getJsxFromContent } from "./utils";
import { DEFAULT_ACCEPT_TEXT, DEFAULT_CANCEL_TEXT, GREEN_COLOR, RED_COLOR } from "./consts";

type EditContractModalProps = {
    dataId: string;
    title: string;
    content: Array<ReactNode> | ReactNode;
    isOpen: boolean;
    rejectProps: {
        onReject: () => void;
        color?: "green" | "red";
        variant?: "outline" | "filled";
        displayCloseButton?: boolean;
        text?: string;
    };
    acceptProps: {
        onAccept: () => void;
        color?: "green" | "red";
        variant?: "outline" | "filled";
        text?: string;
    };
};

export const Modal = ({
    title,
    content,
    dataId,
    isOpen,
    rejectProps,
    acceptProps,
}: EditContractModalProps): ReactElement => {
    return (
        <MantineModal
            data-id={dataId}
            centered
            size="lg"
            padding="md"
            radius="md"
            opened={isOpen}
            onClose={rejectProps.onReject}
            withCloseButton={rejectProps.displayCloseButton}
            closeOnEscape
            closeOnClickOutside
            title={
                <Text
                    fz="xl"
                    fw="bold"
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "title",
                    })}
                >
                    {title}
                </Text>
            }
        >
            {getJsxFromContent(content)}

            <Group position="right">
                <Button
                    uppercase
                    variant={rejectProps.variant ?? "outline"}
                    color={rejectProps.color ? getColor(rejectProps.color) : RED_COLOR}
                    onClick={rejectProps.onReject}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "cancel-button",
                    })}
                >
                    {rejectProps.text ?? DEFAULT_CANCEL_TEXT}
                </Button>

                <Button
                    uppercase
                    variant={acceptProps.variant ?? "filled"}
                    color={acceptProps.color ? getColor(acceptProps.color) : GREEN_COLOR}
                    onClick={acceptProps.onAccept}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "accept-button",
                    })}
                >
                    {acceptProps.text ?? DEFAULT_ACCEPT_TEXT}
                </Button>
            </Group>
        </MantineModal>
    );
};
