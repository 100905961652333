import { ReactElement, useMemo, useState } from "react";
import { ScaleInput, scaleOrdinal, ScaleTypeToD3Scale } from "@visx/scale";
import { Stack } from "@mantine/core";
import { TickFormatter } from "@visx/axis";
import { labelToDataId } from "@flexidao/ui-lib";
import { AreaChartLegend } from "./legend/legend";
import { Brush } from "./brush/brush";
import { AreaChartCurveSeries } from "./types";
import { AreaChart } from "./chart/chart";
import { Bounds } from "@visx/brush/lib/types";
import { ParentSize } from "@visx/responsive";
import { BRUSH_MARGIN } from "@flexidao/ui-lib/components/data-display/charts/area-chart-with-brush/brush/consts";
import { DateValue } from "@visx/mock-data/lib/generators/genDateValue";
import { getX } from "./utils";

type AreaChartWithBrushProps = {
    chartDataId: string;
    chartHeight: number;
    series: AreaChartCurveSeries;
    xTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["time"]>>;
    brush?: {
        height: number;
    };
    onBrushChange?: (domain: [Date, Date] | null) => void;
    period: [Date, Date];
};

export const AreaChartWithBrush = ({
    chartDataId,
    chartHeight,
    series,
    xTickFormatter,
    brush,
    onBrushChange: onBrushChangeCallback,
    period,
}: AreaChartWithBrushProps): ReactElement => {
    const [filteredPeriod, setFilteredPeriod] = useState<[Date, Date]>(period);
    const [filteredSeries, setFilteredSeries] = useState<AreaChartCurveSeries>(series);

    // Series labels and colors for the legend
    const allSeriesLabelList: Array<string> = [series.seriesLabel];
    const allSeriesColorList: Array<string> = [series.seriesColor];
    const colorScale = scaleOrdinal<string, string>({
        domain: allSeriesLabelList,
        range: allSeriesColorList,
    });

    // Brush variables and methods
    const brushDataId: string = useMemo(
        () =>
            labelToDataId({
                prefix: chartDataId,
                label: "brush",
            }),
        [chartDataId],
    );
    const onBrushClick = (): void => {
        setFilteredSeries(series);
        onBrushChangeCallback?.(null);
    };

    const onBrushChange = (domain: Bounds | null): void => {
        if (!domain) {
            setFilteredPeriod(period);
            onBrushChangeCallback?.(null);
            return;
        }

        const { x0, x1 } = domain;

        const newPeriod: [Date, Date] = [new Date(x0), new Date(x1)];
        setFilteredPeriod(newPeriod);

        const filteredSeriesData: Array<DateValue> = series.seriesData.filter((dateValue) => {
            const x: number = getX(dateValue).getTime();

            return x > x0 && x < x1;
        });

        setFilteredSeries({
            ...filteredSeries,
            seriesData: filteredSeriesData,
        });

        const firstSeriesDate: Date | undefined = filteredSeriesData[0]?.date;
        const lastSeriesDate: Date | undefined =
            filteredSeriesData[filteredSeriesData.length - 1]?.date;

        if (firstSeriesDate && lastSeriesDate) {
            onBrushChangeCallback?.(newPeriod);
        } else {
            onBrushChangeCallback?.(null);
        }
    };

    return (
        <ParentSize style={{ width: "100%" }}>
            {({ width }): ReactElement => (
                <Stack data-id={chartDataId} spacing="xs" pos="relative">
                    <AreaChart
                        period={filteredPeriod}
                        chartDataId={chartDataId}
                        chartHeight={chartHeight}
                        series={filteredSeries}
                        xTickFormatter={xTickFormatter}
                    />

                    <AreaChartLegend
                        colorScale={colorScale}
                        series={series}
                        justifyLegend="start"
                    />

                    {brush && (
                        <AreaChart
                            period={period}
                            chartDataId={brushDataId}
                            chartHeight={brush.height}
                            series={series}
                            xTickFormatter={xTickFormatter}
                            hideAxes
                            showLine={false}
                            enableTooltip={false}
                            chartMargin={BRUSH_MARGIN}
                            chartPadding={{ bottom: 0, left: 0, right: 0, top: 0 }}
                        >
                            <Brush
                                period={period}
                                onBrushClick={onBrushClick}
                                onBrushChange={onBrushChange}
                                series={series}
                                brushHeight={brush.height}
                                brushWidth={width}
                            />
                        </AreaChart>
                    )}
                </Stack>
            )}
        </ParentSize>
    );
};
