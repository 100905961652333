/**
 * @description Method that receives a start and end time and returns a formatted string representing the period
 *
 * The end time is decreased by one day to represent the period correctly
 *
 * @param startTime - The start time
 * @param endTime - The end time
 * @param timezone - The timezone of the period
 *
 * @returns The formatted date period
 */
export const formatDatePeriod = ({
    startTime,
    endTime,
    timezone,
}: {
    startTime: Date;
    endTime: Date;
    timezone?: string;
}): string => {
    const formattedStartTime: string = startTime.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone: timezone,
    });

    const newEndTime: Date = new Date(endTime);
    newEndTime.setDate(newEndTime.getDate() - 1);

    const formattedEndTime: string = newEndTime.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone: timezone,
    });

    return `${formattedStartTime} to ${formattedEndTime}`;
};
