import { FilterOption, labelToDataId } from "@flexidao/ui-lib";
import { ReactElement } from "react";
import { Checkbox } from "@mantine/core";
import { Label } from "./label";

type ListItemProps<T extends string> = {
    dataIdPrefix: string;
    filterOption: FilterOption<T>;
    onFilterOptionCheckToggle: (checked: boolean) => void;
    searchQuery: string;
};

export const ListItem = <T extends string>({
    dataIdPrefix,
    filterOption,
    onFilterOptionCheckToggle,
    searchQuery,
}: ListItemProps<T>): ReactElement => (
    <Checkbox
        p="xs"
        pl="md"
        label={<Label label={filterOption.label} searchQuery={searchQuery} />}
        key={filterOption.key}
        checked={filterOption.checked}
        onChange={(event): void => {
            onFilterOptionCheckToggle(event.currentTarget.checked);
        }}
        data-id={labelToDataId({
            prefix: dataIdPrefix,
            label: filterOption.label,
        })}
    />
);
