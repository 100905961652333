import { ReactElement } from "react";
import { Center, Skeleton, Stack } from "@mantine/core";

export const TableSkeleton = (): ReactElement => {
    const rowMinHeight: number = 30; // 32px - 2px of spacing
    const numRows: number = 11; // 10 rows + 1 header

    return (
        <Stack spacing={2}>
            {Array(numRows)
                .fill(null)
                .map((_, i: number) => (
                    <Center px={16} key={i}>
                        <Skeleton height={rowMinHeight} />
                    </Center>
                ))}
        </Stack>
    );
};
