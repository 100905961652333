import { CSSProperties } from "react";
import { defaultStyles } from "@visx/tooltip";

export const CHART_HEIGHT = 300;
export const CHART_MARGIN = { bottom: 8, left: 0, right: 0, top: 0 };
export const CHART_PADDING = { bottom: 0, left: 0, right: 24, top: 20 };

export const TOOLTIP_STYLES: CSSProperties = {
    ...defaultStyles,
    minWidth: 60,
    pointerEvents: "none",
};

export const NUM_Y_TICKS = 4;
export const NUM_X_TICKS = 4;
