import { ContainerProps, MantineSize, rem } from "@mantine/core";

export const CONTAINER_SIZE_XS = 540;
export const CONTAINER_SIZE_SM = 720;
export const CONTAINER_SIZE_MD = 960;
export const CONTAINER_SIZE_LG = 1140;
export const CONTAINER_SIZE_XL = 1320;

export const containerDefaultProps: Partial<ContainerProps> & {
    sizes: Record<MantineSize, number | string>;
} = {
    sizes: {
        xs: rem(CONTAINER_SIZE_XS),
        sm: rem(CONTAINER_SIZE_SM),
        md: rem(CONTAINER_SIZE_MD),
        lg: rem(CONTAINER_SIZE_LG),
        xl: rem(CONTAINER_SIZE_XL),
    },
};
