import { Brush as VisxBrush } from "@visx/brush";
import {
    BRUSH_HANDLE_WIDTH,
    BRUSH_HEIGHT,
    BRUSH_MARGIN,
    FILL_COLOR,
    FILL_OPACITY,
    STROKE_COLOR,
    STROKE_OPACITY,
    STROKE_WIDTH,
} from "./consts";
import { ReactElement, useMemo } from "react";
import { BrushHandle } from "./brush-handle";
import { Bounds, PartialBrushStartEnd } from "@visx/brush/lib/types";
import { AreaChartCurveSeries } from "@flexidao/ui-lib";
import { DateValue } from "@visx/mock-data/lib/generators/genDateValue";
import { max } from "@visx/vendor/d3-array";
import { scaleLinear, scaleTime } from "@visx/scale";
import { getY } from "../utils";
import { BrushHandleRenderProps } from "@visx/brush/lib/BrushHandle";

type BrushProps = {
    period: [Date, Date];
    onBrushClick: () => void;
    onBrushChange: (bounds: Bounds | null) => void;
    series: AreaChartCurveSeries;
    brushHeight?: number;
    brushWidth: number;
    brushMargin?: { top: number; right: number; bottom: number; left: number };
};

export const Brush = ({
    period: [startDate, endDate],
    onBrushClick,
    onBrushChange,
    series,
    brushHeight = BRUSH_HEIGHT,
    brushWidth,
    brushMargin = BRUSH_MARGIN,
}: BrushProps): ReactElement => {
    const allDataFromSeries: Array<DateValue> = series.seriesData;

    // Domains
    const brushXDomain: [Date, Date] = useMemo(() => [startDate, endDate], [startDate, endDate]);
    const brushYDomain: [number, number] = useMemo(
        () => [0, max(allDataFromSeries, getY) as number],
        [allDataFromSeries],
    );

    const innerWidth: number = useMemo(
        () => Math.max(brushWidth - brushMargin.right - brushMargin.left, 0),
        [brushWidth, brushMargin],
    );

    // Scales
    const brushXScale = useMemo(
        () =>
            scaleTime<number>({
                range: [0, innerWidth],
                domain: brushXDomain,
            }),
        [brushXDomain, innerWidth],
    );
    const brushYScale = useMemo(
        () =>
            scaleLinear({
                range: [brushHeight, 0],
                domain: brushYDomain,
            }),
        [brushYDomain],
    );

    const initialBrushPosition: PartialBrushStartEnd | undefined = useMemo(() => {
        if (allDataFromSeries.length === 0) {
            return undefined;
        }

        return {
            start: { x: brushXScale(startDate) },
            end: { x: brushXScale(endDate) },
        };
    }, [allDataFromSeries, startDate, endDate]);

    const getBrushHandle = (props: BrushHandleRenderProps): ReactElement | null => (
        <BrushHandle {...props} />
    );

    return (
        <VisxBrush
            xScale={brushXScale}
            yScale={brushYScale}
            width={innerWidth}
            height={brushHeight}
            margin={brushMargin}
            handleSize={BRUSH_HANDLE_WIDTH}
            resizeTriggerAreas={["left", "right"]}
            brushDirection="horizontal"
            initialBrushPosition={initialBrushPosition}
            onChange={onBrushChange}
            onClick={onBrushClick}
            selectedBoxStyle={{
                fill: FILL_COLOR,
                fillOpacity: FILL_OPACITY,
                stroke: STROKE_COLOR,
                strokeWidth: STROKE_WIDTH,
                strokeOpacity: STROKE_OPACITY,
            }}
            useWindowMoveEvents
            renderBrushHandle={getBrushHandle}
        />
    );
};
