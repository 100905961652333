import { FilterOption } from "./types";

export const getSortedCheckedKeys = <T extends string>(options: Array<FilterOption<T>>): Array<T> =>
    options
        .filter((value) => value.checked)
        .map((value) => value.key)
        .sort();

export const normalizeString = (str: string): string =>
    str
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase();

export const getNumMatchingOptions = <T extends string>(
    options: Array<FilterOption<T>>,
    searchQuery: string,
): number => {
    const normalizedSearchQuery: string = normalizeString(searchQuery);

    return options.reduce(
        (acc, option) =>
            acc + (normalizeString(option.label).includes(normalizedSearchQuery) ? 1 : 0),
        0,
    );
};

export const sortByMatchAndAlphabetically = <T extends string>(
    options: Array<FilterOption<T>>,
    searchQuery: string,
): Array<FilterOption<T>> => {
    const normalizedSearchQuery: string = normalizeString(searchQuery);

    return [...options].sort((a, b) => {
        if (
            searchQuery.length > 0 &&
            normalizeString(a.label).includes(normalizedSearchQuery) &&
            !normalizeString(b.label).includes(normalizedSearchQuery)
        ) {
            return -1;
        }

        if (
            searchQuery.length > 0 &&
            !normalizeString(a.label).includes(normalizedSearchQuery) &&
            normalizeString(b.label).includes(normalizedSearchQuery)
        ) {
            return 1;
        }

        return a.label.localeCompare(b.label);
    });
};

const escapeRegExp = (str: string): string => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape all special regex characters
};

export const getNormalizedParts = (label: string, searchQuery: string): Array<string> => {
    if (searchQuery.length === 0 || label.length === 0) {
        return [label];
    }

    const normalizedLabel: string = normalizeString(label);
    const normalizedSearchQuery: string = normalizeString(searchQuery);
    const escapedNormalizedSearchQuery: string = escapeRegExp(normalizedSearchQuery);

    return normalizedLabel
        .split(new RegExp(`(${escapedNormalizedSearchQuery})`, "g"))
        .filter((s) => s.length > 0);
};
