import { Divider } from "@mantine/core";
import { ReactElement } from "react";

export type KpiSectionDividerProps = {
    verticalPadding?: number | string;
    dataId?: string;
};

export const KpiSectionDivider = ({
    verticalPadding = 0,
    dataId,
}: KpiSectionDividerProps): ReactElement => {
    return <Divider my={verticalPadding} data-id={dataId} />;
};
