import { ReactElement } from "react";

export const FlexidaoLogoDarkFill = ({ height }: { height: number }): ReactElement => (
    <svg
        height={height}
        style={{ marginTop: 6 }}
        viewBox="0 0 130 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.6875 12.6908H26.6181V14.758H18.9146V20.9079H26.4597V22.9765H18.9146V31.2467H16.6875V12.6908Z"
            fill="#000722"
        />
        <path
            d="M36.8478 25.309C36.9008 26.6171 37.3076 27.668 38.0681 28.4632C38.8272 29.2584 39.887 29.6563 41.2489 29.6563C41.8672 29.6563 42.4059 29.5863 42.8658 29.4448C43.3242 29.3033 43.7181 29.1081 44.0446 28.8611C44.3711 28.6142 44.6364 28.3265 44.8411 27.9993C45.0432 27.6728 45.2064 27.3238 45.3316 26.9524L47.2927 27.4823C46.9226 28.7013 46.237 29.6958 45.2384 30.4644C44.2398 31.2331 42.893 31.6181 41.1958 31.6181C40.2591 31.6181 39.3973 31.4535 38.6116 31.127C37.8246 30.8005 37.1484 30.3366 36.5832 29.7366C36.0179 29.1353 35.5764 28.4197 35.2581 27.5891C34.9397 26.7579 34.7812 25.8389 34.7812 24.8322V24.1955C34.7812 23.2765 34.9445 22.4201 35.2717 21.6249C35.5982 20.8297 36.0533 20.1447 36.6369 19.5699C37.2205 18.9965 37.9042 18.5462 38.6912 18.2176C39.4782 17.8911 40.3299 17.7272 41.2502 17.7272C42.3277 17.7272 43.2603 17.9258 44.046 18.3237C44.833 18.721 45.4778 19.2121 45.9812 19.7944C46.4846 20.378 46.856 21.0147 47.0947 21.7031C47.3335 22.3929 47.4532 23.0282 47.4532 23.6118V25.309H36.8491H36.8478ZM41.2489 19.6896C40.6652 19.6896 40.1217 19.7815 39.6183 19.9678C39.115 20.1529 38.6728 20.4148 38.2933 20.7494C37.913 21.0848 37.6035 21.4779 37.3648 21.9296C37.126 22.3799 36.9716 22.8704 36.9015 23.4003H45.384C45.3486 22.835 45.2119 22.3228 44.9738 21.863C44.735 21.4031 44.4255 21.0147 44.0453 20.6964C43.665 20.378 43.2331 20.1311 42.7467 19.9542C42.261 19.7774 41.7617 19.6889 41.2495 19.6889L41.2489 19.6896Z"
            fill="#000722"
        />
        <path
            d="M59.0385 18.0985H61.3976L57.5278 24.3546V24.6716L61.7159 31.246H59.3038L55.7245 25.4667H54.6633L51.084 31.246H48.6719L52.8607 24.6716V24.3546L48.9902 18.0985H51.3493L54.6633 23.5587H55.7245L59.0385 18.0985Z"
            fill="#000722"
        />
        <path
            d="M80.3847 29.2319H80.0664C79.8535 29.64 79.5848 29.9917 79.2576 30.2931C78.9297 30.593 78.576 30.84 78.1971 31.0352C77.8162 31.2297 77.4155 31.3753 76.9904 31.4719C76.5666 31.5685 76.1598 31.6181 75.7714 31.6181C74.8864 31.6181 74.0647 31.4671 73.3056 31.1665C72.5458 30.8665 71.8771 30.4339 71.3037 29.8679C70.7289 29.3026 70.2786 28.6006 69.9514 27.7606C69.6249 26.9218 69.4609 25.962 69.4609 24.8846V24.4594C69.4609 23.3989 69.629 22.4493 69.9643 21.6099C70.2997 20.7699 70.7513 20.0631 71.3166 19.4897C71.8819 18.9149 72.5451 18.4775 73.3049 18.1762C74.064 17.8762 74.8687 17.7258 75.717 17.7258C76.6176 17.7258 77.4625 17.9245 78.2481 18.3224C79.0351 18.7197 79.6399 19.3346 80.065 20.1645H80.3834V12.6908H82.4506V31.2467H80.3834V29.2312L80.3847 29.2319ZM75.9836 29.657C76.602 29.657 77.1761 29.5421 77.706 29.3122C78.2359 29.0836 78.7012 28.7557 79.0977 28.332C79.4957 27.9068 79.8092 27.4 80.0392 26.8069C80.2677 26.2151 80.384 25.558 80.384 24.8329V24.5145C80.384 23.8078 80.2677 23.1588 80.0392 22.5657C79.8092 21.9739 79.4957 21.4657 79.0977 21.042C78.7005 20.6168 78.2325 20.2869 77.6924 20.0468C77.1536 19.808 76.5836 19.6897 75.9836 19.6897C75.3837 19.6897 74.7851 19.8046 74.247 20.0345C73.7083 20.2631 73.2403 20.5903 72.8417 21.0147C72.4444 21.4385 72.1349 21.9419 71.9145 22.5255C71.6928 23.1092 71.5826 23.754 71.5826 24.4608V24.8859C71.5826 25.628 71.6928 26.2899 71.9145 26.8735C72.1349 27.4572 72.4444 27.9572 72.8417 28.3721C73.2396 28.787 73.7035 29.1054 74.2334 29.3258C74.7633 29.5475 75.347 29.6577 75.9836 29.6577V29.657Z"
            fill="#000722"
        />
        <path
            d="M95.8378 29.2319H95.5195C95.3066 29.64 95.0379 29.9917 94.7107 30.293C94.3828 30.593 94.0291 30.8399 93.6502 31.0352C93.2693 31.2297 92.8686 31.3753 92.4435 31.4719C92.0197 31.5685 91.613 31.6181 91.2245 31.6181C90.3396 31.6181 89.5179 31.4671 88.7587 31.1665C87.9989 30.8665 87.3302 30.4338 86.7568 29.8679C86.182 29.3026 85.7317 28.6006 85.4045 27.7606C85.078 26.9218 84.9141 25.962 84.9141 24.8845V24.4594C84.9141 23.3989 85.0821 22.4493 85.4174 21.6099C85.7528 20.7699 86.2045 20.0631 86.7697 19.4897C87.335 18.9149 87.9982 18.4775 88.758 18.1761C89.5172 17.8762 90.3219 17.7258 91.1701 17.7258C92.0707 17.7258 92.9156 17.9245 93.7012 18.3224C94.4883 18.7196 95.093 19.3346 95.5181 20.1644H95.8365V18.185H97.9037V31.2454H95.8365V29.2298L95.8378 29.2319ZM91.4368 29.657C92.0551 29.657 92.6292 29.5421 93.1591 29.3122C93.689 29.0836 94.1543 28.7557 94.5508 28.3319C94.9488 27.9068 95.2624 27.4 95.4923 26.8069C95.7208 26.2151 95.8372 25.558 95.8372 24.8329V24.5145C95.8372 23.8077 95.7208 23.1588 95.4923 22.5657C95.2624 21.9739 94.9488 21.4657 94.5508 21.0419C94.1536 20.6168 93.6856 20.2869 93.1455 20.0468C92.6068 19.808 92.0367 19.6896 91.4368 19.6896C90.8368 19.6896 90.2382 19.8046 89.7001 20.0345C89.1614 20.2631 88.6934 20.5903 88.2948 21.0147C87.8975 21.4385 87.588 21.9419 87.3676 22.5255C87.1459 23.1092 87.0357 23.754 87.0357 24.4608V24.8859C87.0357 25.628 87.1459 26.2899 87.3676 26.8735C87.588 27.4572 87.8975 27.9571 88.2948 28.3721C88.6927 28.787 89.1566 29.1054 89.6865 29.3258C90.2164 29.5475 90.8001 29.6577 91.4368 29.6577V29.657Z"
            fill="#000722"
        />
        <path
            d="M113.311 24.8322C113.311 25.9273 113.13 26.896 112.768 27.7347C112.404 28.5748 111.923 29.2815 111.322 29.855C110.722 30.4297 110.019 30.8671 109.215 31.1671C108.41 31.4671 107.575 31.6188 106.71 31.6188C105.844 31.6188 104.987 31.4678 104.192 31.1671C103.396 30.8671 102.698 30.4297 102.097 29.855C101.496 29.2815 101.015 28.5741 100.653 27.7347C100.29 26.896 100.109 25.9273 100.109 24.8322V24.5138C100.109 23.4356 100.29 22.4765 100.653 21.6378C101.015 20.7977 101.5 20.0862 102.11 19.5026C102.72 18.9203 103.422 18.4788 104.217 18.1775C105.012 17.8775 105.844 17.7272 106.71 17.7272C107.576 17.7272 108.407 17.8775 109.202 18.1775C109.997 18.4788 110.699 18.921 111.309 19.5026C111.919 20.0862 112.404 20.7977 112.768 21.6378C113.13 22.4765 113.311 23.435 113.311 24.5138V24.8322ZM106.71 29.6563C107.346 29.6563 107.933 29.5461 108.473 29.3244C109.012 29.104 109.484 28.7809 109.89 28.3571C110.297 27.932 110.615 27.4252 110.845 26.832C111.074 26.2402 111.19 25.5729 111.19 24.8315V24.5131C111.19 23.8064 111.074 23.1574 110.845 22.5643C110.615 21.9725 110.297 21.4643 109.89 21.0406C109.484 20.6154 109.007 20.2855 108.46 20.0454C107.912 19.8066 107.328 19.6883 106.71 19.6883C106.091 19.6883 105.508 19.8066 104.961 20.0454C104.412 20.2848 103.936 20.6154 103.529 21.0406C103.122 21.4643 102.804 21.9725 102.575 22.5643C102.345 23.1574 102.23 23.8064 102.23 24.5131V24.8315C102.23 25.5736 102.345 26.2402 102.575 26.832C102.804 27.4252 103.122 27.932 103.529 28.3571C103.936 28.7809 104.408 29.104 104.947 29.3244C105.486 29.5461 106.074 29.6563 106.71 29.6563Z"
            fill="#000722"
        />
        <path
            d="M65.5263 15.547C65.9181 15.547 66.2521 15.413 66.5296 15.1362C66.816 14.8498 66.95 14.5151 66.95 14.1233C66.95 13.7315 66.8167 13.388 66.5296 13.1104C66.2528 12.8336 65.9181 12.6901 65.5263 12.6901C65.1345 12.6901 64.791 12.8336 64.5141 13.1104C64.2278 13.3873 64.0938 13.722 64.0938 14.1233C64.0938 14.5246 64.2278 14.8491 64.5141 15.1362C64.791 15.413 65.1257 15.547 65.5263 15.547Z"
            fill="#000722"
        />
        <path
            d="M66.5616 29.1794V20.1657V18.0985V18.0951H64.4917V18.0985H63.5781V20.1657H64.4917V29.1794V31.2453V31.2466H67.4752V29.1794H66.5616Z"
            fill="#000722"
        />
        <path
            d="M31.8826 29.1795V14.758V12.6908H29.812H28.8984V14.758H29.812V29.1795V31.2454V31.2467H32.7961V29.1795H31.8826Z"
            fill="#000722"
        />
    </svg>
);
