import { ReactElement, useMemo, useState } from "react";
import {
    CSSObject,
    Divider,
    Group,
    MantineTheme,
    Paper,
    Popover,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { formatDate, IconCalendar, labelToDataId, TWO_YEAR_MS } from "@flexidao/ui-lib";
import { DateRangeOption } from "./types";
import { getDateRangeFromDateRangeOption, getDateRangeOptionFromDateRange } from "./utils";
import { DateRangeActions } from "./sections/date-range-actions";
import { DateRangePresets } from "./sections/date-range-presets";
import { DateRangeCalendar } from "./sections/date-range-calendar";
import { DateRangeHeader } from "./sections/date-range-header";

type DateRangeFilterProps = {
    title: string;
    initialDateRange: [Date, Date];
    handleApply: (dateRange: [Date, Date]) => void;
    dataId?: string;
    disabled?: boolean;
    maxRangeMs?: number;
    dateRangeOptions?: Array<DateRangeOption>;
};

export const DateRangeFilter = ({
    title,
    initialDateRange,
    handleApply,
    dataId = "date-range-picker",
    disabled = false,
    maxRangeMs = TWO_YEAR_MS,
    dateRangeOptions = [DateRangeOption.LastYear, DateRangeOption.YearToDate],
}: DateRangeFilterProps): ReactElement => {
    const initialDateRangeOption: DateRangeOption = useMemo(
        () => getDateRangeOptionFromDateRange(initialDateRange),
        [initialDateRange],
    );

    // Local state for the date range picker:
    //   - datePickerFilterOpen is a boolean that indicates if the date range picker is open
    //   - selectedDateRange is the date range selected by the user
    //   - selectedDateRangeOption is the date range option selected by the user
    const [datePickerFilterOpen, setDatePickerFilterOpen] = useState<boolean>(false);
    const [selectedDateRange, setSelectedDateRange] =
        useState<[Date | null, Date | null]>(initialDateRange);
    const [selectedDateRangeOption, setSelectedDateRangeOption] =
        useState<DateRangeOption>(initialDateRangeOption);

    // If the selected period changes, update the selected date range option
    const handleSelectedDateRangeChange = (newDateRange: [Date | null, Date | null]): void => {
        setSelectedDateRange(newDateRange);
        setSelectedDateRangeOption(getDateRangeOptionFromDateRange(newDateRange));
    };

    // If the selected date range option changes, update the selected date range
    const handleDateRangeOptionSelected = (newDateRangeOption: DateRangeOption): void => {
        setSelectedDateRangeOption(newDateRangeOption);

        if (newDateRangeOption !== DateRangeOption.Custom) {
            const newDateRange: [Date, Date] = getDateRangeFromDateRangeOption(newDateRangeOption);
            setSelectedDateRange(newDateRange);
        }
    };

    const handleDatePickerChange = (opened: boolean): void => {
        setSelectedDateRange(initialDateRange);

        setSelectedDateRangeOption(
            getDateRangeOptionFromDateRange([initialDateRange[0], initialDateRange[1]]),
        );

        setDatePickerFilterOpen(opened);
    };

    const handleDateApplied = (): void => {
        if (selectedDateRange[0] == null || selectedDateRange[1] == null) {
            return;
        }

        handleApply([selectedDateRange[0], selectedDateRange[1]]);
        setDatePickerFilterOpen(!datePickerFilterOpen);
    };

    const handleDateCleared = (): void => {
        setSelectedDateRange([null, null]);
        setSelectedDateRangeOption(DateRangeOption.Custom);
    };

    return (
        <Popover
            opened={datePickerFilterOpen}
            position="bottom-start"
            onChange={handleDatePickerChange}
            closeOnClickOutside
            closeOnEscape
        >
            <Popover.Target>
                <Paper
                    onClick={
                        disabled
                            ? undefined
                            : (): void => {
                                  setDatePickerFilterOpen(!datePickerFilterOpen);
                              }
                    }
                    px={12}
                    py={4}
                    bg={disabled ? "flexidaoGrey.1" : "white"}
                    sx={{
                        cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "target",
                    })}
                >
                    <Group>
                        <Stack spacing={2}>
                            <Title fz="12px" lh="14px" fw={600}>
                                {title}
                            </Title>
                            <Text fz="12px" lh="14px" size="sm" c="flexidaoGrey.8">
                                {formatDate(initialDateRange[0])}&nbsp;-&nbsp;
                                {formatDate(initialDateRange[1])}&nbsp;
                            </Text>
                        </Stack>

                        <IconCalendar size={16} />
                    </Group>
                </Paper>
            </Popover.Target>

            <Popover.Dropdown
                p={0}
                sx={(theme: MantineTheme): CSSObject => ({
                    border: "none",
                    borderRadius: theme.radius.md,
                })}
            >
                <Paper
                    p={0}
                    sx={(): CSSObject => ({
                        width: "fit-content",
                    })}
                    data-id={labelToDataId({
                        prefix: dataId,
                        label: "dropdown",
                    })}
                >
                    <DateRangeHeader selectedDateRange={selectedDateRange} dataId={dataId} />

                    <Divider />

                    <Group
                        align="flex-start"
                        sx={{
                            gap: "0",
                            columnGap: "0",
                        }}
                    >
                        <DateRangePresets
                            selectedDateRange={selectedDateRange}
                            dateRangeOptions={dateRangeOptions}
                            selectedDateRangeOption={selectedDateRangeOption}
                            handleDateRangeOptionSelected={handleDateRangeOptionSelected}
                        />

                        <Divider orientation="vertical" />

                        <DateRangeCalendar
                            selectedDateRange={selectedDateRange}
                            maxRangeMs={maxRangeMs}
                            handleSelectedDateRangeChange={handleSelectedDateRangeChange}
                        />
                    </Group>

                    <Divider />

                    <DateRangeActions
                        selectedDateRange={selectedDateRange}
                        initialDateRange={initialDateRange}
                        handleDateCleared={handleDateCleared}
                        handleDateApplied={handleDateApplied}
                    />
                </Paper>
            </Popover.Dropdown>
        </Popover>
    );
};
